interface IRow {
  nome: string;
  modelo: string;
  dataCriacao: string;
  html: string;
}

const columns = [
  { title: 'Nome do projeto', field: 'descricao' },
  {
    title: 'Tipo de modelo',
    field: 'modelo',
    render: (rowData: IRow) => {
      if (rowData.modelo === 'ModeloQRCode') {
        return 'Modelo QR Code';
      } else if (rowData.modelo === 'ModeloLinhaDoTempo') {
        return 'Modelo Linha do tempo';
      } else if (rowData.modelo === 'TabelaComparativa') {
        return 'Modelo Tabela';
      } else if (rowData.modelo === 'ModeloFluxograma') {
        return 'Modelo Fluxograma';
      } else if (rowData.modelo === 'ModeloGrafico') {
        return 'Modelo Gráfico';
      } else if (rowData.modelo === 'ModeloLinguagemSimples') {
        return 'Modelo Texto Aprimorado';
      } else if (rowData.modelo === 'ModeloEmenta') {
        return 'Modelo Resumo Jurídico';
      } else if (rowData.modelo === 'ModeloBibliotecaIcones') {
        return 'Modelo Banco de Ícones';
      }
    },
  },
  {
    title: 'Data de criação',
    field: 'dataCriacao',
    render: (rowData: IRow) => rowData?.dataCriacao && new Date(rowData?.dataCriacao).toLocaleDateString('pt-BR'),
  },
];
export default columns;
