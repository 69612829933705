import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(Paper)`
  width: 450px;
  max-width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;

  h1 {
    margin: 0px;
    color: #3e4756;
  }

  h2 {
    color: #3e4756;
  }

  p {
    color: #3e4756;
  }

  button {
    height: 53px;
    border-radius: 5px;
  }

  .btnSalvar {
    background-color: #3fced2;
    color: #fff;
  }

  .MuiTextField-root {
    margin-bottom: 30px;
  }
`;
