import styled from 'styled-components';

export const ImageCardContainer = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  height: 205px;
  width: 97%;
  background-color: white;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: 'contain';
  }
`;
