/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  .MuiFormControl-root {
    height: 100%;
  }

  .MuiTable-root,
  .MuiInputBase-root,
  .MuiTableCell-head {
    color: #3e4756;
  }
`;

export const GreenText = styled.span`
  color: #3fced2;
`;

export const RedText = styled.span`
  color: #f1504d;
`;

export const SelectBetweenTables = styled.div`
  border-radius: 5px;
  width: 320px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
  color: #3e4756;
  p:nth-child(1) {
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #e6e6e6;
    background: transparent;
    ${({ selectedTitle }) =>
      selectedTitle === 'InternalUsers' &&
      `
    color: #3FCED2;
    background:#F1F8FF;
    border: 1px solid #3FCED2;
    `}
    width: 100%;
    padding: 8px;
    text-align: center;
  }
  p:nth-child(2) {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #e6e6e6;

    ${({ selectedTitle }) =>
      selectedTitle !== 'InternalUsers' &&
      `
    background:#F1F8FF;
    color: #3FCED2;
    border: 1px solid #3FCED2;
    `}
    width: 100%;
    padding: 8px;
    text-align: center;
  }
`;

export const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    fontWeight: 400,
    backgroundColor: '#3FCED2',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
  svg: {
    marginRight: 8,
  },
});
