import React from 'react';
import { Button } from '@material-ui/core';
import { Content } from '../styles';
import Logo from '../../../assets/Logo-large.svg';
import { useHistory } from 'react-router';

interface IProps {
  email?: string;
}
const ResquestSent = ({ email }: IProps): React.ReactElement => {
  const history = useHistory();
  function handleClick() {
    history.push('/login');
  }
  return (
    <Content elevation="5" style={{ padding: 50 }}>
      <img src={Logo} className="logo" />
      <h2>Solicitação enviada</h2>
      <p>
        Enviamos a solicitação para o e-mail: <strong>{email}</strong>
      </p>
      <Button className="btnVoltarLogin" variant="contained" fullWidth onClick={handleClick}>
        Voltar para o login
      </Button>
    </Content>
  );
};

export default ResquestSent;
