export const formatAccessProfileCheckboxesByUserPermissions = (data, arrayPermissions) => {
  let aux = JSON.parse(JSON.stringify(arrayPermissions));

  aux.map((arr) => {
    if (!(arr.view && data.find((item) => item?.permission?.value.includes(arr.view.value)))) {
      arr.view = { id: null, value: arr.view.value };
    }
    if (!(arr.edit && data.find((item) => item.permission?.value.includes(arr.edit.value)))) {
      arr.edit = { id: null, value: arr.edit.value };
    }
    if (!(arr.insert && data.find((item) => item.permission?.value.includes(arr.insert.value)))) {
      arr.insert = { id: null, value: arr.insert.value };
    }
    if (!(arr.delete && data.find((item) => item.permission?.value.includes(arr.delete.value)))) {
      arr.delete = { id: null, value: arr.delete.value };
    }
    return arr;
  });
  return aux;
};

export const formatAllAccessProfileCheckboxesPermissions = (permissions) => {
  const Home = filterByAccessName([...permissions], 'Home', 'Home');
  const InternalUsers = filterByAccessName([...permissions], 'InternalUsers', 'Usuários internos');
  const ExternalUsers = filterByAccessName([...permissions], 'ExternalUsers', 'Usuários externos');
  const Roles = filterByAccessName([...permissions], 'Roles', 'Perfis de acesso');
  const Plans = filterByAccessName([...permissions], 'Plans', 'Planos');

  return [Home, InternalUsers, ExternalUsers, Roles, Plans];
};

export const filterByAccessName = (data, value, name) => {
  const dataToFilter = [...data];
  const permission = dataToFilter.filter((item) => item.value.includes(value));

  const objectFormatted = { name, view: {}, insert: {}, edit: {}, delete: {} };
  permission.map((item) => {
    if (item.value.includes('Read')) {
      objectFormatted.view = item;
    } else if (item.value.includes('Create')) {
      objectFormatted.insert = item;
    } else if (item.value.includes('Update')) {
      objectFormatted.edit = item;
    } else if (item.value.includes('Delete')) {
      objectFormatted.delete = item;
    }
  });

  const obj = {
    name: objectFormatted.name,
    insert: objectFormatted.insert,
    edit: objectFormatted.edit,
    delete: objectFormatted.delete,
    view: objectFormatted.view,
  };

  return obj;
};
