import { v4 as uuidv4 } from 'uuid';

/* eslint-disable prettier/prettier */
export const formatVerticalData = (arr, setData = () => { }) => {
  let flowChartElements = [];
  let x = 80;
  let y = 10;
  let ElementsByLevel = 1;
  let LevelAmount = 1;
  let auxData = [...arr];
  let dataPositionHasChanged = false;
  auxData.map((level, indexLevel, arrayLevels) => {
    LevelAmount = LevelAmount < arrayLevels.length ? arrayLevels.length : LevelAmount; // verify how many levels it has

    level.map((item, indexElement, arrayElements) => {
      ElementsByLevel = ElementsByLevel < arrayElements.length ? arrayElements.length : ElementsByLevel; // verify how many elements a level has
      flowChartElements.push({
        id: item.id || uuidv4(),
        type: existeLigacaoEntreElementoMesmoNivel(item, indexLevel, auxData) ? "custom" : "default",
        position: item.VerticalPosition ? item.VerticalPosition : { x, y },
        data: { label: item.description },
        className: `card${indexLevel}${indexElement}`,
        style: {
          //backgroundColor: item.color + '66',
          color: item.color,
          border: `2px solid ${item.color}`,
          borderRadius: 10,
          lineBreak: 'auto',
          width: 'min-content',
          minWidth: '175px',
          fontSize: 18,
          fontWeight: 'bold',
          padding: '10px', //Padding Fluxograma 21px 16px
          alignItens: 'center',
        },
      });
      if (item?.parents?.length > 0) {
        item.parents.map((parent) => {
          if (parentMesmoNivel(parent, indexLevel, auxData)) {
            flowChartElements.push({
              id: uuidv4(),
              source: item.id,
              target: parent,
              type: 'step',
              sourceHandle: 'right',
              targetHandle: 'left'
            })
          } else {
            flowChartElements.push({
              id: uuidv4(),
              source: parent,
              target: item.id,
              type: 'step',
            });
          }
        });
      }

      if (!item.VerticalPosition) {
        auxData[indexLevel][indexElement].VerticalPosition = { x, y };
        dataPositionHasChanged = true;
      }
      x += 200;
    });
    y += 220;
    x = 80;
  });
  if (dataPositionHasChanged) setData(auxData);
  const size = findLargerWidthAndHeight(flowChartElements);
  return { elementsFormatted: flowChartElements, width: size.x + 250, height: size.y + 220 };
};

function existeLigacaoEntreElementoMesmoNivel(item, indexLevel, auxData) {
  let mesmoNivel = false;

  if (item?.parents?.length > 0) {
    item.parents.map((parent) => { 
      if (!mesmoNivel) {
        mesmoNivel = auxData[indexLevel].filter(elementLevel => elementLevel.id === parent).length > 0;
      }     
    });
  }

  if (!mesmoNivel) {
    auxData[indexLevel].map(elementLevel => {  
      if (elementLevel.parents?.length > 0 && !mesmoNivel) {
          mesmoNivel =  elementLevel.parents.filter(parent => parent == item.id).length > 0;
      }
    });
  }

  return mesmoNivel;
}

function parentMesmoNivel(itemId, indexLevel, auxData) {
  let mesmoNivel = false;

  auxData[indexLevel].map(elementLevel => {

    if (!mesmoNivel && elementLevel.id === itemId) {
      mesmoNivel = true;
    }
  });

  return mesmoNivel;
}

const findLargerWidthAndHeight = (flowChartElements) => {
  let x = 0;
  let y = 0;
  flowChartElements.map((f) => {
    if (f.position?.x > x) {
      x = f.position.x;
    }
    if (f.position?.y > y) {
      y = f.position.y;
    }
  });
  return { x, y };
};

export const formatHorizontalData = (arr, setData = () => { }) => {
  let flowChartElements = [];
  let x = 80;
  let y = 10;
  let ElementsByLevel = 1;
  let LevelAmount = 1;
  let auxData = [...arr];
  let dataPositionHasChanged = false;
  auxData.map((level, indexLevel, arrayLevels) => {
    LevelAmount = LevelAmount < arrayLevels.length ? arrayLevels.length : LevelAmount; // verify how many levels it has

    level.map((item, indexElement, arrayElements) => {
      ElementsByLevel = ElementsByLevel < arrayElements.length ? arrayElements.length : ElementsByLevel; // verify how many elements a level has
      flowChartElements.push({
        id: item.id || uuidv4(),
        position: item.HorizontalPosition ? item.HorizontalPosition : { x, y },
        data: { label: item.description },
        className: `card${indexLevel}${indexElement}`,
        style: {
          //backgroundColor: item.color + '66',
          color: item.color,
          border: `2px solid ${item.color}`,
          borderRadius: 10,
          fontSize: 18,
          fontWeight: 'bold',
          padding: '10px', //Padding Fluxograma 21px 16px
          width: 'min-content',
          minWidth: '175px',
          alignItens: 'center',
        },
        sourcePosition: 'right',
        targetPosition: 'left',
      });
      if (item?.parents?.length > 0) {
        item.parents.map((parent) => {
          flowChartElements.push({
            id: uuidv4(),
            source: parent,
            target: item.id,
            type: 'step',
          });
        });
      }

      if (!item.HorizontalPosition) {
        auxData[indexLevel][indexElement].HorizontalPosition = { x, y };
        dataPositionHasChanged = true;
      }
      y += 220;
    });
    y = 10;
    x += 210;
  });
  if (dataPositionHasChanged) setData(auxData);
  let size = findLargerWidthAndHeight(flowChartElements);

  return { elementsFormatted: flowChartElements, width: size.x + 350, height: size.y + 220 };
};
