import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { Buttons, Container, Content, TopContent, useStyles } from './styles';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ModalForgotPassword from '../ModalForgotPassword';
import { useLoading } from '../../contexts/loading';
import { useAlert } from '../../contexts/alert';
import { changePassword } from '../../services/auth';
import { useAuth } from '../../contexts/auth';

const ModalChangePassword: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(true);
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user } = useAuth();

  const changePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required('Senha atual obrigatória'),
    newPassword: Yup.string()
      .required('Nova senha obrigatória')
      .notOneOf([Yup.ref('currentPassword'), null], 'A nova senha não pode ser igual a atual')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Senha deve conter no mínimo 8 dígitos, máximo 20 dígitos, letras maiúsculas, minusculas, números e carácter especial'
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'A senha não corresponde')
      .required('Confirmação de senha é obrigatória.'),
  });

  const formik = useFormik({
    initialValues: {
      userId: user?.tokenData?.UserId,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      showLoading(true);
      changePassword(formik.values)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Alteração efetuada com sucesso.');
        })
        .catch((error) => {
          showLoading(false);
          showAlertError('Senha atual incorreta');
        });
    },
  });

  return (
    <>
      <Container elevation={1}>
        <Modal
          style={{ border: 'none' }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              height: 360,
              backgroundColor: '#FFF',
              border: 'none',
            }}
          >
            <ModalForgotPassword handleClose={handleClose} />
          </Box>
        </Modal>
        <form onSubmit={formik.handleSubmit}>
          <TopContent>
            <span className="color-text">Alterar senha</span>
          </TopContent>
          <Content>
            <div className="inputs">
              <TextField
                variant="outlined"
                label="Senha atual"
                fullWidth
                id="currentPassword"
                type={!isPasswordVisible ? 'text' : 'password'}
                name="currentPassword"
                autoFocus
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                helperText={formik?.errors?.currentPassword}
                onKeyDown={(event) => event.key === 'Enter' && formik.handleSubmit()}
                InputProps={{
                  endAdornment: !isPasswordVisible ? (
                    <VisibilityOutlined onClick={changePasswordVisibility} />
                  ) : (
                    <VisibilityOffOutlined onClick={changePasswordVisibility} />
                  ),
                }}
              />
              <p className={'forgot'} onClick={handleOpen}>
                Esqueci minha senha
              </p>
              <TextField
                variant="outlined"
                label="Nova senha"
                fullWidth
                id="newPassword"
                type={!isPasswordVisible ? 'text' : 'password'}
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik?.errors?.newPassword}
                onKeyDown={(event) => event.key === 'Enter' && formik.handleSubmit()}
                InputProps={{
                  endAdornment: !isPasswordVisible ? (
                    <VisibilityOutlined onClick={changePasswordVisibility} />
                  ) : (
                    <VisibilityOffOutlined onClick={changePasswordVisibility} />
                  ),
                }}
              />
              <TextField
                variant="outlined"
                label="Confirme a nova senha"
                fullWidth
                id="confirmNewPassword"
                type={!isPasswordVisible ? 'text' : 'password'}
                name="confirmNewPassword"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                helperText={formik?.errors?.confirmNewPassword}
                onKeyDown={(event) => event.key === 'Enter' && formik.handleSubmit()}
                InputProps={{
                  endAdornment: !isPasswordVisible ? (
                    <VisibilityOutlined onClick={changePasswordVisibility} />
                  ) : (
                    <VisibilityOffOutlined onClick={changePasswordVisibility} />
                  ),
                }}
              />
            </div>
          </Content>

          <Buttons>
            <Button
              className={classes.buttonRed}
              variant="outlined"
              onClick={() => {
                history.push(user?.tokenData?.UserType == 'Internal User' ? '/minha-conta' : '/meus-dados');
              }}
            >
              Cancelar
            </Button>
            <Button className={classes.buttonGreen} variant="contained" type="submit">
              Salvar
            </Button>
          </Buttons>
        </form>
      </Container>
    </>
  );
};

export default ModalChangePassword;
