import React from 'react';
import Modal from '@mui/material/Modal';
import { ContentModal } from './styles';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
interface IProps {
  open: boolean;
  text: string;
  onCancel: () => void;
  onSave: () => void;
  showActions?: boolean;
}

const ModalOptions = ({ open, text, onCancel, onSave, showActions = true }: IProps): React.ReactElement => {
  return (
    <>
      <Modal open={open} onClose={onCancel} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ContentModal>
          {!showActions && (
            <span style={{ alignSelf: 'end', cursor: 'pointer' }} onClick={onCancel}>
              <CloseIcon />
            </span>
          )}
          <p>{text}</p>
          {showActions && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button variant="outlined" onClick={onCancel} style={{ color: '#3FCED2', borderColor: '#3FCED2' }}>
                Não
              </Button>
              <Button style={{ marginLeft: 10, backgroundColor: '#3FCED2' }} variant="contained" onClick={onSave}>
                Sim
              </Button>
            </div>
          )}
        </ContentModal>
      </Modal>
    </>
  );
};

export default React.memo(ModalOptions);
