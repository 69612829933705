import styled from 'styled-components';
import { Card } from '@material-ui/core';

export const Content = styled(Card)`
  max-width: 345px;
  height: 230px;
  cursor: pointer;

  .btnYouTube {
    color: #c4302b;
  }

  .btnYouTube:hover {
    color: #c4302b94;
  }

  .background {
    height: 156.38px;
    /* width: 185px; */
    object-fit: cover;
  }

  .titulo {
    color: #3e4756;
    font-family: 'Mosk Semi Bold';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 5px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  :hover {
    scale: calc(1.1);
    transition: 0.3s;
  }
`;
