import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const Container = styled(Paper)`
  padding: 20px;
  width: 100%;
  max-width: 100vw;
  margin-top: 14px;
  .MuiTextField-root {
    height: auto;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    color: #3e4756;
  }

  a {
    margin-right: 10px;
  }
  p {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #9ba3a9;
    font-size: 24px;
    font-family: 'Mosk Semi Bold';

    .color-text {
      font-size: 24px;
      color: #3fced2;
      font-family: 'Mosk Semi Bold';
    }
  }
`;

export const Content = styled.div`
  padding: 20px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  .inputs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .descricao {
      width: 70%;
    }
    .modelo {
      width: 30%;
    }
  }
  .selects {
    display: flex;
    gap: 20px;
    .posicao {
      width: 29%;
    }
    .quebra-linha {
      width: 20%;
    }
    .data-hora {
      width: 20%;
    }
    .ordenacao {
      width: 31%;
    }
  }
`;

export const Download = styled.div`
  width: 100%;
  height: 165px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 40px;
  /* margin: 0; */
  padding: 0;
  .timeline-input {
    display: flex;
    padding: 20px;
    /* height: 80px; */
    width: 100%;
    gap: 20px;
    justify-content: space-between;

    .inputs {
      display: flex;
      width: 100%;
      margin: 0;
      gap: 20px;
    }
    .qr-title {
      min-width: 38%;
      margin-bottom: 20px;
    }
    .info-url {
      min-width: 38%;
      margin-bottom: 20px;
    }
    p.generateQrCode {
      all: unset;
      color: #3fced2;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom: 15px;
      min-width: 11%;
      .qr-icon {
        color: #3fced2;
      }
    }
  }
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
    height: 65px;
    margin: 0;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
      color: #3e4756;
    }
  }

  svg {
    color: #3e4756;
    margin-left: 20px;
  }
`;

export const ShowQRCode = styled.div`
  p {
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
  }
  .qrcode-position {
    display: flex;
    justify-content: center;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 45px;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
