import React, { Fragment, useEffect } from 'react';
import logo from '../../assets/Logo-small.svg';
import { HomeOutlined, PeopleAltOutlined, PersonOutline } from '@material-ui/icons';
import { Menu, MenuItem } from '@mui/material';
import { Fade } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { Content, Header, HeaderContent } from './styles';
import { useAuth } from '../../contexts/auth';

interface IMasterPageProps {
  children: React.ReactElement | React.FC;
}

const MasterPage = ({ children }: IMasterPageProps): React.ReactElement => {
  const history = useHistory();
  const { user, permissions } = useAuth();
  const location = useLocation();

  const selectedMenu = localStorage.getItem('menuSelected');

  useEffect(() => {
    if (location.pathname.includes('/usuarios-internos')) {
      persistMenu('Usuários');
    } else if (location.pathname.includes('/usuarios-externos')) {
      persistMenu('Usuários');
    } else if (location.pathname.includes('/planos')) {
      persistMenu('Plano');
    } else if (location.pathname.includes('/dashboards')) {
      persistMenu('Home');
    }
  }, []);

  const persistMenu = (menuName: string) => {
    localStorage.setItem('menuSelected', menuName);
  };

  const menu = [
    {
      name: 'Home',
      icon: <HomeOutlined />,
      submenu: [],
      access: 'CanReadHome',
      onClick: () => {
        persistMenu('Home');
        history.push('/dashboards');
      },
    },
    {
      name: 'Usuários',
      icon: <PeopleAltOutlined />,
      onClick: () => {},
      access: 'Users',
      submenu: [
        {
          name: 'Externo',
          access: 'CanReadExternalUsers',
          onClick: () => {
            history.push('/usuarios-externos');
          },
        },
        {
          name: 'Interno',
          access: 'CanReadInternalUsers',
          onClick: () => {
            history.push('/usuarios-internos');
          },
        },
      ],
    },
    {
      name: 'Plano',
      icon: <span className="material-icons-outlined">diamond</span>,
      submenu: [],
      access: 'CanReadPlans',
      onClick: () => {
        persistMenu('Plano');
        history.push('/planos');
      },
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<Array<null | (EventTarget & Element)>>([null]);

  const [anchorElMyAccount, setAnchorElMyAccount] = React.useState(null);
  const openMyAccount = Boolean(anchorElMyAccount);

  const handleClickMenuOptions = (event: React.SyntheticEvent, index: number) => {
    const aux = anchorEl;
    aux[index] = event.currentTarget;
    setAnchorEl([...aux]);
  };

  const handleClickMyAccount = (event) => {
    setAnchorElMyAccount(event.currentTarget);
  };

  const handleCloseMyAccount = () => {
    setAnchorElMyAccount(null);
  };

  const handleClose = (index: number) => {
    const aux = anchorEl;
    aux[index] = null;
    setAnchorEl([...aux]);
  };

  return (
    <>
      <Header elevation={1}>
        <HeaderContent>
          <div>
            <img src={logo} />
          </div>
          <div>
            {menu.map((item, index) =>
              permissions?.includes(item?.access) || item?.access == 'Users' ? (
                <Fragment key={index}>
                  <p
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    className={selectedMenu === item.name ? 'menu-selected' : ''}
                    aria-expanded={Boolean(anchorEl[index]) ? 'true' : undefined}
                    onClick={(event) => {
                      handleClickMenuOptions(event, index);
                      item?.onClick();
                    }}
                  >
                    {item.icon}
                    <div>{item.name}</div>
                  </p>

                  {item.submenu.length > 0 ? (
                    <Menu
                      id="fade-menu"
                      anchorEl={anchorEl[index]}
                      open={Boolean(anchorEl[index])}
                      onClose={() => handleClose(index)}
                      TransitionComponent={Fade}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {item.submenu.map((subItem) =>
                        subItem?.access ? (
                          permissions?.includes(subItem?.access) ? (
                            <MenuItem
                              key={subItem.name}
                              onClick={() => {
                                handleClose(index);
                                subItem.onClick();
                              }}
                            >
                              {subItem.name}
                            </MenuItem>
                          ) : null
                        ) : (
                          <MenuItem
                            key={subItem.name}
                            onClick={() => {
                              handleClose(index);
                              subItem.onClick();
                            }}
                          >
                            {subItem.name}
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  ) : null}
                </Fragment>
              ) : null
            )}
            <Fragment>
              <p
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                className={selectedMenu === 'Minha Conta' ? 'menu-selected' : ''}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickMyAccount}
              >
                <PersonOutline />
                <div>{user?.username}</div>
              </p>

              <Menu
                id="basic-menu"
                anchorEl={anchorElMyAccount}
                open={openMyAccount}
                onClose={handleCloseMyAccount}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  key="Minha Conta"
                  onClick={() => {
                    handleCloseMyAccount();
                    persistMenu('Minha Conta');
                    history.push('/minha-conta');
                  }}
                >
                  Minha Conta
                </MenuItem>
                <MenuItem key="Minha Conta" onClick={() => history.push('/login')}>
                  Sair
                </MenuItem>
              </Menu>
            </Fragment>
          </div>
        </HeaderContent>
      </Header>
      <Content>{children}</Content>
    </>
  );
};

export default MasterPage;
