import React, { useEffect, useState } from 'react';
import { useAlert } from '../../contexts/alert';
import { FormControl } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isValid } from 'date-fns';
import SoldChart from '../../components/DashbordCharts/SoldChart';
import CancelPlanChart from '../../components/DashbordCharts/CancelPlanChart';
import AliveClientsChart from '../../components/DashbordCharts/AliveClientsChart';
import ModelsChart from '../../components/DashbordCharts/ModelsChart';
import CreateModelsChart from '../../components/DashbordCharts/CreateModelsChart';
import { useLoading } from '../../contexts/loading';
import { Container, Content, DashboardContainer, GrayContainer, LastDashboardContainer, Row } from './styles';
import {
  getAliveClients,
  getCancellationByPlan,
  getCreateAndNotDownload,
  getModels,
  getSoldPlans,
} from '../../services/dashboards';
import { useAuth } from '../../contexts/auth';

const HomeInternalUser = (): React.ReactElement => {
  const { showLoading } = useLoading();
  const { showAlertError } = useAlert();
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [dateNow, setDateNow] = useState<string>('');
  const [soldData, setSoldData] = useState<any>([]);
  const [clientData, setClientData] = useState<any>([]);
  const [modelData, setModelData] = useState<any>([]);
  const [createNotDownloadData, setCreateNotDownloadData] = useState<any>([]);
  const [cancelPlanData, setCancelPlanData] = useState<any>([]);
  const [soldLabels, setSoldLabels] = useState<any>([]);
  const [soldValues, setSoldValues] = useState<any>([]);
  const [cancelLabels, setCancelLabels] = useState<any>([]);
  const [cancelValues, setCancelValues] = useState<any>([]);
  const [aliveClients, setAliveClients] = useState<any>([]);
  const [models, setModels] = useState<any>([]);
  const [notDownloaded, setNotDownloaded] = useState<any>([]);
  const [created, setCreated] = useState<any>([]);
  const { permissions } = useAuth();

  useEffect(() => {
    let date = new Date().toISOString();
    setDateNow(date);
  }, []);

  useEffect(() => {
    if (permissions?.includes('CanReadHome')) {
      showLoading(true);
      getSoldPlans(initialDate, finalDate)
        .then((response) => {
          setSoldData(response.data);
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
          if (err?.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao buscar os planos mais vendidos.');
          }
        });
      getSoldValues();
    }
  }, [initialDate, finalDate, dateNow]);

  useEffect(() => {
    if (permissions?.includes('CanReadHome')) {
      showLoading(true);
      getAliveClients(initialDate, finalDate)
        .then((response) => {
          setClientData(response.data);
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
          if (err?.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao buscar os usuarios ativos.');
          }
        });
      getClients();
    }
  }, [initialDate, finalDate, dateNow]);

  useEffect(() => {
    if (permissions?.includes('CanReadHome')) {
      showLoading(true);
      getModels(initialDate, finalDate)
        .then((response) => {
          setModelData(response.data);
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
          if (err.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao buscar os modelos mais usados.');
          }
        });
      getModelsValues();
    }
  }, [initialDate, finalDate, dateNow]);

  useEffect(() => {
    if (permissions?.includes('CanReadHome')) {
      showLoading(true);
      getCancellationByPlan(initialDate, finalDate)
        .then((response) => {
          setCancelPlanData(response.data);
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
          if (err.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao buscar os modelos cancelados por plano.');
          }
        });
      getModelsValues();
    }
  }, [initialDate, finalDate, dateNow]);

  useEffect(() => {
    if (permissions?.includes('CanReadHome')) {
      showLoading(true);
      getCreateAndNotDownload(initialDate, finalDate)
        .then((response) => {
          setCreateNotDownloadData(response.data);
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
          if (err.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao buscar os modelos criados porem não baixados.');
          }
        });
      getModelsValues();
    }
  }, [initialDate, finalDate, dateNow]);

  useEffect(() => {
    getSoldValues();
  }, [soldData]);

  useEffect(() => {
    getClients();
  }, [clientData]);

  useEffect(() => {
    getModelsValues();
  }, [modelData]);

  useEffect(() => {
    getModelsCancelValues();
  }, [cancelPlanData]);

  useEffect(() => {
    getCreateNotDownloadValues();
  }, [createNotDownloadData]);

  function getSoldValues() {
    let labels = [];
    let values = [];

    for (let i = 0; i < soldData.length; i++) {
      labels.push(soldData[i].text);
      values.push(soldData[i].count);
    }
    setSoldLabels(labels);
    setSoldValues(values);
  }

  function getModelsCancelValues() {
    let labels = [];
    let values = [];

    for (let i = 0; i < cancelPlanData.length; i++) {
      labels.push(cancelPlanData[i].text);
      values.push(cancelPlanData[i].count);
    }
    setCancelLabels(labels);
    setCancelValues(values);
  }

  function getClients() {
    let values = [];

    for (let i = 0; i < clientData.length; i++) {
      values.push(clientData[i].count);
    }
    setAliveClients(values);
  }

  function getModelsValues() {
    let values = [];

    for (let i = 0; i < modelData.length; i++) {
      values.push(modelData[i].count);
    }

    setModels(values);
  }

  function getCreateNotDownloadValues() {
    let create = [];
    let notDownload = [];

    for (let i = 0; i < createNotDownloadData.length; i++) {
      create.push(createNotDownloadData[i].qtCriados);
      notDownload.push(createNotDownloadData[i].qtNaoBaixados);
    }

    setCreated(create);
    setNotDownloaded(notDownload);
  }

  function handleInitialDate(value: any) {
    setInitialDate(value);
  }

  function handleFinalDate(value: any) {
    setFinalDate(value);
  }

  return (
    <Container>
      {permissions?.includes('CanReadHome') ? (
        <Content>
          <div className="row">
            <label className="title">Dashboard</label>
          </div>
          <GrayContainer>
            <div className="row">
              <label>Filtrar por gráfico:</label>
            </div>
            <div className="row">
              <FormControl className="data" variant="outlined">
                <DatePicker
                  label="Data de início"
                  defaultValue={new Date().setFullYear(new Date().getFullYear() - 1)}
                  autoOk
                  placeholder="dd/mm/aaaa"
                  onChange={(date: Date) => {
                    if (isValid(date)) {
                      handleInitialDate(new Date(date.setHours(0, 0, 0)).toISOString());
                    }
                  }}
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  disableFuture
                  maxDateMessage={'Data inicial não pode ser maior que a data de hoje!'}
                />
              </FormControl>
              <FormControl className="data" variant="outlined">
                <DatePicker
                  label="Data de fim"
                  defaultValue={new Date()}
                  autoOk
                  placeholder="dd/mm/aaaa"
                  onChange={(date: Date) => {
                    if (isValid(date)) {
                      handleFinalDate(new Date(date).toISOString());
                    }
                  }}
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  minDate={initialDate}
                  minDateMessage={'Data final não pode ser menor que a data inicial!'}
                  disabled={initialDate != null && initialDate >= dateNow}
                />
              </FormControl>
            </div>
          </GrayContainer>
          <Row>
            <DashboardContainer>
              <label>Planos mais vendidos</label>
              <SoldChart labels={soldLabels} values={soldValues} />
            </DashboardContainer>
            <DashboardContainer>
              <label>Cancelamentos por plano</label>
              <CancelPlanChart labels={cancelLabels} values={cancelValues} />
            </DashboardContainer>
          </Row>
          <Row>
            <DashboardContainer>
              <label>Modelos mais usados</label>
              <ModelsChart values={models} />
            </DashboardContainer>
            <DashboardContainer>
              <label>Usuários ativos que não utilizam</label>
              <AliveClientsChart values={aliveClients} />
            </DashboardContainer>
          </Row>
          <LastDashboardContainer>
            <label>Modelos criados, porém não baixados</label>
            <CreateModelsChart created={created} notDownloaded={notDownloaded} />
          </LastDashboardContainer>
        </Content>
      ) : (
        ''
      )}
    </Container>
  );
};

export default HomeInternalUser;
