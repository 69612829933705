import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const Container = styled(Paper)`
  padding: 20px;
  width: 100%;
  min-height: 1200px;
  max-width: 100vw;
  max-height: 100%;
  margin-top: 14px;
  .MuiTextField-root {
    height: auto;
  }

  .gutter {
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    color: #3e4756;
  }

  a {
    margin-right: 10px;
  }
  p {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #9ba3a9;
    font-size: 24px;
    font-family: 'Mosk Semi Bold';

    .color-text {
      font-size: 24px;
      color: #3fced2;
      font-family: 'Mosk Semi Bold';
    }
  }
`;

export const Content = styled.form`
  padding: 20px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  .inputs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .descricao-tabela {
      width: 70%;
    }
    .modelo {
      width: 30%;
    }
  }
  .selects {
    display: flex;
    gap: 20px;
    .posicao {
      width: 20%;
    }
    .layout {
      width: 20%;
    }
    .quebra-linha {
      width: 20%;
    }
    .data-hora {
      width: 20%;
    }
    .ordenacao {
      width: 20%;
    }
  }
`;
export const TimeLineContainer = styled.div`
  display: flex;
  gap: 20px;

  > div {
    width: 50%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: flex-end;
  align-items: flex-end;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
