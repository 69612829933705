import styled from 'styled-components';

export const Container = styled.div`
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #e6e6e6;

  .informative-title {
    color: #3e4756;
  }

  table,
  tr,
  td,
  th,
  thead,
  tbody {
    border-collapse: collapse;
  }

  thead tr:first-child {
    height: 69px;
    font-weight: bold;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  tbody {
    td:first-child,
    th:first-child {
      background: #f5f5f6;
    }
  }

  tr {
    height: 69px;
  }

  .table-title {
    padding: 20px;
    height: 80px;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .table-configuration {
    width: 100%;

    .table-head {
      border: solid red #f5f5f6;
      span {
        color: #3e4756;
      }
    }
  }

  .editIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: #3fced2 !important;
  }
`;
export const TableContent = styled.div`
  overflow: auto;
  height: calc(100vh - 290px);
`;

export const ButtonNewCell = styled.button`
  margin: 5px 0px auto auto !important;
  all: unset;
  color: #3fced2;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
