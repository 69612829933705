import React, { useState } from 'react';
import { Container } from './styles';
import ResquestSent from './RequestSent';
import PasswordRecovery from './PasswordRecovery';
import { asksForPasswordRecovery } from '../../services/auth';
import { Paper } from '@material-ui/core';
import { useLoading } from '../../contexts/loading';
import { useAlert } from '../../contexts/alert';

const ForgotPassword: React.FC = (props) => {
  const { showLoading } = useLoading();
  const { showAlertError } = useAlert();
  const [step, setStep] = useState<number>(0);
  const [email, setEmail] = useState<string>(props.location?.state || '');

  const handleSendEmail = (value: string) => {
    setEmail(value);
    showLoading(true);
    asksForPasswordRecovery(value)
      .then((response) => {
        showLoading(false);
        setStep(1);
      })
      .catch((err) => {
        showAlertError('ocorreu um erro ao tentar enviar nova senha para o seu email');
        showLoading(false);
      });
  };

  return (
    <Container>
      <Paper elevation={3}>
        {step === 0 ? <PasswordRecovery email={email} sendEmail={handleSendEmail} /> : <ResquestSent email={email} />}
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
