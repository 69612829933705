import { createGlobalStyle } from 'styled-components';
import MoskSemiBold from './assets/fonts/Mosk-Semi-Bold.ttf';
import MoskBold from './assets/fonts/Mosk-Bold.ttf';
import MoskExtraBold from './assets/fonts/Mosk-Extra-Bold.ttf';
import MoskUltraBold from './assets/fonts/Mosk-Ultra-Bold.ttf';
import MoskLight from './assets/fonts/Mosk-Light.ttf';
import MoskMedium from './assets/fonts/Mosk-Medium.ttf';
import MoskThin from './assets/fonts/Mosk-Thin.ttf';

const globalStyle = createGlobalStyle`
  
  body {
  margin: 0;
  width: 100%;
  height: 100%;
  background: #EBF2F2;
  font-family:'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: 'Mosk Semi Bold';
    src: url(${MoskSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'Mosk Bold';
    src: url(${MoskBold}) format('truetype');
  }

  @font-face {
    font-family: 'Mosk Ultra Bold';
    src: url(${MoskUltraBold}) format('truetype');
  }

  @font-face {
    font-family: 'Mosk Extra Bold';
    src: url(${MoskExtraBold}) format('truetype');
  }

  @font-face {
    font-family: 'Mosk Medium';
    src: url(${MoskMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Mosk Light';
    src: url(${MoskLight}) format('truetype');
  }

  @font-face {
    font-family: 'Mosk Thin';
    src: url(${MoskThin}) format('truetype');
  }

  html {
    height: 100%;
    width: 100%;
  }

  * {
    box-sizing: border-box;
  }

  .MuiTextField-root {
    height: 53px;
  }

  .MuiAlert-root {
    width: fit-content;
    margin: 0 auto; 
    margin-top:10px ;
  }

  #root {
    height: 100%;
  }

  .loading{
    width: 100vw;
    height: 100vh;
    background: #e6e6e6;
    opacity: 0.6;
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`;

export default globalStyle;
