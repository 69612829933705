import TabelaComparativa from '../pages/Templates/ComparativeTable';
import UsuarioDependente from '../pages/DependentUser/List';
import EdicaoUsuarioDependente from '../pages/DependentUser/CreateOrEdit';
import CriarUsuarioDependente from '../pages/DependentUser/CreateOrEdit';
import LinhaDoTempo from '../pages/Templates/TimeLine';
import IALinguagemSimples from '../pages/Templates/IA/SampleLanguage';
import IAEmentaModel from '../pages/Templates/IA/Ementa';
import QRCode from '../pages/Templates/QRCode';
import NovoModelo from '../pages/NewTemplate';
import MeusModelos from '../pages/Templates/List';
import MyData from '../pages/MyData';
import ModalChangePassword from '../components/ModalChangePassword';
import Diagram from '../pages/Templates/Diagram';
import Fluxograma from '../pages/Templates/FlowChart';
import Icons from '../pages/Templates/Icons';
import Login from '../pages/Login';
import NewHome from '../pages/NewHome';

export const externalUserRoutes = [
  {
    name: 'Modelos',
    url: '/tabela-comparativa',
    component: TabelaComparativa,
  },
  {
    name: 'Modelos',
    url: '/ia-linguagem-simples',
    component: IALinguagemSimples,
  },
  {
    name: 'Modelos',
    url: '/ementa',
    component: IAEmentaModel,
  },
  {
    name: 'Usuário Dependente',
    url: '/usuarios-dependentes',
    component: UsuarioDependente,
  },
  {
    name: 'Edicao Usuário Dependente',
    url: '/edicao-usuario-dependente',
    component: EdicaoUsuarioDependente,
  },
  {
    name: 'Criar Usuário Dependente',
    url: '/cadastro-usuario-dependente',
    component: CriarUsuarioDependente,
  },
  {
    name: 'Editar Usuário Dependente',
    url: '/edicao-usuario-Dependente/:id',
    component: CriarUsuarioDependente,
  },
  {
    name: 'Linha do tempo',
    url: '/linha-do-tempo',
    component: LinhaDoTempo,
  },
  {
    name: 'QRCode',
    url: '/qrcode',
    component: QRCode,
  },
  {
    name: 'Diagram',
    url: '/grafico',
    component: Diagram,
  },
  {
    name: 'Modelos',
    url: '/fluxograma',
    component: Fluxograma,
  },
  {
    name: 'alterar Senha',
    url: '/alterar-senha',
    component: ModalChangePassword,
  },
  {
    name: 'Novo projeto',
    url: '/novo-modelo',
    component: NovoModelo,
  },
  {
    name: 'Meus projetos',
    url: '/meus-modelos',
    component: MeusModelos,
  },
  {
    name: 'Meus Dados',
    url: '/meus-dados',
    component: MyData,
  },
  {
    name: 'Pagina Inicial',
    url: '/',
    component: NewHome,
  },
  {
    name: 'Usuarios Internos',
    url: '/usuarios-internos',
    component: Login,
    props: {},
  },
  {
    name: 'Cadastro Perfil de Acesso',
    url: '/cadastro-perfil-acesso',
    component: Login,
  },
  {
    name: 'Edição Perfil de Acesso',
    url: '/edicao-perfil-acesso/:id',
    component: Login,
  },
  {
    name: 'Cadastro Usuário Interno',
    url: '/cadastro-usuario-interno',
    component: Login,
  },
  {
    name: 'Editar Usuário Interno',
    url: '/edicao-usuario-interno/:id',
    component: Login,
  },
  {
    name: 'Cadastrar Plano',
    url: '/planos/cadastro-plano',
    component: Login,
  },
  {
    name: 'Editar Plano',
    url: '/planos/edicao-plano/:id',
    component: Login,
  },
  {
    name: 'Plano',
    url: '/planos',
    component: Login,
  },
  {
    name: 'Usuários Externos',
    url: '/usuarios-externos',
    component: Login,
  },
  {
    name: 'Editar Usuário Externo',
    url: '/edicao-usuario-externo/:id',
    component: Login,
  },
  {
    name: 'Home Usuário Interno',
    url: '/dashboards',
    component: Login,
  },
  {
    name: 'Minha Conta',
    url: '/minha-conta',
    component: Login,
  },
  {
    name: 'Icones',
    url: '/icons',
    component: Icons,
  },
];
