import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { Buttons, Content, TopContent, useStyles } from './styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { editInternalUserById, getInternalUserById } from '../../../services/internalUsersAndProfileAccess';
import { userInitialValues, userValidationSchema } from './validation';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { useAuth } from '../../../contexts/auth';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';

const PersonalDataInternalUser: React.FC = () => {
  const history = useHistory();
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const classes = useStyles();
  const { user } = useAuth();

  useEffect(() => {
    getInternalUser();
  }, []);

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: userValidationSchema,
    onSubmit: () => {
      showLoading(true);
      editInternalUserById(formik.values, user?.tokenData?.UserId)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Alteração efetuada com sucesso.');
        })
        .catch((error) => {
          showLoading(false);
          showAlertError('Ocorreu um erro ao tentar atualizar os dados');
        });
    },
  });

  const getInternalUser = () => {
    showLoading(true);
    getInternalUserById(user?.tokenData?.UserId)
      .then((response) => {
        let dataFormik: any = {
          name: response?.data?.value[0]?.name,
          email: response.data.value[0].email,
          roleId: response.data.value[0].roleId,
        };

        formik.setValues(dataFormik);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  return (
    <>
      <div>
        <TopContent>
          <p
            onClick={() => {
              history.push('/alterar-senha');
            }}
          >
            <LockOutlinedIcon /> Alterar senha
          </p>
        </TopContent>
        <form onSubmit={formik.handleSubmit}>
          <Content>
            <div className="inputs">
              <TextField
                label="Nome"
                InputLabelProps={{ required: true }}
                className="name"
                variant="outlined"
                id="name"
                name="name"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                label="E-mail"
                InputLabelProps={{ required: true }}
                className="email"
                variant="outlined"
                type="text"
                id="email"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
          </Content>
          <Buttons>
            <Button
              className={classes.buttonRed}
              variant="outlined"
              onClick={() => {
                history.push('/dashboards');
              }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonGreen}
              variant="contained"
              type="submit"
              onClick={() => {
                if (!isEmpty(formik.errors))
                  showAlertError(
                    'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.'
                  );
              }}
            >
              Salvar
            </Button>
          </Buttons>
        </form>
      </div>
    </>
  );
};

export default PersonalDataInternalUser;
