import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ArrowBackIos } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { Buttons, Container, Content, TopContent, useStyles } from './styles';
import { createIconsLib, getModelById, updateModel } from '../../../services/models';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { useAuth } from '../../../contexts/auth';
import { createImageFile } from '../../../helper/convertHTML';
import { v4 as uuidv4 } from 'uuid';
import IconsConfiguration from './IconsConfiguration';
import ModalOptions from '../../../components/ModalOptions';
import { isEmpty } from 'lodash';

interface IFormik {
  id: string;
  descricao: string;
  titulo: string;
  html: string;
  json: string;
  clienteTitularId: string;
  htmlImage: string;
}

const validationSchema = Yup.object({
  descricao: Yup.string().required('Descrição é obrigatória.').max(500, 'Precisa ter no máximo 500 letras'),
  size: Yup.string().required('O preenchimento do tamanho é obrigatório.'),
  shape: Yup.string().required('O preenchimento da opção de traço é obrigatório.'),
  term: Yup.string().required('O preenchimento do tema é obrigatório.'),
  html: Yup.string(),
  json: Yup.string(),
});

const Icons: React.FC = () => {
  const { user } = useAuth();
  const classes = useStyles();
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const clientTitularId: string = user?.tokenData?.ClientId;
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const [isSaved, setIsSaved] = useState(false);
  const [loadIcon, setLoadIcon] = useState<boolean>(false);
  const history = useHistory();
  const [idModel, setIdModel] = useState(history?.location?.state?.id);
  const [temporaryPathName, setTemporaryPathName] = useState('');
  const [controlCancelModal, setControlCancelModal] = useState(false);
  const handleOpenCancelModal = () => history.push('/novo-modelo');
  const handleCloseCancelModal = () => setControlCancelModal(false);

  const formik = useFormik({
    initialValues: {
      id: '',
      descricao: '',
      titulo: '',
      html: '',
      json: '',
      size: '',
      shape: '',
      color: 'solid-black',
      term: '',
      iconId: '',
      clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      htmlImage: '',
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      //onSave(formik.values);
    },
  });

  const onSave = async (values: IFormik) => {
    if (ref.current) {
      values.html = ref.current.outerHTML;
      values.htmlImage = await createImageFile(document.getElementById('myCanvas'), 'scale(0.7,0.7)');
    }

    values.json = JSON.stringify(ref.current.outerHTML);
    showLoading(true);

    let body = {
      id: values.id,
      descricao: values.descricao,
      titulo: values.descricao,
      html: values.html,
      json: values.json,
      size: values.size,
      shape: values.shape,
      //color: values.color,
      term: values.term,
      iconId: values.iconId,
      clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      htmlImage: values.htmlImage,
    };

    if (!idModel) {
      body.id = uuidv4();
      createIconsLib(body)
        .then(() => {
          setIsSaved(true);
          showLoading(false);
          showAlertSuccess('Banco de Ícones criado com sucesso.');
          setIdModel(values.id);
        })
        .catch((error) => {
          showLoading(false);
          if (error.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else if (error.status === 412) {
            showAlertError('Atingido o limite de modelos do plano.');
          } else {
            showAlertError('Ocorreu um erro ao enviar os dados do banco de ícones.');
          }
        });
    } else {
      updateModel('ModeloBibliotecaIcones', body)
        .then(() => {
          setIsSaved(true);
          showLoading(false);
          showAlertSuccess('Banco de Ícones atualizado com sucesso.');
          setIdModel(idModel);
        })
        .catch((err: any) => {
          showLoading(false);
          if (err.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao atualizar os dados da tabela.');
          }
        });
    }
  };

  const getIconLibbyId = async (modeloId: string) => {
    showLoading(true);

    const response = await getModelById('ModeloBibliotecaIcones', clientTitularId, modeloId);

    if (response.status === 200) {
      let dataFormik = {
        id: response.data[0].id,
        descricao: response.data[0].descricao,
        titulo: response.data[0].descricao,
        url: response.data[0].url,
        html: ref.current?.outerHTML,
        size: response.data[0].size,
        shape: response.data[0].shape,
        color: response.data[0].color,
        term: response.data[0].term,
        iconId: response.data[0].iconId,
        json: JSON.stringify(ref.current?.outerHTML),
        clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      };
      formik.setValues(dataFormik);
      setLoadIcon(true);
      setIsSaved(true);
      showLoading(false);
    } else {
      showLoading(false);

      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
      }
    }
  };

  useEffect(() => {
    if (idModel) {
      getIconLibbyId(idModel);
    }
  }, [idModel]);

  useEffect(() => {
    // handle refresh page
    const beforeUnloadCallback = (event) => {
      if (!isSaved) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    if (!isSaved) {
      history.block((prompt) => {
        setTemporaryPathName(prompt.pathname);
        setControlCancelModal(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      history.block(() => {});
    };
  }, [history, isSaved]);

  const ref = useRef<HTMLCanvasElement>();

  return (
    <Container elevation={1}>
      <form onSubmit={formik.handleSubmit}>
        <ModalOptions
          text={`As alterações não foram salvas, deseja continuar?`}
          open={controlCancelModal}
          onCancel={handleCloseCancelModal}
          onSave={() => {
            history.block(() => {});
            history.push(temporaryPathName);
          }}
        />
        <Content>
          <TopContent>
            <div>
              <Link to="meus-modelos">
                <ArrowBackIos />
              </Link>
              <p>
                Modelo / <span className="color-text">Banco de Ícones</span>
              </p>
            </div>
          </TopContent>
          <div className="inputs">
            <TextField
              label="Identificação do modelo de Banco de Ícones"
              className="description"
              variant="outlined"
              type="text"
              id="description"
              name="description"
              fullWidth
              value={formik.values.descricao}
              onChange={(event) =>
                //formik.handleChange();
                formik.setFieldValue('descricao', event.target.value)
              }
              error={formik.touched.descricao && Boolean(formik.errors.descricao)}
              helperText={formik.touched.descricao && formik.errors.descricao}
            />
            <TextField
              label="Modelo"
              className="modelo"
              variant="outlined"
              value="Banco de Ícones"
              fullWidth
              disabled
              onChange={() => {}}
            />
          </div>
        </Content>
        <IconsConfiguration
          setIsSaved={setIsSaved}
          formik={formik}
          refView={ref}
          loadIcon={loadIcon}
          setLoadIcon={setLoadIcon}
        />
        <Buttons>
          <Button className={classes.buttonRed} variant="outlined" onClick={handleOpenCancelModal}>
            Cancelar
          </Button>
          <Button
            className={classes.buttonGreen}
            variant="contained"
            type="submit"
            onClick={() => {
              if (!isEmpty(formik.errors)) {
                showAlertError(
                  'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.'
                );
              } else {
                onSave(formik.values);
              }
            }}
          >
            Salvar
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default Icons;
