import React from 'react';
import { AlertProvider } from './contexts/alert';
import Routes from './routes';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { AuthProvider } from './contexts/auth';
import { LoadingProvider } from './contexts/loading';
import GlobalStyles from './globalStyles';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FloatingWhatsApp } from './components/FloatingWhatsApp/FloatingWhatsAppProps';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2', // This is an orange looking color
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        color: '#777',
      },
    },
  },
});

const App = (): React.ReactElement => {
  return (
    <LoadingProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
        <AuthProvider>
          <AlertProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <Routes />
              <FloatingWhatsApp
                phoneNumber="553186071717"
                accountName="Suporte Iris"
                allowEsc
                allowClickAway
                notification
                notificationSound
              />
            </ThemeProvider>
          </AlertProvider>
        </AuthProvider>
      </LocalizationProvider>
    </LoadingProvider>
  );
};

export default App;
