import React, { RefObject, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { convertHTMLToImage, convertHTMLToPDF, copyImage } from '../../../../helper/convertHTML';
import { Container } from './styles';
import { TableContent } from '../ConfigurationTable/styles';
import ModalOptions from '../../../../components/ModalOptions';
import { updateDownloadComparativeTable } from '../../../../services/models';

interface IProps {
  id: string;
  columnsBorderStyle: React.CSSProperties;
  columns: Array<ITableCell> | [];
  rows: Array<Array<ITableCell>> | [];
  copyRef: RefObject<HTMLTableElement>;
  tableRef: RefObject<HTMLTableElement>;
  titleColor?: string | undefined;
  titleText?: string | undefined;
  isSaved: boolean;
  fileName: string;
}

interface ITableCell {
  description: string;
  color?: string;
  width: number;
  height: number;
}

const ViewTable = ({
  id,
  columnsBorderStyle,
  columns,
  rows,
  copyRef,
  tableRef,
  titleColor,
  titleText,
  isSaved,
  fileName,
}: IProps) => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);

  const downloadPDF = () => {
    convertHTMLToPDF(tableRef.current, fileName);
    updateCountDownloadComparativeTable(id);
  };

  const downloadImage = () => {
    convertHTMLToImage(tableRef.current, fileName);
    updateCountDownloadComparativeTable(id);
  };

  const copy = (totalWidth) => {
    copyImage(copyRef.current, null, totalWidth);
    setOpenToolTipCopyImage(true);
    updateCountDownloadComparativeTable(id);
  };

  useEffect(() => {
    let width = 0;
    columns.forEach((column) => {
      width += column.width;
    });
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy(width);
    }
  }, [controlDownloadModal]);

  const calculateColumnWidth = () => {
    let width = 0;
    columns.map((column) => {
      width += column.width;
    });
    return width;
  };

  const updateCountDownloadComparativeTable = (modelId: string) => {
    updateDownloadComparativeTable(modelId).catch((err) => {
      console.log(err);
    });
  };

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="informative-title">
        <p>Visualização do modelo</p>
        <div>
          <Tooltip title="Download">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer', color: '#3E4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <span
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
                style={{ cursor: 'pointer', color: '#3E4756' }}
                className="material-icons-outlined"
              >
                <ContentCopyOutlinedIcon />
              </span>
            </Tooltip>
          </Tooltip>
          <Tooltip title="Download PDF">
            <LocalPrintshopOutlinedIcon
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
              style={{ color: '#3E4756' }}
            />
          </Tooltip>
        </div>
      </div>
      <TableContent>
        <div ref={copyRef}>
          <table
            id="table-model"
            ref={tableRef}
            className="table-model"
            style={{
              ...columnsBorderStyle,
              minWidth: 300,
              width: calculateColumnWidth(),
              maxWidth: 1200,
              height: 'auto',
            }}
          >
            <caption
              className="table-title"
              style={{
                padding: 20,
                ...columnsBorderStyle,
                textAlign: 'left',
                wordBreak: 'normal',
                fontSize: 24,
                minHeight: 70,
                wordSpacing: 1,
              }}
            >
              {titleText}
            </caption>
            <thead style={{ ...columnsBorderStyle }}>
              <tr style={{ background: titleColor, ...columnsBorderStyle }}>
                {columns.map((c, index) => (
                  <td
                    key={index}
                    id={`view0${index}`}
                    style={{
                      wordBreak: 'normal',
                      background: c.color,
                      fontWeight: 'bold',
                      fontSize: 22,
                      width: c.width,
                      height: c.height,
                      padding: 15,
                      ...columnsBorderStyle,
                    }}
                  >
                    {c.description}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody style={{ ...columnsBorderStyle }}>
              {rows.map((r, index) => (
                <tr key={index} style={{ ...columnsBorderStyle }}>
                  {r.map((cell, cellIndex) => (
                    <td
                      id={`view${index + 1}${cellIndex}`}
                      style={{
                        wordBreak: 'normal',
                        background: cell.color,
                        padding: 15,
                        ...columnsBorderStyle,
                        height: cell.height,
                        marginBottom: cell.height,
                      }}
                      key={cellIndex}
                    >
                      {cell?.description}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <br />
        </div>
      </TableContent>
    </Container>
  );
};

export default React.memo(ViewTable);
