import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 30px;

  h1 {
    color: #3e4756;
    text-align: center;
    font-family: 'Mosk Semi Bold';
    padding: 10px;
  }

  div {
    display: flex;
    justify-content: center;
    gap: 10;
    flex-wrap: wrap;
  }

  .MuiCard-root {
    margin: 10px;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 5px;
  }

  .MuiTypography-h5 {
    font-family: 'Mosk Semi Bold';
    color: #3e4756;
  }

  a {
    text-decoration: none;
  }

  .MuiCardMedia-root {
    display: flex;
    background-color: #ebf2f2;
  }

  .btnYouTube {
    color: #c4302b;
  }

  .btnYouTube:hover {
    color: #c4302b94;
  }
`;
