import React, { FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import { Button, Switch, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Buttons, Container, Fields, TopContent, useStyles } from './styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { createDependentUser, editDependentUserById, getDependentUsersById } from '../../../services/dependentUser';
import { useAlert } from '../../../contexts/alert';
import { History } from 'history';
import { useLoading } from '../../../contexts/loading';
import { GreenText, RedText } from '../List/styles';
import { useAuth } from '../../../contexts/auth';
import { v4 as uuidv4 } from 'uuid';

interface IDependentUsers {
  ownerUserId?: string;
  subscriptionId?: string;
  clientId?: string;
  name?: string;
  email?: string;
  active: boolean;
  userId?: string;
}

const EdicaoUsuarioDependente = (): React.ReactElement => {
  const classes = useStyles();
  const history: History = useHistory();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { showLoading } = useLoading();
  const { user } = useAuth();
  const dependentId = history.location.pathname.replace('/edicao-usuario-dependente/', '');
  const isEditingUser = history.location.pathname.includes('edicao');

  const validationSchema = object({
    email: string().email('E-mail Inválido').required('E-mail é obrigatório'),
    name: string().required('Nome é obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      ownerUserId: user?.tokenData?.ClientId,
      subscriptionId: '00000000-0000-0000-0000-000000000000',
      clientId: uuidv4(),
      name: '',
      email: '',
      active: true,
      userId: uuidv4(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveUser(values);
    },
  });

  useEffect(() => {
    if (isEditingUser) {
      getDependentUsersById(dependentId)
        .then((response) => {
          formik.setValues(response?.data?.value[0]);
        })
        .catch((error) => {
          if (error.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError(error?.response?.data || 'Ocorreu um erro ao buscar os dados do dependente');
          }
        });
    }
  }, []);

  const saveUser = (values: IDependentUsers) => {
    showLoading(true);

    if (isEditingUser) {
      values.userId = dependentId;
      editDependentUserById(values)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Usuario dependente editado com sucesso');
          history.push({ pathname: '/usuarios-dependentes', search: 'InternalUsers' });
        })
        .catch((error) => {
          showLoading(false);
          if (error.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError(
              (error?.data && error?.data[0]?.ErrorMessage) || 'Ocorreu um erro ao tentar realizar a alteração'
            );
          }
        });
    } else {
      createDependentUser(values)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Usuario dependentes cadastrado com sucesso');
          history.push({ pathname: '/usuarios-dependentes', search: 'InternalUsers' });
        })
        .catch((error) => {
          showLoading(false);
          if (error.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else if (error.status === 412) {
            showAlertError('Atingido limite de dependentes do plano.');
          } else {
            showAlertError(
              (error?.data && error?.data[0].ErrorMessage) || 'Ocorreu um erro ao tentar realizar o cadastro'
            );
          }
        });
    }
  };

  return (
    <Container elevation={3}>
      <TopContent>
        <div>
          <Link to={{ pathname: '/usuarios-dependentes', search: 'InternalUsers' }}>
            <ArrowBackIos />
          </Link>
          <p>
            Adicionar usuários / <span className="color-text">{isEditingUser ? 'Editar' : 'Cadastrar'}</span>
          </p>
        </div>
        <span>
          {isEditingUser ? formik.values.active ? <GreenText>Ativo</GreenText> : <RedText>Inativo</RedText> : 'Ativo'}

          <Switch
            disabled={!isEditingUser}
            checked={isEditingUser ? formik.values.active : true}
            name="status"
            onChange={(event) => {
              const checked = event.target.checked;
              formik.setFieldValue('active', checked);
            }}
            className={classes.disabled}
            classes={
              isEditingUser
                ? {
                    track: classes.switch_track,
                    switchBase: classes.switch_base,
                    colorPrimary: classes.switch_primary,
                  }
                : {}
            }
          />
        </span>
      </TopContent>
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Fields>
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            name="name"
            error={Boolean(formik.errors.name)}
            helperText={formik?.errors?.name}
            value={formik.values.name || ''}
            onChange={formik.handleChange}
            InputLabelProps={{
              required: true,
            }}
          />
          <TextField
            fullWidth
            label="E-mail"
            type="email"
            variant="outlined"
            name="email"
            className="email"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            InputLabelProps={{
              required: true,
            }}
            error={Boolean(formik.errors.email)}
            helperText={formik?.errors?.email}
          />
        </Fields>
        <Buttons>
          <Button
            className={classes.buttonRed}
            variant="outlined"
            onClick={() => {
              history.push('/usuarios-dependentes');
            }}
          >
            Cancelar
          </Button>
          <Button className={classes.buttonGreen} variant="contained" type="submit">
            Salvar
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default EdicaoUsuarioDependente;
