import React from 'react';
import HasBreakPageMod1 from './modelo1/HasBreakPage';
import HasNoBreakPageMod1 from './modelo1/HasNoBreakPage';
import HasBreakPageMod2 from './modelo2/HasBreakPage';
import HasNoBreakPageMod2 from './modelo2/HasNoBreakPage';
import { DATETYPEINPUT, TIPO } from '../../../../../interfaces/timeline';

interface IItem {
  date: Date | null;
  title: string;
  description: string;
}

interface IProps {
  id: string;
  title: string;
  data: Array<IItem>;
  dateType: DATETYPEINPUT;
  breakPage: boolean;
  refHasBreakPage: RefObject<HTMLDivElement>;
  refHasNoBreakPage: RefObject<HTMLDivElement>;
  fileName: string;
  isSaved: boolean;
  tipo: TIPO;
  colWidth: number;
}

const VerticalView: React.ReactElement = ({
  id,
  title,
  data,
  dateType,
  breakPage,
  refHasBreakPage,
  refHasNoBreakPage,
  fileName,
  isSaved,
  colWidth,
  tipo = 1,
}: IProps) => {
  return breakPage ? (
    tipo == TIPO.MODELO1 ? (
      <HasBreakPageMod1 {...{ id, title, data, dateType, refView: refHasBreakPage, fileName, isSaved, colWidth }} />
    ) : (
      <HasBreakPageMod2 {...{ id, title, data, dateType, refView: refHasBreakPage, fileName, isSaved, colWidth }} />
    )
  ) : tipo == TIPO.MODELO1 ? (
    <HasNoBreakPageMod1 {...{ id, title, data, dateType, refView: refHasNoBreakPage, fileName, isSaved, colWidth }} />
  ) : (
    <HasNoBreakPageMod2 {...{ id, title, data, dateType, refView: refHasNoBreakPage, fileName, isSaved, colWidth }} />
  );
};

export default VerticalView;
