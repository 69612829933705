import React from 'react';
// eslint-disable-next-line sort-imports
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function CreateModelsChart({ created, notDownloaded }: any) {
  const state = {
    labels: ['Fluxograma', 'Gráfico', 'Linha do tempo', 'QR Code', 'Tabela'],
    datasets: [
      {
        label: 'Itens Criados',
        data: created,
        backgroundColor: '#D1E5F3',
        borderColor: '#B7DDF8',
      },
      {
        label: 'Não baixados',
        data: notDownloaded,
        backgroundColor: '#FFE0E6',
        borderColor: '#FC9EB2',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', paddingRight: '10%', paddingLeft: '10%' }}>
      <Bar options={options} data={state} height={100} />
    </div>
  );
}
