import React, { RefObject, useEffect, useState } from 'react';
import { Container } from '../../styles';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  convertHTMLTimeLineHasBreakPageToPDF,
  convertHTMLToImage,
  copyImage,
} from '../../../../../../../helper/convertHTML';
import { Tooltip } from '@mui/material';
import { DATETYPEINPUT } from '../../../../../../../interfaces/timeline';
import { format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';
import ModalOptions from '../../../../../../../components/ModalOptions';
import { updateDownloadTimeLine } from '../../../../../../../services/models';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

interface IItem {
  date: Date | null;
  title: string;
  description: string;
  color?: string;
}

interface IProps {
  id: string;
  title: string;
  data: Array<IItem>;
  dateType: DATETYPEINPUT;
  refView: RefObject<HTMLDivElement>;
  fileName: string;
  isSaved: boolean;
  colWidth: number;
}

const HasBreakPage = ({ id, title, data, dateType, refView, fileName, isSaved, colWidth }: IProps) => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);

  const splitToChunks = (arr: IItem[], size: number) => {
    const chunkSize = size;
    return arr
      .map((e: IItem, i: number) => {
        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
  };

  const newData = splitToChunks(data, 5);

  const downloadImage = () => {
    convertHTMLToImage(refView.current, fileName);
    updateCountDownloadTimeLine(id);
  };

  const downloadPDF = () => {
    convertHTMLTimeLineHasBreakPageToPDF(refView.current, fileName, 'p');
    updateCountDownloadTimeLine(id);
  };

  const copy = () => {
    copyImage(refView.current, fileName);
    setOpenToolTipCopyImage(true);
    updateCountDownloadTimeLine(id);
  };

  const updateCountDownloadTimeLine = (modelId: string) => {
    updateDownloadTimeLine(modelId).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="info-title">
        <p>Visualização do modelo</p>

        <div>
          <Tooltip title="Download imagem">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer', color: '#3E4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <ContentCopyOutlinedIcon
                style={{ cursor: 'pointer', color: '#3E4756' }}
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
              />
            </Tooltip>
          </Tooltip>
          <Tooltip title="Download PDF">
            <LocalPrintshopOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
            />
          </Tooltip>
        </div>
      </div>
      <div className="view-table">
        <div id="#view" ref={refView} style={{ width: 'max-content', background: '#fff' }}>
          <table style={{ borderCollapse: 'collapse', minWidth: '400px' }}>
            {newData.map((page: Array<IItem> | any, pageIndex: number) => (
              <tbody key={pageIndex} className="page">
                {pageIndex === 0 ? (
                  <tr className="title" style={{ fontSize: 24 }}>
                    <p
                      style={{
                        padding: 0,
                        fontSize: 24,
                        margin: 0,
                        textAlign: 'center',
                        height: 70,
                        wordBreak: 'break-all',
                      }}
                    >
                      {title}
                    </p>
                  </tr>
                ) : (
                  ''
                )}
                <tr>
                  <td style={{ padding: 0 }}>
                    <Timeline position="alternate">
                      {page.map((row: IItem, rowIndex: number) => (
                        <React.Fragment key={rowIndex}>
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot style={{ borderWidth: '7px', backgroundColor: row.color }} />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Typography variant="h6" component="span">
                                {dateType === DATETYPEINPUT.DATETIME ? (
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      borderRadius: '16px',
                                    }}
                                  >
                                    {row.date ? format(new Date(row.date), 'dd/MM/yyy HH:mm') : ''}
                                  </p>
                                ) : dateType === DATETYPEINPUT.DATE ? (
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      borderRadius: '16px',
                                    }}
                                  >
                                    {row.date ? format(new Date(row.date), 'dd/MM/yyy') : ''}
                                  </p>
                                ) : dateType === DATETYPEINPUT.MONTHYEAR ? (
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      borderRadius: '16px',
                                    }}
                                  >
                                    {row.date
                                      ? format(new Date(row.date), 'MM/yyyy', {
                                          locale: ptBR,
                                        })
                                      : ''}
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      borderRadius: '16px',
                                    }}
                                  >
                                    {row.date ? new Date(row.date).getFullYear() : ' '}
                                  </p>
                                )}
                              </Typography>
                              <Typography variant="h5" component="span">
                                {row.title}
                              </Typography>
                              <Typography style={{ padding: 0, lineBreak: 'auto', maxWidth: '600px' }}>
                                {row.description}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        </React.Fragment>
                      ))}
                    </Timeline>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </Container>
  );
};

export default HasBreakPage;
