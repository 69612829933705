import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  h1 {
    color: #3fced2;
    text-align: center;
  }

  div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    justify-content: center;
    padding: 30px 50px 30px 50px;
  }

  p {
    text-align: center;
  }
`;

export const useStyles = makeStyles({
  button: {
    marginTop: '30px',
    marginBottom: '20px',
    textTransform: 'uppercase',
    fontWeight: 400,
    backgroundColor: '#3fced2',
    color: '#fff',
  },
});
