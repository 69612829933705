import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ArrowBackIos } from '@material-ui/icons';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Buttons, Container, ContainerView, Content, TopContent, useStyles } from './styles';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { useAuth } from '../../../contexts/auth';
import VerticalView from './VerticalView';
import ConfigurationContainer, { IItem } from './ConfigurationContainer';
import HorizontalView from './HorizontalView';
import ModalColors from '../../../components/ModalChooseColor';
import ModalConnectElement from '../../../components/ModalChooseConnectElement';
import ModalOptions from '../../../components/ModalOptions';
import { createFlowChart, getModelById, updateFlowChart } from '../../../services/models';
import ModalGifFlowChart from '../../../components/ModalGifFlowChart';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import { isElement, isEmpty } from 'lodash';
import { getClientColors } from '../../../services/externalUsers';
import Split from 'react-split';
import { image } from 'd3';
import { convertHTMLToImage, convertImagetoFile, createImageFile, snapshotCreator } from '../../../helper/convertHTML';

interface IElement {
  id?: string;
  parents: Array<IParents>;
  description: string;
  color: string;
  HorizontalPosition: IPosition;
  VerticalPosition: IPosition;
  expanded?: boolean;
  conexaoEntreElementos?: boolean;
  parentSameLevel?: string;
  positionConnectionSameLevel?: string;
}

interface IParents {
  id?: string;
  description: string;
}

type ILevel = Array<IElement>;

interface IFlowChart {
  data: Array<ILevel>;
}

const FlowChart: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const history = useHistory();
  const { showLoading } = useLoading();
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert();
  const [firstColor, setFirstColor] = useState('');
  const [lastColor, setLastColor] = useState('');
  const [allItemColor, setAllItemColor] = useState('');
  const [flowchartData, setFlowChartData] = useState<IFlowChart>({
    data: [[{ id: uuidv4(), description: '', color: '', expanded: true }]],
  });
  const [temporaryPathName, setTemporaryPathName] = useState('');
  const [controlModalColor, setControlModalColor] = useState<any>(null);
  const [controlDeleteModal, setControlDeleteModal] = useState<string | null>(null);
  const [controlCancelEditingModal, setControlCancelEditingModal] = useState<boolean>(false);
  const clientTitularId: string = user?.tokenData?.ClientId;
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const clientId: string = user?.tokenData?.ClientId;
  const [idModel, setIdModel] = useState(history?.location?.state?.id);
  const [isSaved, setIsSaved] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [splitSizes, setSplitSizes] = useState([50, 50]);

  const validationSchema = Yup.object({
    description: Yup.string().required('Identificação é obrigatória.'),
    title: Yup.string(),
    position: Yup.number(),
    clienteTitularId: Yup.string(),
  });

  useEffect(() => {
    setOpen(true);
    showLoading(true);
    getClientColors(user?.tokenData?.UserId)
      .then((response: any) => {
        setFirstColor(response?.data.value[0].color1);
        setLastColor(response?.data.value[0].color1);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  useEffect(() => {
    setFlowChartData({
      data: [[{ id: uuidv4(), description: '', color: firstColor, expanded: true }]],
    });
  }, [firstColor]);

  //handle onuser leaving the page
  useEffect(() => {
    // handle refresh page
    const beforeUnloadCallback = (event) => {
      if (!isSaved) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    if (!isSaved) {
      history.block((prompt) => {
        setTemporaryPathName(prompt.pathname);
        setControlCancelEditingModal(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      history.block(() => {});
    };
  }, [history, isSaved]);

  const colors = user.tokenData?.Colors || null;

  const getFlowChartById = async (modeloId: string) => {
    showLoading(true);
    const promiseFlowChart = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(getModelById('ModeloFluxograma', OwnerUserId ? OwnerUserId : clientTitularId, modeloId));
      }, 1000);
    });
    const response = await promiseFlowChart;
    if (response.status === 200) {
      const dataFlowChart = JSON.parse(response.data[0].json);
      setFlowChartData(dataFlowChart);
      let dataFormik = {
        id: response.data[0].id,
        title: response.data[0].titulo,
        clienteTitularId: clientId,
        description: response.data[0].descricao,
        position: response.data[0].posicao,
        //htmlImage: response.data[0].htmlImage,
      };
      formik.setValues(dataFormik);
      showLoading(false);
      setIsSaved(true);
    } else {
      showLoading(false);
      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
      }
    }
  };

  useEffect(() => {
    if (idModel) {
      getFlowChartById(idModel);
    }
  }, [idModel]);

  const formik = useFormik({
    initialValues: {
      id: '',
      description: '',
      title: '',
      position: 2,
      clienteTitularId: clientId,
      htmlImage: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (ref.current) {
        values.html = ref.current.outerHTML;
        values.htmlImage = await createImageFile(document.getElementById('#view'));
      }
      let json = flowchartData;
      values.json = JSON.stringify(json);
      showLoading(true);
      if (values.id) {
        updateFlowChart(values)
          .then((response) => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Fluxograma editado com sucesso.');
            setIdModel(values.id);
          })
          .catch((error) => {
            showLoading(false);
            if (error.status === 401) {
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            } else if (error.status === 412) {
              showAlertError('Atingido o limite de modelos do plano.');
            } else {
              showAlertError(error || 'Ocorreu um erro ao enviar os dados do grafico.');
            }
          });
      } else {
        values.id = uuidv4();
        createFlowChart(values)
          .then((response) => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Fluxograma criado com sucesso.');
            setIdModel(values.id);
          })
          .catch((error) => {
            showLoading(false);
            if (error.status === 401) {
              formik.setFieldValue('id', '');
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            } else if (error.status === 412) {
              formik.setFieldValue('id', '');
              showAlertError('Atingido o limite de modelos do plano.');
            } else {
              showAlertError('Ocorreu um erro ao enviar os dados do grafico.');
            }
          });
      }
    },
  });

  const addNewLevel = (indexLevel: number) => {
    setIsSaved(false);

    let auxData = [...flowchartData.data];

    let isAnyEmptyField = false;
    auxData.map((level) => {
      level.map((field, indexField) => {
        if ((indexField === 0 && !field.description) || (indexField !== 0 && (!field.description || !field.parents)))
          isAnyEmptyField = true;
      });
    });
    if (isAnyEmptyField) {
      showAlertError('Não é possível inserir um novo nível enquanto houver elementos vazios');
      return;
    }

    let newLevel: ILevel = [];
    let newItem: IElement = {
      id: uuidv4(),
      description: '',
      parents: [],
      color: allItemColor ? allItemColor : lastColor,
      expanded: true,
    };

    newLevel.push(newItem);

    if (indexLevel < 0) {
      auxData.push(newLevel);
    } else {
      auxData.splice(indexLevel + 1, 0, newLevel);
      auxData.map((level, i) => {
        if (i > indexLevel) {
          level.forEach((element) => {
            element.parents = [];
          });
        }
      });
    }

    setFlowChartData((prevState: any) => ({
      ...prevState,
      data: auxData,
    }));

    const timeOut = setTimeout(() => {
      //document.getElementsByClassName(`card${flowchartData.data?.length}0`)[0]?.scrollIntoView();
      document.getElementById(`item${flowchartData.data?.length}0`)?.focus();
      clearTimeout(timeOut);
    }, 5);
  };

  const addNewElement = (indexLevel: number) => {
    setIsSaved(false);
    let auxData = [...flowchartData.data];
    let isAnyEmptyField = false;
    auxData[indexLevel].map((field, index) => {
      if ((index === 0 && !field.description) || (index !== 0 && !field.description && !field.parents))
        isAnyEmptyField = true;
    });
    if (isAnyEmptyField) {
      showAlertError('Não é possível inserir um novo elemento enquanto houver elementos vazios');
      return;
    }
    auxData[indexLevel] = [
      ...auxData[indexLevel],
      { id: uuidv4(), description: '', parents: [], color: allItemColor ? allItemColor : lastColor, expanded: true },
    ];
    setFlowChartData((prevState: any) => ({ ...prevState, data: auxData }));

    const timeOut = setTimeout(() => {
      //document.getElementsByClassName(`card${indexLevel}${auxData[indexLevel].length - 1}`)[0]?.scrollIntoView();
      document.getElementById(`item${indexLevel}${auxData[indexLevel].length - 1}`)?.focus();

      clearTimeout(timeOut);
    }, 5);
  };

  const changeItemValue = (indexItem: number, indexLevel: number, value: string, attribute: string) => {
    setIsSaved(false);
    let dataAux = [...flowchartData.data];
    dataAux[indexLevel][indexItem][attribute] = value;
    if (attribute === 'parents') {
      dataAux[indexLevel][indexItem].parents = value;
    }
    setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
  };

  const onCancelEditing = () => history.push('/novo-modelo');

  const changeSelectedColor = (name: string, value: string) => {
    setIsSaved(false);
    let dataAux = [...flowchartData.data];

    if (controlModalColor.name === 'all') {
      dataAux.map((data) => {
        data.map((d) => {
          d.color = value;
        });
      });

      setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
      setAllItemColor(value);
    } else {
      if (controlModalColor.name?.length >= 2) {
        const indexes = controlModalColor.name?.split(',');

        dataAux[indexes[0]][indexes[1]].color = value;
      } else {
        dataAux[controlModalColor.name].map((element) => {
          element.color = value;
        });
      }

      setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
      setLastColor(value);
    }
  };

  const changeConnectElement = (
    name: string,
    conexaoEntreElementos: boolean,
    parentSameLevel: string,
    positionConnectionSameLevel: string
  ) => {
    setIsSaved(false);
    let dataAux = [...flowchartData.data];

    if (controlModalConnectElement.name?.length >= 2) {
      const indexes = controlModalConnectElement.name?.split(',');

      dataAux[indexes[0]][indexes[1]].conexaoEntreElementos = conexaoEntreElementos;
      dataAux[indexes[0]][indexes[1]].parentSameLevel = parentSameLevel;
      dataAux[indexes[0]][indexes[1]].positionConnectionSameLevel = positionConnectionSameLevel;
    } else {
      dataAux[controlModalColor.name].map((element) => {
        element.conexaoEntreElementos = conexaoEntreElementos;
        element.parentSameLevel = parentSameLevel;
        element.positionConnectionSameLevel = positionConnectionSameLevel;
      });
    }

    setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
  };

  const onSaveModalOptions = () => {
    const indexes = controlDeleteModal?.split(',');
    if (indexes?.length == 2) deleteElement(indexes[0], indexes[1]);
    else deleteLevel(Number(controlDeleteModal));
    setControlDeleteModal(null);
  };

  const deleteLevel = (index) => {
    setIsSaved(false);
    let dataAux = [...flowchartData.data];

    // used to reduce the position of levels
    dataAux.map((level, indexLevel) => {
      if (indexLevel > index) {
        level.map((element, indexElement) => {
          if (dataAux[indexLevel][indexElement].VerticalPosition) {
            let y = dataAux[indexLevel][indexElement].VerticalPosition.y - 150;
            dataAux[indexLevel][indexElement].VerticalPosition.y = y;
          }
          if (dataAux[indexLevel][indexElement].HorizontalPosition) {
            let x = dataAux[indexLevel][indexElement].HorizontalPosition.x - 210;
            dataAux[indexLevel][indexElement].HorizontalPosition.x = x;
          }
        });
      }
    });
    dataAux.splice(index, 1);

    setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
  };

  const deleteElement = (indexLevel, indexElement) => {
    setIsSaved(false);
    let dataAux = [...flowchartData.data];
    if (dataAux[indexLevel].length == 1) {
      deleteLevel(indexLevel);
    } else {
      // used to reduce the position of levels
      dataAux[indexLevel].map((element, index) => {
        if (index > indexElement && dataAux[indexLevel][index].VerticalPosition) {
          let x = dataAux[indexLevel][index].VerticalPosition.x - 200;
          dataAux[indexLevel][index].VerticalPosition.x = x;
        }
        if (index > indexElement && dataAux[indexLevel][index].HorizontalPosition) {
          let y = dataAux[indexLevel][index].HorizontalPosition.y - 175;
          dataAux[indexLevel][index].HorizontalPosition.y = y;
        }
      });
      dataAux[indexLevel].splice(indexElement, 1);
      setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
    }
  };

  const centerFirstItem = (indexLevel, indexElement) => {
    setIsSaved(false);
    let dataAux = [...flowchartData.data];
    // used to reduce the position of levels
    dataAux[indexLevel].map((element, index) => {
      if (dataAux[indexLevel][index].VerticalPosition) {
        const w = ref.current?.offsetWidth - 150;
        dataAux[indexLevel][index].VerticalPosition.x = w / 2;
      }
      if (dataAux[indexLevel][index].HorizontalPosition) {
        const h = ref.current?.offsetHeight - 175;
        dataAux[indexLevel][index].HorizontalPosition.y = h / 2;
      }
    });
    setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
  };

  const changeExpandedCard = (indexLevel: number, expanded: boolean) => {
    let dataAux = [...flowchartData.data];
    dataAux[indexLevel].map((element) => {
      element.expanded = expanded;
    });
    setFlowChartData((prevState) => ({ ...prevState, data: dataAux }));
  };

  return (
    <Container elevation={1}>
      <Modal
        style={{ border: 'none' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: 'absolute',
            top: '10%',
            left: '25%',
            // transform: 'translate(-50%, -50%)',
            width: 670,
            height: 490,
            backgroundColor: '#FFF',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          <ModalGifFlowChart close={handleClose} />
        </Box>
      </Modal>
      <ModalColors
        closeModal={() => setControlModalColor(null)}
        open={Boolean(controlModalColor)}
        changeColor={changeSelectedColor}
        selectedColor={controlModalColor}
      />
      <ModalOptions
        text={`As alterações não foram salvas, deseja continuar?`}
        open={Boolean(controlCancelEditingModal)}
        onCancel={() => setControlCancelEditingModal(false)}
        onSave={() => {
          history.block(() => {});
          history.push(temporaryPathName);
        }}
      />
      <ModalOptions
        text={
          controlDeleteModal?.length > 1
            ? `Tem certeza que deseja excluir este item?`
            : 'Tem certeza que deseja excluir este nível e todos os seus elementos?'
        }
        open={Boolean(controlDeleteModal)}
        onCancel={() => setControlDeleteModal(null)}
        onSave={onSaveModalOptions}
      />

      <form onSubmit={formik.handleSubmit}>
        <Content>
          <TopContent>
            <div>
              <Link to="meus-modelos">
                <ArrowBackIos />
              </Link>
              <p>
                Modelo / <span className="color-text">Fluxograma</span>
              </p>
            </div>
          </TopContent>
          <div className="inputs">
            <TextField
              label="Identificação do modelo de Fluxograma"
              className="description"
              variant="outlined"
              type="text"
              id="description"
              name="description"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.description)}
              helperText={formik.errors.description}
            />
            <TextField
              label="Modelo"
              className="modelo"
              variant="outlined"
              value="Fluxograma"
              fullWidth
              disabled
              onChange={() => {}}
            />
            <FormControl className="posicao" name="position" fullWidth variant="outlined">
              <InputLabel id="posicao">Posição do fluxograma</InputLabel>
              <Select
                labelId="posicao"
                id="position"
                name="position"
                value={formik.values.position}
                onChange={formik.handleChange}
                label="Posição do fluxograma"
              >
                <MenuItem value={1}>Sequencial</MenuItem>
                <MenuItem value={2}>Hierárquico</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Content>
        <ContainerView>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
            <Split
              sizes={splitSizes}
              minSize={[670, 1000]}
              maxSize={[850, Infinity]}
              direction="horizontal"
              style={{ display: 'flex', width: '100%' }}
              cursor="col-resize"
              gutterSize={10}
              onDragEnd={(sizes) => {
                setSplitSizes(sizes);
              }}
            >
              <ConfigurationContainer
                setIsSaved={setIsSaved}
                changeExpandedCard={changeExpandedCard}
                data={flowchartData.data}
                addNewLevel={addNewLevel}
                addNewElement={addNewElement}
                changeTitle={(title) => {
                  formik.setFieldValue('title', title);
                }}
                titleText={formik.values.title}
                positionFlow={formik.values.position}
                changeItemValue={changeItemValue}
                controlDeleteModal={(indexes: string) => setControlDeleteModal(indexes)}
                centerFirstItem={centerFirstItem}
                controlModalColor={(indexes: string, value: string) =>
                  setControlModalColor({ name: indexes, value: value })
                }
                allItemColor={allItemColor}
              />
              {formik.values.position == 1 ? (
                <HorizontalView
                  id={formik.values.id}
                  isSaved={isSaved}
                  fileName={formik.values.description}
                  reference={ref}
                  data={flowchartData.data}
                  setData={(data) => {
                    setFlowChartData((prevState) => ({ ...prevState, data }));
                  }}
                  titleText={formik.values.title}
                  addNewLevel={addNewLevel}
                  addNewElement={addNewElement}
                  changeItemValue={changeItemValue}
                  centerFirstItem={centerFirstItem}
                />
              ) : (
                <VerticalView
                  id={formik.values.id}
                  isSaved={isSaved}
                  fileName={formik.values.description}
                  reference={ref}
                  titleText={formik.values.title}
                  data={flowchartData.data}
                  setData={(data) => {
                    setFlowChartData((prevState) => ({ ...prevState, data }));
                  }}
                  addNewLevel={addNewLevel}
                  addNewElement={addNewElement}
                  changeItemValue={changeItemValue}
                  centerFirstItem={centerFirstItem}
                />
              )}
            </Split>
          </div>
        </ContainerView>
      </form>
      <Buttons>
        <Button className={classes.buttonRed} variant="outlined" onClick={onCancelEditing}>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (!isEmpty(formik.errors))
              showAlertError('Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.');
            else formik.handleSubmit();
          }}
          className={classes.buttonGreen}
          variant="contained"
        >
          Salvar
        </Button>
      </Buttons>
    </Container>
  );
};

export default FlowChart;
