import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
interface InitContextProps {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
  permissions: [];
}

interface IUser {
  authenticated: boolean;
  accessToken?: string;
  email: string;
  username: string;
  type: string;
  tokenData?: any;
}

const AuthContext = createContext<InitContextProps>({
  user: { authenticated: false, username: '', email: '', type: '' },
  setUser: () => {},
  permissions: [],
});

const getUserFromLocalStorage = () => {
  const userStored = localStorage.getItem('user');
  if (userStored) {
    return JSON.parse(userStored);
  } else return null;
};

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<IUser | null>(getUserFromLocalStorage());
  const [permissions, setPermissions] = useState<any>([]);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (user?.accessToken) {
      setUser((prevState) => ({ ...prevState, tokenData: jwt_decode(user.accessToken) }));
    }
  }, [user?.accessToken]);

  useEffect(() => {
    setPermissions(user?.tokenData?.['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision']);
  }, [user?.tokenData]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return {
    user: context.user,
    setUser: context.setUser,
    permissions: context.permissions,
  };
}
