import styled from 'styled-components';

export const Content = styled.div`
  background-color: #f7f7f7;
  border: 1px solid #00000029;
  border-radius: 5px;
  text-align: center;
  padding: 30px;

  h2 {
    padding: 0px;
    color: #3e4756;
  }

  .message {
    padding-bottom: 10px;
    color: #3e4756;
  }

  a {
    color: #1976d2;
  }
`;
