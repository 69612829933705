import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 450px;
  min-height: 400px;
  max-height: 500px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiButton-root {
    display: flex;
    align-self: flex-start;
    text-transform: capitalize;
    font-size: 16px;
    padding: 0px;
    font-weight: normal;
    color: #3fced2;
  }

  .btnVoltarLogin{
    display: flex;
    align-self: flex-start;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0px;
    height: 53px;
    font-weight: normal;
    color: #fff;
    background-color: #3fced2;
    &:hover {
      background-color: #3fced2;
    }
  }
  }

  div.go-back {
    display: flex;
    align-self: flex-start;
    padding: 15px;
    button {
      color: #3fced2;

      img {
        color: #3fced2;
      }
    }
  }
  div.email {
    display: flex;
    flex-direction: column;
    padding: 20px 50px 50px;
  }
  img.logo {
    width: 240px;
    height: 78px;
    margin-bottom: 40px;
    display: flex;
    align-self: center;
  }

  img {
    padding: 0 8px 0 0;
    height: 15px;
    width: 18px;
  }

  h2 {
    color: #3e4756;
    text-align: center;
    margin: 0px;
  }

  p {
    color: #3e4756;
  }

  .btnRecuperar {
    height: 53px;
    border-radius: 5px;
    text-transform: uppercase;
    color: white;
    background-color: #3fced2;
    &:hover {
      background-color: #3fced2;
    }
  }

  .MuiTextField-root {
    width: 350px;
    height: 53px;
    margin-bottom: 50px;
  }
`;
