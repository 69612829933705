import React, { RefObject, useEffect, useState } from 'react';
import { CardView, Container } from '../../styles';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  convertHTMLTimeLineHasNoBreakPageToPDF,
  convertHTMLToImage,
  copyImage,
} from '../../../../../../../helper/convertHTML';
import { Tooltip, Typography } from '@mui/material';
import { DATETYPEINPUT } from '../../../../../../../interfaces/timeline';
import { format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';
import ModalOptions from '../../../../../../../components/ModalOptions';
import { updateDownloadTimeLine } from '../../../../../../../services/models';
import { TimelineHorizontal } from '../timeline';

const chunkAmount = 5;
const timeLineElementwidth = 300;

interface IItem {
  date: Date | null;
  title: string;
  description: string;
  color?: string;
}

interface IProps {
  id: string;
  title: string;
  data: Array<IItem>;
  dateType: DATETYPEINPUT;
  refView: RefObject<HTMLDivElement>;
  fileName: string;
  isSaved: boolean;
  colWidth: number;
}

const HasNoBreakPage = ({ id, title, data, dateType, refView, fileName, isSaved, colWidth }: IProps) => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);

  const splitToChunks = (arr: IItem[]) => {
    const chunkSize = chunkAmount;
    return arr
      .map((e, i) => {
        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
  };

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  const downloadImage = () => {
    convertHTMLToImage(refView.current, fileName);
    updateCountDownloadTimeLine(id);
  };

  const downloadPDF = () => {
    convertHTMLTimeLineHasNoBreakPageToPDF(refView.current, fileName, 'l');
    updateCountDownloadTimeLine(id);
  };

  const copy = () => {
    const imageSize = data.length < chunkAmount ? data.length : chunkAmount;
    copyImage(refView.current, fileName, imageSize * timeLineElementwidth + 50, -1);
    setOpenToolTipCopyImage(true);
    updateCountDownloadTimeLine(id);
  };

  const updateCountDownloadTimeLine = (modelId: string) => {
    updateDownloadTimeLine(modelId).catch((err) => {
      console.log(err);
    });
  };

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="info-title">
        <p>Visualização do modelo</p>
        <div>
          <Tooltip title="Download imagem">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer', color: '#3E4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <ContentCopyOutlinedIcon
                style={{ cursor: 'pointer', color: '#3E4756' }}
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
              />
            </Tooltip>
          </Tooltip>
          <Tooltip title="Download PDF">
            <LocalPrintshopOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
            />
          </Tooltip>
        </div>
      </div>
      <div>
        <table id="#view" ref={refView} style={{ borderCollapse: 'collapse', background: '#fff' }}>
          <tr className="title" style={{ fontSize: 36 }}>
            <td style={{ textAlign: 'center', wordBreak: 'break-all' }}>{title}</td>
          </tr>
          {splitToChunks(data).map((row: IItem[] | any, rowIndex: number) => (
            <TimelineHorizontal key={rowIndex}>
              <ol>
                {row.map((column: IItem, columnIndex: number, arr: []) => (
                  <li
                    key={columnIndex}
                    data-year={
                      dateType === DATETYPEINPUT.DATETIME
                        ? column.date
                          ? format(new Date(column.date), 'dd/MM/yyy HH:mm')
                          : ''
                        : dateType === DATETYPEINPUT.DATE
                        ? column.date
                          ? format(new Date(column.date), 'dd/MM/yyy')
                          : ''
                        : dateType === DATETYPEINPUT.MONTHYEAR
                        ? column.date
                          ? format(new Date(column.date), 'MM/yyyy', {
                              locale: ptBR,
                            })
                          : ''
                        : column.date
                        ? new Date(column.date).getFullYear()
                        : ' '
                    }
                  >
                    <div>
                      <p style={{ fontSize: 22 }}>{column.title}</p>
                      <Typography style={{ fontSize: 18 }} component="span">
                        {column.description}
                      </Typography>
                    </div>
                    <span
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: 'calc(93%)',
                        bottom: 0,
                        width: '24px',
                        height: '24px',
                        border: '8px solid #fff',
                        transform: 'translateY(-50%)',
                        borderRadius: '50%',
                        zIndex: 999,
                        boxSizing: 'content-box',
                        backgroundColor: column.color == 'e6' ? 'lightgrey' : column.color,
                      }}
                    ></span>
                  </li>
                ))}
              </ol>
            </TimelineHorizontal>
          ))}
        </table>
      </div>
    </Container>
  );
};

export default HasNoBreakPage;
