import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 805px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const TopContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    color: #3e4756;
  }
  p {
    display: inline-block;
    margin: 0 auto;
    color: #9ba3a9;
    font-size: 24px;
    font-family: 'Mosk Semi Bold';

    .color-text {
      color: #3fced2;
      font-family: 'Mosk Semi Bold';
    }
  }
  span {
    font-size: 17px;
  }
`;

export const GreenText = styled.span`
  color: #00c2a7;
`;

export const RedText = styled.span`
  color: #f1504d;
`;

export const NameInput = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  > div {
    width: 50%;
  }
`;
export const Access = styled.table`
  color: #3e4756;
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    text-align: start;
    width: 100%;
  }
  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    th:nth-child(2),
    td:nth-child(2) {
      margin-left: 70px;
    }
    th:last-child,
    td:last-child {
      margin-right: -6%;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: flex-end;
  align-items: flex-end;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles({
  switch_base: {
    color: '#FF5F5D',
    '&.Mui-disabled': {
      color: '#FF5F5D',
    },
    '&.Mui-checked': {
      color: '#0ABF8F',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0ABF8F',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#0ABF8F',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0ABF8F',
    },
  },
  switch_track: {
    backgroundColor: '#FF5F5D',
  },
  disabled: {
    color: '#9BA3A9',
    '&.Mui-disabled': {
      color: '#9BA3A9',
    },
    '&.Mui-checked': {
      color: '#9BA3A9',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#9BA3A9',
    },
  },
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
