import { Button, IconButton, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Container, Content } from './styles';
import * as Yup from 'yup';
import jwt_decode from 'jwt-decode';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { recoverPassword } from '../../services/auth';
import { useHistory } from 'react-router-dom';
import { useAlert } from '../../contexts/alert';
import Logo from '../../assets/Logo-large.svg';
import { useLoading } from '../../contexts/loading';

const validationSchema = Yup.object({
  password: Yup.string().min(8, 'No minímo 8 caracteres').max(20, 'Máximo 20 caracteres').required('Obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas precisam ser iguais')
    .required('Obrigatório'),
});

interface IFirstAccess {
  email: string;
}

const FirstAccess: React.FC = () => {
  const history = useHistory();
  const { showLoading } = useLoading();
  const isFirstAccess = history.location?.pathname?.includes('primeiro');
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const { showAlertSuccess, showAlertError } = useAlert();
  const token = history.location?.search?.replace('?token=', '');
  const email = token && jwt_decode(token)?.unique_name[0];

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      showLoading(true);
      recoverPassword(email, values.password, token)
        .then((response) => {
          showLoading(false);
          showAlertSuccess(isFirstAccess ? 'Senha criada com sucesso' : 'Senha alterada com sucesso');
          history.push('/login');
        })
        .catch((err) => {
          console.log(err);
          showLoading(false);
          showAlertError('Ocorreu um erro.');
        });
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <Content elevation="5">
        <img src={Logo} />
        <h2>{isFirstAccess ? 'Primeiro acesso' : 'Redefinir senha'}</h2>
        <p>
          {isFirstAccess
            ? 'Você está criando uma senha para o e-mail:'
            : 'Você está redefinindo a sua senha para o e-mail:'}{' '}
          <strong>{email}</strong>{' '}
        </p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="password"
            name="password"
            variant="outlined"
            label="Informe sua senha"
            autoFocus
            tabIndex={0}
            type={!showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: !showPassword ? (
                <VisibilityOutlined onClick={handleClickShowPassword} />
              ) : (
                <VisibilityOffOutlined onClick={handleClickShowPassword} />
              ),
            }}
          />
          <TextField
            fullWidth
            id="confirmPassword"
            variant="outlined"
            name="confirmPassword"
            label="Confirme sua senha"
            tabIndex={1}
            type={!showPassword ? 'text' : 'password'}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            InputProps={{
              endAdornment: !showPassword ? (
                <VisibilityOutlined onClick={handleClickShowPassword} />
              ) : (
                <VisibilityOffOutlined onClick={handleClickShowPassword} />
              ),
            }}
          />
          <Button className="btnSalvar" variant="contained" fullWidth type="submit">
            Salvar
          </Button>
        </form>
      </Content>
    </Container>
  );
};

export default FirstAccess;
