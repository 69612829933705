import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { BoxStyle, Btns, Content, Info, Row, useStyles } from './styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getClientNewPlan, getClientPlan } from '../../../services/plans';
import { cancelSubscription } from '../../../services/externalUsers';
import { useAlert } from '../../../contexts/alert';
import { useAuth } from '../../../contexts/auth';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useLoading } from '../../../contexts/loading';
import { useHistory } from 'react-router-dom';
import { isValid } from 'date-fns';

const Plan: React.FC = ({ formik, plans, filterPlans, recurrenceTypes }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [clientPlan, setClientPlan] = useState(null);
  const { user } = useAuth();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [changePlanModal, setChangePlanModal] = useState<boolean | string>(false);
  const [cancelPlanModal, setCancelPlanModal] = useState<boolean | string>(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [note, setNote] = useState('');
  const handleOpen = () => setOpen(true);
  const { showAlertError, showAlertWarning, showAlertSuccess } = useAlert();
  const { showLoading } = useLoading();
  const handleClose = () => setOpen(false);
  const handleCloseModal = () => setShowModal(false);
  const [expiratePlan, setExpiratePlan] = useState('');
  const history = useHistory();

  const handleCloseChangePlanModal = () => {
    setChangePlanModal(false);
  };

  const handleSubmitPlan = () => {
    formik.setFieldValue('planId', changePlanModal);
    setChangePlanModal(false);
  };

  const handleCancelPlanModal = () => {
    handleClose();
    setCancelPlanModal(true);
  };

  const handleCloseCancelPlanModal = () => {
    setCancelPlanModal(false);
  };

  const handleShowModal = () => {
    showLoading(true);
    setCancelPlanModal(false);
    cancelSubscription(cancellationReason, note, user?.tokenData.ClientId)
      .then((response) => {
        if (isValid(new Date(response.data).toLocaleDateString('pt-BR'))) {
          setExpiratePlan(new Date(response.data).toLocaleDateString('pt-BR'));
        } else {
          setExpiratePlan('--/--/--');
        }
        showLoading(false);
        setShowModal(true);
      })
      .catch((error) => {
        showLoading(false);
        showAlertError(error?.data[0]?.ErrorMessage || 'Ocorreu um erro ao tentar cancelar a assinatura');
      });
  };

  const handleShowCancelModal = () => {
    if (user?.tokenData?.CancelDate) {
      showAlertWarning('Solicitação de cancelamento já realizada, por favor aguarde');
    } else {
      handleOpen();
    }
  };

  useEffect(() => {
    if (user?.tokenData.PlanId) {
      getClientPlan(user?.tokenData.PlanId, user?.tokenData.PlanRecurrenceType)
        .then((response) => {
          setClientPlan(response.data.value[0]);
        })
        .catch((error) => {
          showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar o plano do cliente.');
        });
    }
  }, []);

  useEffect(() => {
    if (formik.values.planId && formik.values.recurrenceTypeId != undefined) {
      getClientNewPlan(formik.values.planId, formik.values.recurrenceTypeId)
        .then((response) => {
          setClientPlan(response.data.value[0]);
        })
        .catch((error) => {
          showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar o plano do cliente.');
        });
    }
  }, [formik.values.planId, formik.values.recurrenceTypeId]);

  return (
    <>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: '20px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            backgroundColor: '#FFF',
            margin: '0px',
          }}
        >
          <BoxStyle>
            <div className="icon">
              <CheckCircleOutlineIcon className={classes.iconGreen} />
            </div>
            <div className="text-modal">
              <h2 style={{ margin: '0px', color: '#3E4756', fontSize: '32px' }}>Plano cancelado</h2>
              <p style={{ color: '#3E4756' }}>
                Cancelamos seu plano <label style={{ color: '#1976d2' }}>{user?.tokenData?.Plan}.</label> Seu serviço
                ficará disponível até {expiratePlan}
              </p>
            </div>
          </BoxStyle>
          <Btns>
            <Button className={classes.buttonBlue} onClick={handleCloseModal}>
              OK
            </Button>
          </Btns>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: '20px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            backgroundColor: '#FFF',
            margin: '0px',
          }}
        >
          <h2 style={{ marginTop: '0px', color: '#3E4756', fontSize: '32px' }}>Cancelamento de plano</h2>
          <p style={{ color: '#3E4756' }}>
            Você está prestes a cancelar o seu plano{' '}
            <label style={{ color: '#1976d2' }}>{user?.tokenData?.Plan}.</label>
          </p>
          <p>
            Ao cancelá-lo ele não será renovado e o acesso aos benefícios será encerrado a partir do ultimo dia pago.
          </p>

          <Btns>
            <Button className={classes.buttonBW} onClick={handleClose}>
              FECHAR
            </Button>
            <Button className={classes.buttonAllRed} onClick={handleCancelPlanModal}>
              CANCELAR ASSINATURA
            </Button>
          </Btns>
        </Box>
      </Modal>
      <Modal
        open={changePlanModal}
        onClose={handleCancelPlanModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: '20px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            backgroundColor: '#FFF',
            margin: '0px',
          }}
        >
          <h2 style={{ marginTop: '0px', color: '#3E4756', fontSize: '32px' }}>Troca de assinatura</h2>
          <p style={{ color: '#3E4756' }}>Tem certeza que deseja efetuar a troca de assinatura?</p>
          <Btns>
            <Button className={classes.buttonBW} onClick={handleCloseChangePlanModal}>
              FECHAR
            </Button>
            <Button className={classes.buttonAllRed} onClick={handleSubmitPlan}>
              SIM
            </Button>
          </Btns>
        </Box>
      </Modal>
      <Modal
        open={cancelPlanModal}
        onClose={handleCancelPlanModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: '20px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 692,
            backgroundColor: '#FFF',
            margin: '0px',
          }}
        >
          <h2 style={{ marginTop: '0px', color: '#3E4756', fontSize: '32px' }}>Qual motivo do cancelamento?</h2>
          <FormControl>
            <RadioGroup
              name="cancellationReason"
              value={cancellationReason}
              onChange={(event) => setCancellationReason(event.target.value)}
            >
              <FormControlLabel
                value="A plataforma não foi util para mim"
                control={<Radio />}
                label="A plataforma não foi util para mim"
              />
              <FormControlLabel
                value="Tive dificuldade em usar a plataforma"
                control={<Radio />}
                label="Tive dificuldade em usar a plataforma"
              />
              <FormControlLabel value="Outro motivo" control={<Radio />} label="Outro motivo" />
            </RadioGroup>
          </FormControl>

          <h2 style={{ marginTop: '20px', color: '#3E4756', fontSize: '16px' }}>
            Nos conte para que possamos melhorar nossos serviços:
          </h2>
          <div className={classes.cancelBoxField}>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              fullWidth
              placeholder="Escrever um comentário..."
              value={note}
              onChange={(event) => setNote(event.target.value)}
              inputProps={{
                maxLength: 500,
              }}
            />
          </div>

          <Btns>
            <Button className={classes.buttonBW} onClick={handleCloseCancelPlanModal}>
              FECHAR
            </Button>
            <Button className={classes.buttonAllRed} onClick={handleShowModal}>
              CANCELAR ASSINATURA
            </Button>
          </Btns>
        </Box>
      </Modal>

      <form>
        <Content>
          <div className="inputs">
            <FormControl fullWidth className="planId" variant="outlined">
              <InputLabel id="planId">Plano</InputLabel>
              <Select
                label="Plano"
                id="planId"
                name="planId"
                fullWidth
                value={formik.values.planId}
                onChange={(event) => {
                  setChangePlanModal(event.target.value);
                }}
                error={formik.touched.planId && Boolean(formik.errors.planId)}
                helperText={formik.touched.planId && formik.errors.planId}
              >
                {filterPlans?.map((plan) => {
                  return (
                    <MenuItem key={plan.id} value={plan.id} disabled={plan.name == 'Plano Gratuito'}>
                      {plan.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className="planPeriod"
              variant="outlined"
              error={formik.touched.recurrenceTypeId && Boolean(formik.errors.recurrenceTypeId)}
              helperText={formik.touched.recurrenceTypeId && formik.errors.recurrenceTypeId}
            >
              <InputLabel id="planPeriod">Período</InputLabel>
              <Select
                label="Período"
                id="recurrenceTypeId"
                name="recurrenceTypeId"
                fullWidth
                value={formik.values.recurrenceTypeId}
                onChange={(event) => formik.setFieldValue('recurrenceTypeId', event.target.value)}
                //disabled={user?.tokenData?.Plan == 'Plano Parceiro' ? true : false}
              >
                {recurrenceTypes?.map((recType) =>
                  recType?.price ? (
                    <MenuItem key={recType?.recurrenceTypeId} value={recType?.recurrenceTypeId}>
                      {recType.recurrenceType?.value}
                    </MenuItem>
                  ) : null
                )}
              </Select>
            </FormControl>
          </div>
          {user?.tokenData?.PlanActive == 'Ativo' ? (
            user?.tokenData?.Plan !== 'Plano Gratuito' ? (
              <p onClick={handleShowCancelModal} style={{ color: '#3FCED2', cursor: 'pointer' }}>
                Cancelar plano
              </p>
            ) : (
              ''
            )
          ) : (
            <p onClick={formik.handleSubmit} style={{ color: '#3FCED2', cursor: 'pointer' }}>
              Reativar plano
            </p>
          )}

          {clientPlan ? (
            <Info>
              <Row>
                <h1>{clientPlan?.name}</h1>
                {user?.tokenData?.PlanActive == 'Inativo' ? <span>Cancelado</span> : ''}
              </Row>
              <label>R$: {clientPlan?.recurrencePlans[0]?.price || 0}/mês</label>
              <br />
              <Divider />
              <br />
              <Row>
                <p>Quantidade de usuários:</p>
                <label>{clientPlan?.userIlimited ? 'Ilimitado' : clientPlan?.userLimit}</label>
              </Row>
              <Row>
                <p>Quantidade de modelos:</p>
                <label>{clientPlan?.modelIlimited ? 'Ilimitado' : clientPlan?.modelLimit}</label>
              </Row>
              <Row>
                <p>Dias trial:</p>
                <label>{clientPlan?.trialDays}</label>
              </Row>
              <Row>
                <p>Período:</p>
                <label>{clientPlan?.recurrencePlans[0]?.recurrenceType?.value}</label>
              </Row>
              {user?.tokenData?.PlanActive !== 'Inativo' ? (
                <Row>
                  <p>Assinado em:</p>
                  <label>
                    {clientPlan.id == user?.tokenData?.PlanId
                      ? new Date(user?.tokenData?.SubscriptionDate).toLocaleDateString('pt-BR')
                      : '--/--/--'}
                  </label>
                </Row>
              ) : (
                ''
              )}
              {user?.tokenData?.Expiration ? (
                <Row>
                  <p>Expira em:</p>
                  <label>
                    {clientPlan.id == user?.tokenData?.PlanId
                      ? new Date(user?.tokenData?.Expiration).toLocaleDateString('pt-BR')
                      : '--/--/--'}
                  </label>
                </Row>
              ) : (
                ''
              )}
            </Info>
          ) : (
            ''
          )}
        </Content>
      </form>
    </>
  );
};

export default Plan;
