import React from 'react';
import Modal from '@mui/material/Modal';
import YouTube, { YouTubeProps } from 'react-youtube';

export interface IVideoYoutube {
  videoId: string;
  titulo: string | null;
}

interface IProps {
  open: boolean;
  videoYoutube: IVideoYoutube;
  closeModal: () => void;
}

const VideoYoutube = ({ open, videoYoutube, closeModal }: IProps): React.ReactElement => {
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <>
        {videoYoutube.titulo !== null && <h1>{videoYoutube.titulo}</h1>}
        <YouTube videoId={videoYoutube.videoId} opts={opts} onReady={onPlayerReady} />
      </>
    </Modal>
  );
};

export default VideoYoutube;
