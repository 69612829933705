/* eslint-disable prettier/prettier */
import api from './api';
import { v4 as uuidv4 } from 'uuid';
import { onlyNumbers } from '../helper/numbers';

export const getExternalUsers = async (page, pageSize, search, filter) => {
  try {
    let route = `odata/Client?$count=true&$skip=${
      page * pageSize
    }&$top=${pageSize}&$expand=gender,uf,occupationArea,ownerUser,user,clientSubscriptions($filter=subscription/active eq true;$expand=subscription($expand=recurrencePlan($expand=plan)))&$orderby=user/name asc`;

    if (filter.length > 0 && !search) {
      route += `&$filter=`;
      filter.map((f, index, array) => {
        if (f.field === 'active') {
          if (f.value === ' ') {
            route += `${f.field} ne null`;
          } else {
            route += `${f.field} eq ${Boolean(Number(f.value))} `;
          }
        } else if (f.field === 'birthDate') {
          route += `${f.field.replaceAll('.', '/')} eq ${f.value}`;
        } else if (f.field == 'subscription.planStatus') {
          route += `${f.field.replaceAll('.', '/')} eq '${f.value}'`;
        } else {
          route += `contains(${f.field.replaceAll('.', '/')},'${f.value}')`;
        }
        if (index !== array.length - 1) {
          route += 'and ';
        }
      });
    }

    if (search) {
      //route += `&$filter=contains(user/name, '${search}') or contains(user/email, '${search}') or contains(gender/value, '${search}') or contains(uf/initials, '${search}') or contains(occupationArea/value, '${search}')  or contains(subscription/recurrencePlan/plan/name, '${search}')`;
      route += `&$filter=contains(user/name, '${search}') or contains(user/email, '${search}') or contains(uf/initials, '${search}') or contains(occupationArea/value, '${search}') or contains(gender/value, '${search}')`;
    }
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const createOwnerUser = async (values) => {
  let id = uuidv4();

  let body = {
    id: id,
    birthDate: values.birthDate,
    genderId: values.gender,
    phone: values.phone,
    oab: values.oab,
    ufId: values.state,
    occupationAreaId: values.occupation,
    company: values.work,
    color1: values.colors.firstColor,
    color2: values.colors.secondColor,
    color3: values.colors.thirdColor,
    color4: values.colors.fourthColor,
    color5: values.colors.fifthColor,
    user: {
      email: values.email,
      name: values.name,
      lastName: values.lastName,
      active: true,
    },
  };

  try {
    return await api.post('Client/Owner', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const editExternalUserById = async (values) => {
  try {
    return await api.put('Client', values);
  } catch (error) {
    return Promise.reject(error.response || error);
  }
};

export const getAccessProfiles = (page, pageSize, search, filter) => {
  try {
    // prettier-ignore
    let route = `odata/role?$orderby=Name asc${page || pageSize ? `&$count=true&$skip=${page * pageSize}&$top=${pageSize}` : ''}`;

    if (filter && filter.length > 0 && !search) {
      route += `&$filter=`;
      filter.map((f, index, array) => {
        if (f.field === 'active') {
          if (f.value === ' ') {
            route += `${f.field} ne null`;
          } else {
            route += `${f.field} eq ${Boolean(Number(f.value))} `;
          }
        } else {
          route += `contains(${f.field.replace('.', '/')}, '${f.value}')`;
        }
        if (index !== array.length - 1) {
          route += 'and ';
        }
      });
    }
    if (search) {
      route += `&$filter=contains(name, '${search}') ${
        search.toUpperCase() === 'ATIVO'
          ? `or active eq ${true}`
          : search.toUpperCase() === 'INATIVO'
          ? `or active eq ${false}`
          : ''
      }`;
    }
    return api.get(route);
  } catch (error) {
    console.log(error);
    return Promise.reject(error.response);
  }
};

export const getExternalUserById: AxiosResponse<unknown> = async (id) => {
  let route = `/odata/client?$filter=id eq ${id}&$expand=gender,uf,occupationArea,ownerUser,user,clientSubscriptions($filter=subscription/active eq true;$expand=subscription($expand=recurrencePlan))`;
  try {
    const response = await api.get(route);
    return response;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const updateExternalUserById: AxiosResponse<unknown> = async (values) => {
  try {
    return await api.put('Client', values);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const clientSubscription = async (values) => {
  let body = {
    id: values.id,
    planId: values.planId,
    recurrenceTypeId: values.recurrenceTypeId,
    creditCardOwnerName: values.creditCardOwnerName,
    documentNumber: onlyNumbers(values.documentNumber),
    creditCardNumber: onlyNumbers(values.creditCardNumber),
    creditCardDueDateMonth: values.creditCardDueDateMonth,
    creditCardDueDateYear: values.creditCardDueDateYear,
    securityCode: values.securityCode,
    automaticRenew: values.automaticRenew,
    subscriptionId: values.subscriptionId,
    customerId: values.customerId,
    hasCard: values.hasCard,
    changeCard: values.changeCard,
    ownerCEP: values.ownerCEP,
    ownerUF: values.ownerUF,
    ownerCity: values.ownerCity,
    ownerAddress: values.ownerAddress,
  };

  try {
    return await api.put('client/ClientSubscription', body);
  } catch (error) {
    return Promise.reject(error?.response || error);
  }
};

export const cancelSubscription = async (cancellationReason, note, clientId) => {
  let body = {
    cancellationReason: cancellationReason,
    note: note,
    clientId: clientId,
  };

  try {
    return await api.put('client/CancelClientSubscription', body);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getClientCard = async (clientId) => {
  try {
    return await api.get(`/Client/Card/${clientId}`);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getClientColors = async (clientId) => {
  let route = `odata/client?$filter=userId eq ${clientId}&$select=color1,color2,color3,color4,color5`;

  try {
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};
