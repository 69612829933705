import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Buttons, Container, Fields, GreenText, RedText, TopContent, useStyles } from './styles';
import { IExternalUser } from '../../../interfaces/externalUser';
import { useFormik } from 'formik';
import { createOwnerUser, editExternalUserById, getExternalUserById } from '../../../services/externalUsers';
import { getUf } from '../../../services/uf';
import { getPlans } from '../../../services/plans';
import { getOccupationArea } from '../../../services/occupationArea';
import { useAlert } from '../../../contexts/alert';
import { History } from 'history';
import { useLoading } from '../../../contexts/loading';
import { initialValues, validationSchema } from './validation';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isValid } from 'date-fns';
import { useAuth } from '../../../contexts/auth';
import { includeMaskCel } from '../../../helper/mask/celMask';
import { includeMaskTel } from '../../../helper/mask/telMask';

const ExternalUser = (): React.ReactElement => {
  const classes = useStyles();
  const history: History = useHistory();
  const [data, setData] = useState();
  const [occupations, setOccupations] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [plans, setPlans] = useState<any>([]);
  const [errorBirth, setErrorBirth] = useState(false);
  const { showAlertError, showAlertSuccess } = useAlert();
  const { showLoading } = useLoading();
  const { permissions } = useAuth();
  const userId = history.location.pathname.replace('/edicao-usuario-externo/', '');

  useEffect(() => {
    showLoading(true);
    if (isEditingUser) {
      getExternalUserById(userId)
        .then((response) => {
          showLoading(false);
          const values = response?.data?.value[0];

          let body = {
            email: values.user?.email,
            name: values.user?.name,
            lastName: values.user?.lastName,
            birthDate: values.birthDate,
            phone: values.phone,
            oab: values.oab,
            state: values.ufId,
            occupation: values.occupationAreaId,
            company: values.company,
            planName: values.clientSubscriptions[0]?.subscription?.recurrencePlan.planId,
            originalPlanId: values.clientSubscriptions[0]?.subscription?.recurrencePlan.planId,
            active: values.user.active,
            roleId: values.user.roleId,
            ownerUserId: values.ownerUserId,
            marketPlaceId: values.user?.marketPlaceId == null ? 1 : values.user?.marketPlaceId,
          };
          setData(response?.data?.value[0]);
          formik.setValues(body);
        })
        .catch((error) => {
          showLoading(false);
          if (error?.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError(error?.response?.data || 'Ocorreu um erro ao buscar os dados do usuário');
          }
        });
    }

    getPlans()
      .then((response) => {
        setPlans(response.data?.value);
      })
      .catch((error) => {
        if (error?.status === 401) {
          showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
        } else {
          showAlertError(error?.response?.data || 'Ocorreu um erro ao buscar os planos disponíveis');
        }
      });

    getUf()
      .then((response) => {
        setStates(response.data?.value);
      })
      .catch((error) => {
        if (error?.status === 401) {
          showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
        } else {
          showAlertError(error?.response?.data || 'Ocorreu um erro ao buscar os estados disponíveis');
        }
      });

    getOccupationArea()
      .then((response) => {
        setOccupations(response.data?.value);
      })
      .catch((error) => {
        if (error?.status === 401) {
          showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
        } else {
          showAlertError(error?.response?.data || 'Ocorreu um erro ao buscar as ocupações disponíveis');
        }
      });
  }, []);

  const isEditingUser = history.location.pathname.includes('edicao');

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveUser(values);
    },
  });

  const saveUser = (values: IExternalUser) => {
    showLoading(true);
    if (isEditingUser) {
      let body = { ...data };
      body.birthDate = values.birthDate;
      body.planId = values.planName;
      body.originalPlanId = values.originalPlanId;
      body.phone = values.phone;
      body.oab = values.oab;
      body.ufId = values.state;
      body.occupationAreaId = values.occupation;
      body.company = values.company;
      body.user = {
        id: data.user.id,
        email: values.email,
        name: values.name,
        active: values.active,
        lastName: data.user.lastName,
        roleId: data.user.roleId,
        marketPlaceId: values.marketPlaceId == null ? 1 : values.marketPlaceId,
      };
      delete body.clientSubscriptions;
      delete body.gender;
      delete body.uf;
      delete body.occupationArea;
      delete body.ownerUser;

      editExternalUserById(body)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Usuario externo editado com sucesso');
          history.push({ pathname: '/usuarios-externos', search: 'ExternalUsers' });
        })
        .catch((error) => {
          console.log(error);
          showLoading(false);
          if (error?.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError(error?.response?.data || 'Ocorreu um erro ao tentar realizar a alteração');
          }
        });
    } else {
      createOwnerUser(values)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Usuario externo cadastrado com sucesso');
          history.push({ pathname: '/usuarios-externos', search: 'ExternalUsers' });
        })
        .catch((error) => {
          showLoading(false);
          if (error?.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError(error?.response || 'Ocorreu um erro ao tentar realizar o cadastro');
          }
        });
    }
  };

  function handleDate(name: string, value: any) {
    let dateNow = new Date();

    if (isValid(value)) {
      if (value < dateNow) {
        let date = new Date(new Date(value).setHours(0, 0, 0)).toISOString();
        formik.setFieldValue(name, date);
        setErrorBirth(false);
      } else {
        setErrorBirth(true);
      }
    }
  }

  return (
    <Container elevation={3}>
      <TopContent>
        <div>
          <Link to={{ pathname: '/usuarios-externos', search: 'ExternalUsers' }}>
            <ArrowBackIos />
          </Link>
          <p>
            Usuários externos / <span className="color-text">Editar</span>
          </p>
        </div>
        <span>
          {permissions?.includes('CanDeleteExternalUsers') ? (
            <>
              {isEditingUser ? (
                formik.values.active ? (
                  <GreenText>Ativo</GreenText>
                ) : (
                  <RedText>Inativo</RedText>
                )
              ) : (
                'Ativo'
              )}

              <Switch
                disabled={!isEditingUser}
                checked={isEditingUser ? formik.values.active : true}
                name="status"
                onChange={(event) => {
                  const checked = event.target.checked;
                  formik.setFieldValue('active', checked);
                }}
                className={classes.disabled}
                classes={
                  isEditingUser
                    ? {
                        track: classes.switch_track,
                        switchBase: classes.switch_base,
                        colorPrimary: classes.switch_primary,
                      }
                    : {}
                }
              />
            </>
          ) : (
            ''
          )}
        </span>
      </TopContent>

      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Fields>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Nome"
              variant="outlined"
              name="name"
              error={Boolean(formik.errors.name)}
              helperText={formik?.errors?.name}
              value={formik.values.name || ''}
              onChange={formik.handleChange}
              className={classes.field}
              InputLabelProps={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Sobrenome"
              variant="outlined"
              name="lastName"
              error={Boolean(formik.errors.lastName)}
              helperText={formik?.errors?.lastName}
              value={formik.values.lastName || ''}
              onChange={formik.handleChange}
              className={classes.field}
              InputLabelProps={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
            >
              <DatePicker
                autoOk
                label="Data de nascimento"
                value={formik.values.birthDate == null ? null : new Date(formik.values.birthDate)}
                placeholder="dd/mm/aaaa"
                onChange={(value) => handleDate('birthDate', value)}
                maxDate={new Date()}
                disableFuture
                inputVariant="outlined"
                variant="inline"
                error={errorBirth}
                helperText={formik?.errors?.birthDate}
                format="dd/MM/yyyy"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="E-mail"
              variant="outlined"
              name="email"
              value={formik.values.email || ''}
              onChange={formik.handleChange}
              className={classes.field}
              InputLabelProps={{
                required: true,
              }}
              error={Boolean(formik.errors.email)}
              helperText={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Telefone"
              variant="outlined"
              name="phone"
              error={Boolean(formik.errors.phone)}
              helperText={formik?.errors?.phone}
              value={formik.values.phone || ''}
              onChange={(event) => {
                let value;
                if (event.target.value.length <= 14) {
                  value = includeMaskTel(event.target.value);
                } else {
                  value = includeMaskCel(event.target.value);
                }
                formik.setFieldValue('phone', value);
              }}
              className={classes.field}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="OAB"
              variant="outlined"
              name="oab"
              error={Boolean(formik.errors.oab)}
              helperText={formik?.errors?.oab}
              value={formik.values.oab || ''}
              onChange={formik.handleChange}
              className={classes.field}
              inputProps={{
                maxLength: 7,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.state && Boolean(formik.errors.state)}
            >
              <InputLabel id="state">Seccional</InputLabel>
              <Select
                name="state"
                labelId="state"
                id="state"
                label="Seccional"
                value={formik.values.state}
                onChange={formik.handleChange}
              >
                {states.map((uf) => (
                  <MenuItem key={uf.id} value={uf.id}>
                    <em>{uf.state}</em>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.touched.state && formik.errors.state}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.occupation && Boolean(formik.errors.occupation)}
            >
              <InputLabel id="occupation">Área de atuação</InputLabel>
              <Select
                name="occupation"
                labelId="occupation"
                id="occupation"
                label="Área de atuação"
                value={formik.values.occupation}
                onChange={formik.handleChange}
              >
                {occupations.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <em>{item.value}</em>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.touched.occupation && formik.errors.occupation}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="company"
              name="company"
              label="Empresa"
              variant="outlined"
              className={classes.field}
              value={formik.values.company}
              onChange={formik.handleChange}
              error={formik.touched.company && Boolean(formik.errors.company)}
              helperText={formik.touched.company && formik.errors.company}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.planName && Boolean(formik.errors.planName)}
            >
              <InputLabel id="planName">Plano</InputLabel>
              <Select
                name="planName"
                labelId="planName"
                id="planName"
                label="Plano"
                value={formik.values.planName}
                onChange={formik.handleChange}
                disabled={formik.values.marketPlaceId == 2 ? true : false}
              >
                {plans.map((plan) =>
                  plan.name == 'Plano Gratuito' || plan.name == 'Plano Parceiro' ? (
                    <MenuItem key={plan.id} value={plan.id}>
                      <em>{plan.name}</em>
                    </MenuItem>
                  ) : (
                    <MenuItem key={plan.id} value={plan.id} disabled>
                      <em>{plan.name}</em>
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>{formik.touched.planName && formik.errors.planName}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.marketPlaceId && Boolean(formik.errors.marketPlaceId)}
            >
              <InputLabel id="marketPaceId">Marketplace</InputLabel>
              <Select
                name="marketPlaceId"
                labelId="marketPlaceId"
                id="marketPlaceId"
                label="Marketplace"
                value={formik.values.marketPlaceId}
                onChange={formik.handleChange}
              >
                <MenuItem value="1" key="1">
                  Padrão
                </MenuItem>
                <MenuItem value="2" key="2">
                  Hotmart
                </MenuItem>
              </Select>
              <FormHelperText>{formik.touched.marketPlaceId && formik.errors.marketPlaceId}</FormHelperText>
            </FormControl>
          </Grid>
        </Fields>
        <Buttons>
          <Button
            className={classes.buttonRed}
            variant="outlined"
            onClick={() => {
              history.push('/usuarios-externos');
            }}
          >
            Cancelar
          </Button>
          <Button
            className={classes.buttonGreen}
            variant="contained"
            type="submit"
            disabled={!permissions?.includes('CanUpdateExternalUsers') ? true : false}
          >
            Salvar
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default ExternalUser;
