import React from 'react';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  id: Yup.string().nullable(),
  name: Yup.string().required('Campo de nome é obrigatório').min(3, 'Nome inválido').max(250, 'Nome inválido'),
  status: Yup.boolean(),
  initialDate: Yup.date().typeError('Data inválida').required('Campo de data de início é obrigatório'),
  endDate: Yup.date()
    .nullable()
    .typeError('Data inválida')
    .when('undetermined', {
      is: (undetermined: boolean) => !undetermined,
      then: Yup.date().typeError('Data inválida').required('Campo de data de fim é obrigatório'),
    }),
  undetermined: Yup.boolean(),
  userLimit: Yup.string()
    .when('userIlimited', {
      is: (userIlimited: boolean) => !userIlimited,
      then: Yup.string().required('Campo de limite de usuarios é obrigatório').nullable(),
    })
    .nullable(),
  userIlimited: Yup.boolean(),
  modelLimit: Yup.string()
    .when('modelIlimited', {
      is: (modelIlimited: boolean) => !modelIlimited,
      then: Yup.string()
        .max(2, 'Número inválido')
        .required('Campo de limite de modelos salvo por usuário é obrigatório')
        .nullable(),
    })
    .nullable(),
  modelIlimited: Yup.boolean(),
  monthlyPrice: Yup.number().nullable(),
  quarterlyPrice: Yup.number().nullable(),
  yearlyPrice: Yup.number().nullable(),
  automaticRenewal: Yup.boolean(),
  trialDays: Yup.string().required('Campo de dias trial é obrigatório'),
  readjustmentValue: Yup.string().nullable(),
  readjustmentDays: Yup.string().nullable(),
  pagarMEPlanId: Yup.string().nullable(),
});

export const initialValues = {
  id: null,
  name: '',
  status: true,
  initialDate: null,
  endDate: null,
  undetermined: false,
  userLimit: '',
  userIlimited: false,
  modelLimit: '',
  modelIlimited: false,
  recurrenceMountTypeId: null,
  readjustmentValue: '',
  readjustmentDays: '',
  pagarMEPlanId: '',
  recurrencePlans: [
    {
      id: null,
      price: 0,
    },
    {
      id: null,
      price: 0,
    },
    {
      id: null,
      price: 0,
    },
  ],
  automaticRenewal: true,
  trialDays: '',
};
