import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { CheckCircleOutline, WarningAmber } from '@mui/icons-material';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useAlert } from '../contexts/alert';
import InternalUserMenu from '../components/InternalUserMenu';
import ExternalUserMenu from '../components/ExternalUserMenu';
import ExpirateUserMenu from '../components/ExpirateUserMenu';
import { internalUserRoutes } from './internalUserRoutes';
import { externalUserRoutes } from './externalUserRoutes';
import { expiratePlanRoutes } from './expiratePlanRoutes';
import { publicRoutes } from './publicRoutes';
import { useAuth } from '../contexts/auth';
import { useLoading } from '../contexts/loading';
import { CircularProgress, Collapse } from '@material-ui/core';
import { compareDesc, format } from 'date-fns';

const PrivateComponent = () => {
  const { user } = useAuth();
  const [expiredPlan, setExpiredPlan] = useState(false);

  useEffect(() => {
    if (user?.tokenData?.Expiration) {
      let expire = new Date(user?.tokenData?.Expiration);
      compareDate(expire);
    }

    if (user?.tokenData?.PlanActive == 'Inativo') {
      setExpiredPlan(true);
    }
  }, [user?.tokenData]);

  const compareDate = (expire: Date) => {
    let result = compareDesc(new Date(), expire);

    if (result == -1) {
      setExpiredPlan(true);
    }
  };

  return user?.tokenData?.UserType === 'Internal User' ? (
    //usuario interno
    <>
      {internalUserRoutes.map((route) => {
        const Component = route.component;
        return (
          <React.Fragment key={route.name}>
            <Route
              exact
              path={route.url}
              render={() =>
                user?.authenticated ? (
                  <InternalUserMenu>
                    <Component />
                  </InternalUserMenu>
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          </React.Fragment>
        );
      })}
    </>
  ) : expiredPlan ? (
    //usuario externo plano expirado
    <>
      {expiratePlanRoutes.map((route) => {
        const Component = route.component;
        return (
          <React.Fragment key={route.name}>
            <Route
              exact
              path={route.url}
              render={() =>
                user?.authenticated ? (
                  <ExpirateUserMenu>
                    <Component />
                  </ExpirateUserMenu>
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          </React.Fragment>
        );
      })}
    </>
  ) : (
    //usuario externo
    <>
      {externalUserRoutes.map((route) => {
        const Component = route.component;

        return user?.authenticated ? (
          <React.Fragment key={route.name}>
            <Route
              exact
              path={route.url}
              render={() =>
                user?.authenticated ? (
                  <ExternalUserMenu>
                    <Component />
                  </ExternalUserMenu>
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          </React.Fragment>
        ) : (
          <Redirect to="/login" />
        );
      })}
    </>
  );
};

const routes: React.FC = () => {
  const { loading } = useLoading();
  const {
    messageAlertError,
    messageAlertWarning,
    messageAlertSucess,
    showAlertError,
    showAlertWarning,
    showAlertSuccess,
  } = useAlert();

  useEffect(() => {
    setTimeout(() => {
      showAlertSuccess('');
    }, 6000);
  }, [messageAlertSucess]);

  useEffect(() => {
    setTimeout(() => {
      showAlertError('');
    }, 6000);
  }, [messageAlertError]);

  useEffect(() => {
    setTimeout(() => {
      showAlertWarning('');
    }, 9000);
  }, [messageAlertWarning]);

  return (
    <>
      {loading && (
        <div className="loading">
          <CircularProgress size={60} style={{ color: 'black' }} />
        </div>
      )}
      <div
        style={{
          width: '100%',
          position: 'fixed',
          zIndex: 2000,
          display: 'flex',
          justifyContent: 'center',
          margin: '0 auto',
        }}
      >
        {messageAlertSucess && (
          <Collapse in={Boolean(messageAlertSucess)}>
            <Alert
              icon={<CheckCircleOutline fontSize="inherit" />}
              onClose={() => {
                showAlertSuccess('');
              }}
              variant="filled"
              severity="success"
            >
              {messageAlertSucess}
            </Alert>
          </Collapse>
        )}
        {messageAlertWarning && (
          <Collapse in={Boolean(messageAlertWarning)}>
            <Alert
              icon={<WarningAmber fontSize="inherit" />}
              onClose={() => {
                showAlertWarning('');
              }}
              variant="filled"
              severity="warning"
            >
              {messageAlertWarning}
            </Alert>
          </Collapse>
        )}
        {messageAlertError && (
          <Collapse in={Boolean(messageAlertError)}>
            <Alert
              icon={<CheckCircleOutline fontSize="inherit" />}
              onClose={() => {
                showAlertError('');
              }}
              variant="filled"
              severity="error"
            >
              {messageAlertError}
            </Alert>
          </Collapse>
        )}
      </div>
      <BrowserRouter>
        <Switch>
          {publicRoutes.map((route) => {
            const Component = route.component;
            return <Route key={route.name} exact path={route.url} component={Component} />;
          })}
          <Route path="/" component={PrivateComponent} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default routes;
