import React, { useCallback, useEffect, useState } from 'react';
import { Container } from './styles';
import { Tooltip } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { convertHTMLToImage, copyImage, downloadPDFFlowChart } from '../../../../helper/convertHTML';
import { formatHorizontalData } from '../formatData';
import FlowChartView from '../FlowChartView';
import ModalOptions from '../../../../components/ModalOptions';
import localforage from 'localforage';
import { updateDownloadFlowChart } from '../../../../services/models';

localforage.config({
  name: 'react-flow-docs',
  storeName: 'flows',
});
interface IItem {
  id?: string;
  parents: Array<IParents>;
  description: string;
  color: string;
  HorizontalPosition: IPosition;
  VerticalPosition: IPosition;
}

interface IPosition {
  x: number;
  y: number;
}

interface IParents {
  id?: string;
  description: string;
}

interface IProps {
  id: string;
  titleText: string;
  data: Array<Array<IItem>>;
  reference: any;
  setData: (data: Array<Array<IItem>>) => void;
  fileName: string;
  isSaved: boolean;
}
const HorizontalView: React.ReactElement = ({ id, data, setData, titleText, reference, fileName, isSaved }: IProps) => {
  const [minWidth, setMinWidth] = useState(900);
  const [minHeight, setMinHeight] = useState(600);
  const [elements, setElements] = useState();
  const [rfInstance, setRfInstance] = useState(null);
  const [updateFlowChart, setUpdateFlowChart] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [backgroundPattern, setBackgroundPattern] = useState('lines');

  const flowKey = 'example-flow';

  useEffect(() => {
    setElements([{ sourcePosition: 'right', targetPosition: 'left', data: { label: '' } }]);
    const { elementsFormatted, width, height } = formatHorizontalData(data, setData);
    setBackgroundPattern('lines');
    setMinWidth(width);
    setMinHeight(height);
    setElements(elementsFormatted);
    setUpdateFlowChart(!updateFlowChart);
  }, [data, setElements]);

  useEffect(() => {
    onSave();
    setTimeout(() => {
      onRestore();
    }, 1000);
  }, [updateFlowChart]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = await localforage.getItem(flowKey);

      if (flow) {
        setElements(flow.elements || []);
      }
    };
    restoreFlow();
  }, [setElements]);

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localforage.setItem(flowKey, flow);
    }
  }, [rfInstance]);

  const changePositionOfOriginalData = (node: any) => {
    let indexesOriginalArray = [];
    data.map((level, indexLevel) => {
      level.map((element, indexElement) => {
        if (element.id == node.id) indexesOriginalArray = [indexLevel, indexElement];
      });
    });

    //if (node.position?.x <= 0 || node.position?.y <= 0) {
    //  setData(data);
    //  return;
    //}

    let auxOriginalData = [...data];
    if (auxOriginalData[indexesOriginalArray[0]] && auxOriginalData[indexesOriginalArray[0]][indexesOriginalArray[1]])
      auxOriginalData[indexesOriginalArray[0]][indexesOriginalArray[1]].HorizontalPosition = node.position;
    setData(auxOriginalData);
  };

  useEffect(() => {
    setBackgroundPattern('none');
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      setTimeout(() => {
        downloadImage();
      }, 1000);
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      setTimeout(() => {
        downloadPDF();
      }, 1000);
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
    setTimeout(() => {
      setBackgroundPattern('lines');
    }, 1000);
  }, [controlDownloadModal]);

  const downloadImage = () => {
    convertHTMLToImage(reference.current, fileName);
    updateCountDownloadFlowChart(id);
  };

  const downloadPDF = () => {
    downloadPDFFlowChart(reference.current, fileName, 'l');
    updateCountDownloadFlowChart(id);
  };

  const copy = () => {
    copyImage(reference.current, fileName);
    setOpenToolTipCopyImage(true);
    updateCountDownloadFlowChart(id);
  };

  const updateCountDownloadFlowChart = (modelId: string) => {
    updateDownloadFlowChart(modelId).catch((err) => {
      console.log(err);
    });
  };

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="info-title">
        <p>Visualização do modelo</p>
        <div>
          <Tooltip title="Download imagem">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <ContentCopyOutlinedIcon
                style={{ cursor: 'pointer', color: '#3E4756' }}
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
              />
            </Tooltip>
          </Tooltip>

          <Tooltip title="Download PDF">
            <LocalPrintshopOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
            />
          </Tooltip>
        </div>
      </div>
      <FlowChartView
        titleText={titleText}
        minWidth={minWidth}
        minHeight={minHeight}
        elements={elements}
        reference={reference}
        nodesConnectable={false}
        changePositionOfOriginalData={changePositionOfOriginalData}
        setRfInstance={setRfInstance}
        backgroundPattern={backgroundPattern}
      />
    </Container>
  );
};

export default HorizontalView;
