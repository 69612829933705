import React, { RefObject, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { convertHTMLToImage, copyImage, downloadPDFFlowChart } from '../../../../helper/convertHTML';
import { Container } from './styles';
import Chart from '../../../../components/Chart';
import { DIAGRAMTYPE } from '../../../../interfaces/diagram';
import ModalOptions from '../../../../components/ModalOptions';
import { updateDownloadDiagram } from '../../../../services/models';

interface IProps {
  id: string;
  refView: RefObject<HTMLTableElement>;
  titleText?: string;
  horizontalText?: string;
  verticalText?: string;
  data: any;
  diagramSelect: string;
  position: number;
  fileName: string;
  isSaved: string;
}

const ViewDiagram = ({
  id,
  isSaved,
  horizontalText,
  verticalText,
  refView,
  titleText,
  data,
  diagramSelect,
  position,
  fileName,
}: IProps) => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [diagramType, setDiagramType] = useState<string>('');
  const [controlDownloadModal, setControlDownloadModal] = useState(null);

  useEffect(() => {
    if (diagramSelect == DIAGRAMTYPE.BAR) {
      if (position == 0) {
        setDiagramType('bar');
      } else {
        setDiagramType('column');
      }
    } else {
      setDiagramType('line');
    }
  }, [diagramSelect, position]);

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  const downloadPDF = () => {
    downloadPDFFlowChart(refView.current, fileName, 'l');
    updateCountDownloadDiagram(id);
  };

  const downloadImage = () => {
    convertHTMLToImage(refView.current, fileName);
    updateCountDownloadDiagram(id);
  };

  const copy = () => {
    copyImage(refView.current, fileName, -1, refView.current?.offsetHeight + 50);
    setOpenToolTipCopyImage(true);
    updateCountDownloadDiagram(id);
  };

  const updateCountDownloadDiagram = (modelId: string) => {
    updateDownloadDiagram(modelId).catch((err) => {
      console.log(err);
    });
  };

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="informative-title">
        <p>Visualização do modelo</p>
        <div>
          <Tooltip title="Download">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer', color: '#3e4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <ContentCopyOutlinedIcon
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
                style={{ cursor: 'pointer', color: '#3e4756' }}
                className="material-icons-outlined"
              />
            </Tooltip>
          </Tooltip>
          <Tooltip title="Imprimir">
            <LocalPrintshopOutlinedIcon
              style={{ cursor: 'pointer', color: '#3e4756' }}
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
            />
          </Tooltip>
        </div>
      </div>
      <br />
      <div id="page" style={{ overflow: 'auto' }}>
        <div
          className="page"
          ref={refView}
          style={{ minWidth: 700, maxWidth: 1200, minHeight: 300, width: '100%', background: '#fff' }}
        >
          <h1 className="diagram-title" style={{ wordBreak: 'break-all' }}>
            {titleText}
          </h1>
          <div id="#view">
            <Chart {...{ horizontalText, verticalText, diagramData: data, diagramType, position }} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default React.memo(ViewDiagram);
