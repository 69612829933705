import React from 'react';
// eslint-disable-next-line sort-imports
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function ModelsChart({ values }: any) {
  const state = {
    labels: ['Fluxograma', 'Gráfico', 'Linha do Tempo', 'QR Code', 'Tabela', 'Texto Aprimorado', 'Resumo Jurídico'],
    datasets: [
      {
        backgroundColor: ['#FFE0E6', '#EBE0FF', '#FFF5DD', '#DBF2F2', '#D7ECFB'],
        borderColor: ['#FC9EB2', '#D8C5FF', '#FFF1D1', '#C8E6E6', '#B7DDF8'],
        borderWidth: 2,
        data: values,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'none',
      },
    },
  };

  return <Bar data={state} options={options} height={100} />;
}
