import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div`
  margin-top: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .MuiTextField-root {
    margin-bottom: 7px;
  }

  .main {
    width: 65%;

    h1 {
      font-size: 24px;
      color: #3fced2;
      font-family: 'Mosk Semi Bold';
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: 20px;
  margin-bottom: 20px;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
