import React from 'react';
import ActiveSelect from '../../../components/SelectTrueOrFalseComponentTable';
import { GreenText, RedText } from './styles';

const columns = [
  { title: 'Nome', field: 'user.name' },
  { title: 'E-mail', field: 'user.email' },
  {
    title: 'Situação',
    field: 'user.active',
    render: (rowData) => {
      if (rowData?.user?.active) {
        return (
          <p style={{ width: 150, margin: 0 }}>
            <GreenText>Ativo</GreenText>
          </p>
        );
      } else {
        return (
          <p style={{ width: 150, margin: 0 }}>
            <RedText>Inativo</RedText>
          </p>
        );
      }
    },
    filterComponent: (props: any): React.ReactElement => {
      return <ActiveSelect {...props} />;
    },
  },
];
export default columns;
