import styled from 'styled-components';

export const Container = styled.div`
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #e6e6e6;
  max-width: 100%;

  .informative-title {
    color: #3e4756;
  }

  table {
    border-collapse: collapse;
  }

  tr {
    height: 81px;
  }

  .table-model {
    width: 100%;
  }
  .diagram-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 36px;
    font-weight: 500;
    word-break: break-all;
  }

  #page {
    height: calc(100vh - 225px);
  }
`;
