import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  height: auto;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const Content = styled(Paper)`
  max-width: 90%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  @media (max-width: 600px) {
    max-width: 100%;
    padding: 10px;
  }

  .go-back-btn {
    display: flex;
    align-self: flex-start;
    text-transform: capitalize;
    font-size: 16px;
    padding: 0px;
    font-weight: normal;
    color: #1976d2;
  }

  div.go-back {
    display: flex;
    align-self: flex-start;
    padding: 15px;
  }

  img {
    padding: 0 8px 0 0;
    height: 15px;
    width: 18px;
  }

  img.logo {
    width: 240px;
    height: 78px;
    margin-bottom: 40px;
    display: flex;
    align-self: center;
  }
`;
