import styled from 'styled-components';
import { Grid } from '@mui/material';

export const GridContente = styled(Grid)`
  .title {
    font-size: 18px;
    color: #3e4756;
    font-family: 'Mosk Semi Bold';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
