import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

interface InitContextProps {
  messageAlertSucess: string;
  showAlertSuccess: Dispatch<SetStateAction<string>>;
  messageAlertError: string;
  showAlertError: Dispatch<SetStateAction<string>>;
  messageAlertWarning: string;
  showAlertWarning: Dispatch<SetStateAction<string>>;
  expireMessage: boolean;
  showExpireMessage: Dispatch<SetStateAction<boolean>>;
}

const AlertContext = createContext<InitContextProps>({
  messageAlertSucess: '',
  showAlertSuccess: () => {},
  messageAlertError: '',
  showAlertError: () => {},
  messageAlertWarning: '',
  showAlertWarning: () => {},
  expireMessage: false,
  showExpireMessage: () => {},
});

export const AlertProvider = ({ children }: any) => {
  const [messageAlertSucess, setMessageAlertSucess] = useState<string>('');
  const [messageAlertError, setMessageAlertError] = useState<string>('');
  const [messageAlertWarning, setMessageAlertWarning] = useState<string>('');
  const [expireMessage, setExpireMessage] = useState<boolean>(true);

  return (
    <AlertContext.Provider
      value={{
        messageAlertSucess,
        showAlertSuccess: setMessageAlertSucess,
        messageAlertError,
        showAlertError: setMessageAlertError,
        messageAlertWarning,
        showAlertWarning: setMessageAlertWarning,
        expireMessage,
        showExpireMessage: setExpireMessage,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export function useAlert() {
  const context = useContext(AlertContext);

  return {
    messageAlertSucess: context.messageAlertSucess,
    showAlertSuccess: context?.showAlertSuccess,
    messageAlertError: context?.messageAlertError,
    showAlertError: context?.showAlertError,
    messageAlertWarning: context?.messageAlertWarning,
    showAlertWarning: context?.showAlertWarning,
    expireMessage: context?.expireMessage,
    showExpireMessage: context?.showExpireMessage,
  };
}
