import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Nome obrigatório'),
  birthDate: Yup.date().max(new Date(), 'Não é possível incluir uma data futura').nullable(),
  email: Yup.string().email('E-mail Inválido').required('E-mail obrigatório'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'O e-mail não corresponde')
    .required('Confirmação de e-mail é obrigatória.'),
  oab: Yup.string(),
  occupation: Yup.string().when('oab', {
    is: (oab) => oab,
    then: Yup.string().required('Área de atuação é obrigatória quando OAB é preenchido.'),
  }),
  lastName: Yup.string().required('Sobrenome obrigatório'),
  gender: Yup.string(),
  phone: Yup.string()
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, { message: 'Número de telefone inválido', excludeEmptyString: false })
    .required('Telefone é obrigatório'),
  state: Yup.string().when('oab', {
    is: (oab) => oab,
    then: Yup.string().required('Seccional é obrigatório quando OAB é preenchido'),
  }),
  work: Yup.string(),
  terms: Yup.boolean().oneOf([true], 'Necessita aceitar os Termos e Condições'),
  acceptNews: Yup.boolean(),
  colors: Yup.object({
    firstColor: Yup.string(),
    secondColor: Yup.string(),
    thirdColor: Yup.string(),
    fourthColor: Yup.string(),
    fifthColor: Yup.string(),
  }),
});

export const initialValues = {
  name: '',
  birthDate: null,
  email: '',
  confirmEmail: '',
  oab: '',
  occupation: '',
  lastName: '',
  gender: '',
  phone: '',
  state: '',
  work: '',
  terms: false,
  acceptNews: false,
  colors: {
    firstColor: '',
    secondColor: '',
    thirdColor: '',
    fourthColor: '',
    fifthColor: '',
  },
};
