import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('O preenchimento do nome é obrigatório'),
  lastName: Yup.string().required('O preenchimento do sobrenome é obrigatório'),
  birthDate: Yup.date().nullable(),
  phone: Yup.string().required('O preenchimento do número do telefone é obrigatório'),
  company: Yup.string().nullable(),
  planName: Yup.string(),
  roleId: Yup.string().nullable(),
  ownerUserId: Yup.string().nullable(),
  email: Yup.string().email('E-mail Inválido').required('O preenchimento do e-mail obrigatório'),
  oab: Yup.string().nullable(),
  occupation: Yup.string()
    .when('oab', {
      is: (oab) => oab,
      then: Yup.string().required('Área de atuação é obrigatória quando OAB é preenchido.').nullable(),
    })
    .nullable(),
  state: Yup.string()
    .when('oab', {
      is: (oab) => oab,
      then: Yup.string().required('Seccional é obrigatório quando OAB é preenchido.').nullable(),
    })
    .nullable(),
});

export const initialValues = {
  email: '',
  name: '',
  lastName: '',
  birthDate: null,
  phone: '',
  oab: '',
  state: '',
  occupation: '',
  company: '',
  planName: '',
  active: true,
  roleId: '',
  ownerUserId: '',
  marketPlaceId: 1,
};
