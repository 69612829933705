import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoYoutube, { IVideoYoutube } from '../VideoYouTube';
import { Content } from './styles';
import { Link } from 'react-router-dom';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface IProps {
  videoYoutube: IVideoYoutube;
  titulo: string;
  descricao: string;
  link: string;
  imagem: any;
}

const ItemModelo = ({ videoYoutube, imagem, titulo, link, descricao }: IProps): React.ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const [controlModalYouTube, setControlModalYouTube] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleModalYoutubeClick = () => {
    setControlModalYouTube(true);
  };

  return (
    <Content>
      <VideoYoutube
        closeModal={() => setControlModalYouTube(false)}
        open={controlModalYouTube}
        videoYoutube={videoYoutube}
      />
      <Link to={link} style={{ textDecoration: 'none' }}>
        <CardMedia className="background" component="img" height="131" image={imagem} alt="Modelo da Imagem" />
        <CardContent style={{ padding: 8 }}>
          <Typography className="titulo">{titulo}</Typography>
        </CardContent>
      </Link>
      <CardActions style={{ padding: 0 }} disableSpacing>
        <IconButton aria-label="Video demostrativo" onClick={handleModalYoutubeClick}>
          <YouTubeIcon className="btnYouTube" />
        </IconButton>
        {/* <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore> */}
      </CardActions>
      {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Descritivo:</Typography>
            <Typography paragraph>{descricao}</Typography>
          </CardContent>
        </Collapse> */}
    </Content>
  );
};

export default ItemModelo;
