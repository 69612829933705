import styled from 'styled-components';
import { Paper, makeStyles } from '@material-ui/core';

export const Container = styled(Paper)`
  padding: 20px;
  width: 100%;
  max-width: 100vw;
  margin-top: 14px;

  .MuiTextField-root {
    height: auto;
  }
  .info-title {
    color: #3e4756;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    color: #3e4756;
  }

  a {
    margin-right: 10px;
  }
  p {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #9ba3a9;
    font-size: 24px;
    font-family: 'Mosk Semi Bold';

    .color-text {
      font-size: 24px;
      color: #3fced2;
      font-family: 'Mosk Semi Bold';
    }
  }
`;

export const Content = styled.div`
  padding: 20px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  .inputs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 45px;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
