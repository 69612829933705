import React, { RefObject, useEffect, useState } from 'react';
import { CardView, Container } from '../../styles';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  convertHTMLTimeLineHasNoBreakPageToPDF,
  convertHTMLToImage,
  copyImage,
} from '../../../../../../../helper/convertHTML';
import { Tooltip } from '@mui/material';
import { DATETYPEINPUT } from '../../../../../../../interfaces/timeline';
import { format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';
import ModalOptions from '../../../../../../../components/ModalOptions';
import { updateDownloadTimeLine } from '../../../../../../../services/models';

interface IItem {
  date: Date | null;
  title: string;
  description: string;
  color?: string;
}

interface IProps {
  id: string;
  title: string;
  data: Array<IItem>;
  dateType: DATETYPEINPUT;
  refView: RefObject<HTMLDivElement>;
  fileName: string;
  isSaved: boolean;
  colWidth: number;
}

const HasNoBreakPage = ({ id, title, data, dateType, refView, fileName, isSaved, colWidth }: IProps) => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);

  const splitToChunks = (arr: IItem[]) => {
    const chunkSize = 5;
    return arr
      .map((e, i) => {
        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
  };

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  const downloadImage = () => {
    convertHTMLToImage(refView.current, fileName);
    updateCountDownloadTimeLine(id);
  };

  const downloadPDF = () => {
    convertHTMLTimeLineHasNoBreakPageToPDF(refView.current, fileName, 'l');
    updateCountDownloadTimeLine(id);
  };

  const copy = () => {
    copyImage(refView.current, fileName);
    setOpenToolTipCopyImage(true);
    updateCountDownloadTimeLine(id);
  };

  const updateCountDownloadTimeLine = (modelId: string) => {
    updateDownloadTimeLine(modelId).catch((err) => {
      console.log(err);
    });
  };

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="info-title">
        <p>Visualização do modelo</p>
        <div>
          <Tooltip title="Download imagem">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer', color: '#3E4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <ContentCopyOutlinedIcon
                style={{ cursor: 'pointer', color: '#3E4756' }}
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
              />
            </Tooltip>
          </Tooltip>
          <Tooltip title="Download PDF">
            <LocalPrintshopOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
            />
          </Tooltip>
        </div>
      </div>
      <div>
        <table id="#view" ref={refView} style={{ borderCollapse: 'collapse', background: '#fff' }}>
          <tr className="title" style={{ fontSize: 24 }}>
            <td style={{ textAlign: 'start', wordBreak: 'break-all' }}>{title}</td>
          </tr>
          {splitToChunks(data).map((row: IItem[] | any, rowIndex: number) => (
            <div key={rowIndex}>
              <tr style={{ display: 'flex', marginTop: 20 }}>
                {row.map((column: IItem, columnIndex: number, arr: []) => (
                  <td
                    key={columnIndex}
                    style={{
                      padding: 0,
                      height: 40,
                      minWidth: 350,
                    }}
                  >
                    {dateType === DATETYPEINPUT.DATETIME ? (
                      <p
                        style={{
                          backgroundColor: column.color,
                          margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                          padding: '10px 20px',
                          height: 40,
                          minWidth: 350,
                          width: colWidth,
                          borderRadius: '12px',
                        }}
                      >
                        {column.date ? format(new Date(column.date), 'dd/MM/yyy HH:mm') : ''}
                      </p>
                    ) : dateType === DATETYPEINPUT.DATE ? (
                      <p
                        style={{
                          backgroundColor: column.color,
                          margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                          padding: '10px 20px',
                          height: 40,
                          minWidth: 350,
                          width: colWidth,
                          borderRadius: '12px',
                        }}
                      >
                        {column.date ? format(new Date(column.date), 'dd/MM/yyy') : ''}
                      </p>
                    ) : dateType === DATETYPEINPUT.MONTHYEAR ? (
                      <p
                        style={{
                          backgroundColor: column.color,
                          margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                          padding: '10px 20px',
                          height: 40,
                          minWidth: 350,
                          width: colWidth,
                          borderRadius: '12px',
                        }}
                      >
                        {column.date
                          ? format(new Date(column.date), 'MM/yyyy', {
                              locale: ptBR,
                            })
                          : ''}
                      </p>
                    ) : (
                      <p
                        style={{
                          backgroundColor: column.color,
                          margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                          padding: '10px 20px',
                          height: 40,
                          minWidth: 350,
                          width: colWidth,
                          borderRadius: '12px',
                        }}
                      >
                        {column.date ? new Date(column.date).getFullYear() : ' '}
                      </p>
                    )}
                  </td>
                ))}
              </tr>
              <tr style={{ display: 'flex', minWidth: 350, width: colWidth }}>
                {row.map((column: IItem, columnIndex: number, arr: []) => (
                  <td key={columnIndex}>
                    <p style={{ width: colWidth, marginLeft: 8, minWidth: 350, padding: '10px 20px' }}>
                      <CardView>
                        <p
                          style={{
                            fontWeight: 'bold',
                            margin: columnIndex !== arr.length - 1 ? '0px 10px 0px 0px' : '0px',
                            marginTop: 4,
                            wordBreak: 'break-all',
                            width: colWidth,
                          }}
                        >
                          {column.title}
                        </p>
                        <p
                          style={{
                            color: '#3E4756',
                            margin: columnIndex !== arr.length - 1 ? '0px 10px 0px 0px' : '0px',
                            padding: '10px 10px',
                            marginBottom: 17,
                            minHeight: '50%',
                            marginTop: 25,
                            wordBreak: 'break-all',
                            width: colWidth,
                          }}
                        >
                          {column.description}
                        </p>
                      </CardView>
                    </p>
                  </td>
                ))}
              </tr>
            </div>
          ))}
        </table>
      </div>
    </Container>
  );
};

export default HasNoBreakPage;
