import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ColorsContent, ColorsTitle, ContainerCheckbox, TableContent, TextAccount, useStyles } from './styles';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModalColors from '../../../../components/ModalChooseColor';
import { get } from 'lodash';
import { useAlert } from '../../../../contexts/alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IExternalUser } from '../../../../interfaces/externalUser';
import { initialValues, validationSchema } from './validation';
import Modal from '@mui/material/Modal';
import { isValid } from 'date-fns';
import { getOccupationArea } from '../../../../services/occupationArea';
import { getUf } from '../../../../services/uf';
import { includeMaskCel } from '../../../../helper/mask/celMask';
import { includeMaskTel } from '../../../../helper/mask/telMask';
import Terms from '../../../../components/TermsOrPolicies/Terms';
import Policies from '../../../../components/TermsOrPolicies/Policies';
import ReactInputMask from 'react-input-mask';
import Tooltip from '@mui/material/Tooltip';

interface IProps {
  user: IExternalUser | null;
  setUser: Dispatch<SetStateAction<IExternalUser | null>>;
}

const BasicData = ({ user, setUser }: IProps): React.ReactElement => {
  const classes = useStyles();
  const { showAlertError } = useAlert();
  const [showTermsOrPolicies, setShowTermsOrPolicies] = useState<string | boolean>('');
  const [openModalColors, setOpenModalColors] = useState<boolean>(false);
  const [occupations, setOccupations] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [errorBirth, setErrorBirth] = useState(false);
  const [phoneTest, setPhoneTest] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedColor, setSelectedColor] = useState({
    name: '',
    value: '',
  });

  useEffect(() => {
    getOccupations();
    getStates();
    if (user) formik.setValues({ ...user });
  }, []);

  const getOccupations = () => {
    getOccupationArea()
      .then((response) => {
        setOccupations(response.data.value);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar as Áreas de ocupação.');
      });
  };

  const getStates = () => {
    getUf()
      .then((response) => {
        setStates(response.data.value);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar os Estados.');
      });
  };

  const changeColors = (attribute: string, color: string) => {
    formik.setFieldValue(`colors.${attribute}`, color);
  };

  const changeSelectedColor = (name: string, value: string) => {
    setOpenModalColors(true);
    setSelectedColor({ name, value });
  };

  function handleDate(name: string, value: any) {
    let dateNow = new Date();

    if (isValid(value)) {
      if (value < dateNow) {
        let date = new Date(new Date(value).setHours(0, 0, 0)).toISOString();
        formik.setFieldValue(name, date);
        setErrorBirth(false);
      } else {
        setErrorBirth(true);
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setUser((prevState) => ({ ...prevState, ...values }));
    },
  });

  useEffect(() => {
    let data;

    if (phoneTest.length <= 14) {
      data = includeMaskTel(phoneTest);
      setPhone(data);
      formik.values.phone = data;
    } else {
      data = includeMaskCel(phoneTest);
      setPhone(data);
      formik.values.phone = data;
    }
  }, [phoneTest]);

  const handleChangePhone = (value) => {
    setPhoneTest(value);
    setPhone(value);
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Modal
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        open={Boolean(showTermsOrPolicies)}
        onClose={() => setShowTermsOrPolicies(false)}
      >
        <Paper
          elevation={1}
          style={{ width: 800, padding: '40px 60px', height: '90%', maxWidth: '100vw', overflow: 'auto' }}
        >
          <h1> {showTermsOrPolicies === 'terms' ? 'Termos de uso' : 'Política de Privacidade'}</h1>
          <>{showTermsOrPolicies === 'terms' ? <Terms /> : <Policies />}</>
        </Paper>
      </Modal>
      <ModalColors
        closeModal={() => setOpenModalColors(false)}
        open={openModalColors}
        changeColor={changeColors}
        selectedColor={selectedColor}
      />
      <h1 style={{ color: '#3e4756', textAlign: 'center' }}>Cadastre-se</h1>
      <form onSubmit={formik.handleSubmit}>
        <TableContent>
          <Grid item xs={12} sm={6}>
            <TextField
              InputLabelProps={{
                required: true,
              }}
              fullWidth
              id="name"
              name="name"
              label="Nome"
              variant="outlined"
              className={classes.field}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              InputLabelProps={{
                required: true,
              }}
              id="lastName"
              name="lastName"
              label="Sobrenome"
              variant="outlined"
              className={classes.field}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              InputLabelProps={{
                required: true,
              }}
              fullWidth
              id="email"
              name="email"
              label="E-mail"
              variant="outlined"
              className={classes.field}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              InputLabelProps={{
                required: true,
              }}
              id="confirmEmail"
              name="confirmEmail"
              label="Confirmar E-mail"
              variant="outlined"
              onPaste={handlePaste}
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
              className={classes.field}
              error={formik.touched.confirmEmail && Boolean(formik.errors.confirmEmail)}
              helperText={formik.touched.confirmEmail && formik.errors.confirmEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
            >
              {errorBirth ? (
                <DatePicker
                  autoOk
                  label="Data de Nascimento (Opcional)"
                  value={formik.values.birthDate == null ? null : new Date(formik.values.birthDate)}
                  placeholder="dd/mm/aaaa"
                  onChange={(value) => handleDate('birthDate', value)}
                  maxDate={new Date()}
                  disableFuture
                  inputVariant="outlined"
                  variant="inline"
                  error={true}
                  helperText={'Data Invalida'}
                  format="dd/MM/yyyy"
                />
              ) : (
                <DatePicker
                  autoOk
                  label="Data de Nascimento (Opcional)"
                  value={formik.values.birthDate == null ? null : new Date(formik.values.birthDate)}
                  placeholder="dd/mm/aaaa"
                  onChange={(value) => handleDate('birthDate', value)}
                  maxDate={new Date()}
                  disableFuture
                  inputVariant="outlined"
                  variant="inline"
                  error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                  helperText={formik.touched.birthDate && formik.errors.birthDate}
                  format="dd/MM/yyyy"
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              InputLabelProps={{
                required: true,
              }}
              id="phone"
              name="phone"
              label="Celular"
              variant="outlined"
              value={phone}
              onChange={(event) => handleChangePhone(event.target.value)}
              className={classes.field}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="OAB"
              variant="outlined"
              name="oab"
              error={formik.touched.oab && Boolean(formik.errors.oab)}
              helperText={formik.touched.oab && formik.errors.oab}
              value={formik.values.oab || ''}
              onChange={(event) => formik.setFieldValue('oab', event.target.value)}
              className={classes.field}
              inputProps={{
                maxLength: 7,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.state && Boolean(formik.errors.state)}
            >
              <InputLabel id="state">Seccional (opcional)</InputLabel>
              <Select
                name="state"
                labelId="state"
                id="state"
                label="Seccional (opcional)"
                value={formik.values.state}
                onChange={formik.handleChange}
              >
                {states.map((uf) => (
                  <MenuItem key={uf.id} value={uf.id}>
                    <em>{uf.initials}</em>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.touched.state && formik.errors.state}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className={classes.field}
              variant="outlined"
              error={formik.touched.occupation && Boolean(formik.errors.occupation)}
            >
              <InputLabel id="occupation">Área de atuação (opcional)</InputLabel>
              <Select
                name="occupation"
                labelId="occupation"
                id="occupation"
                label="Área de atuação (opcional)"
                value={formik.values.occupation}
                onChange={formik.handleChange}
              >
                {occupations.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <em>{item.value}</em>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.touched.occupation && formik.errors.occupation}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="work"
              name="work"
              label="Escritório (opcional)"
              variant="outlined"
              className={classes.field}
              value={formik.values.work}
              onChange={formik.handleChange}
              error={formik.touched.work && Boolean(formik.errors.work)}
              helperText={formik.touched.work && formik.errors.work}
            />
          </Grid>
        </TableContent>

        <ContainerCheckbox>
          <FormControl fullWidth error={formik.touched.terms && Boolean(formik.errors.terms)}>
            <label>
              <Checkbox color="primary" name="terms" checked={formik.values.terms} onChange={formik.handleChange} />
              Li e concordo com os{' '}
              <a href="#" onClick={() => setShowTermsOrPolicies('terms')}>
                Termos de Uso
              </a>{' '}
              e{' '}
              <a href="#" onClick={() => setShowTermsOrPolicies('policies')}>
                Política de Privacidade.
              </a>
            </label>
            <FormHelperText>{formik.touched.terms && formik.errors.terms}</FormHelperText>
          </FormControl>
        </ContainerCheckbox>

        <ColorsTitle>Escolha de 3 a 5 cores padrões:</ColorsTitle>
        <ColorsContent>
          {Object.keys(formik.values.colors).map(function (key) {
            return (
              <div
                key={key}
                onClick={() => changeSelectedColor(key, get(formik.values.colors, key))}
                style={{ background: `${get(formik.values.colors, key)}` }}
              >
                <Tooltip title="Escolha sua cor" arrow>
                  <a>{!get(formik.values.colors, key) && <AddCircleOutlineIcon style={{ color: '#3E4756' }} />}</a>
                </Tooltip>
              </div>
            );
          })}
        </ColorsContent>

        <Button variant="contained" fullWidth type="submit" className={classes.btn}>
          Cadastrar
        </Button>
        <TextAccount>
          Já possui uma conta? <Link to="/login">Entrar</Link>
        </TextAccount>
      </form>
    </>
  );
};

export default BasicData;
