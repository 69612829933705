export enum DATETYPEINPUT {
  DATE = 1,
  DATETIME = 2,
  MONTHYEAR = 3,
  YEAR = 4,
}

export enum SORT {
  CRESCENTE = 1,
  DECRESCENTE = 2,
}

export enum POSITION {
  HORIZONTAL = 1,
  VERTICAL = 2,
}

export enum TIPO {
  MODELO1 = 1,
  MODELO2 = 2,
}
