import React, { useEffect, useState } from 'react';
import { Container, Content } from './styles';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ModalColors from '../../../../components/ModalChooseColor';
import { findIcons } from '../../../../services/models';
import { useLoading } from '../../../../contexts/loading';
import { Gallery } from 'react-grid-gallery';
import { useAlert } from '../../../../contexts/alert';
import Tooltip from '@mui/material/Tooltip';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import { convertHTMLToImage, copyImage } from '../../../../helper/convertHTML';
import { useHistory } from 'react-router-dom';

interface IProps {
  setIsSaved: (value: boolean) => void;
  formik: any;
  refView: RefObject<HTMLCanvasElement>;
  loadIcon: boolean;
  setLoadIcon: (value: boolean) => void;
}

const IconsConfiguration: React.FC = ({ setIsSaved, formik, refView, loadIcon, setLoadIcon }: IProps) => {
  const { showLoading } = useLoading();
  const [galleryMeasure, setGalleryMeasure] = useState('207');
  const [iconsArray, setIconsArray] = useState([]);
  const [imageView, setImageView] = useState<HTMLImageElement>();
  const [newColor, setNewColor] = useState(null);
  const [controlModalColor, setControlModalColor] = useState(null);
  const { showAlertError } = useAlert();
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(540);
  const [canvasHeight, setCanvasHeight] = useState(540);
  const history = useHistory();

  const loadIconsGallery = async () => {
    if (formik.values.term == null || formik.values.term == '') {
      showAlertError('O campo Tema tem que ser preenchido obrigatóriamente.');
      setIconsArray([]);
    } else {
      setIsSaved(false);
      showLoading(true);
      setGalleryMeasure(formik.values.size + 'px');
      const response = await findIcons(
        formik.values.term,
        formik.values.color,
        formik.values.shape,
        formik.values.size
      );
      if (response.data != '') {
        const icons = response.data?.map((item) => {
          return { alt: item.id, caption: '#' + item.id, src: item.url };
        });
        setIconsArray(icons);
        showLoading(false);
        clearImage();
        setImageView(null);
      } else {
        setIconsArray([]);
      }
    }
  };

  const showIcon = async (index: number) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = iconsArray[index].src;
    img.onload = () => {
      setImageView(img);
      setCanvasWidth(img.naturalWidth);
      setCanvasHeight(img.naturalHeight);
      putImage(img, 0, 0);
    };
    formik.setFieldValue('iconId', iconsArray[index].alt);
  };

  function replaceColor(originalColor, finalColor) {
    putImage(imageView, 0, 0);
    const canvas = document.getElementById('myCanvas');
    const src = hexToRgb(originalColor);
    const dst = hexToRgb(finalColor);
    if (canvas instanceof HTMLCanvasElement) {
      const ctx = canvas.getContext('2d');
      //ctx?.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
      const im = ctx.getImageData(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < im.data.length; i += 4) {
        if (im.data[i] === src.r && im.data[i + 1] === src.g && im.data[i + 2] === src.b) {
          im.data[i] = dst.r;
          im.data[i + 1] = dst.g;
          im.data[i + 2] = dst.b;
        }
      }
      ctx.putImageData(im, 0, 0);
    }
  }

  function putImage(img, top, left) {
    const canvas = document.getElementById('myCanvas');
    if (canvas instanceof HTMLCanvasElement) {
      const ctx = canvas.getContext('2d');
      clearImage();
      ctx?.drawImage(img, top, left, img.naturalWidth, img.naturalHeight);
    }
  }

  function clearImage() {
    const canvas = document.getElementById('myCanvas');
    if (canvas instanceof HTMLCanvasElement) {
      const ctx = canvas.getContext('2d');
      const box = document.getElementById('#view');
      ctx?.clearRect(0, 0, box?.offsetWidth, box?.offsetHeight);
    }
  }

  function clearIcons() {
    clearSize();
    clearShape();
    clearTerm();
    clearIconsArray();
    clearImage();
    setImageView(null);
    setIsSaved(false);
  }

  function clearSize() {
    formik.setFieldValue('size', '');
  }

  function clearShape() {
    formik.setFieldValue('shape', '');
  }

  function clearTerm() {
    formik.setFieldValue('term', '');
  }

  function clearIconsArray() {
    setIconsArray([]);
  }

  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  const changeSelectedColor = (name: string, value: string) => {
    setIsSaved(false);
    setControlModalColor('#000000');
    setNewColor(value);
  };

  useEffect(() => {
    if (newColor) {
      if (imageView != null) {
        replaceColor('#000000', newColor);
      }
    }
  }, [newColor]);

  useEffect(() => {
    if (iconsArray.length == 0 && loadIcon) {
      loadIconsGallery();
    } else if (iconsArray.length > 0 && loadIcon) {
      const found = iconsArray.findIndex((x) => x.alt == formik.values.iconId);
      if (found >= 0) {
        showIcon(found);
        setLoadIcon(false);
      }
    }
  }, [loadIcon, iconsArray]);

  const copy = () => {
    const canvas = document.getElementById('myCanvas');
    copyImage(canvas, null, imageView?.naturalWidth, imageView?.naturalHeight);
    setOpenToolTipCopyImage(true);
    //updateCountDownloadIcon(id);
  };

  const downloadImage = () => {
    if (imageView == null) {
      showAlertError('Não há nenhum icone selecionado para download.');
    } else {
      const canvas = document.getElementById('myCanvas');
      convertHTMLToImage(canvas, 'icone');
    }
  };

  return (
    <Container elevation={1}>
      <ModalColors
        closeModal={() => setControlModalColor(null)}
        open={Boolean(controlModalColor)}
        changeColor={changeSelectedColor}
        selectedColor={controlModalColor}
      />
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Alterar a cor">
            <ColorLensOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() => changeSelectedColor('')}
            />
          </Tooltip>
          <Tooltip title="Apagar icones carregados">
            <DeleteOutlineOutlinedIcon style={{ cursor: 'pointer', color: '#F1504D' }} onClick={clearIcons} />
          </Tooltip>
          <Tooltip title="Download do icone">
            <span
              onClick={downloadImage}
              style={{ cursor: 'pointer', color: '#3E4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>
          <Tooltip
            title="Icone copiado"
            leaveDelay={1500}
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
          >
            <Tooltip title="Copiar icone">
              <span style={{ cursor: 'pointer', color: '#3E4756' }} className="material-icons-outlined">
                <ContentCopyOutlinedIcon onClick={copy} style={{ color: '#3E4756' }} />
              </span>
            </Tooltip>
          </Tooltip>
        </div>
        <div
          className="inputs"
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '20px',
          }}
        >
          <Content style={{ maxWidth: '310px', minHeight: 540 }}>
            <FormControl className="inputs" id="formIconSize" name="iconSize" fullWidth variant="outlined">
              <InputLabel id="iconSize">Tamanho</InputLabel>
              <Select
                labelId="iconSize"
                id="iconSize"
                name="iconSize"
                label="Tamanho"
                onClick={(event) => {
                  clearIconsArray();
                  clearImage();
                  formik.setFieldValue('size', event.target.value);
                  setIsSaved(false);
                }}
                value={formik.values.size}
                onChange={formik.handleChange}
                error={formik.touched.size && Boolean(formik.errors.size)}
                helperText={formik.touched.size && formik.errors.size}
                endAdornment={
                  <IconButton
                    style={{
                      visibility: formik.values.size == '' || formik.values.size == null ? 'hidden' : 'visible',
                    }}
                    onClick={clearSize}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              >
                <MenuItem value={'64'}>Micro (64x64)</MenuItem>
                <MenuItem value={'128'}>Pequeno (128x128)</MenuItem>
                <MenuItem value={'256'}>Médio (256x256)</MenuItem>
                <MenuItem value={'512'}>Grande (512x512)</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="inputs" name="iconFill" fullWidth variant="outlined">
              <InputLabel id="iconFill">Opções</InputLabel>
              <Select
                labelId="iconFill"
                id="iconFill"
                name="iconFill"
                label="Opções"
                onClick={(event) => {
                  clearIconsArray();
                  clearImage();
                  formik.setFieldValue('shape', event.target.value);
                  setIsSaved(false);
                }}
                value={formik.values.shape}
                onChange={formik.handleChange}
                error={formik.touched.shape && Boolean(formik.errors.shape)}
                helperText={formik.touched.shape && formik.errors.shape}
                endAdornment={
                  <IconButton
                    style={{
                      visibility: formik.values.shape == '' || formik.values.shape == null ? 'hidden' : 'visible',
                    }}
                    onClick={clearShape}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              >
                <MenuItem value={'outline'}>Traço Fino</MenuItem>
                <MenuItem value={'fill'}>Preenchido</MenuItem>
                <MenuItem value={'lineal-color'}>Linhas coloridas</MenuItem>
                <MenuItem value={'hand-drawn'}>Feito a mão</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className="inputs"
              type="text"
              name="theme"
              size="normal"
              label="Digite o Tema"
              fullWidth
              variant="outlined"
              onChange={(event) => {
                formik.setFieldValue('term', event.target.value);
                setIsSaved(false);
              }}
              value={formik.values.term}
              error={formik.touched.term && Boolean(formik.errors.term)}
              helperText={formik.touched.term && formik.errors.term}
              InputProps={{
                endAdornment: (
                  <IconButton
                    style={{
                      visibility: formik.values.term == '' || formik.values.term == null ? 'hidden' : 'visible',
                    }}
                    onClick={clearTerm}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              //error={formik.touched.title && Boolean(formik.errors.title)}
              //helperText={formik.touched.title && formik.errors.title}
            />
            <Button
              className="inputs"
              type="submit"
              variant="outlined"
              //onClick={() => replaceColor('#000000', '#00FFFF')}
              onClick={loadIconsGallery}
              fullWidth
              style={{ display: 'flex', justifyContent: 'center', height: '60px', background: 'LightGray' }}
            >
              Carregar Icones
            </Button>
          </Content>
          <Content style={{ width: '80%', maxHeight: '600px', overflowY: 'auto' }}>
            <Gallery
              enableImageSelection={false}
              images={iconsArray}
              rowHeight={
                galleryMeasure == '64px' ? 64 : galleryMeasure == '128px' ? 122 : galleryMeasure == '256px' ? 154 : 207
              }
              thumbnailStyle={{
                width:
                  galleryMeasure == '64px'
                    ? galleryMeasure
                    : galleryMeasure == '128px'
                    ? '122px'
                    : galleryMeasure == '256px'
                    ? '154px'
                    : '207px',
              }}
              onClick={(index) => {
                showIcon(index);
              }}
            />
          </Content>
          <Content id="#view" style={{ maxWidth: '540px', maxHeight: '600px', overflow: 'hidden' }}>
            <canvas ref={refView} id="myCanvas" width={canvasWidth} height={canvasHeight}>
              <img id="myImage" src={imageView} />
            </canvas>
          </Content>
        </div>
      </div>
    </Container>
  );
};

export default IconsConfiguration;
