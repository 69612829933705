import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Container } from './styles';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/auth';
import { useAlert } from '../../contexts/alert';
import GeneralRulesImg from '../../assets/general-rules.svg';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import { Grid } from '@material-ui/core';
import { tipoModelo } from '../../services/models';
import YouTubeIcon from '@mui/icons-material/YouTube';

const NovoModelo: React.FC = () => {
  const modelos = tipoModelo();
  const { user } = useAuth();
  const formatedDate = user?.tokenData?.Expiration && format(new Date(user?.tokenData?.Expiration), 'dd/MM/yyyy');
  const [now, setNow] = useState(format(new Date(), 'dd/MM/yyyy'));
  const { showAlertWarning, showExpireMessage, expireMessage } = useAlert();
  const [state, setState] = useState();
  const [expandBar, setExpandBar] = useState('');

  useEffect(() => {
    if (expireMessage) {
      if (now !== formatedDate && user?.tokenData?.Plan == 'Plano Gratuito') {
        showAlertWarning('Seu plano gratuito expira em ' + (formatedDate || ''));
        showExpireMessage(false);
      }
    }
  }, []);

  return (
    <Container>
      <h1>Escolha uma ferramenta</h1>
      <div>
        {modelos.map((modelo) => (
          <Container key={modelo.id}>
            <Grid container direction="column">
              <Link to={modelo.link}>
                <Card
                  className="card"
                  sx={{ width: 350 }}
                  onMouseOver={() => setExpandBar(modelo.id)}
                  onMouseOut={() => setExpandBar('')}
                >
                  <CardActionArea>
                    <CardMedia component="img" height="200" image={modelo.imagemNewTemplate} alt={modelo.titulo} />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" align="center">
                        {modelo.titulo}
                      </Typography>
                      <Collapse in={modelo.id === expandBar} timeout="auto" unmountOnExit>
                        <div className="descriptionCard" dangerouslySetInnerHTML={{ __html: modelo.descricao }} />
                      </Collapse>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
              <Button
                size="small"
                variant="text"
                href={`https://www.youtube.com/watch?v=${modelo.video.videoId}`}
                target="_blank"
                style={{ maxWidth: '80px' }}
                sx={{ marginLeft: 1 }}
              >
                <YouTubeIcon className="btnYouTube" /> Tutorial
              </Button>
            </Grid>
          </Container>
        ))}
        <Container>
          <Grid container direction="column">
            <Card
              className="card"
              sx={{ width: 350 }}
              onMouseOver={() => setExpandBar('cardAviso')}
              onMouseOut={() => setExpandBar('')}
            >
              <CardActionArea>
                <CardMedia component="img" height="200" image={GeneralRulesImg} alt="diagram" />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" align="center">
                    Regras gerais
                  </Typography>
                  <Collapse in={'cardAviso' === expandBar} timeout="auto" unmountOnExit>
                    <div className="descriptionCard">
                      O navegador padrão é o Chrome. Alguns recursos podem não estar disponíveis no Safari. Todos os
                      modelos modelos têm o campo “Identificação” que possibilita localizar e abrir os arquivos salvos
                      em “Meus projetos” e um campo “Título” que aparecerá na imagem. A navegação, além do mouse, pode
                      ser com a tecla TAB para percorrer os campos dos Modelos e com seta para a esquerda e delete para
                      alterar final do lançamento em cada elemento, com TAB o cursor será posicionado no botão CANCELAR.
                      O sistema utiliza a cor cadastrada nos Dados Pessoais, mas pode ser alterada com a paleta de cores
                      posicionada nos modelos.
                    </div>
                  </Collapse>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Container>
      </div>
    </Container>
  );
};

export default NovoModelo;
