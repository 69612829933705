import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 5px;
  table {
    border-collapse: collapse;
  }

  p {
    color: #3e4756;
  }

  .informative-title {
    svg {
      color: #3e4756;
    }
  }
`;
