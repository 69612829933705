import React, { useEffect, useState } from 'react';
import EmailContainer from './EmailContainer';
import PasswordContainer from './PasswordContainer';
import { Paper } from '@material-ui/core';
import { Container } from './styles';
import { SignIn } from '../../services/auth';
import { useHistory } from 'react-router-dom';
import { useAlert } from '../../contexts/alert';
import { useAuth } from '../../contexts/auth';
import jwt_decode from 'jwt-decode';
import { compareDesc, format } from 'date-fns';

const Login: React.FC = () => {
  const history = useHistory();
  const { showAlertError } = useAlert();
  const { setUser } = useAuth();
  const [step, setStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    setUser(null);
  }, []);

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const compareDate = (expire: Date) => {
    let result = compareDesc(new Date(), expire);
    return result;
  };

  const persistMenu = (menuName: string) => {
    localStorage.setItem('menuSelected', menuName);
  };

  const handleChangePassword = (password: string) => {
    setIsLoading(true);
    SignIn({ email, password })
      .then((response: any) => {
        setUser(response.data);
        const tokenData: any = jwt_decode(response.data?.accessToken);
        let expire;

        if (tokenData?.UserType == 'Internal User') {
          history.push('/dashboards');
        } else {
          expire = new Date(tokenData?.Expiration);

          let result = compareDate(expire);
          let statusPlan = response.data?.marketPlaceId == 2 ? tokenData?.HotmartPlanActive : tokenData?.PlanActive;

          if (result == -1) {
            history.push('/plano-expirado');
          } else if (statusPlan == 'Inativo') {
            history.push('/assinatura-cancelada');
          } else if (response.data.firstLogin == 0) {
            persistMenu('Dados Complementares');
            history.push('/meus-dados');
          } else {
            persistMenu('Página Inicial');
            history.push('/');
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showAlertError(error?.response?.data || error?.message || 'Ocorreu um erro ao tentar realizar o login');
      });
  };

  return (
    <Container>
      <Paper elevation={3}>
        {step === 0 ? (
          <EmailContainer nextStep={() => setStep(1)} email={email} changeEmail={handleChangeEmail} />
        ) : (
          <PasswordContainer
            prevStep={() => setStep(0)}
            email={email}
            submit={handleChangePassword}
            loadingIndicator={isLoading}
          />
        )}
      </Paper>
    </Container>
  );
};

export default Login;
