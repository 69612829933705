import Login from '../pages/Login';
import RecuperarSenha from '../pages/ForgotPassword';
import PrimeiroAcesso from '../pages/FirstAccessOrResetPassword';
import RedefinirSenha from '../pages/FirstAccessOrResetPassword';
import CadastroUsuarioExterno from '../pages/ExternalUsers/Create';

export const publicRoutes = [
  {
    name: 'Login',
    url: '/login',
    component: Login,
  },
  {
    name: 'Primeiro Acesso',
    url: '/primeiro-acesso',
    component: PrimeiroAcesso,
  },
  {
    name: 'Recuperar Senha',
    url: '/recuperar-senha',
    component: RecuperarSenha,
  },
  {
    name: 'Redefinir Senha',
    url: '/redefinir-senha',
    component: RedefinirSenha,
  },
  {
    name: 'Cadastrar Usuário Externo',
    url: '/cad-usu-externo',
    component: CadastroUsuarioExterno,
  },
];
