import React from 'react';
import { Content } from './styles';

const Policies = () => {
  return (
    <Content>
      <h2>Termo de política de privacidade e consentimento para coleta de dados</h2>
      <p>
        Este documento visa dar ciência ao titular de dados USUÁRIO do software IRIS - Jurídico Descomplicado, que
        determinados dados serão acessíveis, cujo tratamento obedecerá integralmente à lei nº 13.709 – Lei Geral de
        Proteção de Dados Pessoais (LGPD).
      </p>
      <h2>Controlador dos dados</h2>
      <p>
        Os dados são tratados pelo controlador ALONSO FERNANDES JUNIOR, brasileiro, casado, engenheiro de software,
        inscrito no CPF sob o nº 489.993.776-87, portador da Carteira de Identidade 12.432.373/SSP-SP, residente e
        domiciliado à Rua San Marino, nº 762, Vila Castela, Nova Lima – MG, CEP 34.007-076, endereço eletrônico
        alonso.f.junior@hotmail.com, a quem compete a tomada de decisões e ações referente ao tratamento dos dados.
      </p>
      <h2>Dados coletados, forma e finalidade</h2>
      <p>
        O controlador informa, ainda, que utiliza as plataformas Google Ads e Facebook para campanhas promocionais e,
        para tanto, são colhidos cookies do visitante do site, para fins de geolocalização, análise estatística dos
        dados demográficos, bem como dados de uso do sistema, como periodicidade de acesso do USUÁRIO nas rotinas do
        aplicativo e tipos de modelos salvos.
        <br />
        Os cookies, por sua vez, são armazenados nas plataformas Google e Facebook pelo período de utilização do
        sistema, conforme vigência contratual.
        <br />
        Esclarece o controlador, que são coletados os seguintes dados pessoais:
        <br />
        Nome e sobrenome; e-mail; data de nascimento; telefone; número de inscrição na Ordem dos Advogados do Brasil;
        seccional da Ordem dos Advogados do Brasil à qual encontra-se inscrito; área de atuação profissional, nome do
        escritório.
        <br />
        Os dados poderão ser compartilhados entre a equipe responsável pela manutenção do sistema.
      </p>
      <h2>Consentimento</h2>
      <p>
        Ao ler o presente termo de política de privacidade e consentimento para coleta de dados, o usuário - titular dos
        dados a serem colhidos - registra a sua manifestação livre, informada e inequívoca e o seu consentimento para o
        tratamento de seus dados pessoais, bem como dos dados colhidos através de cookies, para finalidade específica de
        cotação, compra direta e campanhas promocionais supramencionadas, em conformidade com a Lei nº 13.709 – Lei
        Geral de Proteção de Dados Pessoais (LGPD).
        <br />
        Ao manifestar sua aceitação para com o presente termo, o titular consente e concorda que o controlador ALONSO
        FERNANDES JUNIOR tome decisões referentes ao tratamento de seus dados pessoais, consubstanciados na coleta,
        produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
        arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação,
        transferência, difusão ou extração.
      </p>
      <h2>Medidas de segurança</h2>
      <p>
        O Controlador responsabiliza-se pela manutenção de medidas de segurança técnica e administrativas aptas a
        proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição,
        perda, alteração, extração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
        <br />
        Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao Titular e à Autoridade Nacional de
        Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao
        Titular.
      </p>
      <h2>Duração do tratamento</h2>
      <p>
        O Controlador poderá manter e tratar os dados pessoais do titular durante todo o período em que forem
        pertinentes ao alcance das finalidades listadas neste termo, enquanto perdurar a relação contratual. Dados
        pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período
        indefinido, para fins de arquivamento.
      </p>
      <h2>Direitos do titular dos dados</h2>
      <p>
        Em razão do disposto no inciso VII do art. 9º da Lei nº 13.709, são direitos do titular dos dados:
        <br />
        Art. 18. O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do titular por
        ele tratados, a qualquer momento e mediante requisição:
        <br />
        I - confirmação da existência de tratamento;
        <br />
        II - acesso aos dados;
        <br />
        III - correção de dados incompletos, inexatos ou desatualizados;
        <br />
        IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com
        o disposto nesta Lei;
        <br />
        V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados
        os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador;
        <br />
        V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo
        com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; (Redação dada pela
        Lei nº 13.853, de 2019) Vigência
        <br />
        VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no
        art. 16 desta Lei;
        <br />
        VII - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de
        dados;
        <br />
        VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
        <br />
        IX - revogação do consentimento, nos termos do § 5º do art. 8º desta Lei.
        <br />
      </p>
      <h2>Acesso à informação, alteração, revogação e portabilidade dos dados.</h2>
      <p>
        O Titular poderá solicitar via e-mail ou correspondência ao controlador, a qualquer momento, que sejam
        eliminados os dados pessoais coletados, enviando a solicitação para os seguintes endereços:
        alonso.f.junior@hotmail.com e Rua San Marino, nº 762, Vila Castela, Nova Lima – MG, CEP 34.007-076. O Titular
        fica ciente de que poderá ser inviável ao controlador continuar o fornecimento de produtos ou serviços ao
        titular a partir da eliminação dos dados pessoais. Fica ressalvado o tratamento dos dados para o cumprimento de
        obrigação legal ou regulatória pelo controlador.
        <br />
        Caso o usuário tenha dúvidas quanto à existência de tratamento de seus dados pessoais pelo presente Controlador
        poderá enviar consulta de confirmação de existência de tratamento de dados para os endereços acima descritos,
        podendo solicitar também, além da eliminação dos dados, a correção, o complemento, a atualização, a
        portabilidade e a revogação do consentimento.
      </p>
      <p>
        Alonso Fernandes Junior
        <br />
        CPF 489.993.776-87
        <br />
        alonso.f.junior@hotmail.com
      </p>
    </Content>
  );
};
export default React.memo(Policies);
