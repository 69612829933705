import React, { useEffect, useState } from 'react';
import ConfigurationCard from '../ConfigurationCard';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Container, Insert } from './styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import { TextField, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
    marginBottom: 10,
    borderRadius: 5,
  },
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem', fontWeight: 'bold' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export interface IItem {
  date: Date | null;
  title: string;
  description: string;
}

interface IProps {
  data: Array<IItem>;
  addNewLevel: () => void;
  addNewElement: () => void;
  changeItemValue: (indexItem: number, indexLevel: number, value: string, name: string) => void;
  controlDeleteModal: (indexes: string) => void;
  centerFirstItem: (indexes: string) => void;
  controlModalColor: (indexes: string, value: string) => void;
  changeTitle: (value: string) => void;
  titleText?: string;
  positionFlow?: number;
  setIsSaved: (value: boolean) => void;
  changeExpandedCard: (indexLevel: number) => void;
  allItemColor?: string;
}

function HorizontalConfiguration({
  data,
  addNewLevel,
  addNewElement,
  changeItemValue,
  controlDeleteModal,
  centerFirstItem,
  controlModalColor,
  titleText,
  positionFlow,
  changeTitle,
  setIsSaved,
  changeExpandedCard,
  allItemColor,
}: IProps): JSX.Element {
  const [expanded, setExpanded] = useState(['panel0']);
  const [count, setCount] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setCount(data.length);
  }, [data]);

  return (
    <Container>
      <div className="info-title" style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <div>
            <p style={{ marginRight: 10, color: '#3E4756' }}>Informe os dados do modelo</p>
            <Tooltip title="Alterar cor de todos os elementos">
              <ColorLensOutlinedIcon
                style={{ fill: '#3E4756', cursor: 'pointer' }}
                onClick={() => controlModalColor(`all`, allItemColor)}
              />
            </Tooltip>
          </div>
          <Insert onClick={addNewLevel} style={{ color: '#3FCED2' }}>
            <ControlPointIcon fontSize="small" /> Inserir nível
          </Insert>
        </div>
      </div>
      <div style={{ padding: 16 }}>
        <div>
          <div>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <TextField
                label="Título do Fluxograma (opcional)"
                variant="outlined"
                value={titleText}
                fullWidth
                onChange={(event) => {
                  setIsSaved(false);
                  changeTitle(event.target.value);
                }}
              />
            </div>

            {data.map((level, indexLevel, arrayLevels) => (
              <Accordion
                key={indexLevel}
                defaultExpanded={true}
                onChange={() => changeExpandedCard(indexLevel, !Boolean(level[0].expanded))}
                expanded={Boolean(level[0].expanded)}
              >
                <AccordionSummary aria-controls={`panel${indexLevel}d-content`} id={`panel-${indexLevel}-header`}>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <strong>Dados do nível {indexLevel + 1}</strong>
                    <p style={{ padding: 0, margin: 0 }}>
                      {arrayLevels.length > 1 && (
                        <Tooltip title="Deletar nível">
                          <DeleteOutlineOutlinedIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              const timeOut = setTimeout(() => {
                                setExpanded(`panel${indexLevel}`);
                                clearTimeout(timeOut);
                              }, 10);
                              controlDeleteModal(indexLevel.toString());
                            }}
                            color="error"
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="Alterar cor">
                        <ColorLensOutlinedIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const timeOut = setTimeout(() => {
                              setExpanded(`panel${indexLevel}`);
                              clearTimeout(timeOut);
                            }, 10);
                            controlModalColor(indexLevel.toString(), level[0].color);
                          }}
                        />
                      </Tooltip>
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div key={indexLevel}>
                    {level.map((element, indexElement) => {
                      let listaParents = [];
                      let isFirstItem = !Boolean(indexLevel);

                      if (!isFirstItem && data[indexLevel - 1]) {
                        listaParents = data[indexLevel - 1];
                      }

                      if (data[indexLevel] && Array.isArray(data[indexLevel])) {
                        listaParents = listaParents.concat(
                          data[indexLevel].filter((parent) => parent.id != element.id)
                        );
                      }

                      return (
                        <ConfigurationCard
                          count={count}
                          isFirstItem={isFirstItem}
                          key={indexElement}
                          inputId={`item${indexLevel}${indexElement}`}
                          parentsElements={listaParents}
                          parentValue={element.parents}
                          descriptionInputValue={element.description}
                          deleteItem={() => controlDeleteModal(`${indexLevel},${indexElement}`)}
                          centerFirstItem={() => centerFirstItem(indexLevel, indexElement)}
                          changeColorItem={() => controlModalColor(`${indexLevel},${indexElement}`, element.color)}
                          changeValueItem={(value, name) => {
                            setIsSaved(false);
                            changeItemValue(indexElement, indexLevel, value, name);
                          }}
                          positionFlow={positionFlow}
                        />
                      );
                    })}
                    <div style={{ display: 'flex' }}>
                      <Insert onClick={() => addNewElement(indexLevel)} style={{ color: '#3FCED2' }}>
                        <ControlPointIcon fontSize="small" /> Novo item&nbsp;&nbsp;&nbsp;
                      </Insert>
                      <Insert onClick={() => addNewLevel(indexLevel)} style={{ color: '#3FCED2' }}>
                        <ControlPointIcon fontSize="small" /> Inserir nível
                      </Insert>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <Insert onClick={() => addNewLevel(-1)} style={{ color: '#3FCED2', marginTop: 10, marginBottom: 10 }}>
            <ControlPointIcon fontSize="small" /> Novo nível
          </Insert>
        </div>
      </div>
    </Container>
  );
}

export default HorizontalConfiguration;
