import React from 'react';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import TextField from '../../../../components/EditTableTemplateTextField';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as AddColumnIcon } from '../../../../assets/Add_Column_icon.svg';
import { ReactComponent as AddLineIcon } from '../../../../assets/Add_Line_icon.svg';
import { ButtonNewCell, CellIcons, Container, TableContent } from './styles';
import { SIZECELL } from '../../../../interfaces/comparativeTable';

interface IColor {
  name: string;
  value?: string;
}

interface IModalDelete {
  attributeType: string;
  index?: number;
}

interface IProps {
  formik: any;
  columnsBorderStyle: React.CSSProperties;
  columns: Array<ITableCell> | [];
  rows: Array<Array<ITableCell>> | [];
  changeRowHeight: (index: number, type: SIZECELL) => void;
  changeColumnWidth: (index: number, type: SIZECELL) => void;
  setControlModalColor: (obj: IColor) => void;
  widthColumn: number;
  changeColumnValue: (index: number, value: string) => void;
  changeRowValue: (indexItem: number, indexRow: number, value: string) => void;
  setControlDeleteModal: (obj: IModalDelete) => void;
  addNewColumn: (index?: number) => void;
  addNewRow: (index?: number) => void;
  changeTableTitle: (value: string) => void;
}

interface ITableCell {
  description: string;
  color?: string;
  width: number;
  height: number;
}

const ConfigurationTable = ({
  formik,
  columnsBorderStyle,
  columns,
  rows,
  changeColumnWidth,
  changeRowHeight,
  setControlModalColor,
  changeColumnValue,
  changeRowValue,
  setControlDeleteModal,
  addNewColumn,
  addNewRow,
  changeTableTitle,
}: IProps) => {
  return (
    <Container>
      <div className="informative-title">
        <div>
          <p>Informe os dados do modelo</p>
          <Tooltip title="Alterar cor de todos os elementos">
            <ColorLensOutlinedIcon style={{ fill: '#394955' }} onClick={() => setControlModalColor({ name: `all` })} />
          </Tooltip>
        </div>
        <div>
          <ButtonNewCell onClick={addNewColumn} type="button">
            <AddColumnIcon />
            Nova coluna
          </ButtonNewCell>
          <ButtonNewCell onClick={addNewRow} type="button">
            <AddLineIcon />
            Nova linha
          </ButtonNewCell>
        </div>
      </div>

      <TableContent>
        <div className="table-title">
          <TextField
            size="normal"
            label="Título da tabela (opcional)"
            type="column"
            name="title"
            defaultValue={formik.values.title}
            onChange={(value) => changeTableTitle(value)}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </div>
        <table className="table-configuration" style={{ minWidth: 400 }}>
          <thead style={{ ...columnsBorderStyle }}>
            <tr style={{ ...columnsBorderStyle }}>
              <td style={{ background: '#D4D5D7', ...columnsBorderStyle }} />
              {columns.map((s, index) => (
                <td key={index} style={{ background: '#f5f5f6', ...columnsBorderStyle }}>
                  <div>
                    <span style={{ color: '#394955' }}>Coluna {index + 1}</span>{' '}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        <Tooltip title="Aumentar largura da coluna">
                          <AddIcon
                            style={{ fill: '#394955' }}
                            onClick={() => changeColumnWidth(index, SIZECELL.INCREASE)}
                          />
                        </Tooltip>
                      </span>
                      <span>
                        <Tooltip title="Diminuir largura da coluna">
                          <RemoveIcon
                            style={{ fill: '#394955' }}
                            onClick={() => changeColumnWidth(index, SIZECELL.DECREASE)}
                          />
                        </Tooltip>
                      </span>
                      <span>
                        <Tooltip title="Alterar cor">
                          <ColorLensOutlinedIcon
                            style={{ fill: '#394955' }}
                            onClick={() => setControlModalColor({ name: `column-${index}`, value: s.color })}
                          />
                        </Tooltip>
                      </span>

                      <span>
                        {columns.length > 1 && (
                          <Tooltip title="Deletar coluna">
                            <DeleteOutlineOutlinedIcon
                              onClick={() => {
                                setControlDeleteModal({ attributeType: 'coluna', index });
                              }}
                              color="error"
                            />
                          </Tooltip>
                        )}
                      </span>
                    </div>
                  </div>
                </td>
              ))}
            </tr>
            <tr style={{ ...columnsBorderStyle }}>
              <th style={{ width: 65, background: '#f5f5f6', ...columnsBorderStyle }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <span>
                    <Tooltip title="Alterar cor">
                      <ColorLensOutlinedIcon
                        style={{ fill: '#394955' }}
                        onClick={() => setControlModalColor({ name: `titleColor` })}
                      />
                    </Tooltip>
                  </span>
                  <span>
                    <Tooltip title="Aumentar altura da coluna">
                      <AddIcon style={{ fill: '#394955' }} onClick={() => changeRowHeight(-1, SIZECELL.INCREASE)} />
                    </Tooltip>
                  </span>
                  <span>
                    <Tooltip title="Diminuir altura da coluna">
                      <RemoveIcon style={{ fill: '#394955' }} onClick={() => changeRowHeight(-1, SIZECELL.DECREASE)} />
                    </Tooltip>
                  </span>
                </div>
              </th>
              {columns.map((s, index) => (
                <th key={index} style={{ minWidth: 270, ...columnsBorderStyle }}>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      id={`item0${index}`}
                      maxLength={150}
                      defaultValue={s.description}
                      onChange={(value: string | number) => {
                        changeColumnValue(index, value);
                      }}
                      type="column"
                      size="normal"
                      speechVisible={true}
                    />
                    <CellIcons>
                      <Tooltip title="Inserir nova coluna à direita">
                        <AddColumnIcon onClick={() => addNewColumn(index)} />
                      </Tooltip>
                      <Tooltip title="Inserir nova linha abaixo">
                        <AddLineIcon onClick={() => addNewRow(-1)} />
                      </Tooltip>
                    </CellIcons>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ ...columnsBorderStyle }}>
            {rows.map((r, rowIndex) => (
              <tr key={rowIndex} style={{ ...columnsBorderStyle }}>
                <td style={{ width: 65, ...columnsBorderStyle }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 5 }}>
                    <span>
                      <Tooltip title="Alterar cores">
                        <ColorLensOutlinedIcon
                          style={{ fill: '#394955' }}
                          onClick={() => setControlModalColor({ name: `row-${rowIndex}`, value: r[0].color })}
                        />
                      </Tooltip>
                    </span>
                    <span>
                      <Tooltip title="Deletar linha">
                        <DeleteOutlineOutlinedIcon
                          onClick={() => setControlDeleteModal({ attributeType: 'linha', index: rowIndex })}
                          color="error"
                        />
                      </Tooltip>
                    </span>
                    <span>
                      <Tooltip title="Aumentar altura da coluna">
                        <AddIcon
                          style={{ fill: '#394955' }}
                          onClick={() => changeRowHeight(rowIndex, SIZECELL.INCREASE)}
                        />
                      </Tooltip>
                    </span>
                    <span>
                      <Tooltip title="Diminuir altura da coluna">
                        <RemoveIcon
                          style={{ fill: '#394955' }}
                          onClick={() => changeRowHeight(rowIndex, SIZECELL.DECREASE)}
                        />
                      </Tooltip>
                    </span>
                  </div>
                </td>
                {r.map((cell, cellIndex) => (
                  <td key={cellIndex} style={{ minWidth: 270, ...columnsBorderStyle }}>
                    <div style={{ display: 'flex' }}>
                      <TextField
                        id={`item${rowIndex + 1}${cellIndex}`}
                        maxLength={150}
                        defaultValue={cell.description}
                        onChange={(value: string | number) => {
                          changeRowValue(cellIndex, rowIndex, value);
                        }}
                        type="row"
                        size="normal"
                        speechVisible={true}
                      />

                      <CellIcons>
                        <Tooltip title="Inserir nova coluna à direita">
                          <AddColumnIcon onClick={() => addNewColumn(cellIndex)} />
                        </Tooltip>
                        <Tooltip title="Inserir nova linha abaixo">
                          <AddLineIcon onClick={() => addNewRow(rowIndex)} />
                        </Tooltip>
                      </CellIcons>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContent>
    </Container>
  );
};

export default ConfigurationTable;
