import styled from 'styled-components';

export const ColorsTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  padding-top: 20px;
`;

export const ColorsContent = styled.div`
  display: flex;

  div {
    border: 1px solid #3e4756;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    margin: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
  }
`;

export const ContentModal = styled.form`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  height: 550px;
  padding: 10px;
  border: none;
  border-radius: 5px;

  h1 {
    color: #3e4756;
  }

  p {
    color: #3e4756;
  }

  .btnSalvar {
    width: 250px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: white;
    background-color: #3fced2;
  }

  .btnSalvar:hover {
    background-color: #3fced2;
  }

  .react-colorful {
    width: 250px;
    border: 1px solid #3e4756;
    border-radius: 10px;
  }

  input {
    width: 100%;
    height: 30px;
  }

  .btnLimpar {
    padding: 0px;
  }

  .close-icon {
    display: flex;
    align-self: flex-end;
    cursor: pointer;
  }
`;

export const DefaultColors = styled.div`
  display: flex;
  justify-content: flex-start !important;

  div {
    cursor: pointer;
    margin: 10px 5px 0px 5px;
    width: 25px;
    height: 25px;
    border-radius: 2px;
  }
`;
