import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  > div {
    width: 100%;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  width: 91%;

  div {
    width: auto;

    gap: 70px;
    display: flex;
    align-items: center;
    img {
      object-fit: none;
      cursor: pointer;
      height: 52px;
    }
    .menu-selected {
      color: #3fced2;
    }
    .color-p::before {
      color: #3fced2;
    }
  }

  div + div {
    justify-content: flex-end;
    width: 60%;

    p {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0;

      div {
        /* min-width: 80px; */
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Mosk Medium';
      }

      svg,
      img,
      span {
        margin-right: 5px;
      }
    }
  }
`;

export const Header = styled(Paper)`
  border-radius: 0px !important;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
`;

export const Exit = styled.label`
  color: #d1382e;
`;
