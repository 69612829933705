import styled from 'styled-components';

export const Content = styled.div`
  background: #fff;
  padding: 30px;
  width: 100vw;
  min-height: 358px;
  overflow: hidden;
  margin-bottom: -10px;

  .principal {
    margin-top: 0px;
  }

  .carousel {
    max-width: 79vw;
    height: 256px;
  }

  .topo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  .title {
    font-size: 26px;
    color: #3e4756;
    font-family: 'Mosk Semi Bold';
  }

  .swiper-container {
    min-height: 258px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  /* navigation */
  .swiper-container .swiper-button-prev:hover,
  .swiper-container .swiper-button-next:hover {
    background: #16161666;
    color: white;
  }

  /* .swiper-container .swiper-button-prev,
  .swiper-container .swiper-button-next {
    background: #16161666;
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50px;
  }

  .swiper-container .swiper-button-prev:hover,
  .swiper-container .swiper-button-next:hover {
    color: #4f2d58;
    background: white;
  } */

  .h2EMkQ {
    width: 384px;
    height: 288px;
  }
`;
