import * as Yup from 'yup';
import 'yup-phone';
// import { cnpjValidate, cpfValidate } from '../../../helper/validate';

export const clientInitialValues = {
  id: '',
  name: '',
  lastName: '',
  birthDate: null,
  email: '',
  oab: '',
  uf: '',
  occupation: '',
  pagarMECustomerId: '',
  genderId: '',
  phone: '',
  company: '',
  colors: {
    firstColor: '',
    secondColor: '',
    thirdColor: '',
    fourthColor: '',
    fifthColor: '',
  },
  userId: '',
  active: '',
  roleId: '',
};

export const clientValidationSchema = Yup.object({
  name: Yup.string().required('Nome é obrigatório'),
  lastName: Yup.string().required('Sobrenome  é obrigatório'),
  birthDate: Yup.date()
    .max(new Date(), 'Não é possível incluir uma data futura')
    .required('Data de nascimento é obrigatória'),
  email: Yup.string().required('E-mail  é obrigatório'),
  phone: Yup.string()
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, { message: 'Número de telefone inválido', excludeEmptyString: false })
    .required('Telefone é obrigatório'),
  oab: Yup.string().nullable(),
});
