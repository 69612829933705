import React, { useState } from 'react';

import { Container, useStyles } from './styles';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/auth';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const ExpiratePlan: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [formatedDate, setFormatedDate] = useState(format(new Date(user?.tokenData?.Expiration), 'dd/MM/yyyy'));
  const classes = useStyles();

  const handleChangeRoute = () => {
    history.push('/meus-dados');
  };

  return (
    <Container>
      <div>
        <h1>Plano expirado</h1>
        <p>
          Seu plano gratuito expirou em {formatedDate || ''}, para ter acesso às funcionalidades será necessário
          adquirir um plano pago.
        </p>
        <Button className={classes.button} variant="contained" onClick={handleChangeRoute}>
          Adquirir um novo plano
        </Button>
      </div>
    </Container>
  );
};

export default ExpiratePlan;
