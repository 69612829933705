import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { localizationOptions } from '../../../helper/tableHelper';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import EditIcon from '../../../assets/edit-icon.svg';
import { ControlPoint } from '@material-ui/icons';
import { Container, useStyles } from './styles';
import { useAlert } from '../../../contexts/alert';
import { getMonthlyPlans } from '../../../services/plans';
import { filterRequest } from '../../../interfaces/requests';
import { useAuth } from '../../../contexts/auth';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';

import columns from './columns';

const Planos: React.FC = () => {
  const { showAlertError } = useAlert();
  const classes = useStyles();
  const history = useHistory();
  const { permissions } = useAuth();

  return (
    <Container>
      {permissions?.includes('CanReadPlans') ? (
        <MaterialTable
          title="Plano"
          columns={columns}
          actions={[
            {
              icon: () => (permissions?.includes('CanUpdatePlans') ? <img src={EditIcon} /> : <VisibilityOutlined />),
              tooltip: permissions?.includes('CanUpdatePlans') ? 'Editar' : 'Vizualizar',
              position: 'row',

              onClick: (event, rowData) => history.push(`/planos/edicao-plano/${rowData.id}`),
            },
            {
              icon: () => (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={permissions?.includes('CanCreatePlans') ? false : true}
                >
                  <ControlPoint className={classes.svg} /> Novo
                </Button>
              ),
              disabled: permissions?.includes('CanCreatePlans') ? false : true,
              tooltip: 'Adicionar',
              onClick: (event, rowData) => history.push(`/planos/cadastro-plano`),
              position: 'toolbar',
            },
          ]}
          localization={localizationOptions}
          data={({ page, pageSize, search, filters }) => {
            return new Promise((resolve, reject) => {
              let filter: Array<filterRequest> = [];
              if (filters[0]?.column) {
                filter = filters?.map((f) => {
                  return {
                    field: f?.column?.field,
                    value: f.value,
                  };
                });
              }
              getMonthlyPlans(page, pageSize, search, filter)
                .then((result: any) => {
                  if (result) {
                    resolve({
                      data: result.data.value,
                      page: page,
                      totalCount: result.data['@odata.count'],
                    });
                  } else {
                    reject({
                      data: [],
                      page: page,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: page,
                    totalCount: 0,
                  });
                  showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os planos');
                });
            });
          }}
          options={{
            headerStyle: {
              padding: '20px',
            },
            rowStyle: {
              padding: 20,
            },
            filtering: true,
            actionsColumnIndex: -1,
            pageSize: 3,
            pageSizeOptions: [3, 5, 10],
            sorting: false,
            debounceInterval: 700,
          }}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default Planos;
