import styled from 'styled-components';

export const TimelineHorizontal = styled.section`
  white-space: nowrap;
  overflow-x: auto;
  width: 1600px;
  justifycontent: 'flex-start';
  display: flex;

  ol {
    font-size: 0;
    width: 100vw;
    padding: 255px 0;
    transition: all 1s;
  }

  ol li {
    position: relative;
    display: inline-block;
    list-style-type: none;
    width: 280px;
    height: 3px;
    background: #bdbdbd;
  }

  ol li:first-child {
    height: 0px;
    margin-left: -100px;
  }

  /* Pontos Timeline  */
  /* ol li:after {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(100% + 1px);
    bottom: 0;
    width: 24px;
    height: 24px;
    border: 8px solid #fff;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 10;
    margin-top: -20px;
    background-color: ${(props) => props.color || 'lightgrey'};
  } */

  ol li:before {
    display: inline-block;
    content: attr(data-year);
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    left: calc(100% + 1px);
    min-width: 200px !important;
    transform: translateX(-48%);
    color: #1f1e1e;
  }

  ol li:nth-child(odd):before {
    top: -50px;
  }

  ol li:nth-child(even):before {
    bottom: -50px;
  }

  ol li:after {
    display: inline-block;
    content: attr(data-text);
    font-size: 16px;
    position: absolute;
    transform: translateX(-50%);
  }

  ol li div {
    position: absolute;
    width: 380px;
    padding: 10px;
    font-size: 1rem;
    margin-left: 90px;
    white-space: normal;
    text-align: center;
    color: black;
    line-break: normal;
  }

  ol li:nth-child(even) div {
    top: -16px;
    transform: translateY(-100%);
  }

  ol li div p {
    display: block;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;
