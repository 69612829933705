import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(Paper)`
  width: 100%;
  height: auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .row {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    color: #3e4756;

    .title {
      font-size: 30px;
      color: #3e4756;
      font-family: 'Mosk Semi Bold';
    }
  }
`;

export const GrayContainer = styled.div`
  width: 100%;
  background: #ebf2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 40px;

  .row {
    width: 100%;
    height: 40px;

    label {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .data {
    width: 200px;
    margin-right: 30px;
    background-color: #fff;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const DashboardContainer = styled(Paper)`
  width: 41.5vw;
  height: auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 80px 60px 80px;

  label {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #3e4756;
  }
`;

export const LastDashboardContainer = styled(Paper)`
  width: 100%;
  padding-right: 20%;
  padding-left: 20%;
  height: auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 80px 60px 80px;

  label {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #3e4756;
  }
`;
