import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;

  .MuiFormControl-root {
    height: 100%;
  }

  .MuiTypography-h6 {
    font-size: 30px;
    color: #3e4756;
    font-family: 'Mosk Semi Bold';
  }

  .MuiToolbar-root {
    padding-left: 20px;
  }

  .MuiTable-root,
  .MuiInputBase-root,
  .MuiTableCell-head {
    color: #3e4756;
    font-size: 16px;
  }

  #renderTable {
    width: intrinsic; /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content; /* Firefox/Gecko */
    width: -webkit-max-content;
    height: intrinsic; /* Safari/WebKit uses a non-standard name */
    height: -moz-max-content; /* Firefox/Gecko */
    height: -webkit-max-content;
  }
`;

export const ButtonsModal = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin: 5px;
    width: 100px;
  }
  .btnNao {
    border-color: #3fced2;
    color: #3fced2;
  }
  .btnSim {
    background-color: #3fced2;
  }
`;
