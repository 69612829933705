import React, { useEffect, useState } from 'react';
import { GreenText, RedText } from './styles';
import ActiveSelect from '../../../components/SelectTrueOrFalseComponentTable';
import { IconButton, MenuItem, Select, Tooltip } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { states } from '../../../helper/statesHelper';
import { isValid } from 'date-fns';
import { getPlans } from '../../../services/plans';
import { getOccupationArea } from '../../../services/occupationArea';

interface IPlan {
  id: string;
  name: string;
}

interface IOccupation {
  id: string;
  value: string;
}

let occupationArea: any = [];
let plans: any = [];

const columns = [
  {
    title: 'Nome',
    field: 'user.name',
    render: (rowData) => <p style={{ width: 150, margin: 0 }}>{rowData.user.name + ' ' + rowData.user.lastName}</p>,
  },
  {
    title: 'E-mail',
    field: 'user.email',
    render: (rowData) => <p style={{ width: 200, margin: 0, wordBreak: 'break-word' }}>{rowData.user.email}</p>,
  },
  {
    title: 'Data de nascimento',
    field: 'birthDate',
    render: (rowData) => rowData?.birthDate && new Date(rowData?.birthDate).toLocaleDateString('pt-BR'),
    filterComponent: (props) => (
      <div style={{ display: 'flex', alignItems: 'center', width: 200 }}>
        <DatePicker
          autoOk
          placeholder="dd/mm/aaaa"
          onChange={(date) => {
            if (isValid(date)) {
              props.onFilterChanged(
                props?.columnDef?.tableData?.id,
                new Date(new Date(date).setHours(0, 0, 0)).toISOString()
              );
            }
          }}
          defaultValue={null}
          variant="inline"
          format="dd/MM/yyyy"
        />
        <Tooltip title="Limpar">
          <IconButton>
            <CloseIcon
              fontSize="small"
              onClick={() => {
                props.onFilterChanged(props?.columnDef?.tableData?.id, null);
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Seccional',
    field: 'uf.initials',
    render: (rowData) => <p style={{ width: 100, margin: 0 }}>{rowData?.uf?.initials}</p>,
    filterComponent: (props: any) => (
      <Select
        value={props?.columnDef?.tableData?.filterValue || ' '}
        onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
      >
        <MenuItem value=" ">
          <em>Selecione</em>
        </MenuItem>
        {states.map((uf) => (
          <MenuItem key={uf.id_uf} value={uf.sigla}>
            <em>{uf.sigla}</em>
          </MenuItem>
        ))}
      </Select>
    ),
  },
  {
    title: 'Área de atuação',
    field: 'occupationArea.value',
    render: (rowData) => <p style={{ width: 120, margin: 0 }}>{rowData?.occupationArea?.value}</p>,
    filterComponent: (props: any) => (
      <Select
        value={props?.columnDef?.tableData?.filterValue || ' '}
        onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value=" ">
          <em>Selecione</em>
        </MenuItem>
        {
          (getOccupationArea().then((resp) => {
            occupationArea = resp.data.value;
          }),
          occupationArea.map((occupation: IOccupation) => {
            return (
              <MenuItem key={occupation.id} value={occupation.value}>
                {occupation.value}
              </MenuItem>
            );
          }))
        }
      </Select>
    ),
  },
  {
    title: 'Plano',
    field: 'clientSubscriptions.subscription.recurrencePlan.plan.name',
    render: (rowData) => (
      <p style={{ width: 100, margin: 0 }}>{rowData?.clientSubscriptions[0]?.subscription?.recurrencePlan.plan.name}</p>
    ),
    filterComponent: (props: any) => (
      <Select
        value={props?.columnDef?.tableData?.filterValue || ' '}
        onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value=" ">
          <em>Selecione</em>
        </MenuItem>
        {
          (getPlans().then((resp) => {
            plans = resp.data.value;
          }),
          plans.map((plan: IPlan) => {
            return (
              <MenuItem key={plan.id} value={plan.name}>
                {plan.name}
              </MenuItem>
            );
          }))
        }
      </Select>
    ),
  },
  {
    field: 'clientSubscriptions.subscription.planStatus',
    title: 'Situação do plano',
    render: (rowData) => (
      <p style={{ width: 100, margin: 0 }}>
        {rowData?.clientSubscriptions[0]?.subscription?.planStatus == 'InUse' ? (
          <p style={{ width: 150, margin: 0 }}>
            <GreenText>Em uso</GreenText>
          </p>
        ) : rowData?.clientSubscriptions[0]?.subscription?.planStatus == 'Trial' ? (
          <p style={{ width: 150, margin: 0 }}>
            <GreenText>Trial</GreenText>
          </p>
        ) : rowData?.clientSubscriptions[0]?.subscription?.planStatus == 'CanceledTrial' ? (
          <p style={{ width: 150, margin: 0 }}>
            <RedText>Cancelado Trial</RedText>
          </p>
        ) : (
          <p style={{ width: 150, margin: 0 }}>
            <RedText>Cancelado</RedText>
          </p>
        )}
      </p>
    ),
    filterComponent: (props: any) => (
      <Select
        value={props?.columnDef?.tableData?.filterValue || ' '}
        onChange={(e) => props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value=" ">
          <em>Selecione</em>
        </MenuItem>
        <MenuItem value="InUse">Em uso</MenuItem>
        <MenuItem value="Trial">Trial</MenuItem>
        <MenuItem value="CanceledTrial">Cancelado trial</MenuItem>
        <MenuItem value="Canceled">Cancelado</MenuItem>
      </Select>
    ),
  },
  {
    title: 'Situação',
    field: 'user.active',
    render: (rowData) => {
      if (rowData?.user?.active) {
        return (
          <p style={{ width: 150, margin: 0 }}>
            <GreenText>Ativo</GreenText>
          </p>
        );
      } else {
        return (
          <p style={{ width: 150, margin: 0 }}>
            <RedText>Inativo</RedText>
          </p>
        );
      }
    },
    filterComponent: (props: any): React.ReactElement => {
      return <ActiveSelect {...props} />;
    },
  },
  {
    title: 'Market Place',
    field: 'user.marketPlace',
    render: (rowData) => {
      if (rowData?.user?.marketPlaceId == null || rowData?.user?.marketPlaceId == 1) {
        return <p style={{ width: 100, margin: 0 }}>Padrão</p>;
      } else {
        return <p style={{ width: 100, margin: 0 }}>Hotmart</p>;
      }
    },
    filterComponent: (props: any): React.ReactElement => {
      return <ActiveSelect {...props} />;
    },
  },
];
export default columns;
