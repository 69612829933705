import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { ContentModal, DefaultColors } from './styles';
import { Button, FormControl, FormHelperText, IconButton, Tooltip } from '@material-ui/core';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/auth';
interface ISelectedColor {
  name?: string;
  value?: string;
}

interface IProps {
  closeModal: () => void;
  open: boolean;
  changeColor: (name: string, color: string) => void;
  selectedColor: ISelectedColor | null;
}

const validationSchema = Yup.object({
  color: Yup.string(),
});

const ModalColors = ({ closeModal, open, changeColor, selectedColor }: IProps): React.ReactElement => {
  const { user } = useAuth();
  const formik = useFormik({
    initialValues: {
      color: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changeColor(selectedColor?.name, values.color);
      closeModal();
    },
  });

  useEffect(() => {
    if (selectedColor?.value) formik.setFieldValue('color', selectedColor?.value);
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ContentModal onSubmit={formik.handleSubmit}>
          <CloseIcon className="close-icon" onClick={closeModal} />
          <h1>Escolha uma cor</h1>
          <p>Selecione ou insira uma cor abaixo</p>

          <FormControl error={formik.touched.color && Boolean(formik.errors.color)}>
            <HexColorPicker color={formik.values.color} onChange={(value) => formik.setFieldValue('color', value)} />
            <DefaultColors>
              {user?.tokenData?.Colors?.map(
                (color, index) =>
                  color && (
                    <div
                      key={index}
                      style={{ background: color }}
                      onClick={() => formik.setFieldValue('color', color)}
                    />
                  )
              )}
            </DefaultColors>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
              <HexColorInput
                color={formik.values.color}
                onChange={(value) => formik.setFieldValue('color', value)}
                prefixed={true}
              />
              {formik.values.color && (
                <Tooltip title="Limpar a cor escolhida">
                  <IconButton disabled={!formik.values.color} className="btnLimpar">
                    <DeleteOutlinedIcon
                      className="delete-icon"
                      style={{ color: '#F1504D' }}
                      onClick={() => formik.setFieldValue('color', '')}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <FormHelperText>{formik.touched.color && formik.errors.color}</FormHelperText>
          </FormControl>
          <Button className="btnSalvar" variant="contained" fullWidth type="submit">
            Salvar
          </Button>
        </ContentModal>
      </Modal>
    </>
  );
};

export default React.memo(ModalColors);
