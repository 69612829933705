import UsuariosInternos from '../pages/InternalUsersAndAccessProfile/List';
import CadastroPerfisDeAcesso from '../pages/InternalUsersAndAccessProfile/CreateOrEdit/AccessProfile';
import CadastroUsuarioInterno from '../pages/InternalUsersAndAccessProfile/CreateOrEdit/InternalUser';
import Planos from '../pages/Plans/List';
import CadastroPlanos from '../pages/Plans/CreateOrEdit';
import UsuariosExternos from '../pages/ExternalUsers/List';
import EdicaoUsuarioExterno from '../pages/ExternalUsers/Edit';
import UsuarioDependente from '../pages/DependentUser/List';
import CriarUsuarioDependente from '../pages/DependentUser/CreateOrEdit';
import HomeUsuarioExterno from '../pages/HomeExternalUsers';
import HomeUsuarioInterno from '../pages/HomeInternalUsers';
import PersonalData from '../pages/MyData';
import ModalChangePassword from '../components/ModalChangePassword';
import Login from '../pages/Login';

export const internalUserRoutes = [
  {
    name: 'Usuarios Internos',
    url: '/usuarios-internos',
    component: UsuariosInternos,
    props: {},
  },
  {
    name: 'Cadastro Perfil de Acesso',
    url: '/cadastro-perfil-acesso',
    component: CadastroPerfisDeAcesso,
  },
  {
    name: 'Edição Perfil de Acesso',
    url: '/edicao-perfil-acesso/:id',
    component: CadastroPerfisDeAcesso,
  },
  {
    name: 'Cadastro Usuário Interno',
    url: '/cadastro-usuario-interno',
    component: CadastroUsuarioInterno,
  },
  {
    name: 'Editar Usuário Interno',
    url: '/edicao-usuario-interno/:id',
    component: CadastroUsuarioInterno,
  },
  {
    name: 'Cadastrar Plano',
    url: '/planos/cadastro-plano',
    component: CadastroPlanos,
  },
  {
    name: 'Editar Plano',
    url: '/planos/edicao-plano/:id',
    component: CadastroPlanos,
  },
  {
    name: 'Plano',
    url: '/planos',
    component: Planos,
  },
  {
    name: 'Usuários Externos',
    url: '/usuarios-externos',
    component: UsuariosExternos,
  },
  {
    name: 'Editar Usuário Externo',
    url: '/edicao-usuario-externo/:id',
    component: EdicaoUsuarioExterno,
  },

  {
    name: 'Usuário Dependente',
    url: '/usuarios-dependentes',
    component: UsuarioDependente,
  },
  {
    name: 'Home Usuário Interno',
    url: '/dashboards',
    component: HomeUsuarioInterno,
  },
  {
    name: 'Minha Conta',
    url: '/minha-conta',
    component: PersonalData,
  },
  {
    name: 'Criar Usuário Dependente',
    url: '/cadastro-usuario-dependente',
    component: CriarUsuarioDependente,
  },
  {
    name: 'Home Usuário Externo',
    url: '/home-usuario-externo',
    component: HomeUsuarioExterno,
  },

  {
    name: 'alterar Senha',
    url: '/alterar-senha',
    component: ModalChangePassword,
  },
  {
    name: 'Modelos',
    url: '/tabela-comparativa',
    component: Login,
  },
  {
    name: 'Edicao Usuário Dependente',
    url: '/edicao-usuario-dependente',
    component: Login,
  },
  {
    name: 'Criar Usuário Dependente',
    url: '/cadastro-usuario-dependente',
    component: Login,
  },
  {
    name: 'Editar Usuário Dependente',
    url: '/edicao-usuario-Dependente/:id',
    component: Login,
  },
  {
    name: 'Linha do tempo',
    url: '/linha-do-tempo',
    component: Login,
  },
  {
    name: 'QRCode',
    url: '/qrcode',
    component: Login,
  },
  {
    name: 'Diagram',
    url: '/grafico',
    component: Login,
  },
  {
    name: 'Modelos',
    url: '/fluxograma',
    component: Login,
  },
  {
    name: 'Novo projeto',
    url: '/novo-modelo',
    component: Login,
  },
  {
    name: 'Meus projetos',
    url: '/meus-modelos',
    component: Login,
  },
  {
    name: 'Meus Dados',
    url: '/meus-dados',
    component: Login,
  },
];
