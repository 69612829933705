import React, { useEffect, useRef, useState } from 'react';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import ModalColors from '../../../components/ModalChooseColor';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import ModalOptions from '../../../components/ModalOptions';
import VerticalView from './VerticalTimeLine/VerticalView';
import HorizontalView from './HorizontalTimeLine/HorizontalView';
import HorizontalConfiguration from './HorizontalTimeLine/HorizontalConfiguration';
import VerticalConfiguration from './VerticalTimeLine/VerticalConfiguration';
import { Buttons, Container, Content, TimeLineContainer, TopContent, useStyles } from './styles';
import { useFormik } from 'formik';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { createTimeLine, getModelById, updateModel } from '../../../services/models';
import { useAuth } from '../../../contexts/auth';
import { POSITION, SORT } from '../../../interfaces/timeline';
import { isEmpty } from 'lodash';
import { getClientColors } from '../../../services/externalUsers';
import Split from 'react-split';
import { createImageFile } from '../../../helper/convertHTML';

interface IItem {
  date: Date | any;
  title: string;
  description: string;
  color?: string;
}
interface ITimeLine {
  data: IItem[];
  title: string;
}

interface IModalColor {
  index: number | string;
  value?: string;
}

interface IModalDelete {
  index?: number;
}

interface IFormik {
  id: string;
  description: string;
  position: number;
  breakPage: boolean;
  dateType: number;
  sort: number;
  tipo: number;
  json: string;
  title: string;
  html: string;
  clienteTitularId: string;
  columnWidth: number;
  htmlImage: string;
}

const validationSchema = Yup.object({
  description: Yup.string().required('Descrição é obrigatória.').max(500, 'Precisa ter no máximo 500 letras'),
  position: Yup.number(),
  breakPage: Yup.boolean(),
  dateType: Yup.number(),
  sort: Yup.number(),
  json: Yup.string(),
  title: Yup.string(),
  html: Yup.string(),
  clienteTitularId: Yup.string(),
});

const TimeLine: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [controlModalColor, setControlModalColor] = useState<IModalColor | null>(null);
  const [controlDeleteModal, setControlDeleteModal] = useState<IModalDelete | null>(null);
  const [controlCancelEditingModal, setControlCancelEditingModal] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState(false);
  const { showLoading } = useLoading();
  const { user } = useAuth();
  const [idModel, setIdModel] = useState(history?.location?.state?.id);
  const clientTitularId: string = user?.tokenData?.ClientId;
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const { showAlertError, showAlertSuccess } = useAlert();
  const [temporaryPathName, setTemporaryPathName] = useState('');
  const [splitSizes, setSplitSizes] = useState([50, 50]);

  const refVerticalHasBreakPage = useRef(null);
  const refHorizontalHasBreakPage = useRef(null);
  const refVerticalHasNoBreakPage = useRef(null);
  const refHorizontalHasNoBreakPage = useRef(null);
  const [firstColor, setFirstColor] = useState('');
  const [lastColor, setLastColor] = useState('');
  const [timeLine, setTimeLine] = useState<ITimeLine>({
    title: '',
    data: [],
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      description: '',
      position: 1,
      breakPage: false,
      dateType: 1,
      sort: 1,
      tipo: 1,
      json: '',
      title: '',
      html: '',
      clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      columnWidth: 450,
      htmlImage: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: IFormik) => {
      values.json = JSON.stringify(timeLine.data);
      values.title = timeLine.title;
      values.clienteTitularId = OwnerUserId ? OwnerUserId : clientTitularId;
      if (values.breakPage) {
        if (values.position === POSITION.HORIZONTAL) values.html = refHorizontalHasBreakPage?.current?.outerHTML;
        else values.html = refVerticalHasBreakPage.current?.outerHTML;
      } else {
        if (values.position === POSITION.HORIZONTAL) values.html = refHorizontalHasNoBreakPage.current?.outerHTML;
        else values.html = refVerticalHasNoBreakPage.current?.outerHTML;
      }
      values.htmlImage = await createImageFile(document.getElementById('#view'));
      showLoading(true);

      let body = {
        id: idModel ? idModel : uuidv4(),
        descricao: values.description,
        quebraPagina: values.breakPage,
        json: values.json,
        html: values.html,
        dataHora: values.dateType,
        posicao: values.position,
        tipo: values.tipo,
        titulo: values.title,
        ordenacao: values.sort,
        clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
        LarguraColuna: values.columnWidth,
        htmlImage: values.htmlImage,
      };

      if (!idModel) {
        createTimeLine(body)
          .then(() => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Linha do tempo salva com sucesso.');
            setIdModel(body.id);
          })
          .catch((error) => {
            showLoading(false);
            if (error.status === 401) {
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            }
            if (error.status === 412) {
              showAlertError('Atingido limite de dependentes do plano.');
            } else {
              showAlertError('Ocorreu um erro ao enviar os dados da linha do tempo.');
            }
          });
      } else {
        updateModel('ModeloLinhaDoTempo', body)
          .then(() => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Linha do tempo atualizada com sucesso.');
            setIdModel(body.id);
          })
          .catch((err: any) => {
            showLoading(false);
            if (err.status === 401) {
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            }
            if (error.status === 412) {
              showAlertError('Atingido limite de dependentes do plano.');
            } else {
              showAlertError('Ocorreu um erro ao atualizar os dados da linha do tempo.');
            }
          });
      }
    },
  });

  const changeSelectedColor = (name: string, value: string) => {
    let dataAux = [...timeLine.data];
    setIsSaved(false);
    if (name == 'all') {
      dataAux?.map((data) => {
        data.color = value + 'e6';
      });
    } else {
      dataAux[name] = {
        ...dataAux[name],
        color: value + 'e6',
      };
    }
    setTimeLine((prevState) => ({ ...prevState, data: dataAux }));
    setLastColor(value);
  };

  const addNewItem = (index: number) => {
    setIsSaved(false);
    let auxData = [...timeLine.data];
    auxData.splice(index + 1, 0, { date: null, title: '', description: '', color: lastColor + 'e6' });
    setTimeLine((prevState) => ({
      ...prevState,
      data: auxData,
    }));

    const timeOut = setTimeout(() => {
      document.getElementById(`item${index + 1}`)?.focus();
      document.getElementById(`view${index + 1}`)?.scrollIntoView();
      clearTimeout(timeOut);
    }, 5);
    return () => clearInterval(timeOut);
  };

  const changeItemValue = (index: number, value: string, attribute: string) => {
    setIsSaved(false);
    let dataAux = [...timeLine.data];
    dataAux[index] = {
      ...dataAux[index],
      [attribute]: value,
    };
    setTimeLine((prevState) => ({ ...prevState, data: dataAux }));
  };

  const onSaveModalOptions = () => {
    deleteItem(Number(controlDeleteModal?.index));
    setControlDeleteModal(null);
  };

  const deleteItem = (index: number) => {
    setIsSaved(false);
    let dataAux = [...timeLine.data];
    dataAux.splice(index, 1);
    setTimeLine((prevState) => ({ ...prevState, data: dataAux }));
  };

  const increaseWidth = () => {
    setIsSaved(false);
    formik.values.columnWidth += 10;
    setTimeLine((prevState) => ({ ...prevState, data: [...timeLine.data] }));
  };

  const decreaseWidth = () => {
    setIsSaved(false);
    formik.values.columnWidth -= 10;
    if (formik.values.columnWidth < 350) {
      formik.values.columnWidth = 350;
    }
    setTimeLine((prevState) => ({ ...prevState, data: [...timeLine.data] }));
  };

  const reorderItens = () => {
    sortData(formik.values.sort);
  };

  const sortData = (type: number) => {
    setIsSaved(false);
    let dataAux = [...timeLine.data];
    if (type == SORT.CRESCENTE) {
      dataAux.sort(function (a, b) {
        return new Date(a.date).setHours(0, 0, 0) - new Date(b.date).setHours(0, 0, 0);
      });
    } else if (type == SORT.DECRESCENTE) {
      dataAux.sort(function (a, b) {
        return new Date(b.date).setHours(0, 0, 0) - new Date(a.date).setHours(0, 0, 0);
      });
    }
    setTimeLine((prevState) => ({ ...prevState, data: dataAux }));
  };

  const onCancelEditing = () => history.push('/novo-modelo');

  const validadeDateRequest = (formatdata: string) => {
    let result = 6;
    if (formatdata == 'DDMMAAAA') {
      result = 1;
    }
    if (formatdata == 'DDMMAAAAHH') {
      result = 2;
    }
    if (formatdata == 'MMAAAA') {
      result = 3;
    }
    if (formatdata == 'AAAA') {
      result = 4;
    }
    if (formatdata == 'DDMM') {
      result = 5;
    }

    return result;
  };

  const getLinhaTempoById = async (modeloId: string) => {
    showLoading(true);
    const response = await getModelById('ModeloLinhaDoTempo', OwnerUserId ? OwnerUserId : clientTitularId, modeloId);
    if (response.status === 200) {
      let dataFormik = {
        description: response.data[0].descricao,
        position: response.data[0].posicao,
        breakPage: response.data[0].quebraPagina,
        dateType: response.data[0].dataHora,
        sort: response.data[0].ordenacao,
        title: response.data[0].titulo,
        tipo: response.data[0].tipo == undefined ? 1 : response.data[0].tipo,
        clienteTitularId: clientTitularId,
        columnWidth: response.data[0].larguraColuna == undefined ? 450 : response.data[0].larguraColuna,
      };
      formik.setValues(dataFormik);

      const dataTimeLine = JSON.parse(response.data[0].json);
      setIsSaved(true);

      setTimeLine({ data: dataTimeLine, title: response.data[0].titulo });
      showLoading(false);
    } else {
      showLoading(false);

      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
      }
    }
  };

  const changeTimelineTitle = (value) => {
    setIsSaved(false);
    setTimeLine((prevState) => ({ ...prevState, title: value }));
  };

  useEffect(() => {
    showLoading(true);
    getClientColors(user?.tokenData?.UserId)
      .then((response: any) => {
        setFirstColor(response?.data?.value[0]?.color1);
        setLastColor(response?.data?.value[0]?.color1);
        showLoading(false);
      })
      .catch(() => {
        showLoading(false);
      });
  }, []);

  useEffect(() => {
    setTimeLine({
      title: '',
      data: [
        {
          date: null,
          title: '',
          description: '',
          color: firstColor + 'e6',
        },
      ],
    });
  }, [firstColor]);

  useEffect(() => {
    if (idModel) {
      getLinhaTempoById(idModel);
    }
  }, [idModel]);

  useEffect(() => {
    // handle refresh page
    const beforeUnloadCallback = (event) => {
      if (!isSaved) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    if (!isSaved) {
      history.block((prompt) => {
        setTemporaryPathName(prompt.pathname);
        setControlCancelEditingModal(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      history.block(() => {});
    };
  }, [history, isSaved]);

  return (
    <Container elevation={1}>
      <ModalColors
        closeModal={() => setControlModalColor(null)}
        open={Boolean(controlModalColor)}
        changeColor={changeSelectedColor}
        selectedColor={controlModalColor}
      />
      <ModalOptions
        text={`Tem certeza que deseja excluir este item?`}
        open={Boolean(controlDeleteModal)}
        onCancel={() => setControlDeleteModal(null)}
        onSave={onSaveModalOptions}
      />
      <ModalOptions
        text={`As alterações não foram salvas, deseja continuar?`}
        open={Boolean(controlCancelEditingModal)}
        onCancel={() => setControlCancelEditingModal(false)}
        onSave={() => {
          history.block(() => {});
          history.push(temporaryPathName);
        }}
      />
      <Content onSubmit={formik.handleSubmit}>
        <TopContent>
          <div>
            <a style={{ cursor: 'pointer' }} onClick={() => history.push('/meus-modelos')}>
              <ArrowBackIos />
            </a>
            <p>
              Modelo / <span className="color-text">Linha do tempo</span>
            </p>
          </div>
        </TopContent>
        <div className="inputs">
          <TextField
            label="Identificação do modelo da linha do tempo"
            className="descricao-tabela"
            variant="outlined"
            type="text"
            name="description"
            value={formik.values.description}
            onChange={(event) => {
              setIsSaved(false);
              formik.handleChange(event);
            }}
            inputProps={{
              maxLength: 500,
            }}
            error={Boolean(formik.errors.description)}
            helperText={formik.errors.description}
          />

          <TextField
            name="Modelo"
            label="Modelo"
            InputLabelProps={{ shrink: true }}
            className="modelo"
            variant="outlined"
            value={'Linha do tempo'}
            disabled
            onChange={() => {}}
            type="text"
          />
        </div>
        <div className="selects">
          <FormControl className="posicao" fullWidth variant="outlined">
            <InputLabel id="posicao">Posição da linha do tempo</InputLabel>
            <Select
              labelId="posicao"
              id="position"
              defaultValue={1}
              value={formik.values.position}
              onChange={(e) => {
                setIsSaved(false);
                formik.setFieldValue('position', e.target.value);
              }}
              label="Posição da linha do tempo"
            >
              <MenuItem value={1}>Horizontal</MenuItem>
              <MenuItem value={2}>Vertical</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="layout" variant="outlined">
            <InputLabel id="tipo">Layout</InputLabel>
            <Select
              labelId="sort"
              id="sort"
              label="layout"
              defaultValue={1}
              value={formik.values.tipo}
              onChange={(e) => {
                setIsSaved(false);
                formik.setFieldValue('tipo', e.target.value);
              }}
            >
              <MenuItem value={1}>Modelo 1</MenuItem>
              <MenuItem value={2}>Modelo 2</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="quebra-linha" variant="outlined">
            <InputLabel id="quebra-linha">Quebra de página</InputLabel>
            <Select
              labelId="quebra-linha"
              id="quebra-linha"
              defaultValue={true}
              value={formik.values.breakPage ? 'yes' : 'no'}
              label="Quebra de linha"
              onChange={(e) => {
                setIsSaved(false);
                formik.setFieldValue('breakPage', e.target.value === 'yes' ? true : false);
              }}
            >
              <MenuItem value="yes">Sim</MenuItem>
              <MenuItem value="no">Não</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="data-hora" variant="outlined">
            <InputLabel id="data-hora">Data/Hora</InputLabel>
            <Select
              labelId="data-hora"
              id="data-hora"
              label="Data/Hora"
              defaultValue={1}
              value={formik.values.dateType}
              onChange={(e) => {
                setIsSaved(false);
                formik.setFieldValue('dateType', e.target.value);
              }}
            >
              <MenuItem value={1}>Data</MenuItem>
              <MenuItem value={2}>Data/Hora</MenuItem>
              <MenuItem value={3}>Mes/Ano</MenuItem>
              <MenuItem value={4}>Ano</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="ordenacao" variant="outlined">
            <InputLabel id="ordenacao">Ordenação</InputLabel>
            <Select
              labelId="sort"
              id="sort"
              label="Ordenação"
              defaultValue={1}
              value={formik.values.sort}
              onChange={(e) => {
                setIsSaved(false);
                formik.setFieldValue('sort', e.target.value);
                sortData(Number(e.target.value));
              }}
            >
              <MenuItem value={1}>Menor ao maior</MenuItem>
              <MenuItem value={2}>Maior ao menor</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Content>
      {formik.values.position == POSITION.HORIZONTAL ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxHeight: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <HorizontalConfiguration
            data={timeLine.data}
            dateType={formik.values.dateType}
            addNewItem={addNewItem}
            increaseWidth={increaseWidth}
            decreaseWidth={decreaseWidth}
            reorderItens={reorderItens}
            title={timeLine.title}
            changeItemValue={changeItemValue}
            controlDeleteModal={(index: number) => setControlDeleteModal({ index })}
            controlModalColor={(index: number | string, value: string) =>
              setControlModalColor({ index, name: index, value: value })
            }
            changeTitleTimeline={changeTimelineTitle}
          />
          <HorizontalView
            id={idModel}
            refHasBreakPage={refHorizontalHasBreakPage}
            refHasNoBreakPage={refHorizontalHasNoBreakPage}
            title={timeLine.title}
            data={timeLine.data}
            dateType={formik.values.dateType}
            tipo={formik.values.tipo}
            breakPage={formik.values.breakPage}
            isSaved={isSaved}
            fileName={formik.values.description}
            colWidth={formik.values.columnWidth}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            maxHeight: '100%',
            justifyContent: 'flex-start',
            flexDirection: 'row',
          }}
        >
          <Split
            sizes={splitSizes}
            minSize={[430, 1000]}
            maxSize={[850, Infinity]}
            direction="horizontal"
            style={{ display: 'flex', width: '100%' }}
            cursor="col-resize"
            gutterSize={10}
            onDragEnd={(sizes) => {
              setSplitSizes(sizes);
            }}
          >
            <VerticalConfiguration
              data={timeLine.data}
              title={timeLine.title}
              dateType={formik.values.dateType}
              addNewItem={addNewItem}
              increaseWidth={increaseWidth}
              decreaseWidth={decreaseWidth}
              reorderItens={reorderItens}
              changeItemValue={changeItemValue}
              controlDeleteModal={(index: number) => setControlDeleteModal({ index })}
              controlModalColor={(index: number, value: string) =>
                setControlModalColor({ index, name: index, value: value })
              }
              changeTitleTimeline={changeTimelineTitle}
            />
            <VerticalView
              id={idModel}
              data={timeLine.data}
              dateType={formik.values.dateType}
              tipo={formik.values.tipo}
              title={timeLine.title}
              breakPage={formik.values.breakPage}
              refHasBreakPage={refVerticalHasBreakPage}
              refHasNoBreakPage={refVerticalHasNoBreakPage}
              isSaved={isSaved}
              fileName={formik.values.description}
              colWidth={formik.values.columnWidth}
            />
          </Split>
        </div>
      )}
      <Buttons>
        <Button className={classes.buttonRed} variant="outlined" onClick={onCancelEditing}>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (!isEmpty(formik.errors))
              showAlertError('Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.');
            else formik.handleSubmit();
          }}
          className={classes.buttonGreen}
          variant="contained"
        >
          Salvar
        </Button>
      </Buttons>
    </Container>
  );
};

export default TimeLine;
