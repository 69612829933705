import React, { useEffect, useRef, useState } from 'react';
import ReactFlow, { Background, PanOnScrollMode } from 'react-flow-renderer';
import CustomNodeDirection from './CustomNodeDirection';

interface IProps {
  minWidth: number;
  minHeight: number;
  reference: any;
  titleText?: string;
  elements: any;
  changePositionOfOriginalData?: (node: any) => void;
  setRfInstance?: any;
  backgroundPattern?: string;
}

const FlowChartView: React.FC = ({
  minWidth,
  minHeight,
  reference,
  titleText,
  elements,
  changePositionOfOriginalData = () => {},
  setRfInstance = () => {},
  backgroundPattern,
}: IProps) => {
  const nodeTypes = {
    custom: CustomNodeDirection,
  };

  const [valueToUpdate, setValueToUpdate] = useState(null);
  const [tick, setTick] = useState(500);

  function reset() {
    setTick(500);
  }

  useEffect(() => {
    let id = setTimeout(() => {
      if (tick <= 0) return;
      setTick(tick - 100);
    }, 100);
    return () => clearInterval(id);
  }, [tick]);

  useEffect(() => {
    if (tick <= 0 && valueToUpdate) {
      changePositionOfOriginalData(valueToUpdate);
    }
  }, [tick]);

  return (
    <div id="#view" className="viewContainer">
      <div style={{ width: 300, minWidth, height: 400, minHeight, background: '#ffff' }} ref={reference}>
        <h1 style={{ textAlign: 'center', wordBreak: 'break-all' }} className="flowchart-title">
          {titleText}
        </h1>
        <ReactFlow
          elements={elements}
          nodesConnectable={false}
          onNodeDrag={(event, node) => {
            setValueToUpdate(node);
            reset();
          }}
          onNodeMouseMove={(event, node) => {
            if (node.position.x != Math.trunc(node.position.x / 10) * 10) {
              node.position.x = Math.trunc(node.position.x / 10) * 10;
            }
            if (node.position.y != Math.trunc(node.position.y / 10) * 10) {
              node.position.y = Math.trunc(node.position.y / 10) * 10;
            }
          }}
          nodeTypes={nodeTypes}
          onLoad={setRfInstance}
          zoomOnScroll={false}
          selectNodesOnDrag={false}
          panOnScroll={true}
          //panOnScrollSpeed={false}
          panOnScrollMode={PanOnScrollMode.Free}
          zoomOnPinch={false}
          zoomOnDoubleClick={false}
          paneMoveable={false}
          defaultZoom={1}
          snapGrid={[10, 10]}
          snapToGrid={false}
          nodesDraggable={true}
          fitView
        >
          <Background id="1" gap={10} color="#f1f1f1" variant={backgroundPattern} />
          <Background id="2" gap={100} offset={1} color="#ccc" variant={backgroundPattern} />
        </ReactFlow>
      </div>
    </div>
  );
};

export default FlowChartView;
