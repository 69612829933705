import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { localizationOptions } from '../../../helper/tableHelper';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import EditIcon from '../../../assets/edit-icon.svg';
import { ControlPoint } from '@material-ui/icons';
import { Container, useStyles } from './styles';
import { useAlert } from '../../../contexts/alert';
import { editDependentUserById, getDependentUsers } from '../../../services/dependentUser';
import { filterRequest } from '../../../interfaces/requests';
import { useAuth } from '../../../contexts/auth';

import columns from './columns';

const UsuarioDependente: React.FC = () => {
  const { user } = useAuth();
  const { showAlertError } = useAlert();
  const classes = useStyles();
  const history = useHistory();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (user?.tokenData?.OwnerUserType === 'Dependente') {
      setDisable(true);
    }
  }, []);

  const ownerUserId = user?.tokenData?.ClientId;

  return (
    <Container>
      <MaterialTable
        title="Adicionar usuários"
        columns={columns}
        actions={[
          {
            icon: () => <img src={EditIcon} />,
            tooltip: 'Editar',
            position: 'row',
            onClick: (event, rowData) => history.push(`/edicao-usuario-dependente/${rowData?.user?.id}`),
          },
          {
            icon: () => (
              <Button className={classes.button} variant="contained" color="primary" disabled={disable}>
                <ControlPoint className={classes.svg} /> Novo
              </Button>
            ),
            tooltip: 'Adicionar',
            onClick: (event, rowData) => (disable ? '' : history.push(`/cadastro-usuario-dependente`)),
            position: 'toolbar',
          },
        ]}
        localization={localizationOptions}
        data={({ page, pageSize, search, filters }) => {
          return new Promise((resolve, reject) => {
            let filter: Array<filterRequest> = [];
            if (filters[0]?.column) {
              filter = filters?.map((f) => {
                return {
                  field: f?.column?.field,
                  value: f.value,
                };
              });
            }
            getDependentUsers(ownerUserId, page, pageSize, search, filter)
              .then((result: any) => {
                if (result) {
                  resolve({
                    data: result.data.value[0].dependentUsers,
                    page: page,
                    totalCount: result.data['@odata.count'],
                  });
                } else {
                  reject({
                    data: [],
                    page: page,
                    totalCount: 0,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: page,
                  totalCount: 0,
                });
                if (err?.status === 401) {
                  showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
                } else {
                  showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os perfis');
                }
              });
          });
        }}
        options={{
          headerStyle: {
            padding: '20px',
          },
          rowStyle: {
            padding: 20,
          },
          filtering: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          sorting: false,
          debounceInterval: 700,
        }}
      />
    </Container>
  );
};

export default UsuarioDependente;
