import React, { useState } from 'react';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Tooltip } from '@material-ui/core';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import TextField from '../../../../components/EditTableTemplateTextField';
import { Container, InsertNewItem } from './styles';
import { DATETYPEINPUT } from '../../../../interfaces/timeline';
import { isValid } from 'date-fns';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

interface IProps {
  dateType: DATETYPEINPUT;
  dateInputValue: Date;
  titleInputValue: string;
  descriptionInputValue: string;
  deleteItem: () => void;
  changeColorItem: () => void;
  changeValueItem: (value: any, name: string) => void;
  addNewItem: () => void;
  focusId: string;
}

const ConfigurationCard: React.FC = ({
  dateInputValue,
  dateType,
  descriptionInputValue,
  titleInputValue,
  deleteItem,
  changeColorItem,
  changeValueItem,
  addNewItem,
  focusId,
}: IProps) => {
  const [errorDate, setErrorDate] = useState(false);

  return (
    <Container>
      <div>
        <Tooltip title="Alterar cor">
          <ColorLensOutlinedIcon style={{ cursor: 'pointer', color: '#3E4756' }} onClick={changeColorItem} />
        </Tooltip>
        <Tooltip title="Deletar item">
          <DeleteOutlineOutlinedIcon style={{ cursor: 'pointer' }} onClick={deleteItem} color="error" />
        </Tooltip>
      </div>
      <div>
        {dateType == DATETYPEINPUT.DATE ? (
          <>
            <DatePicker
              autoOk
              id={focusId}
              label="Data"
              value={dateInputValue == null ? null : new Date(dateInputValue)}
              placeholder="dd/mm/aaaa"
              onChange={(value) => {
                if (isValid(value)) {
                  changeValueItem(value, 'date');
                  setErrorDate(false);
                } else {
                  setErrorDate(true);
                }
              }}
              inputVariant="outlined"
              variant="inline"
              helperText={null}
              error={errorDate ? true : false}
              fullWidth
              size="small"
              format="dd/MM/yyyy"
            />
            {errorDate ? <label className="errorDate">Data Inválida</label> : ''}
          </>
        ) : dateType == DATETYPEINPUT.DATETIME ? (
          <>
            <DateTimePicker
              autoOk
              id="data1"
              label="Data/Hora"
              value={dateInputValue == null ? null : new Date(dateInputValue)}
              placeholder="dd/mm/aaaa hh:mm"
              onChange={(value) => {
                if (isValid(value)) {
                  changeValueItem(value, 'date');
                  setErrorDate(false);
                } else {
                  setErrorDate(true);
                }
              }}
              inputVariant="outlined"
              variant="inline"
              fullWidth
              size="small"
              helperText={null}
              error={errorDate ? true : false}
              format="dd/MM/yyyy HH:mm"
            />
            {errorDate ? <label className="errorDate">Data Inválida</label> : ''}
          </>
        ) : dateType == DATETYPEINPUT.MONTHYEAR ? (
          <>
            <DatePicker
              autoOk
              id="data1"
              label="Mes/Ano"
              value={dateInputValue == null ? null : new Date(dateInputValue)}
              onChange={(value) => {
                if (isValid(value)) {
                  changeValueItem(value, 'date');
                  setErrorDate(false);
                } else {
                  setErrorDate(true);
                }
              }}
              inputVariant="outlined"
              variant="inline"
              fullWidth
              size="small"
              format="MM/yyyy"
              helperText={null}
              error={errorDate ? true : false}
              views={['year', 'month']}
            />
            {errorDate ? <label className="errorDate">Data Inválida</label> : ''}
          </>
        ) : (
          <>
            <DatePicker
              autoOk
              id="data1"
              label="Ano"
              value={dateInputValue == null ? null : new Date(dateInputValue)}
              onChange={(value) => {
                if (isValid(value)) {
                  changeValueItem(value, 'date');
                  setErrorDate(false);
                } else {
                  setErrorDate(true);
                }
              }}
              inputVariant="outlined"
              variant="inline"
              fullWidth
              helperText={null}
              error={errorDate ? true : false}
              size="small"
              views={['year']}
            />
            {errorDate ? <label className="errorDate">Data Inválida</label> : ''}
          </>
        )}
        <TextField
          defaultValue={titleInputValue}
          onChange={(value) => changeValueItem(value, 'title')}
          maxLength={50}
          label="Título (opcional)"
          speechVisible={true}
        />
        <TextField
          multiline={true}
          defaultValue={descriptionInputValue}
          onChange={(value) => changeValueItem(value, 'description')}
          speechVisible={true}
          label="Descrição"
          maxLength={150}
        />
        <InsertNewItem onClick={addNewItem}>
          <Tooltip title="Inserir novo item após">
            <span>
              <ControlPointIcon fontSize="small" style={{ color: '#3FCED2' }} /> Inserir
            </span>
          </Tooltip>
        </InsertNewItem>
      </div>
    </Container>
  );
};

export default ConfigurationCard;
