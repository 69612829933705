import api from './api';
export const asksForPasswordRecovery = async (email) => {
  try {
    return await api.put('token', {
      Email: email,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const recoverPassword = async (email, password, token) => {
  try {
    return await api.put(
      'User/RecoverPassword',
      {
        email,
        password,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export const SignIn = (values) => {
  try {
    return api.post('token', {
      Email: values.email,
      Password: values.password,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changePassword = async (values) => {
  try {
    return await api.put('user/changepassword', values);
  } catch (error) {
    return Promise.reject(error);
  }
};
