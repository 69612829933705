import styled from 'styled-components';

export const Content = styled.div`
  h2 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
`;
