import React, { useEffect, useState } from 'react';
import BasicData from './BasicData';
import UserAccepted from './UserAccepted';
import { Container, Content } from './styles';
import Logo from '../../../assets/Logo-large.svg';
import { useAlert } from '../../../contexts/alert';
import { IExternalUser } from '../../../interfaces/externalUser';
import { createOwnerUser } from '../../../services/externalUsers';
import { useLoading } from '../../../contexts/loading';
import { getPlans } from '../../../services/plans';

const CadastroUsuarioExterno: React.FC = () => {
  const [step, setStep] = useState(0);
  const { showAlertError } = useAlert();
  const [user, setUser] = useState<IExternalUser | null>(null);
  const [plan, setPlan] = useState();
  const { showLoading } = useLoading();

  useEffect(() => {
    getPlans().then((response) => {
      const freePlan = response.data.value.find((value) => {
        return value.name?.toUpperCase().includes('GRAT');
      });
      setPlan(freePlan);
    });
  }, []);

  useEffect(() => {
    setUser(null);
  }, []);

  useEffect(() => {
    if (user != null) {
      saveExternalUser();
    }
  }, [user]);

  const saveExternalUser = () => {
    showLoading(true);
    createOwnerUser(user)
      .then((response) => {
        showLoading(false);
        setStep(step + 1);
      })
      .catch((error) => {
        showLoading(false);
        showAlertError(error?.data ? error?.data[0]?.ErrorMessage : 'Ocorreu um erro ao tentar cadastrar no plano');
      });
  };

  return (
    <Container>
      <Content elevation={5}>
        <img src={Logo} className="logo" />
        {step === 0 ? (
          <BasicData key={0} user={user} setUser={setUser} />
        ) : (
          <UserAccepted trialDays={plan?.trialDays} />
        )}
      </Content>
    </Container>
  );
};

export default CadastroUsuarioExterno;
