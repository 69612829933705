import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 20px;

  .timeline-input {
    height: 80px;
    width: 100%;
    .timeline-title {
      width: 400px;
    }
  }
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    margin: 0;
    padding: 20px;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
      color: #3e4756;
    }
  }

  svg {
    color: #1976d2;
    margin-left: 20px;
  }

  > div + div {
    overflow-x: auto;
  }
  table {
    max-width: 100%;
    border-collapse: collapse;
  }
`;

export const CardView = styled.div`
  width: 368px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 500px;
`;
