import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const Container = styled(Paper)`
  padding: 20px;
  width: 100%;
  max-width: 800px;

  .MuiTextField-root {
    height: auto;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  span {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #1976d2;
    font-size: 24px;
    color: #3fced2;
    font-family: 'Mosk Semi Bold';

    .color-text {
      font-size: 24px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  .inputs {
    display: grid;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
    p {
      color: red;
      cursor: pointer;
    }

    .forgot {
      color: #3fced2;
      cursor: pointer;
    }
    .name {
      grid-area: name;
    }
    .date {
      grid-area: date;
    }
    .email {
      grid-area: email;
    }
    .phone {
      grid-area: phone;
    }
    .oab {
      grid-area: oab;
    }
    .seccional {
      grid-area: seccional;
    }
    .occupation {
      grid-area: occupation;
    }
    .company {
      grid-area: company;
    }

    grid-template-columns: repeat(2, 1fr);

    grid-template-areas:
      'name date'
      'email phone'
      'oab seccional'
      'occupation company';
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  /* padding-top: 45px; */
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const ColorsTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  padding-top: 20px;
  color: #3e4756;
`;

export const ColorsContent = styled.div`
  display: flex;

  div {
    border: 1px solid #3e4756;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    margin: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
