import React from 'react';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ChangeIcon from '../../../../assets/changeDiagram-icon.svg';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { ButtonNewCell, Container, TableContent } from './styles';

interface IColor {
  name: string;
  value?: string;
}

interface IModalDelete {
  attributeType: string;
  index?: number;
}

enum POSITION {
  HORIZONTAL = 1,
  VERTICAL = 2,
}

interface IProps {
  formik: any;
  columnsBorderStyle: React.CSSProperties;
  data: Array<ITableCell> | [];
  setChooseDiagram: (value: boolean) => void;
  setControlModalColor: (obj: IColor) => void;
  changeItemValue: (index: number, value: number) => void;
  changeItemLabel: (index: number, value: string) => void;
  setControlDeleteModal: (obj: IModalDelete) => void;
  addNewItem: (index: number) => void;
  changeTableTitle: (event: React.SyntheticEvent) => void;
  position: POSITION;
  setIsSaved: (value: boolean) => void;
  diagramSelect: string;
}

interface ITableCell {
  value: number;
  label: string;
  color?: string;
}

const ConfigurationBarDiagram = ({
  formik,
  columnsBorderStyle,
  data,
  setControlModalColor,
  changeItemValue,
  changeItemLabel,
  setControlDeleteModal,
  addNewItem,
  setChooseDiagram,
  changeTableTitle,
  setIsSaved,
  position,
  diagramSelect,
}: IProps): React.ReactElement => {
  return (
    <Container>
      <div className="informative-title">
        <div style={{ display: 'flex' }}>
          <p>Informe os dados do modelo</p>
          <Tooltip title="Alterar cor de todos os elementos">
            <ColorLensOutlinedIcon style={{ fill: '#3E4756' }} onClick={() => setControlModalColor({ name: `all` })} />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonNewCell type="button" onClick={() => setChooseDiagram(true)}>
            <img src={ChangeIcon} style={{ color: '#3FCED2' }} className="editIcon" />
            Trocar gráfico
          </ButtonNewCell>
        </div>
      </div>
      <div className="table-title">
        <TextField
          type="text"
          name="title"
          size="normal"
          label="Título do gráfico (opcional)"
          value={formik.values.title}
          variant="outlined"
          onChange={(event) => changeTableTitle(event.target.value)}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          fullWidth
        />
      </div>
      <TableContent>
        <table className="table-configuration">
          <thead>
            <tr>
              <td style={{ background: '#D4D5D7', ...columnsBorderStyle }} />
              {position === POSITION.HORIZONTAL ? (
                <>
                  <td className="table-head" style={{ ...columnsBorderStyle }}>
                    <div>
                      <span>Linha Vertical</span>{' '}
                    </div>
                  </td>
                  <td className="table-head" style={{ ...columnsBorderStyle }}>
                    <div>
                      <span>Linha Horizontal</span>{' '}
                    </div>
                  </td>
                </>
              ) : (
                <>
                  <td className="table-head" style={{ ...columnsBorderStyle }}>
                    <div>
                      <span>Linha Horizontal</span>{' '}
                    </div>
                  </td>
                  <td className="table-head" style={{ ...columnsBorderStyle }}>
                    <div>
                      <span>Linha Vertical</span>{' '}
                    </div>
                  </td>
                </>
              )}
            </tr>
            <tr style={{ ...columnsBorderStyle }}>
              <th style={{ minWidth: 30, background: '#f5f5f6', ...columnsBorderStyle }} />

              {position === POSITION.HORIZONTAL ? (
                <>
                  <th style={{ minWidth: 200, ...columnsBorderStyle }}>
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      label="Descrição da linha vertical"
                      value={formik.values.verticalDescription}
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={(event) => {
                        setIsSaved(false);
                        formik.setFieldValue('verticalDescription', event.target.value);
                      }}
                      error={formik.touched.verticalDescription && Boolean(formik.errors.verticalDescription)}
                      helperText={formik.touched.verticalDescription && formik.errors.verticalDescription}
                    />
                  </th>
                  <th style={{ minWidth: 200, ...columnsBorderStyle }}>
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      label="Descrição da linha horizontal"
                      value={formik.values.horizontalDescription}
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={(event) => {
                        setIsSaved(false);
                        formik.setFieldValue('horizontalDescription', event.target.value);
                      }}
                      error={formik.touched.horizontalDescription && Boolean(formik.errors.horizontalDescription)}
                      helperText={formik.touched.horizontalDescription && formik.errors.horizontalDescription}
                    />
                  </th>
                </>
              ) : (
                <>
                  <th style={{ minWidth: 200, ...columnsBorderStyle }}>
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      label="Descrição da linha horizontal"
                      value={formik.values.horizontalDescription}
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={(event) => {
                        setIsSaved(false);
                        formik.setFieldValue('horizontalDescription', event.target.value);
                      }}
                      error={formik.touched.horizontalDescription && Boolean(formik.errors.horizontalDescription)}
                      helperText={formik.touched.horizontalDescription && formik.errors.horizontalDescription}
                    />
                  </th>
                  <th style={{ minWidth: 200, ...columnsBorderStyle }}>
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      label="Descrição da linha vertical"
                      value={formik.values.verticalDescription}
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={(event) => {
                        setIsSaved(false);
                        formik.setFieldValue('verticalDescription', event.target.value);
                      }}
                      error={formik.touched.verticalDescription && Boolean(formik.errors.verticalDescription)}
                      helperText={formik.touched.verticalDescription && formik.errors.verticalDescription}
                    />
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody style={{ ...columnsBorderStyle }}>
            {data.map((item, index) => (
              <tr key={index} style={{ ...columnsBorderStyle }}>
                <td style={{ minWidth: 30, ...columnsBorderStyle }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {diagramSelect !== 'line' && (
                      <Tooltip title="Alterar cor">
                        <ColorLensOutlinedIcon
                          style={{ color: '#3E4756' }}
                          onClick={() => setControlModalColor({ name: index, value: item.color })}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Deletar linha">
                      <DeleteOutlineOutlinedIcon
                        onClick={() => setControlDeleteModal({ attributeType: 'linha', index: index })}
                        color="error"
                      />
                    </Tooltip>
                  </div>
                </td>

                <td key={index} style={{ ...columnsBorderStyle }}>
                  <TextField
                    id={`itemLinha${index + 1}`}
                    label={`Item da linha ${position === POSITION.HORIZONTAL ? 'vertical' : 'horizontal'}`}
                    size="small"
                    value={item.label}
                    fullWidth
                    inputProps={{
                      maxLength: 25,
                    }}
                    onChange={(event) => changeItemLabel(index, event.target.value)}
                    type="text"
                  />

                  <Tooltip title="Inserir novo item abaixo">
                    <ButtonNewCell onClick={() => addNewItem(index)} type="button">
                      <ControlPointIcon fontSize="small" />
                      Novo Item
                    </ButtonNewCell>
                  </Tooltip>
                </td>
                <td key={index} style={{ ...columnsBorderStyle }}>
                  <TextField
                    id={`item${index + 1}`}
                    type="number"
                    inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
                    size="small"
                    defaultValue={0}
                    value={item.value}
                    label={`Item da linha ${position === POSITION.HORIZONTAL ? 'horizontal' : 'vertical'}`}
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                      let value = event.target.value;
                      if (value) changeItemValue(index, value);
                    }}
                  />
                  <div style={{ height: 20 }} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContent>
    </Container>
  );
};

export default ConfigurationBarDiagram;
