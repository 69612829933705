import React, { RefObject, useEffect, useState } from 'react';
import { CardView, Container } from '../../styles';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  convertHTMLTimeLineHasNoBreakPageToPDF,
  convertHTMLToImage,
  copyImage,
} from '../../../../../../../helper/convertHTML';
import { Tooltip } from '@mui/material';
import { DATETYPEINPUT } from '../../../../../../../interfaces/timeline';
import { format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';
import ModalOptions from '../../../../../../../components/ModalOptions';
import { updateDownloadTimeLine } from '../../../../../../../services/models';

interface IItem {
  date: Date | null;
  title: string;
  description: string;
  color?: string;
}

interface IProps {
  id: string;
  title: string;
  data: Array<IItem>;
  dateType: DATETYPEINPUT;
  refView: RefObject<HTMLDivElement>;
  fileName: string;
  isSaved: boolean;
  colWidth: number;
}

const HasNoBreakPage: React.FC = ({ id, title, data, dateType, refView, fileName, isSaved, colWidth }: IProps) => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);

  const downloadImage = () => {
    convertHTMLToImage(refView.current, fileName);
    updateCountDownloadTimeLine(id);
  };

  const downloadPDF = () => {
    convertHTMLTimeLineHasNoBreakPageToPDF(refView.current, fileName);
    updateCountDownloadTimeLine(id);
  };

  const copy = () => {
    copyImage(refView.current, fileName);
    setOpenToolTipCopyImage(true);
    updateCountDownloadTimeLine(id);
  };

  const updateCountDownloadTimeLine = (modelId: string) => {
    updateDownloadTimeLine(modelId).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="info-title">
        <p>Visualização do modelo</p>
        <div>
          <Tooltip title="Download imagem">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer', color: '#3E4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <ContentCopyOutlinedIcon
                style={{ cursor: 'pointer', color: '#3E4756' }}
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
              />
            </Tooltip>
          </Tooltip>
          <Tooltip title="Download PDF">
            <LocalPrintshopOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
            />
          </Tooltip>
        </div>
      </div>
      <div className="view-table">
        <div id="#view" ref={refView} style={{ width: 'max-content', background: '#fff' }}>
          <p style={{ padding: '20px 0px', fontSize: 24, margin: 0, height: 70, wordBreak: 'break-all' }}>{title}</p>
          <table style={{ borderCollapse: 'collapse' }}>
            {data.map((column, columnIndex) => (
              <>
                <tr key={columnIndex}>
                  {dateType === DATETYPEINPUT.DATETIME ? (
                    <p
                      style={{
                        backgroundColor: column.color,
                        padding: '10px 20px',
                        height: 40,
                        margin: 0,
                        borderRadius: '12px',
                      }}
                    >
                      {column.date ? format(new Date(column.date), 'dd/MM/yyy HH:mm') : ''}
                    </p>
                  ) : dateType === DATETYPEINPUT.DATE ? (
                    <p
                      style={{
                        backgroundColor: column.color,
                        padding: '10px 20px',
                        height: 40,
                        margin: 0,
                        width: colWidth,
                        borderRadius: '12px',
                      }}
                    >
                      {column.date ? format(new Date(column.date), 'dd/MM/yyy') : ''}
                    </p>
                  ) : dateType === DATETYPEINPUT.MONTHYEAR ? (
                    <p
                      style={{
                        backgroundColor: column.color,
                        padding: '10px 20px',
                        height: 40,
                        margin: 0,
                        width: colWidth,
                        borderRadius: '12px',
                      }}
                    >
                      {column.date
                        ? format(new Date(column.date), 'MM/yyyy', {
                            locale: ptBR,
                          })
                        : ''}
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: column.color,
                        padding: '10px 20px',
                        height: 40,
                        margin: 0,
                        width: colWidth,
                        borderRadius: '12px',
                      }}
                    >
                      {column.date ? new Date(column.date).getFullYear() : ' '}
                    </p>
                  )}
                </tr>
                <tr key={columnIndex}>
                  <CardView>
                    <p style={{ fontWeight: 'bold', wordBreak: 'break-all', width: colWidth }}>{column.title}</p>
                    <p id={`view${columnIndex}`} style={{ color: '#0b0b0c', wordBreak: 'break-all', width: colWidth }}>
                      {column.description}
                    </p>
                  </CardView>
                </tr>
              </>
            ))}
          </table>
        </div>
      </div>
    </Container>
  );
};

export default HasNoBreakPage;
