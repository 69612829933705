import MyData from '../pages/MyData';
import ModalChangePassword from '../components/ModalChangePassword';
import ExpiratePlan from '../pages/ExpiratePlan';
import CanceledPlan from '../pages/CanceledPlan';
import Login from '../pages/Login';

export const expiratePlanRoutes = [
  {
    name: 'Plano Expirado',
    url: '/plano-expirado',
    component: ExpiratePlan,
  },
  {
    name: 'Assinatura Cancelada',
    url: '/assinatura-cancelada',
    component: CanceledPlan,
  },
  {
    name: 'Meus Dados',
    url: '/meus-dados',
    component: MyData,
  },
  {
    name: 'alterar Senha',
    url: '/alterar-senha',
    component: ModalChangePassword,
  },

  {
    name: 'Modelos',
    url: '/tabela-comparativa',
    component: Login,
  },
  {
    name: 'Usuário Dependente',
    url: '/usuarios-dependentes',
    component: Login,
  },
  {
    name: 'Edicao Usuário Dependente',
    url: '/edicao-usuario-dependente',
    component: Login,
  },
  {
    name: 'Criar Usuário Dependente',
    url: '/cadastro-usuario-dependente',
    component: Login,
  },
  {
    name: 'Editar Usuário Dependente',
    url: '/edicao-usuario-Dependente/:id',
    component: Login,
  },
  {
    name: 'Linha do tempo',
    url: '/linha-do-tempo',
    component: Login,
  },
  {
    name: 'QRCode',
    url: '/qrcode',
    component: Login,
  },
  {
    name: 'Diagram',
    url: '/grafico',
    component: Login,
  },
  {
    name: 'Modelos',
    url: '/fluxograma',
    component: Login,
  },
  {
    name: 'Novo projeto',
    url: '/novo-modelo',
    component: Login,
  },
  {
    name: 'Meus projetos',
    url: '/meus-modelos',
    component: Login,
  },
  {
    name: 'Usuarios Internos',
    url: '/usuarios-internos',
    component: Login,
    props: {},
  },
  {
    name: 'Cadastro Perfil de Acesso',
    url: '/cadastro-perfil-acesso',
    component: Login,
  },
  {
    name: 'Edição Perfil de Acesso',
    url: '/edicao-perfil-acesso/:id',
    component: Login,
  },
  {
    name: 'Cadastro Usuário Interno',
    url: '/cadastro-usuario-interno',
    component: Login,
  },
  {
    name: 'Editar Usuário Interno',
    url: '/edicao-usuario-interno/:id',
    component: Login,
  },
  {
    name: 'Cadastrar Plano',
    url: '/planos/cadastro-plano',
    component: Login,
  },
  {
    name: 'Editar Plano',
    url: '/planos/edicao-plano/:id',
    component: Login,
  },
  {
    name: 'Plano',
    url: '/planos',
    component: Login,
  },
  {
    name: 'Usuários Externos',
    url: '/usuarios-externos',
    component: Login,
  },
  {
    name: 'Editar Usuário Externo',
    url: '/edicao-usuario-externo/:id',
    component: Login,
  },
  {
    name: 'Home Usuário Interno',
    url: '/dashboards',
    component: Login,
  },
  {
    name: 'Minha Conta',
    url: '/minha-conta',
    component: Login,
  },
];
