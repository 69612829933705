import React from 'react';
import { GreenText, RedText } from './styles';
import { IAccessProfiles, IInternalUsers } from '../../../interfaces/internalUserAndAccessProfile';
import ActiveSelect from '../../../components/SelectTrueOrFalseComponentTable';
export const usersColumns = [
  { title: 'Nome', field: 'name' },
  { title: 'Email', field: 'email' },
  { title: 'Perfil de acesso', field: 'role.name' },
  {
    title: 'Situação',
    render: ({ active }: IInternalUsers | IAccessProfiles): React.ReactElement | string =>
      active ? <GreenText>Ativo</GreenText> : <RedText>Inativo</RedText>,
    field: 'active',
    customFilterAndSearch: (term, rowData) => {
      if (term === '1') {
        return rowData.active === true;
      } else if (term === '0') {
        return rowData.active === false;
      } else {
        return true;
      }
    },
    filterComponent: (props: any): React.ReactElement => {
      return <ActiveSelect {...props} />;
    },
  },
];

export const profilesColumns = [
  { title: 'Nome', field: 'name' },
  {
    title: 'Situação',
    render: ({ active }: IInternalUsers | IAccessProfiles): React.ReactElement | string =>
      active ? <GreenText>Ativo</GreenText> : <RedText>Inativo</RedText>,
    field: 'active',
    customFilterAndSearch: (term, rowData) => {
      if (term === '1') {
        return rowData.active === true;
      } else if (term === '0') {
        return rowData.active === false;
      } else {
        return true;
      }
    },
    filterComponent: (props: any): React.ReactElement => {
      return <ActiveSelect {...props} />;
    },
  },
];
