import React from 'react';

import { Container, useStyles } from './styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const CanceledPlan: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useAuth();
  const buttonVisible = user?.marketPlaceId == 2 ? false : true;

  const handleChangeRoute = () => {
    history.push('/meus-dados');
  };

  return (
    <Container>
      <div>
        <h1>Assinatura Cancelada</h1>
        <p>Sua assinatura foi cancelada, para ter acesso às funcionalidades será necessário reativar sua assinatura.</p>
        <p style={{ visibility: buttonVisible ? 'hidden' : 'visible' }}>Verifique junto à Hotmart.</p>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleChangeRoute}
          style={{ visibility: buttonVisible ? 'visible' : 'hidden' }}
        >
          Reativar assinatura
        </Button>
      </div>
    </Container>
  );
};

export default CanceledPlan;
