import { Paper, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const TableContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ContainerCheckbox = styled.div`
  display: grid;
  padding-top: 20px;
  padding-bottom: 0px;

  a {
    text-decoration: none;
    font-weight: bold;
    color: #3fced2;
  }

  button {
    text-decoration: none;
  }

  label span {
    padding-left: 0;
  }
`;

export const TextAccount = styled.p`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 30px;

  a {
    text-decoration: none;
    font-weight: bold;
    color: #3fced2;
    margin-left: 5px;
  }
`;

export const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    '@media (max-width: 600px)': {
      marginRight: 0,
    },
  },
  btn: {
    height: '4rem',
    marginTop: theme.spacing(3),
    backgroundColor: '#3FCED2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
  a: {
    textDecoration: 'none',
    color: '#fff',
  },
}));

export const ColorsTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #3e4756;
  /* padding-top: 20px; */
`;

export const ColorsContent = styled.div`
  display: flex;

  div {
    border: 1px solid #3e4756;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    margin: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
  }
`;
