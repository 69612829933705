import React from 'react';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Content } from '../styles';
import voltar from '../../../assets/back-icon.svg';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/Logo-large.svg';

const validationSchema = Yup.object({
  email: Yup.string().email('E-mail inválido').required('Obrigatório'),
});

interface IProps {
  email?: string;
  sendEmail: (email: string) => void;
}

const PasswordRecovery = ({ email, sendEmail }: IProps): React.ReactElement => {
  const history = useHistory();

  function handleClick() {
    history.push('/login');
  }

  const formik = useFormik({
    initialValues: {
      email: email || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendEmail(values.email);
    },
  });
  return (
    <Content>
      <div className="go-back">
        <Button onClick={handleClick}>
          <img src={voltar} />
          Voltar
        </Button>
      </div>
      <div className="email">
        <img src={Logo} className="logo" />
        <h2>Recuperar senha</h2>
        <p>Digite seu endereço de e-mail. Você receberá um link por e-mail para criar uma nova senha.</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Button className="btnRecuperar" variant="contained" fullWidth type="submit">
            Recuperar
          </Button>
        </form>
      </div>
    </Content>
  );
};

export default PasswordRecovery;
