/* eslint-disable @typescript-eslint/no-loop-func */
import React, { RefObject, useEffect, useState } from 'react';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  convertHTMLTimeLineHasBreakPageToPDF,
  convertHTMLToImage,
  copyImage,
} from '../../../../../../../helper/convertHTML';
import ModalOptions from '../../../../../../../components/ModalOptions';
import { Tooltip } from '@mui/material';
import { CardView, Container } from '../../styles';
import { DATETYPEINPUT } from '../../../../../../../interfaces/timeline';
import { format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';
import { updateDownloadTimeLine } from '../../../../../../../services/models';
import { Block } from '@mui/icons-material';

interface IItem {
  date: Date | null;
  title: string;
  description: string;
}

interface IProps {
  id: string;
  title: string;
  data: Array<IItem>;
  dateType: DATETYPEINPUT;
  refView: RefObject<HTMLTableElement>;
  fileName: string;
  isSaved: string;
  colWidth: number;
}

const HasBreakPage = ({ id, title, data, dateType, refView, fileName, isSaved, colWidth }: IProps) => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);

  const splitToChunks = (arr, size: number) => {
    const chunkSize = size;
    return arr
      .map((e, i) => {
        return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
  };

  const newData = splitToChunks(splitToChunks(data, 5), 3);

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  const downloadImage = () => {
    convertHTMLToImage(refView.current, fileName);
    updateCountDownloadTimeLine(id);
  };

  const downloadPDF = () => {
    convertHTMLTimeLineHasBreakPageToPDF(refView.current, fileName, 'l');
    updateCountDownloadTimeLine(id);
  };

  const copy = () => {
    copyImage(refView.current, fileName);
    setOpenToolTipCopyImage(true);
    updateCountDownloadTimeLine(id);
  };

  const updateCountDownloadTimeLine = (modelId: string) => {
    updateDownloadTimeLine(modelId).catch((err) => {
      console.log(err);
    });
  };

  return (
    <Container>
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <div className="info-title">
        <p>Visualização do modelo</p>
        <div>
          <Tooltip title="Download imagem">
            <span
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
              }
              style={{ cursor: 'pointer', color: '#3E4756' }}
              className="material-icons-outlined"
            >
              file_download
            </span>
          </Tooltip>

          <Tooltip
            title="Imagem copiada"
            open={openToolTipCopyImage}
            onClose={() => setOpenToolTipCopyImage(false)}
            leaveDelay={1500}
          >
            <Tooltip title="Copiar Imagem">
              <ContentCopyOutlinedIcon
                style={{ cursor: 'pointer', color: '#3E4756' }}
                onClick={() =>
                  setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                }
              />
            </Tooltip>
          </Tooltip>
          <Tooltip title="Download PDF">
            <LocalPrintshopOutlinedIcon
              style={{ cursor: 'pointer', color: '#3E4756' }}
              onClick={() =>
                setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
              }
            />
          </Tooltip>
        </div>
      </div>
      <div>
        <table id="#view" ref={refView} style={{ borderCollapse: 'collapse' }}>
          {newData.map((page, index) => (
            <tbody key={index} className="page">
              {index === 0 ? (
                <tr className="title" style={{ fontSize: 24 }}>
                  <td style={{ textAlign: 'start', wordBreak: 'break-all' }}>{title}</td>
                </tr>
              ) : (
                ''
              )}
              {page.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr style={{ display: 'flex', marginTop: 20 }}>
                    {row.map((column, columnIndex, arr) => (
                      <td
                        key={columnIndex}
                        style={{
                          padding: 0,
                          height: 40,
                          minWidth: 350,
                        }}
                      >
                        {dateType === DATETYPEINPUT.DATETIME ? (
                          <p
                            style={{
                              margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                              padding: '10px 20px',
                              minHeight: '100%',
                              minWidth: 350,
                              backgroundColor: column.color,
                              width: colWidth,
                              borderRadius: '12px',
                            }}
                          >
                            {column.date ? format(new Date(column.date), 'dd/MM/yyy HH:mm') : ''}
                          </p>
                        ) : dateType === DATETYPEINPUT.DATE ? (
                          <p
                            style={{
                              backgroundColor: column.color,
                              margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                              padding: '10px 20px',
                              minHeight: '100%',
                              minWidth: 350,
                              width: colWidth,
                              borderRadius: '12px',
                            }}
                          >
                            {column.date ? format(new Date(column.date), 'dd/MM/yyy') : ''}
                          </p>
                        ) : dateType === DATETYPEINPUT.MONTHYEAR ? (
                          <p
                            style={{
                              backgroundColor: column.color,
                              margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                              padding: '10px 20px',
                              minHeight: '100%',
                              minWidth: 350,
                              width: colWidth,
                              borderRadius: '12px',
                            }}
                          >
                            {column.date
                              ? format(new Date(column.date), 'MM/yyyy', {
                                  locale: ptBR,
                                })
                              : ''}
                          </p>
                        ) : (
                          <p
                            style={{
                              backgroundColor: column.color,
                              margin: columnIndex !== arr.length - 1 ? '0px 20px 0px 0px' : '0px',
                              padding: '10px 20px',
                              minHeight: '100%',
                              minWidth: 350,
                              width: colWidth,
                              borderRadius: '12px',
                            }}
                          >
                            {column.date ? new Date(column.date).getFullYear() : ' '}
                          </p>
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr key={rowIndex} style={{ display: 'flex' }}>
                    {row.map((column, columnIndex) => (
                      <td key={columnIndex}>
                        <p style={{ width: colWidth, marginLeft: 8, minWidth: 350, padding: '10px 20px' }}>
                          <CardView>
                            <p
                              style={{
                                fontWeight: 'bold',
                                marginTop: 4,
                                wordBreak: 'break-all',
                              }}
                            >
                              {column.title}
                            </p>
                            <p
                              style={{
                                color: '#3E4756',
                                marginTop: 4,
                                wordBreak: 'break-all',
                              }}
                            >
                              {column.description}
                            </p>
                          </CardView>
                        </p>
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          ))}
        </table>
      </div>
    </Container>
  );
};

export default HasBreakPage;
