import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from './styles';

interface IProps {
  trialDays: string;
}

const UserAccepted = ({ trialDays }: IProps): React.ReactElement => {
  return (
    <Content>
      <h2>Cadastro efetuado com sucesso!</h2>
      <p className="message">
        Em breve você receberá um e-mail para a criação da sua senha. <br />
        Caso não tenha recebido o e-mail, verifique sua caixa de SPAM. <br />
        Acesse o Iris pelo navegador do seu computador para ter a melhor experiência de uso.
      </p>
    </Content>
  );
};
export default UserAccepted;
