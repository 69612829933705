import React from 'react';
import { Button, TextField } from '@material-ui/core';
import Logo from '../../../assets/Logo-large.svg';
import { Link } from 'react-router-dom';
import { Content } from '../styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';

interface IProps {
  nextStep: () => void;
  email?: string;
  changeEmail: (email: string) => void;
}

const validationSchema = object({
  email: string().email('Email inválido').required('E-mail é obrigatório'),
});

const AcesseSuaConta = ({ nextStep, email, changeEmail }: IProps): React.ReactElement => {
  const formik = useFormik({
    initialValues: {
      email: email || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changeEmail(values.email);
      nextStep();
    },
  });

  return (
    <Content>
      <img src={Logo} />
      <p className="title"> Acesse sua conta </p>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        <TextField
          fullWidth
          id="email"
          name="email"
          label="E-mail"
          type="email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik?.errors?.email}
        />

        <Button className="btnLogin" variant="contained" fullWidth onClick={() => formik.handleSubmit()}>
          continuar
        </Button>

        {/* <div className="divRegistro">
          <span>
            Não possui conta? <Link to="/cadastro-usuario-externo">Cadastre-se</Link>
          </span>
        </div> */}
      </form>
    </Content>
  );
};

export default AcesseSuaConta;
