import * as Yup from 'yup';

export const userInitialValues = {
  name: '',
  email: '',
  roleId: '',
};

export const userValidationSchema = Yup.object({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string().required('E-mail  é obrigatório'),
});
