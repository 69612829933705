import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  minheight: 1200px;
  maxheight: 100%;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid #e6e6e6;

  td,
  tr {
    border: 1px solid #e6e6e6;
  }

  .table-title {
    padding: 20px;
    height: 80px;
    font-size: 24px;
    font-weight: 400;
  }

  tr {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  td {
    border-left: none;
    border-top: none;
    border-bottom: none;
  }
  table {
    border: 1px solid #cccccc;
    border-top: none;
    border-collapse: collapse;
    padding: 20px;
    width: 100%;
    minheight: 1200px;
    maxheight: 100%;
    border-left: none;
    border-right: none;
    tr {
      padding: 20px;
    }
    .timeline-title {
      max-width: 390px;
      padding: 20px;
    }
  }
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    margin: 0;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }

    > div {
      padding: 20px;
      width: 100%;
    }

    div:first-child {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  > div + div {
    overflow-x: auto;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  minheight: 1200px;
  maxheight: 100%;
  max-width: 100%;
`;

export const Insert = styled.div`
  all: unset;
  color: #1976d2;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
