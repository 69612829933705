import styled from 'styled-components';

export const Container = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 5px;
  p {
    color: #394955;
  }

  table,
  tr,
  td,
  th,
  thead,
  tbody {
    border-collapse: collapse;
  }

  thead tr:first-child {
    height: 69px;
    font-weight: bold;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  tbody {
    td:first-child,
    th:first-child {
      background: #f5f5f6;
    }
  }

  tr {
    height: 69px;
  }

  .table-title {
    padding: 20px;
    height: 80px;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }
`;
export const TableContent = styled.div`
  height: calc(100vh - 300px);
  overflow: auto;
  /*max-width: 41vw;*/
  display: flex;
  flex-direction: column;
`;

export const ButtonNewCell = styled.button`
  all: unset;
  color: #3fced2;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    fill: #3fced2;
    margin-right: 2px;
  }
`;

export const CellIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  svg {
    fill: #3fced2;
    margin: 0 8px;
  }
`;
