import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { localizationOptions } from '../../../helper/tableHelper';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ControlPoint } from '@material-ui/icons';
import EditIcon from '../../../assets/edit-icon.svg';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { getAccessProfiles, getInternalUsers } from '../../../services/internalUsersAndProfileAccess';
import { Container, useStyles } from './styles';
import { TitleComponentSelector } from './TitleComponentSelector';
import { useAlert } from '../../../contexts/alert';
import { profilesColumns, usersColumns } from './columns';
import { History } from 'history';
import { filterRequest } from '../../../interfaces/requests';
import { useAuth } from '../../../contexts/auth';

const UsariosInternos: React.FC = () => {
  const classes = useStyles();
  const history: History = useHistory();
  const { showAlertError } = useAlert();
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const { permissions } = useAuth();

  const [selectedTitle, setSelectedTitle] = useState<string>(
    history.location.search.replace('?', '') || 'InternalUsers'
  );

  useEffect(() => {
    if (permissions?.includes('CanReadInternalUsers')) {
      setSelectedTitle('InternalUsers');
    } else if (permissions?.includes('CanReadRoles')) {
      setSelectedTitle('AccessProfile');
    }
  }, []);

  const changeToInternalUsers = () => {
    setSelectedTitle('InternalUsers');
  };

  const changeToAccessProfile = () => {
    setSelectedTitle('AccessProfile');
  };

  useEffect(() => {
    history.push('/usuarios-internos?' + selectedTitle);
    setMuiTableKey(muiTableKey + 1);
  }, [selectedTitle]);

  return (
    <Container>
      <MaterialTable
        key={muiTableKey} // used to rerender the component when selectedTitle changes
        title={
          <TitleComponentSelector
            selectedTitle={selectedTitle}
            changeToInternalUsers={changeToInternalUsers}
            changeToAccessProfile={changeToAccessProfile}
          />
        }
        columns={selectedTitle === 'InternalUsers' ? usersColumns : profilesColumns}
        actions={[
          {
            // icon: () => <img src={EditIcon} />,
            icon: () =>
              permissions?.includes('CanUpdateInternalUsers') ? <img src={EditIcon} /> : <VisibilityOutlined />,
            tooltip: permissions?.includes('CanUpdateInternalUsers') ? 'Editar' : 'Vizualizar',
            position: 'row',
            // disabled: !permissions?.includes('CanUpdateInternalUsers') ? true : false,
            onClick: (event, rowData: any) =>
              selectedTitle === 'InternalUsers'
                ? history.push(`/edicao-usuario-interno/${rowData?.id}`)
                : history.push(`/edicao-perfil-acesso/${rowData?.id}`),
          },
          {
            icon: () => (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={!permissions?.includes('CanCreateInternalUsers') ? true : false}
              >
                <ControlPoint className={classes.svg} /> Novo
              </Button>
            ),
            tooltip: 'Adicionar',
            disabled: !permissions?.includes('CanCreateInternalUsers') ? true : false,
            onClick: () =>
              selectedTitle === 'InternalUsers'
                ? history.push(`/cadastro-usuario-interno`)
                : history.push(`/cadastro-perfil-acesso`),
            position: 'toolbar',
          },
        ]}
        onPageChange={() => {}}
        localization={localizationOptions}
        data={({ page, pageSize, search, filters }) => {
          return new Promise((resolve, reject) => {
            let filter: Array<filterRequest> = [];
            if (filters[0]?.column) {
              filter = filters?.map((f) => {
                return {
                  field: f?.column?.field,
                  value: f.value,
                };
              });
            }
            if (selectedTitle === 'AccessProfile') {
              getAccessProfiles(page, pageSize, search, filter)
                .then((result: any) => {
                  if (result) {
                    resolve({
                      data: result.data.value,
                      page: page,
                      totalCount: result.data['@odata.count'],
                    });
                  } else {
                    reject({
                      data: [],
                      page: page,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: page,
                    totalCount: 0,
                  });
                  if (err.status === 401) {
                    showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
                  } else {
                    showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os perfis');
                  }
                });
            } else {
              getInternalUsers(page, pageSize, search, filter)
                .then((result: any) => {
                  if (result)
                    resolve({
                      data: result.data.value,
                      page: page,
                      totalCount: result.data['@odata.count'],
                    });
                  else {
                    resolve({
                      data: [],
                      page: page,
                      totalCount: result.data['@odata.count'],
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: page,
                    totalCount: 0,
                  });
                  if (err.status === 401) {
                    showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
                  } else {
                    showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os usuários');
                  }
                });
            }
          });
        }}
        options={{
          headerStyle: {
            padding: '20px',
          },
          sorting: false,
          rowStyle: {
            padding: 20,
          },
          filtering: true,
          actionsColumnIndex: -1,
          pageSize: 5,
          debounceInterval: 700,
        }}
      />
    </Container>
  );
};

export default UsariosInternos;
