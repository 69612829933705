import React, { useEffect, useState } from 'react';
import { Container, Row } from './styles';
import { DIAGRAMTYPE } from '../../interfaces/diagram';
import {
  Chart as ChartJS,
  // eslint-disable-next-line sort-imports
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

interface IChartData {
  labels: Array<string>;
  data: Array<number>;
  backgroundColor: Array<string>;
}
export default function DiagramChart({ horizontalText, verticalText, diagramData, diagramType, position }: any) {
  const [formattedData, setFormattedData] = useState<IChartData>({
    labels: [],
    data: [],
    backgroundColor: [],
  });

  useEffect(() => {
    formatDiagramData();
  }, [diagramData]);

  const formatDiagramData = () => {
    let labels = [];
    let data = [];
    let backgroundColor = [];
    diagramData.map((item) => {
      labels.push(item.label);
      data.push(item.value);
      backgroundColor.push(item.color);
    });
    setFormattedData({ labels, data, backgroundColor });
  };

  const state = {
    labels: formattedData.labels,
    datasets: [
      {
        backgroundColor: formattedData.backgroundColor,
        borderColor: formattedData.backgroundColor,
        borderWidth: 2,
        data: formattedData.data,
      },
    ],
  };

  const options = {
    indexAxis: position == 2 ? 'x' : 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,

    plugins: {
      legend: {
        position: 'none',
      },
    },

    scales: {
      x: { ticks: { color: 'black', font: { size: 16, style: 'normal', weight: 'bold' } } },
      myScale: {
        position: 'left',
        reverse: position == 2 ? false : true,
        ticks: { color: 'black', font: { size: 16, style: 'normal', weight: 'bold' } },
      },
    },
  };

  return (
    <Container>
      <Row>
        <label className="verticalDescription">{verticalText}</label>
        <div className="chart">
          {diagramType === DIAGRAMTYPE.LINE ? (
            <Line data={state} options={options} height={160} />
          ) : (
            <Bar data={state} options={options} height={160} />
          )}
        </div>
      </Row>
      <label className="horizontalDescription">{horizontalText}</label>
    </Container>
  );
}
