import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  > div {
    padding: 10px;
    > div {
      margin-bottom: 10px !important;
    }
  }
  > div:first-child {
    display: flex;
    padding-top: 20px;
  }

  textarea {
    max-height: 100px;
  }

  .errorDate {
    color: red;
    font-size: 12px;
    display: block;
    margin-block-start: -8px;
    margin-block-end: 5px;
    margin-inline-start: 12px;
    margin-inline-end: 0px;
  }
`;

export const InsertNewItem = styled.div`
  all: unset;
  color: #1976d2;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: end;
  span {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #3fced2;
  }
`;
