export function includeMaskTel(valor) {
  return valor
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/^(\d{2})(\d)/g, '($1) $2') // coloca parênteses em volta dos dois primeiros dígitos
    .replace(/(\d)(\d{4})$/, '$1-$2'); // coloca hífen entre o quarto e o quinto dígitos
}

export const removeMaskCel = (valor) => {
  return valor.replace(/\D/g, '');
};
