import React from 'react';
import ActiveSelect from '../../../components/SelectTrueOrFalseComponentTable';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import NumberFormat from 'react-number-format';
import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { GreenText, RedText } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { isValid } from 'date-fns';

const columns = [
  { title: 'Nome', field: 'name' },
  {
    title: 'Data de início',
    field: 'initialDate',
    render: (rowData) => rowData.initialDate && new Date(rowData.initialDate).toLocaleDateString('pt-BR'),
    filterComponent: (props) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DatePicker
          placeholder="dd/mm/aaaa"
          autoOk
          onChange={(date) => {
            if (isValid(date)) {
              props.onFilterChanged(
                props?.columnDef?.tableData?.id,
                new Date(new Date(date).setHours(0, 0, 0)).toISOString()
              );
            }
          }}
          defaultValue={new Date().setFullYear(new Date().getFullYear() - 1) || null}
          variant="inline"
          format="dd/MM/yyyy"
        />
        <Tooltip title="Limpar">
          <IconButton>
            <CloseIcon
              fontSize="small"
              onClick={() => {
                props.onFilterChanged(props?.columnDef?.tableData?.id, null);
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Data de fim',
    field: 'endDate',
    render: (rowData) => rowData.endDate && new Date(rowData.endDate).toLocaleDateString('pt-BR'),
    filterComponent: (props) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DatePicker
          placeholder="dd/mm/aaaa"
          autoOk
          onChange={(date) => {
            if (isValid(date)) {
              props.onFilterChanged(
                props?.columnDef?.tableData?.id,
                new Date(new Date(date).setHours(0, 0, 0)).toISOString()
              );
            }
          }}
          defaultValue={new Date() || null}
          variant="inline"
          format="dd/MM/yyyy"
        />
        <Tooltip title="Limpar">
          <IconButton>
            <CloseIcon
              fontSize="small"
              onClick={() => {
                props.onFilterChanged(props?.columnDef?.tableData?.id, null);
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Valor mensal',
    field: 'monthlyPrice',
    render: (rowData) =>
      rowData.recurrencePlans[0]?.price
        ? Number(rowData.recurrencePlans[0]?.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        : 'R$ 00,00',

    filterComponent: (props) => (
      <NumberFormat
        value={props?.columnDef?.tableData?.filterValue}
        customInput={TextField}
        fixedDecimalScale={true}
        thousandSeparator="."
        decimalSeparator=","
        name="monthlyPrice"
        decimalScale={2}
        fullWidth
        onValueChange={({ floatValue }) => props.onFilterChanged(props?.columnDef?.tableData?.id, floatValue)}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
      />
    ),
  },
  {
    title: 'Situação',
    render: ({ status }) => (status ? <GreenText>Ativo</GreenText> : <RedText>Inativo</RedText>),
    field: 'status',
    customFilterAndSearch: (term, rowData) => {
      if (term === '1') {
        return rowData.active === true;
      } else if (term === '0') {
        return rowData.active === false;
      } else {
        return true;
      }
    },
    filterComponent: (props: any): React.ReactElement => {
      return <ActiveSelect {...props} />;
    },
  },
];
export default columns;
