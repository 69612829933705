import React from 'react';
import { IValue } from '../../services/interfaceType/IModels';
import { Box, Card, CardActions, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { GridContente } from './styles';
import ImagemTipoArquivo, { convertNomeDescricao } from './ImagemTipoArquivo';

const CARD_PROPERTY = {
  height: '95%',
  borderRadius: 3,
  backgroundColor: '#e9e9e970',
  boxShadow: 1,
  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
  },
};

interface IProps {
  item: IValue;
  imprimirHandleClick: () => void;
  downloadHandleClick: () => void;
  copiarImagemHandleClick: () => void;
  editarImagemHandleClick: () => void;
  excluirImagemHandleClick: () => void;
}

const ItemCardMeusModelos = ({
  item,
  imprimirHandleClick,
  downloadHandleClick,
  copiarImagemHandleClick,
  editarImagemHandleClick,
  excluirImagemHandleClick,
}: IProps): React.ReactElement => {
  const { descricao, id, modelo, dataCriacao } = item;

  return (
    <GridContente
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      xl={2.4}
      sx={{ width: '100%', height: '430px', marginBottom: '-20px' }}
    >
      <Card sx={CARD_PROPERTY}>
        <Box onClick={editarImagemHandleClick} sx={{ cursor: 'pointer' }}>
          <ImagemTipoArquivo tipoModelo={modelo} urlImagem={item.imageUrl} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '12px',
            }}
          >
            <InsertInvitationOutlinedIcon fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)', marginRight: '2px' }} />
            <Typography variant="body2" color="text.secondary">
              {dataCriacao && new Date(dataCriacao).toLocaleDateString('pt-BR')}
            </Typography>
          </Box>
        </Box>
        <CardContent style={{ backgroundColor: '#fff' }}>
          <Box onClick={editarImagemHandleClick} sx={{ cursor: 'pointer' }}>
            <Typography className="title" gutterBottom variant="h6" component="div" style={{ marginTop: '-10px' }}>
              {descricao}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{ marginTop: '-10px' }}>
              {convertNomeDescricao(modelo)}
            </Typography>
          </Box>
          <CardActions sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '5px' }}>
            <Tooltip title="Imprimir">
              <IconButton onClick={() => imprimirHandleClick()}>
                <PrintOutlinedIcon style={{ color: '#3E4756' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Imagem">
              <IconButton onClick={() => downloadHandleClick()}>
                <SaveAltOutlinedIcon style={{ color: '#3E4756' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copiar Imagem">
              <IconButton onClick={() => copiarImagemHandleClick()}>
                <ContentCopyOutlinedIcon style={{ color: '#3E4756' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir">
              <IconButton onClick={() => excluirImagemHandleClick()}>
                <DeleteOutlinedIcon style={{ color: '#F1504D' }} />
              </IconButton>
            </Tooltip>
          </CardActions>
        </CardContent>
      </Card>
    </GridContente>
  );
};

export default ItemCardMeusModelos;
