import React from 'react';
import { Content } from './styles';

const Terms = () => {
  return (
    <Content>
      <h2>Olá! Seja bem-vindo ao Sistema Íris!</h2>
      <p>
        Somos um site criado para auxiliar você, profissional do ramo jurídico, na elaboração de documentos mais
        didáticos, amigáveis e de fácil compreensão. Nosso objetivo é reunir funcionalidades que permitam a criação de
        elementos visuais de forma simples e intuitiva. Lembramos que a permanência e o uso do software implicam
        automaticamente leitura e aceitação tácita dos termos de uso a seguir. Neste momento inicial, selecionamos 5
        modelos: Linha do tempo, fluxograma, gráficos de barra e de linha, tabela e QR Code. Os modelos poderão ser
        criados por você de forma personalizada, conforme seu caso concreto. Primeiro você deve efetuar o cadastro e
        então terá acesso a 30 dias gratuitos. Após esse período, você deverá adquirir uma licença de uso do sistema e
        selecionar o plano de seu interesse. Os modelos criados ficarão salvos dentro do sistema e você poderá
        acessá-los clicando em “Meus projetos”.
      </p>
      <h2>1. DOS DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL</h2>
      <p>
        O software é de propriedade intelectual de Ianna Menezes Cabanelas, brasileira, advogada, OAB/MG nº 200.873, o
        qual possui os direitos sobre as derivações dos direitos de programa de computador, conforme definido em lei. O
        USUÁRIO poderá acessar todo o conteúdo do software, não significando nenhum tipo de cessão/transferência de
        direito. Todos os direitos autorais são preservados, conforme a legislação brasileira, em especial a lei nº
        10.406/2002 – Código Civil, bem como a Lei nº 9.609/1998, que regulamenta a proteção da propriedade intelectual
        de programa de computador e a Lei nº 9.610/2018 – Lei de Direitos Autorais e demais normas e legislações
        aplicáveis à matéria. Todo o conteúdo do software é protegido por direitos autorais e sua utilização,
        reprodução, transmissão, alienação, cessão ou revenda, deve seguir a o que preceitua a lei brasileira, tendo o
        SISTEMA ÍRIS todos os seus direitos reservados e não permitindo qualquer ação, de nenhuma forma e meio, sem
        autorização expressa e por escrito do mesmo. O SISTEMA ÍRIS poderá, em casos concretos, permitir pontualmente
        exceções revogáveis e limitadas a este direito, que serão expressamente destacados, com a forma e permissão de
        uso do conteúdo.
      </p>
      <h2>2. ACEITE DOS TERMOS</h2>
      <p>
        Este termo especifica e exige que todo USUÁRIO ao acessar o software leia e compreenda todas as cláusulas do
        mesmo, visto que ele estabelece entre o SISTEMA ÍRIS e o USUÁRIO direitos e obrigações entre ambas as partes,
        aceitos expressamente pelo USUÁRIO a permanecer em uso do referido software. Para a validade do aceite aos
        termos de uso, fica ciente o USUÁRIO que também deverá aceitar os termos de privacidade e consentimento de uso
        de dados. Ao continuar acessando, o USUÁRIO expressa que aceita e entende todas as cláusulas, assim como
        concorda integralmente com cada uma delas, sendo este aceite imprescindível para permanência de seu uso. Caso o
        USUÁRIO discorde de alguma cláusula ou condição deste Termo, o mesmo deve imediatamente interromper o seu uso.
      </p>
      <h2>3. GLOSSÁRIO</h2>
      <p>
        Os seguintes termos técnicos poderão surgir durante a leitura dos presentes termos, bem como durante o uso do
        software que podem não ser de conhecimento geral, razão pela qual seguem abaixo definidas:
        <br />
        • USUÁRIO: todo e qualquer pessoa previamente cadastrada no sistema que terá acesso predefinido ao aplicativo.
        <br />
        • SOFTWARE: programa de computador que contém rotinas ou conjunto de instruções que executam ações com
        resultados planejados.
        <br />
        • BROWSER: é um software que possibilita a navegação em sites e aplicativos na internet (web).
        <br />
        • COOKIES: pequeno arquivo de computador ou pacote de dados enviados por um site de Internet para o navegador do
        usuário (browser), quando este visita o site, desde que autorizado.
        <br />
        • LOGIN: identificador de usuário solicitado para acessar um sistema informático restrito. São credenciais
        previamente cadastradas no sistema por esse utilizador.
        <br />
        • SENHA: conjunto de caracteres alfabéticos e/ou numéricos que asseguram o acesso ao titular de uma conta
        (Login) a um sistema informático.
        <br />
        • LINKS: palavra, texto ou imagem que quando é clicada pelo usuário, o encaminha para outra página na internet,
        que pode conter outros textos ou imagens.
        <br />
        • ON-LINE: termo utilizado para descrever a condição de estar conectado a uma rede de computadores ou outros
        dispositivos em tempo real.
        <br />
        • OFF-LINE: termo utilizado para designar que um determinado usuário da internet ou de uma outra rede de
        computadores não está conectado à rede, ou seja, está acessando dados locais de uma máquina.
        <br />
        • SPAM: são conteúdos enviados pelo remetente sem o consentimento do destinatário. Em caso de dúvidas sobre
        qualquer palavra utilizada neste termo, o USUÁRIO poderá entrar em contato através dos canais de comunicação
        para os devidos esclarecimentos.
        <br />
      </p>
      <h2>4. ACESSO AO SOFTWARE</h2>
      <p>
        O software funciona 24 (vinte e quatro) horas por dia, porém podem ocorrer pequenas interrupções de forma
        temporária para ajustes, manutenção, mudança de servidores, falhas técnicas ou por ordem de força maior, que
        podem deixar o site indisponível por tempo limitado. Os proprietários intelectuais do SISTEMA ÍRIS não se
        responsabilizam por nenhuma perda de oportunidade ou prejuízos que esta indisponibilidade temporária possa gerar
        ao USUÁRIO. Em caso de manutenção que exija um tempo maior, os responsáveis técnicos pelo SISTEMA ÍRIS irão
        informar previamente aos USUÁRIOS sobre a necessidade e o tempo previsto em que eventualmente fique off-line.
        Para o acesso ao software será necessário realizar um cadastro, aceitar as condições contratuais propostas de
        utilização do SISTEMA ÍRIS, respectivos termos de política de privacidade e uso de dados, preencher formulário
        com dados pessoais e optar pelo plano de pagamento. Todos os dados estão protegidos conforme a LGPD - Lei Geral
        de Proteção de Dados, e ao realizar o cadastro no site, o USUÁRIO concorda integralmente com a coleta de dados
        conforme a Lei e a Política de Privacidade do SISTEMA ÍRIS.
      </p>
      <h2>5. OBRIGAÇÕES</h2>
      <p>
        • O USUÁRIO não poderá, de nenhuma forma ou meio, realizar qualquer tipo de ação que tente invadir, hackear,
        destruir ou prejudicar a estrutura do software, plataforma ou de seus parceiros comerciais. Incluindo, mas não
        se limitando, o envio de vírus de computador, ataques de dados, acesso indevido por falha de proteção ou
        quaisquer outras forma e meio.
        <br />
        • O USUÁRIO não poderá realizar divulgação indevida nas ferramentas do software, como conteúdo de SPAM, dados de
        empresas concorrentes, vírus, conteúdo que não possua direitos autorais ou quaisquer outros que não sejam
        pertinentes à finalidade a que se destina.
        <br />
        • O USUÁRIO não poderá reproduzir qualquer recurso do software sem autorização expressa, podendo responder civil
        e criminalmente pelo mesmo.
        <br />
        • O USUÁRIO deverá acessar, ler e dar ciência e consentimento à Política de Privacidade e uso de dados do
        SISTEMA ÍRIS, caso tenha interesse em sua utilização, podendo a qualquer momento e forma, requerer a exclusão
        dos dados.
        <br />
        • Fica totalmente vedado ao USUÁRIO negociar, alugar, emprestar ou ceder o SISTEMA ÍRIS para terceiros, sob
        qualquer forma, total ou parcialmente, mesmo sendo para empresas do mesmo grupo do USUÁRIO. O não cumprimento do
        disposto nesta cláusula implicará nas penalidades por violação aos direitos de propriedade prevista na lei de
        Direitos Autorais n.º 9.609 e 9.610 de 19/02/1998.
        <br />
        • O SISTEMA ÍRIS está sendo licenciado para uso no ambiente Cloud (nuvem).
        <br />
        • O USUÁRIO declara que conhece e aceita todas as funcionalidades básicas padrão dos programas que compõem o
        SISTEMA ÍRIS e que concorda em utilizá-lo da forma que ele foi projetado e desenvolvido.
        <br />
        • O USUÁRIO fica plenamente ciente de que, o direito de uso do SISTEMA ÍRIS é não-exclusivo, limitado,
        intransferível e restrito ao prazo de vigência e fins do presente termo, sendo certo que a sua utilização para
        quaisquer outros fins constituirá falta gravíssima e será motivo de imediato cancelamento da licença contratada.
        <br />
        • O USUÁRIO não poderá, em hipótese alguma, ceder, transferir, transmitir ou por qualquer outra forma alienar,
        no todo ou em parte, a titularidade do direito de uso do SISTEMA ÍRIS, a nenhuma outra pessoa, seja ela física
        ou jurídica.
        <br />
        • O USUÁRIO se responsabilizará pela utilização adequada e correta do SISTEMA ÍRIS, sendo responsável pelo uso
        indevido de sua parte por pessoa não autorizada ou mesmo incapacitada, a que tenha dado causa, por ação ou
        omissão, culpa ou dolo, sendo os proprietários do sistema isentos de qualquer tipo de responsabilidade a que não
        deram causa.
        <br />
        • A responsabilidade dos proprietários intelectuais do sistema IRIS restringir-se-á ao software, não respondendo
        por problemas relacionados a comunicação de dados, redes de computadores, sistemas operacionais, hardware ou
        outros.
        <br />
        • Os proprietários do SISTEMA ÍRIS não se responsabilizarão por danos decorrentes do mau uso do sistema,
        alimentação errônea e/ou falta de conferência de dados gerados.
        <br />
      </p>
      <h2>6. REMUNERAÇÃO</h2>
      <p>
        Ao contratar um dos planos oferecidos pelo SISTEMA ÍRIS, o usuário estará ciente do valor cobrado e do tempo em
        que o sistema ficará disponível para uso, os quais estarão especificados dentro da plataforma. Os Planos de
        Pagamento serão reajustados a cada 12 meses com base no IGP-M (Índice Geral de Preços do Mercado) da FGV.
      </p>
      <h2>7. TERMOS GERAIS</h2>
      <p>
        Em caso de existência de eventuais conflitos judiciais entre o USUÁRIO e o SISTEMA ÍRIS, o foro eleito para a
        devida ação será o da comarca de Belo Horizonte, mesmo que haja outro mais privilegiado. Estes Termos de Uso são
        válidos a partir de 7 de fevereiro de 2022.
      </p>
    </Content>
  );
};

export default React.memo(Terms);
