import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import FileViewer from 'react-file-viewer';
import clouduploadicon from '../../../../../assets/clouduploadicon.svg';
import Tooltip from '@mui/material/Tooltip';

const onError = (e) => {
  console.log(e, 'error in file-viewer');
};

interface IProps {
  fileType: string;
  filePath: string;
  uploadFunction: () => void;
}

function IAConfiguration({ fileType, filePath, uploadFunction }: IProps): React.ReactElement {
  if (filePath == '') {
    return (
      <Container>
        <div
          style={{
            border: '1px solid #cccccc',
            height: '100%',
            borderRadius: '5px',
          }}
        >
          <Tooltip title="Clique aqui para carregar seu arquivo MS-Word ou Adobe Acrobat (até 6.000 palavras)...">
            <figure>
              <img
                src={clouduploadicon}
                height={'20%'}
                width={'20%'}
                style={{ position: 'relative', marginTop: '10%', left: '40%', opacity: '30%' }}
                onClick={uploadFunction}
              />
              <figcaption
                style={{ marginLeft: '42%', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '24px', opacity: '30%' }}
              >
                DOCX, PDF
              </figcaption>
            </figure>
          </Tooltip>
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <div
          style={{
            border: '1px solid #cccccc',
            height: '100%',
            borderRadius: '5px',
          }}
        >
          <div
            style={{
              paddingTop: '5px',
              paddingBottom: '5px',
              height: '400px',
            }}
          >
            <FileViewer fileType={fileType} filePath={filePath} onError={onError} />
          </div>
        </div>
      </Container>
    );
  }
}

export default IAConfiguration;
