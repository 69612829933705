import React, { useEffect, useState } from 'react';
import { Avatar, ListItemIcon, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from '../../contexts/auth';
import { IMenu } from '../MenuLateral';

interface IProps {
  menu: IMenu[];
}

const AvatarUser = ({ menu }: IProps): React.ReactElement => {
  const { user } = useAuth();

  const [avatarEl, setAvatarEl] = React.useState<HTMLButtonElement | null>(null);

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const open = Boolean(avatarEl);
  const id = open ? 'simpe-popover' : undefined;
  const titleTooltip = `${user?.username}, clique para abrir mais opções`;

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <Button aria-describedby={id} onClick={handleAvatarClick}>
          <Tooltip title={titleTooltip} placement="left-start">
            <Avatar alt={user?.username} src="/static/images/avatar/1.jpg" sx={{ width: 45, height: 45 }} />
          </Tooltip>
          <KeyboardArrowDownIcon />
        </Button>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List disablePadding>
          {menu.map((item) => (
            <>
              <ListItem key={item.name} disablePadding>
                <ListItemButton onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            </>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default AvatarUser;
