import React from 'react';
import IA from '../../../assets/IA.svg';
import EmentaImg from '../../../assets/Ementa.svg';
import IconesImg from '../../../assets/ConjuntoIcones.png';
import ComparativeTableImg from '../../../assets/comparative-table.svg';
import TimeLineImg from '../../../assets/timeline.svg';
import DiagramImg from '../../../assets/diagram.svg';
import QrCodeImg from '../../../assets/qr-code.svg';
import FlowChartImg from '../../../assets/FlowChart.svg';
import { CardMedia } from '@mui/material';
import { ImageCardContainer } from './styles';

export const convertNomeDescricao = (tipoModelo: string) => {
  switch (tipoModelo) {
    case 'ModeloQRCode':
      return 'Modelo QR Code';
    case 'ModeloLinhaDoTempo':
      return 'Modelo Linha do Tempo';
    case 'TabelaComparativa':
      return 'Modelo Tabela';
    case 'ModeloFluxograma':
      return 'Modelo Fluxograma';
    case 'ModeloGrafico':
      return 'Modelo Gráfico';
    case 'ModeloLinguagemSimples':
      return 'Texto Aprimorado';
    case 'ModeloEmenta':
      return 'Resumo Jurídico';
    case 'ModeloBibliotecaIcones':
      return 'Banco de Ícones';
    default:
      return tipoModelo;
  }
};

const cardStyle = {
  media: {
    marginTop: '25px',
    marginLeft: '12px',
    width: '385px',
    height: '185px',
  },
  borderRadius: '5px',
  position: 'center',
};

interface IProps {
  tipoModelo: string;
  urlImagem: string;
}

const ImagemTipoArquivo = ({ tipoModelo, urlImagem }: IProps): React.ReactElement => {
  switch (tipoModelo) {
    case 'ModeloQRCode':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? QrCodeImg : urlImagem} alt="QR Code" />
        </ImageCardContainer>
      );
    case 'ModeloLinhaDoTempo':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? TimeLineImg : urlImagem} alt="Fluxograma" />
        </ImageCardContainer>
      );
    case 'TabelaComparativa':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? ComparativeTableImg : urlImagem} alt="Tabela" />
        </ImageCardContainer>
      );
    case 'ModeloFluxograma':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? FlowChartImg : urlImagem} alt="Fluxograma" />
        </ImageCardContainer>
      );
    case 'ModeloGrafico':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? DiagramImg : urlImagem} alt="Gráfico" />
        </ImageCardContainer>
      );
    case 'ModeloLinguagemSimples':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? IA : urlImagem} alt="Texto Aprimorado" />
        </ImageCardContainer>
      );
    case 'ModeloEmenta':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? EmentaImg : urlImagem} alt="Resumo Jurídico" />
        </ImageCardContainer>
      );
    case 'ModeloBibliotecaIcones':
      return (
        <ImageCardContainer>
          <img src={urlImagem == null ? IconesImg : urlImagem} alt="Banco de Ícones" />
        </ImageCardContainer>
      );
    default:
      return <CardMedia component="img" height="185" image={IA} alt="Linguagem Simples" />;
  }
};

export default ImagemTipoArquivo;
