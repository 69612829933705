import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

interface InitContextProps {
  loading: boolean;
  showLoading: Dispatch<SetStateAction<boolean>>;
}

const LoadingContext = createContext<InitContextProps>({
  loading: false,
  showLoading: () => {},
});

export const LoadingProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        showLoading: setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  const context = useContext(LoadingContext);

  return {
    loading: context.loading,
    showLoading: context.showLoading,
  };
}
