import React from 'react';
// eslint-disable-next-line sort-imports
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function SoldChart({ labels, values }: any) {
  const state = {
    labels: labels,
    datasets: [
      {
        // label: 'Rainfall',
        backgroundColor: ['#D1E5F3'],
        borderColor: ['#B7DDF8'],
        borderWidth: 2,
        data: values,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'none',
      },
    },
  };

  return <Bar data={state} options={options} height={100} />;
}
