import { Paper, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1130px;
  padding: 20px;

  form {
    width: 100%;
  }
`;

export const GreenText = styled.span`
  color: #00c2a7;
`;

export const RedText = styled.span`
  color: #f1504d;
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    color: #3e4756;
  }
  p {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #9ba3a9;
    font-size: 24px;
    font-family: 'Mosk Semi Bold';

    .color-text {
      font-size: 24px;
      font-family: 'Mosk Semi Bold';
      color: #3fced2;
    }
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 45px;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles((theme) => ({
  switch_base: {
    color: '#FF5F5D',
    '&.Mui-disabled': {
      color: '#FF5F5D',
    },
    '&.Mui-checked': {
      color: '#0ABF8F',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0ABF8F',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#0ABF8F',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0ABF8F',
    },
  },
  switch_track: {
    backgroundColor: '#FF5F5D',
  },
  disabled: {
    color: '#9BA3A9',
    '&.Mui-disabled': {
      color: '#9BA3A9',
    },
    '&.Mui-checked': {
      color: '#9BA3A9',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#9BA3A9',
    },
  },
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
  field: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}));
