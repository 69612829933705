import React, { useEffect, useRef, useState } from 'react';
import { Buttons, Container, Content, TableContainer, Tables, TopContent, useStyles } from './styles';
import { ArrowBackIos } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Button, TextField as Input } from '@material-ui/core';
import ModalColors from '../../../components/ModalChooseColor';
import { onlyNumbers } from '../../../helper/numbers';
import ModalOptions from '../../../components/ModalOptions';
import ConfigurationTable from './ConfigurationTable';
import ViewTable from './ViewTable';
import { createComparativeTable, getModelById, updateModel } from '../../../services/models';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getClientColors } from '../../../services/externalUsers';
import { useAuth } from '../../../contexts/auth';
import { isEmpty } from 'lodash';
import Split from 'react-split';
import { createImageFile } from '../../../helper/convertHTML';

const transparencyColor = 'CC';
const widthColumn = 300;
const heightRow = 50;
const columnsBorderStyle: React.CSSProperties = { border: '1px solid #e6e6e6', borderCollapse: 'collapse' };

interface IModalColor {
  name: string;
  value?: string;
}

interface IModalDelete {
  attributeType: string;
  index?: number;
}

interface ITableCell {
  description: string;
  color?: string;
  width: number;
  height: number;
}
interface IComparativeTable {
  columns: Array<ITableCell> | [];
  rows: Array<Array<ITableCell>> | [];
  title: string;
  titleColor?: string;
}

interface IFormik {
  id: string;
  description: string;
  title: string;
  html: string;
  json: string;
  clienteTitularId: string;
  htmlImage: string;
}

const validationSchema = Yup.object({
  description: Yup.string().required('Descrição é obrigatória.').max(500, 'Precisa ter no máximo 500 letras'),
  title: Yup.string().max(500, 'Precisa ter no máximo 500 letras'),
  html: Yup.string(),
  json: Yup.string(),
});

const ComparativeTable: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { user } = useAuth();
  const [firstColor, setFirstColor] = useState('');
  const [lastTitleColor, setLastTitleColor] = useState('');
  const [lastRowColor, setLastRowColor] = useState('');
  const [controlCancelModal, setControlCancelModal] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [idModel, setIdModel] = useState(history?.location?.state?.id);
  const [temporaryPathName, setTemporaryPathName] = useState('');
  const [state, setState] = useState<IComparativeTable>({ columns: [], rows: [], title: '' });
  const clientTitularId: string = user?.tokenData?.ClientId;
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const [splitSizes, setSplitSizes] = useState([50, 50]);

  const handleOpenCancelModal = () => history.push('/novo-modelo');

  const formik = useFormik({
    initialValues: {
      id: '',
      description: '',
      title: '',
      html: '',
      json: '',
      clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      htmlImage: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: IFormik) => {
      if (ref.current) {
        values.html = ref.current.outerHTML;
        values.htmlImage = await createImageFile(document.getElementById('table-model'));
      }
      values.json = JSON.stringify(state);
      showLoading(true);

      let body = {
        id: values.id,
        descricao: values.description,
        titulo: values.title,
        html: values.html,
        json: values.json,
        clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
        htmlImage: values.htmlImage,
      };
      if (!idModel) {
        values.id = uuidv4();
        createComparativeTable(values)
          .then(() => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Tabela criada com sucesso.');
            setIdModel(values.id);
          })
          .catch((error) => {
            showLoading(false);
            if (error.status === 401) {
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            } else if (error.status === 412) {
              showAlertError('Atingido o limite de modelos do plano.');
            } else {
              showAlertError('Ocorreu um erro ao enviar os dados da tabela.');
            }
          });
      } else {
        updateModel('ModeloTabelaComparativa', body)
          .then(() => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Tabela atualizada com sucesso.');
            setIdModel(idModel);
          })
          .catch((err: any) => {
            showLoading(false);
            if (err.status === 401) {
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            } else {
              showAlertError('Ocorreu um erro ao atualizar os dados da tabela.');
            }
          });
      }
    },
  });
  const [controlModalColor, setControlModalColor] = useState<IModalColor | null>(null);
  const [controlDeleteModal, setControlDeleteModal] = useState<IModalDelete | null>(null);

  useEffect(() => {
    showLoading(true);
    getClientColors(user?.tokenData?.UserId)
      .then((response: any) => {
        //setFirstColor(response?.data?.value[0]?.color1);
        //setLastTitleColor(response?.data?.value[0]?.color1);
        //setLastRowColor(response?.data?.value[0]?.color2);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  useEffect(() => {
    setState({
      columns: [{ description: '', color: firstColor + transparencyColor, width: widthColumn, height: heightRow }],
      rows: [],
      title: '',
    });
  }, [firstColor]);

  useEffect(() => {
    // handle refresh page
    const beforeUnloadCallback = (event) => {
      if (!isSaved) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    if (!isSaved) {
      history.block((prompt) => {
        setTemporaryPathName(prompt.pathname);
        setControlCancelModal(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    window.addEventListener(
      'pagehide',
      function (evt) {
        setControlCancelModal(true);
      },
      false
    );
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      history.block(() => {});
    };
  }, [history, isSaved]);

  const ref = useRef<HTMLTableElement>(null);
  const copyRef = useRef<HTMLTableElement>(null);

  const addNewColumn = (index: number) => {
    setIsSaved(false);
    let auxRows = [...state.rows];
    if (state.columns.length < 5) {
      let columnPosition = !isNaN(index) ? index + 1 : state.columns.length;
      auxRows.map((row) =>
        row.splice(columnPosition, 0, {
          description: '',
          color: lastRowColor + transparencyColor,
          width: widthColumn,
          height: heightRow,
        })
      );

      let auxColumns = [...state.columns];
      auxColumns.splice(columnPosition, 0, {
        description: '',
        color: lastTitleColor + transparencyColor,
        width: widthColumn,
        height: heightRow,
      });
      setState((prevState) => ({
        ...prevState,
        columns: auxColumns,
        rows: auxRows,
      }));
    }

    const timeOut = setTimeout(() => {
      document.getElementById(`item0${!isNaN(index) ? index + 1 : state.columns.length}`)?.focus();
      document.getElementById(`view0${!isNaN(index) ? index + 1 : state.columns.length}`)?.scrollIntoView();
      clearTimeout(timeOut);
    }, 5);
    return () => clearInterval(timeOut);
  };

  const addNewRow = (index?: number) => {
    setIsSaved(false);
    let auxRows = [...state.rows];
    if (state.rows.length < 50) {
      let newRow: Array<ITableCell> = [];
      let rowPosition = !isNaN(index) ? index + 1 : auxRows.length;
      state.columns.map(() =>
        newRow.push({
          description: '',
          color: lastRowColor + transparencyColor,
          width: widthColumn,
          height: heightRow,
        })
      );
      auxRows.splice(rowPosition, 0, newRow);
      setState((prevState) => ({ ...prevState, rows: auxRows }));
    }

    const timeOut = setTimeout(() => {
      document.getElementById(`item${!isNaN(index) ? index + 2 : auxRows.length}0`)?.focus();
      document.getElementById(`view${!isNaN(index) ? index + 2 : auxRows.length}0`)?.scrollIntoView();
      clearTimeout(timeOut);
    }, 5);
    return () => clearInterval(timeOut);
  };

  const deleteRow = (index: number) => {
    setIsSaved(false);
    let auxRows = [...state.rows];
    auxRows.splice(index, 1);
    setState((prevState) => ({ ...prevState, rows: auxRows }));
  };

  const deleteColumn = (index: number) => {
    setIsSaved(false);
    let auxRows = [...state.rows];
    auxRows.map((row) => row.splice(index, 1));
    let auxColumns = [...state.columns];
    auxColumns.splice(index, 1);
    setState((prevState) => ({ ...prevState, columns: auxColumns, rows: auxRows }));
  };

  const changeRowValue = (indexColumn: number, indexRow: number, value: string) => {
    setIsSaved(false);
    let rowsAux = [...state.rows];
    rowsAux[indexRow][indexColumn] = {
      ...rowsAux[indexRow][indexColumn],
      description: value,
    };
    setState((prevState) => ({ ...prevState, rows: rowsAux }));
  };

  const changeColumnValue = (index: number, value: string) => {
    setIsSaved(false);
    let columnsAux = [...state.columns];
    columnsAux[index] = {
      ...columnsAux[index],
      description: value,
    };
    setState((prevState) => ({ ...prevState, columns: columnsAux }));
  };

  const changeColumnWidth = (index: number, type: string) => {
    setIsSaved(false);
    let columnsAux = [...state.columns];

    if (type === 'increase') {
      columnsAux[index] = {
        ...columnsAux[index],
        width: columnsAux[index].width + 10,
      };
    } else if (type === 'decrease') {
      columnsAux[index] = {
        ...columnsAux[index],
        width: columnsAux[index].width - 10,
      };
    }
    setState((prevState) => ({ ...prevState, columns: columnsAux }));
  };

  const changeRowHeight = (index: number, type: string) => {
    const action = {
      increase: +10,
      decrease: -10,
    };

    setIsSaved(false);
    let rowsAux = [...state.rows];
    let columnsAux = [...state.columns];

    if (index == -1) {
      columnsAux.map((cell) => {
        if (cell.height + action[type] - heightRow >= 0) cell.height = cell.height + action[type];
      });
    } else {
      rowsAux[index].map((cell) => {
        if (cell.height + action[type] - heightRow >= 0) cell.height = cell.height + action[type];
      });
    }
    setState((prevState) => ({ ...prevState, rows: rowsAux }));
  };

  const changeColumnColor = (index: number, value: string) => {
    setIsSaved(false);
    let columnsAux = [...state.columns];
    columnsAux[index].color = value + transparencyColor;
    let rowsAux = [...state.rows];
    rowsAux?.map((r) => (r[index].color = value + transparencyColor));
    setState((prevState) => ({ ...prevState, columns: columnsAux, rows: rowsAux }));
    setLastRowColor(value);
    setLastTitleColor(value);
  };

  const changeRowColor = (index: number, value: string) => {
    setIsSaved(false);
    let rowsAux = [...state.rows];
    rowsAux[index]?.map((item) => (item.color = value + transparencyColor));
    setState((prevState) => ({ ...prevState, rows: rowsAux }));
    setLastRowColor(value);
  };

  const changeTitleColor = (value: string) => {
    setIsSaved(false);
    let columnsAux = [...state.columns];
    columnsAux.map((c) => (c.color = value + transparencyColor));
    setState((prevState) => ({ ...prevState, columns: columnsAux }));
    setLastTitleColor(value);
  };

  const changeSelectedColor = (attributeName: string, value: string) => {
    if (value) {
      if (attributeName?.includes('column')) {
        changeColumnColor(onlyNumbers(attributeName), value);
      } else if (attributeName?.includes('row')) {
        changeRowColor(onlyNumbers(attributeName), value);
      } else if (attributeName?.includes('titleColor')) {
        changeTitleColor(value);
      } else if (attributeName?.includes('all')) {
        changeColorOfAllElements(value);
      }
    }
  };

  const changeColorOfAllElements = (value) => {
    setIsSaved(false);
    let columnsAux = [...state.columns];
    columnsAux.map((column) => {
      column.color = value + transparencyColor;
    });
    let rowsAux = [...state.rows];
    rowsAux?.map((r) =>
      r.map((t) => {
        t.color = value + transparencyColor;
      })
    );
    setState((prevState) => ({ ...prevState, columns: columnsAux, rows: rowsAux }));
    setLastRowColor(value);
    setLastTitleColor(value);
  };

  const onSaveModalOptions = () => {
    if (controlDeleteModal?.attributeType === 'coluna') {
      deleteColumn(Number(controlDeleteModal?.index));
    } else {
      deleteRow(Number(controlDeleteModal?.index));
    }
    setControlDeleteModal(null);
  };

  const changeTableTitle = (value: string) => {
    setIsSaved(false);
    formik.setFieldValue('title', value);
  };

  const getTabelaComparativaById = async (modeloId: string) => {
    showLoading(true);
    const response = await getModelById('ModeloTabelaComparativa', clientTitularId, modeloId);
    if (response.status === 200) {
      showLoading(false);

      let dataFormik = {
        id: response.data[0].id,
        description: response.data[0].descricao,
        title: response.data[0].titulo,
        html: response.data[0].html,
        json: response.data[0].json,
        clienteTitularId: clientTitularId,
        htmlImage: response.data[0].htmlImage,
      };
      const dataState = JSON.parse(response.data[0].json);
      setState(dataState);
      formik.setValues(dataFormik);
      setIsSaved(true);
    } else {
      showLoading(false);

      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao carregar os dados do modelo.');
      }
    }
  };

  useEffect(() => {
    if (idModel) {
      getTabelaComparativaById(idModel);
    }
  }, [idModel]);

  return (
    <Container elevation={1}>
      <form onSubmit={formik.handleSubmit}>
        <ModalColors
          closeModal={() => setControlModalColor(null)}
          open={Boolean(controlModalColor)}
          changeColor={changeSelectedColor}
          selectedColor={controlModalColor}
        />
        <ModalOptions
          text={`Tem certeza que deseja excluir essa ${controlDeleteModal?.attributeType}?`}
          open={Boolean(controlDeleteModal)}
          onCancel={() => setControlDeleteModal(null)}
          onSave={onSaveModalOptions}
        />
        <ModalOptions
          text={`As alterações não foram salvas, deseja continuar?`}
          open={controlCancelModal}
          onCancel={() => setControlCancelModal(false)}
          onSave={() => {
            history.block(() => {});
            history.push(temporaryPathName);
          }}
        />
        <Content>
          <TopContent>
            <div>
              <a style={{ cursor: 'pointer' }} onClick={() => history.push('/meus-modelos')}>
                <ArrowBackIos />
              </a>
              <p>
                Modelo / <span className="color-text">Tabela</span>
              </p>
            </div>
          </TopContent>
          <div className="inputs">
            <Input
              label="Identificação do modelo da tabela"
              className="descricao-tabela"
              variant="outlined"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 500,
              }}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              type="text"
            />
            <Input name="Modelo" className="modelo" variant="outlined" type="text" value={'Tabela'} disabled />
          </div>
        </Content>
        <Tables>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
            <Split
              sizes={splitSizes}
              minSize={[425, 1000]}
              maxSize={[850, Infinity]}
              direction="horizontal"
              style={{ display: 'flex', width: '100%' }}
              cursor="col-resize"
              gutterSize={10}
              onDragEnd={(sizes) => {
                setSplitSizes(sizes);
              }}
            >
              <TableContainer>
                <ConfigurationTable
                  {...{
                    formik,
                    addNewColumn,
                    addNewRow,
                    changeColumnValue,
                    changeColumnWidth,
                    changeRowHeight,
                    changeRowValue,
                    columnsBorderStyle,
                    setControlDeleteModal,
                    setControlModalColor,
                    widthColumn,
                    columns: state.columns,
                    rows: state.rows,
                    changeTableTitle,
                  }}
                />
              </TableContainer>
              <TableContainer length={state.columns.length}>
                <ViewTable
                  {...{
                    id: formik.values.id,
                    fileName: formik.values.description,
                    isSaved,
                    titleColor: state.titleColor,
                    titleText: formik.values.title,
                    copyRef: copyRef,
                    tableRef: ref,
                    columnsBorderStyle,
                    columns: state.columns,
                    rows: state.rows,
                  }}
                />
              </TableContainer>
            </Split>
          </div>
        </Tables>
        <Buttons>
          <Button className={classes.buttonRed} variant="outlined" onClick={handleOpenCancelModal}>
            Cancelar
          </Button>
          <Button
            className={classes.buttonGreen}
            variant="contained"
            type="submit"
            onClick={() => {
              if (!isEmpty(formik.errors))
                showAlertError(
                  'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.'
                );
            }}
          >
            Salvar
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default ComparativeTable;
