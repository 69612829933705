import { Paper, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Paper)`
  padding: 20px;
  width: 100%;
  max-width: 100vw;
  margin-top: 14px;

  .MuiTextField-root {
    height: auto;
  }
  form {
    width: 100%;
  }
`;

export const Tables = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  background-color: white;
  gap: 20px;

  .informative-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
    height: 65px;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }

    div {
      display: flex;
      justify-content: center;
      gap: 10px;
      p {
        cursor: pointer;
      }
    }

    svg {
      color: #1976d2;
    }
  }

  svg {
    cursor: pointer;
  }

  .gutter {
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  }
`;

export const TableContainer = styled.div`
  padding: 20px;
  width: 100%;
  table {
    td + td,
    th + th {
      padding: 20px;
    }
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    color: #3e4756;
  }

  a {
    margin-right: 10px;
  }
  p {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #9ba3a9;
    font-size: 24px;
    font-family: 'Mosk Semi Bold';

    .color-text {
      font-size: 24px;
      color: #3fced2;
      font-family: 'Mosk Semi Bold';
    }
  }
`;

export const Content = styled.div`
  padding: 20px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  .inputs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .description {
      width: 33%;
    }
  }

  .titleRow {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 20px 0px 30px 0px;
    justify-content: center;

    .titleLabel {
      font-weight: 500;
      font-size: 18px;
      color: #3e4756;
    }
  }

  .titleDiagram {
    font-weight: 600;
    font-size: 16px;
    color: #3e4756;
  }

  .cardsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;

    .MuiCardMedia-root {
      background-color: #ebf2f2;
    }

    .card {
      width: 300px;
      height: auto;
      margin-right: 30px;
    }
  }

  .descriptionCard {
    /* text-align: justify; */
    font-size: 12px;
    color: #747a85;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 45px;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  margin-bottom: 10px;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
