import styled, { ThemeConsumer } from 'styled-components';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const TopContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  p {
    display: flex;
    justify-content: center;
    align-items: inherit;
    color: #3fced2;
    margin-top: 5px;
    cursor: pointer;
    .MuiSvgIcon-root {
      width: 21px;
      height: 21px;
      color: #3fced2;
      margin-right: 5px;
    }
  }

  a {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #3fced2;
    font-size: 24px;
    font-weight: 500;

    .color-text {
      font-size: 24px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  .inputs {
    display: grid;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
    .name {
      grid-area: name;
    }

    .email {
      grid-area: email;
    }

    grid-template-columns: repeat(2, 1fr);

    grid-template-areas: 'name email';
  }
  .selects {
    display: flex;
    gap: 20px;
    .posicao {
      width: 29%;
    }
    .quebra-linha {
      width: 20%;
    }
    .data-hora {
      width: 20%;
    }
    .ordenacao {
      width: 31%;
    }
  }

  .terms-and-policies {
    a {
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
      color: #1976d2;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 45px;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
    backgroundColor: '#fff',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
