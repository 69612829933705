import * as Yup from 'yup';
import { cnpjValidate, cpfValidate } from '../../helper/validate';

export const clientInitialValues = {
  planId: '',
  recurrenceTypeId: '',
  creditCardOwnerName: '',
  documentNumber: '',
  creditCardNumber: null,
  creditCardDueDateMonth: null,
  creditCardDueDateYear: null,
  expireDate: null,
  securityCode: '',
  automaticRenew: true,
  hasCard: false,
  changeCard: false,
  marketPlaceId: 1,
  firstLogin: 0,
};

export const clientValidationSchema = Yup.object({
  creditCardOwnerName: Yup.string().required('Nome titular é obrigatório'),
  documentNumber: Yup.string()
    .required('Campo obrigatório')
    .test('isValid', 'CPF/CNPJ inválido', (value) => {
      return value && value.length <= 14 ? cpfValidate(value) : cnpjValidate(value);
    }),
  creditCardNumber: Yup.string().required('Número do cartão é obrigatório').nullable(),
  expireDate: Yup.date().required('Data de expiração é obrigatória').nullable(),
  securityCode: Yup.string().required('CVV é obrigatório').nullable(),
});

export const clientWitchCardValidationSchema = Yup.object({
  planId: Yup.string().required('Selecione um plano'),
  recurrenceTypeId: Yup.string().required('Selecione o período do plano'),
});
