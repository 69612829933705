import { MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';

// import { Container } from './styles';

function SelectComponentTable(props: any) {
  const [selectValue, setSelectValue] = useState(' ');
  return (
    <Select
      value={selectValue}
      onChange={(e) => {
        setSelectValue(e.target.value);
        props.onFilterChanged(props?.columnDef?.tableData?.id, e.target.value);
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      <MenuItem value=" ">
        <em>Selecione um valor</em>
      </MenuItem>
      <MenuItem value="1">Ativo</MenuItem>
      <MenuItem value="0">Inativo</MenuItem>
    </Select>
  );
}

export default SelectComponentTable;
