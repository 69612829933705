import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { TextField } from '@material-ui/core';

interface IProps {
  text: string;
  setText: (s: string) => void;
}

function IAConfiguration({ text, setText }: IProps): React.ReactElement {
  return (
    <Container>
      <TextField
        fullWidth
        label="Insira o texto que deseja aprimorar (até 3.000 palavras)..."
        defaultValue={text}
        value={text}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        id="inputTextBase"
        name="inputTextBase"
        multiline
        rows={20}
        onChange={(event) => setText(event.target.value)}
      />
    </Container>
  );
}

export default IAConfiguration;
