import React, { Fragment, useEffect, useState } from 'react';
import logo from '../../assets/Logo-small.svg';
import { HomeOutlined, PeopleAltOutlined, PersonOutline } from '@material-ui/icons';
import { Menu, MenuItem } from '@mui/material';
import { Fade } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { Content, Exit, Header, HeaderContent } from './styles';
import { useAuth } from '../../contexts/auth';

interface IMasterPageProps {
  children: React.ReactElement | React.FC;
}

const ExpirateUserMenu = ({ children }: IMasterPageProps): React.ReactElement => {
  const history = useHistory();
  const { user, permissions } = useAuth();
  const location = useLocation();

  const menu = [
    {
      name: user?.username,
      icon: <PersonOutline />,
      onClick: () => {},
      submenu: [
        {
          name: 'Meus dados',
          onClick: () => {
            history.push('/meus-dados');
          },
        },
        { name: <Exit>Sair</Exit>, onClick: () => history.push('/login') },
      ],
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<Array<null | (EventTarget & Element)>>([null]);

  const handleClickMenuOptions = (event: React.SyntheticEvent, index: number) => {
    const aux = anchorEl;
    aux[index] = event.currentTarget;
    setAnchorEl([...aux]);
  };
  const handleClose = (index: number) => {
    const aux = anchorEl;
    aux[index] = null;
    setAnchorEl([...aux]);
  };

  return (
    <>
      <Header elevation={1}>
        <HeaderContent>
          <div>
            <img src={logo} />
          </div>
          <div>
            {menu.map((item, index) => (
              <Fragment key={index}>
                <p
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl[index]) ? 'true' : undefined}
                  onClick={(event) => {
                    handleClickMenuOptions(event, index);
                    item?.onClick();
                  }}
                >
                  {item.icon}
                  <div>{item.name}</div>
                </p>

                {item.submenu.length > 0 ? (
                  <Menu
                    id="fade-menu"
                    anchorEl={anchorEl[index]}
                    open={Boolean(anchorEl[index])}
                    onClose={() => handleClose(index)}
                    TransitionComponent={Fade}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {item.submenu.map((subItem) => (
                      <MenuItem
                        key={subItem.name}
                        onClick={() => {
                          handleClose(index);
                          subItem.onClick();
                        }}
                      >
                        {subItem.name}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : null}
              </Fragment>
            ))}
          </div>
        </HeaderContent>
      </Header>
      <Content>{children}</Content>
    </>
  );
};

export default ExpirateUserMenu;
