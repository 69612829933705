import React from 'react';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import TextField from '../../../../components/EditTableTemplateTextField';
import { Container } from './styles';

interface IProps {
  count: number;
  inputId: string;
  parentValue: Array<string>;
  descriptionInputValue: string;
  deleteItem: () => void;
  changeColorItem: () => void;
  centerFirstItem: () => void;
  changeValueItem: (value: Date, name: string) => void;
  isFirstItem: boolean;
  parentsElements: string[];
  positionFlow: number;
}

const ConfigurationCard: React.FC = ({
  count,
  inputId,
  descriptionInputValue,
  parentValue,
  deleteItem,
  changeColorItem,
  centerFirstItem,
  changeValueItem,
  isFirstItem = false,
  parentsElements,
  positionFlow,
}: IProps) => {
  return (
    <Container>
      <div>
        <TextField
          id={inputId}
          defaultValue={descriptionInputValue}
          onChange={(value) => {
            changeValueItem(value, 'description');
          }}
          maxLength={150}
          label="Descrição do elemento"
          speechVisible={true}
        />
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Conectado a</InputLabel>
          <Select
            multiple
            size="small"
            variant="outlined"
            value={parentValue || []}
            onChange={(event) => {
              changeValueItem(event.target.value, 'parents');
            }}
            label="Conectado a"
          >
            {parentsElements.map((parent, index) => (
              <MenuItem key={index} value={parent.id}>
                {parent.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {isFirstItem && (
          <Tooltip title="Centralizar o primeiro nível">
            <FormatAlignCenterIcon style={{ cursor: 'pointer' }} onClick={centerFirstItem} />
          </Tooltip>
        )}

        <Tooltip title="Alterar cor">
          <ColorLensOutlinedIcon style={{ cursor: 'pointer' }} onClick={changeColorItem} />
        </Tooltip>

        {count > 0 ? (
          <Tooltip title="Deletar item">
            <DeleteOutlineOutlinedIcon style={{ cursor: 'pointer' }} onClick={deleteItem} color="error" />
          </Tooltip>
        ) : (
          ''
        )}
      </div>
    </Container>
  );
};

export default ConfigurationCard;
