import React, { ChangeEvent, useEffect, useState } from 'react';
import { ContainerCheckbox, ContentCard, ContentPlan, useStyles } from './styles';
import { Checkbox, FormControl, TextField } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@material-ui/core/Grid';
import ReactInputMask from 'react-input-mask';
import { getMonth, getYear, isValid } from 'date-fns';
import { includeMaskCPF } from '../../../helper/mask/cpfMask';
import { includeMaskCNPJ } from '../../../helper/mask/cnpjMask';
import MasterBrand from '../../../assets/CardsBrands/master.svg';
import VisaBrand from '../../../assets/CardsBrands/visa.svg';
import EloBrand from '../../../assets/CardsBrands/elo.svg';
import HiperBrand from '../../../assets/CardsBrands/hipercard.svg';
import MenuItem from '@mui/material/MenuItem';

const Payment = ({ formik, card, ufs, setValue }: any) => {
  const classes = useStyles();
  const [cardEdit, setCardEdit] = useState(false);

  function handleDate(name: string, value: any) {
    if (isValid(value)) {
      let date = new Date(new Date(value));
      let mouth = getMonth(date) + 1;
      let year = getYear(date);
      formik.setFieldValue(name, date);
      formik.setFieldValue('creditCardDueDateMonth', mouth);
      formik.setFieldValue('creditCardDueDateYear', year);
    }
  }

  function handleEditCard() {
    setCardEdit(!cardEdit);
    formik.setFieldValue('creditCardOwnerName', '');
    formik.setFieldValue('documentNumber', '');
    formik.setFieldValue('creditCardNumber', '');
    formik.setFieldValue('expireDate', null);
    formik.setFieldValue('securityCode', '');
    formik.setFieldValue('automaticRenew', true);
    formik.setFieldValue('ownerCEP', '');
    formik.setFieldValue('ownerUF', '');
    formik.setFieldValue('ownerCity', '');
    formik.setFieldValue('ownerAddress', '');
    formik.setFieldValue('hasCard', false);
    formik.setFieldValue('changeCard', true);
  }

  const checkCEP = (e: ChangeEvent<HTMLSelectElement>) => {
    const cep = e.target.value;

    if (cep && cep.length > 5) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          ownerUF.textContent = data.uf;
          formik.setFieldValue('ownerUF', data.uf);
          formik.setFieldValue('ownerCity', data.localidade);
          formik.setFieldValue('ownerAddress', data.logradouro);
        });
    }
  };

  return (
    <>
      <h1>Método de pagamento</h1>
      {card.lastFourDigits ? (
        <ContentPlan>
          <div className="container">
            <div className="column">
              {card.brand == 'Mastercard' ? (
                <img src={MasterBrand} width={68} height={40} />
              ) : card.brand == 'Visa' ? (
                <img src={VisaBrand} width={68} height={40} />
              ) : card.brand == 'Hiper' ? (
                <img src={HiperBrand} width={68} height={40} />
              ) : card.brand == 'Elo' ? (
                <img src={EloBrand} width={68} height={40} />
              ) : (
                ''
              )}
            </div>
            <div className="column">
              <div className="row">
                <p>
                  Nome do titular: <span>{card.holderName}</span>
                </p>
              </div>
              <div className="row">
                <p style={{ marginRight: '40px' }}>
                  Número do cartão:
                  <span>xxxx-xxxx-xxxx-{card.lastFourDigits}</span>
                </p>
              </div>
            </div>
            <div className="column">
              <div className="row">
                <p>
                  CPF: <span>{includeMaskCPF(card.holderDocument)}</span>
                </p>
              </div>
              <div className="row">
                <p>
                  Data de vencimento:
                  <span>
                    {card.expMonth}/{card.expYear}
                  </span>
                </p>
              </div>
            </div>
            <div className="column">
              <div className="change-plan" onClick={handleEditCard}>
                Alterar
              </div>
            </div>
          </div>
        </ContentPlan>
      ) : (
        <ContentCard>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nome do titular"
                  InputLabelProps={{ required: true }}
                  className={classes.field}
                  variant="outlined"
                  id="creditCardOwnerName"
                  name="creditCardOwnerName"
                  value={formik.values.creditCardOwnerName}
                  onChange={(event) => formik.setFieldValue('creditCardOwnerName', event.target.value)}
                  error={formik.touched.creditCardOwnerName && Boolean(formik.errors.creditCardOwnerName)}
                  helperText={formik.touched.creditCardOwnerName && formik.errors.creditCardOwnerName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{
                    required: true,
                  }}
                  fullWidth
                  id="document"
                  name="document"
                  label="CPF/CNPJ"
                  variant="outlined"
                  className={classes.field}
                  value={formik.values.documentNumber}
                  onChange={(event) => {
                    const value = event.target.value;

                    if (value.length <= 14) {
                      formik.setFieldValue('documentNumber', includeMaskCPF(value));
                    } else {
                      formik.setFieldValue('documentNumber', includeMaskCNPJ(value));
                    }
                  }}
                  error={formik.touched.documentNumber && Boolean(formik.errors.documentNumber)}
                  helperText={formik.touched.documentNumber && formik.errors.documentNumber}
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ReactInputMask
                  mask="9999.9999.9999.9999"
                  value={formik.values.creditCardNumber}
                  onChange={(event) => formik.setFieldValue('creditCardNumber', event.target.value)}
                >
                  {() => (
                    <TextField
                      fullWidth
                      InputLabelProps={{ required: true }}
                      id="creditCardNumber"
                      name="creditCardNumber"
                      label="Número do Cartão"
                      variant="outlined"
                      className={classes.field}
                      error={formik.touched.creditCardNumber && Boolean(formik.errors.creditCardNumber)}
                      helperText={formik.touched.creditCardNumber && formik.errors.creditCardNumber}
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  className={classes.field}
                  variant="outlined"
                  error={formik.touched.expireDate && Boolean(formik.errors.expireDate)}
                >
                  <DatePicker
                    InputLabelProps={{
                      required: true,
                    }}
                    autoOk
                    label="Data de vencimento"
                    value={formik.values.expireDate == null ? null : new Date(formik.values.expireDate)}
                    placeholder="mm/aaaa"
                    onChange={(value) => handleDate('expireDate', value)}
                    minDate={new Date()}
                    inputVariant="outlined"
                    variant="inline"
                    error={formik.touched.expireDate && Boolean(formik.errors.expireDate)}
                    helperText={formik.touched.expireDate && formik.errors.expireDate}
                    format="MM/yyyy"
                    views={['year', 'month']}
                  />
                </FormControl>
              </Grid>
              <Grid item xs>
                <ReactInputMask
                  mask="999"
                  value={formik.values.securityCode}
                  onChange={(event) => formik.setFieldValue('securityCode', event.target.value)}
                >
                  {() => (
                    <TextField
                      fullWidth
                      InputLabelProps={{ required: true }}
                      id="securityCode"
                      name="securityCode"
                      label="CVV"
                      variant="outlined"
                      className={classes.field}
                      error={formik.touched.securityCode && Boolean(formik.errors.securityCode)}
                      helperText={formik.touched.securityCode && formik.errors.securityCode}
                    />
                  )}
                </ReactInputMask>
              </Grid>
            </Grid>
          </div>
          <div>
            <h1>Endereço de Cobrança</h1>
            <Grid container spacing={2}>
              <Grid item md={3} sm={6}>
                <TextField
                  label="CEP"
                  variant="outlined"
                  id="ownerCEP"
                  name="ownerCEP"
                  fullWidth
                  className={classes.field}
                  value={formik.values.ownerCEP}
                  onChange={(event) => formik.setFieldValue('ownerCEP', event.target.value)}
                  onBlur={checkCEP}
                  error={formik.touched.ownerUF && Boolean(formik.errors.ownerCEP)}
                  helperText={formik.touched.ownerCEP && formik.errors.ownerCEP}
                />
              </Grid>
              <Grid item md={2} sm={6}>
                <TextField
                  label="UF"
                  variant="outlined"
                  id="ownerUF"
                  name="ownerUF"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  className={classes.field}
                  //value={formik.values.ownerUF}
                  onChange={(event) => formik.setFieldValue('ownerUF', event.target.value)}
                  error={formik.touched.ownerUF && Boolean(formik.errors.ownerUF)}
                  helperText={formik.touched.ownerUF && formik.errors.ownerUF}
                  select // tell TextField to render select
                >
                  {ufs &&
                    ufs.map((uf) => {
                      return (
                        <MenuItem key={uf.id} value={uf.id}>
                          {uf.initials}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  fullWidth
                  label="Cidade"
                  InputLabelProps={{ shrink: true }}
                  className={classes.field}
                  variant="outlined"
                  id="ownerCity"
                  name="ownerCity"
                  value={formik.values.ownerCity}
                  onChange={(event) => formik.setFieldValue('ownerCity', event.target.value)}
                  error={formik.touched.ownerCity && Boolean(formik.errors.ownerCity)}
                  helperText={formik.touched.ownerCity && formik.errors.ownerCity}
                />
              </Grid>
              <Grid item md={12} sm={12}>
                <TextField
                  fullWidth
                  label="Endereço"
                  InputLabelProps={{ shrink: true }}
                  className={classes.field}
                  variant="outlined"
                  id="ownerAddress"
                  name="ownerAddress"
                  value={formik.values.ownerAddress}
                  onChange={(event) => formik.setFieldValue('ownerAddress', event.target.value)}
                  error={formik.touched.ownerAddress && Boolean(formik.errors.ownerAddress)}
                  helperText={formik.touched.ownerAddress && formik.errors.ownerAddress}
                />
              </Grid>
            </Grid>
          </div>
        </ContentCard>
      )}
      {cardEdit ? (
        <ContentCard>
          <form>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    InputLabelProps={{ required: true }}
                    fullWidth
                    value={formik?.values?.creditCardOwnerName}
                    onChange={(event) => formik.setFieldValue('creditCardOwnerName', event.target.value)}
                    error={Boolean(formik.errors.creditCardOwnerName)}
                    helperText={formik.errors.creditCardOwnerName}
                    id="creditCardOwnerName"
                    name="creditCardOwnerName"
                    label="Nome do titular"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{
                      required: true,
                    }}
                    fullWidth
                    id="document"
                    name="document"
                    label="CPF/CNPJ"
                    variant="outlined"
                    value={formik.values.documentNumber}
                    onChange={(event) => {
                      const value = event.target.value;

                      if (value.length <= 14) {
                        formik.setFieldValue('documentNumber', includeMaskCPF(value));
                      } else {
                        formik.setFieldValue('documentNumber', includeMaskCNPJ(value));
                      }
                    }}
                    error={Boolean(formik.errors.documentNumber)}
                    helperText={formik.errors.documentNumber}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ReactInputMask
                    mask="9999.9999.9999.9999"
                    value={formik.values.creditCardNumber}
                    onChange={(event) => formik.setFieldValue('creditCardNumber', event.target.value)}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        InputLabelProps={{ required: true }}
                        error={Boolean(formik.errors.creditCardNumber)}
                        helperText={formik.errors.creditCardNumber}
                        id="creditCardNumber"
                        name="creditCardNumber"
                        label="Número do Cartão"
                        variant="outlined"
                        className={classes.field}
                      />
                    )}
                  </ReactInputMask>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    className={classes.field}
                    variant="outlined"
                    error={formik.touched.expireDate && Boolean(formik.errors.expireDate)}
                  >
                    <DatePicker
                      InputLabelProps={{
                        required: true,
                      }}
                      autoOk
                      label="Data de vencimento"
                      value={formik.values.expireDate == null ? null : new Date(formik.values.expireDate)}
                      placeholder="mm/aaaa"
                      onChange={(value) => handleDate('expireDate', value)}
                      minDate={new Date()}
                      inputVariant="outlined"
                      variant="inline"
                      error={formik.touched.expireDate && Boolean(formik.errors.expireDate)}
                      helperText={formik.touched.expireDate && formik.errors.expireDate}
                      format="MM/yyyy"
                      views={['year', 'month']}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <ReactInputMask
                    mask="999"
                    value={formik.values.securityCode}
                    onChange={(event) => formik.setFieldValue('securityCode', event.target.value)}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        InputLabelProps={{ required: true }}
                        id="securityCode"
                        name="securityCode"
                        label="CVV"
                        variant="outlined"
                        className={classes.field}
                        error={formik.touched.securityCode && Boolean(formik.errors.securityCode)}
                        helperText={formik.touched.securityCode && formik.errors.securityCode}
                      />
                    )}
                  </ReactInputMask>
                </Grid>
              </Grid>
            </div>
            <div>
              <h1>Endereço de Cobrança</h1>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6}>
                  <TextField
                    label="CEP"
                    variant="outlined"
                    id="ownerCEP"
                    name="ownerCEP"
                    fullWidth
                    value={formik.values.ownerCEP}
                    onChange={(event) => formik.setFieldValue('ownerCEP', event.target.value)}
                    onBlur={checkCEP}
                    error={formik.touched.ownerUF && Boolean(formik.errors.ownerCEP)}
                    helperText={formik.touched.ownerCEP && formik.errors.ownerCEP}
                  />
                </Grid>
                <Grid item md={2} sm={6}>
                  <TextField
                    label="UF"
                    variant="outlined"
                    id="ownerUF"
                    name="ownerUF"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formik?.values?.ownerUF}
                    onChange={(event) => formik.setFieldValue('ownerUF', event.target.value)}
                    error={Boolean(formik.errors.ownerUF)}
                    helperText={formik.errors.ownerUF}
                    select // tell TextField to render select
                  >
                    {ufs &&
                      ufs.map((uf: any) => {
                        return (
                          <MenuItem key={uf.id} value={uf.id}>
                            {uf.initials}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
                <Grid item md={7} sm={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formik?.values?.ownerCity}
                    onChange={(event) => formik.setFieldValue('ownerCity', event.target.value)}
                    error={Boolean(formik.errors.ownerCity)}
                    helperText={formik.errors.ownerCity}
                    id="ownerCity"
                    name="ownerCity"
                    label="Cidade"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formik?.values?.ownerAddress}
                    onChange={(event) => formik.setFieldValue('ownerAddress', event.target.value)}
                    error={Boolean(formik.errors.ownerAddress)}
                    helperText={formik.errors.ownerAddress}
                    id="ownerAddress"
                    name="ownerAddress"
                    label="Endereço"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </ContentCard>
      ) : (
        ''
      )}
    </>
  );
};
export default Payment;
