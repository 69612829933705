import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 20px;

  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }
  }

  .flowchart-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 500;
  }
  .timeline-input {
    height: 80px;
    width: 100%;
    .timeline-title {
      width: 400px;
    }
  }
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    margin: 0;
    padding: 20px;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }
    svg {
      color: #1976d2;
      margin-left: 20px;
    }
  }
  > div + div {
    max-height: calc(100vh - 310px);
    padding-left: 20px;
    overflow: auto;
  }

  .react-flow__node-default {
    width: 180px;
  }

  .react-flow__node-custom {
    padding: 10px;
    font-size: 12px;
    border-radius: 3px;
    width: 180px;
    font-size: 12px;
    color: #222;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #1a192b;
    background-color: white;
  }
`;

export const CardView = styled.div`
  min-width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 221px;
  max-width: 500px;
`;
