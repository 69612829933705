export const localizationOptions = {
  body: {
    emptyDataSourceMessage: 'Nenhum dado encontrado',
    editRow: {
      deleteText: 'Tem certeza que deseja excluir esse registro?',
    },
    deleteTooltip: 'Excluir',
    editTooltip: 'Editar',
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    nRowsSelected: '{0} linha(s) selecionada(s)',
  },
  pagination: {
    labelRowsSelect: 'linhas',
    firstAriaLabel: 'Primeira página',
    firstTooltip: 'Primeira página',
    previousAriaLabel: 'página anterior',
    previousTooltip: 'página anterior',
    nextAriaLabel: 'próxima página',
    nextTooltip: 'próxima página',
    lastAriaLabel: 'última página',
    lastTooltip: 'última página',
    labelDisplayedRows: '{from}-{to} de {count}',
  },
  header: {
    actions: 'Ações',
  },
};
