export const states = [
  {
    id_uf: 1,
    sigla: 'AC',
    nome: 'Acre',
    codigo: 12,
    id_pais: 1,
  },
  {
    id_uf: 2,
    sigla: 'AL',
    nome: 'Alagoas',
    codigo: 27,
    id_pais: 1,
  },
  {
    id_uf: 3,
    sigla: 'AM',
    nome: 'Amazonas',
    codigo: 13,
    id_pais: 1,
  },
  {
    id_uf: 4,
    sigla: 'AP',
    nome: 'Amapá',
    codigo: 16,
    id_pais: 1,
  },
  {
    id_uf: 5,
    sigla: 'BA',
    nome: 'Bahia',
    codigo: 29,
    id_pais: 1,
  },
  {
    id_uf: 6,
    sigla: 'CE',
    nome: 'Ceará',
    codigo: 23,
    id_pais: 1,
  },
  {
    id_uf: 7,
    sigla: 'DF',
    nome: 'Distrito Federal',
    codigo: 53,
    id_pais: 1,
  },
  {
    id_uf: 8,
    sigla: 'ES',
    nome: 'Espírito Santo',
    codigo: 32,
    id_pais: 1,
  },
  {
    id_uf: 9,
    sigla: 'GO',
    nome: 'Goiás',
    codigo: 52,
    id_pais: 1,
  },
  {
    id_uf: 10,
    sigla: 'MA',
    nome: 'Maranhão',
    codigo: 21,
    id_pais: 1,
  },
  {
    id_uf: 11,
    sigla: 'MG',
    nome: 'Minas Gerais',
    codigo: 31,
    id_pais: 1,
  },
  {
    id_uf: 12,
    sigla: 'MS',
    nome: 'Mato Grosso do Sul',
    codigo: 50,
    id_pais: 1,
  },
  {
    id_uf: 13,
    sigla: 'MT',
    nome: 'Mato Grosso',
    codigo: 51,
    id_pais: 1,
  },
  {
    id_uf: 14,
    sigla: 'PA',
    nome: 'Pará',
    codigo: 15,
    id_pais: 1,
  },
  {
    id_uf: 15,
    sigla: 'PB',
    nome: 'Paraíba',
    codigo: 25,
    id_pais: 1,
  },
  {
    id_uf: 16,
    sigla: 'PE',
    nome: 'Pernambuco',
    codigo: 26,
    id_pais: 1,
  },
  {
    id_uf: 17,
    sigla: 'PI',
    nome: 'Piauí',
    codigo: 22,
    id_pais: 1,
  },
  {
    id_uf: 18,
    sigla: 'PR',
    nome: 'Paraná',
    codigo: 41,
    id_pais: 1,
  },
  {
    id_uf: 19,
    sigla: 'RJ',
    nome: 'Rio de Janeiro',
    codigo: 33,
    id_pais: 1,
  },
  {
    id_uf: 20,
    sigla: 'RN',
    nome: 'Rio Grande do Norte',
    codigo: 24,
    id_pais: 1,
  },
  {
    id_uf: 21,
    sigla: 'RO',
    nome: 'Rondônia',
    codigo: 11,
    id_pais: 1,
  },
  {
    id_uf: 22,
    sigla: 'RR',
    nome: 'Roraima',
    codigo: 14,
    id_pais: 1,
  },
  {
    id_uf: 23,
    sigla: 'RS',
    nome: 'Rio Grande do Sul',
    codigo: 43,
    id_pais: 1,
  },
  {
    id_uf: 24,
    sigla: 'SC',
    nome: 'Santa Catarina',
    codigo: 42,
    id_pais: 1,
  },
  {
    id_uf: 25,
    sigla: 'SE',
    nome: 'Sergipe',
    codigo: 28,
    id_pais: 1,
  },
  {
    id_uf: 26,
    sigla: 'SP',
    nome: 'São Paulo',
    codigo: 35,
    id_pais: 1,
  },
  {
    id_uf: 27,
    sigla: 'TO',
    nome: 'Tocantins',
    codigo: 17,
    id_pais: 1,
  },
];
