import React, { useEffect, useState } from 'react';
import { Content } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from 'swiper/core';
import { useMediaQuery, useTheme } from '@material-ui/core';

import ItemModelo from '../../components/ItemModelo';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import BorderAllOutlinedIcon from '@mui/icons-material/BorderAllOutlined';
import MeusModelos from '../Templates/List';
import { tipoModelo } from '../../services/models';
import { IconButton, Tooltip } from '@mui/material';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

const NewHome: React.FC = () => {
  const modelos = tipoModelo();
  const theme = useTheme();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const [projectViewTable, setProjectViewTable] = useState(stateProjectViewTable());

  const openProjectViewTable = React.useCallback(() => setProjectViewTable(true), []);
  const closeProjectViewTable = React.useCallback(() => setProjectViewTable(false), []);

  useEffect(() => {
    localStorage.setItem('projectViewTable', projectViewTable ? 'open' : 'closed');
  }, [projectViewTable]);

  function stateProjectViewTable() {
    return localStorage.getItem('projectViewTable') == 'open' ? true : false;
  }

  return (
    <Content>
      <h3 className="title">Escolha um modelo de projeto</h3>
      <Swiper
        className="carousel"
        spaceBetween={30}
        slidesPerView={isSmallOrLess ? 2 : isMediumOrLess ? 4 : 6}
        navigation={true}
        // autoplay
        loop
      >
        {modelos.map((modelo) => (
          <SwiperSlide key={modelo.titulo} style={{ listStyle: 'none' }}>
            <div style={{ paddingTop: '15px' }}>
              <ItemModelo
                videoYoutube={modelo.video}
                titulo={modelo.titulo}
                descricao={modelo.descricao}
                link={modelo.link}
                imagem={modelo.imagem}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div>
        <div className="topo">
          <div style={{ marginBottom: '-10px' }}>
            <h3 className="title">Recentes</h3>
          </div>
          <div className="visualizacao" style={{ marginTop: '25px' }}>
            {projectViewTable ? (
              <Tooltip title="Ver como grade">
                <IconButton onClick={closeProjectViewTable}>
                  <BorderAllOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Ver como Lista">
                <IconButton onClick={openProjectViewTable}>
                  <FormatListBulletedOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <MeusModelos exibirTitulo={false} exibirTable={projectViewTable} />
      </div>
    </Content>
  );
};

export default NewHome;
