import React from 'react';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Content } from './styles';
import { useHistory } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { asksForPasswordRecovery } from '../../services/auth';
import { useLoading } from '../../contexts/loading';
import { useAlert } from '../../contexts/alert';

const validationSchema = Yup.object({
  email: Yup.string().email('E-mail inválido').required('Obrigatório'),
});

const ModalForgotPassword = ({ handleClose }: any): React.ReactElement => {
  const history = useHistory();
  const { showLoading } = useLoading();
  const { showAlertSuccess, showAlertError } = useAlert();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSendEmail(values.email);
    },
  });

  const handleSendEmail = (value: string) => {
    showLoading(true);
    asksForPasswordRecovery(value)
      .then((response) => {
        showLoading(false);
        handleClose();
        showAlertSuccess(
          'Solicitação enviada com sucesso, Enviamos a solicitação para o e-mail: ' + formik.values.email
        );
      })
      .catch((err) => {
        showAlertError('ocorreu um erro ao tentar enviar nova senha para o seu email');
        showLoading(false);
      });
  };

  return (
    <Content>
      <div className="go-back">
        <Button>
          <ClearIcon
            onClick={() => {
              handleClose();
            }}
          />
        </Button>
      </div>
      <div className="email">
        <h2>Recuperar senha</h2>
        <p>Digite seu endereço de e-mail. Você receberá um link por e-mail para criar uma nova senha.</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Button className="btnRecuperar" variant="contained" fullWidth type="submit">
            Recuperar
          </Button>
        </form>
      </div>
    </Content>
  );
};

export default ModalForgotPassword;
