import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ArrowBackIos } from '@material-ui/icons';
import { Button, TextField } from '@material-ui/core';
import ModalOptions from '../../../../components/ModalOptions';
import { convertTextToPDF, copyText, downLoadText } from '../../../../helper/convertHTML';
import { askOpenAI, createSampleLanguage, getModelById, updateModel } from '../../../../services/models';
import { Buttons, Container, ContainerView, Content, TopContent, useStyles } from './styles';
import { useLoading } from '../../../../contexts/loading';
import { useAlert } from '../../../../contexts/alert';
import { useAuth } from '../../../../contexts/auth';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import IAConfiguration from './ConfigurationContainer';
import IAView from './AnswerView';
import Tooltip from '@mui/material/Tooltip';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { ArrowRight } from '@mui/icons-material';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { createImageFile } from '../../../../helper/convertHTML';

const IASampleLanguageModel: React.FC = () => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const { user } = useAuth();
  const clientTitularId: string = user?.tokenData?.ClientId;
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const classes = useStyles();
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const [gerarTexto, setgerarTexto] = useState<boolean>(false);
  const [controlCancelEditingModal, setControlCancelEditingModal] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);
  const [temporaryPathName, setTemporaryPathName] = useState('');
  const [xtexto, setXtexto] = useState('');
  const [xChangedTexto, setXchangedTexto] = useState('');
  const history = useHistory();
  const [idModel, setIdModel] = useState(history?.location?.state?.id);

  const validationSchema = Yup.object({
    descricao: Yup.string().min(3, 'O preenchimento da descrição do projeto com no mínimo 3 caracteres é requerido.'),
    //baseText: Yup.string().min(20, 'O texto a ser transformado precisa estar preenchido com no mínimo 20 caracteres.'),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      descricao: '',
      titulo: '',
      baseText: '',
      transformedText: '',
      html: '',
      json: '',
      clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      htmlImage: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      setgerarTexto(true);
    },
  });

  const onSave = async () => {
    let errorBaseText =
      xtexto.length >= 20 ? '' : 'O texto a ser transformado precisa estar preenchido com no mínimo 20 caracteres.';
    let errorDescricao = formik.errors.descricao == undefined ? '' : formik.errors.descricao;
    if (!isEmpty(formik.errors) || isEmpty(formik.values.descricao) || xtexto.length < 20) {
      showAlertError(
        'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente. ' +
          errorBaseText +
          ' ' +
          errorDescricao
      );
      return;
    }

    showLoading(true);

    if (!idModel) {
      formik.values.id = uuidv4();
      formik.values.baseText = xtexto;
      formik.values.transformedText = xChangedTexto;
      formik.values.html = document.getElementById('ContainerViewMensagemSimples').innerHTML;
      formik.values.json = JSON.stringify(xtexto);
      formik.values.htmlImage = await createImageFile(document.getElementById('#view'));
      createSampleLanguage(formik.values)
        .then(() => {
          setIsSaved(true);
          showLoading(false);
          showAlertSuccess('Texto gravado com sucesso.');
          setIdModel(formik.values.id);
        })
        .catch((error) => {
          showLoading(false);
          if (error.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else if (error.status === 412) {
            showAlertError('Atingido o limite de modelos do plano.');
          } else {
            showAlertError('Ocorreu um erro ao gravar o texto.');
          }
        });
    } else {
      formik.values.baseText = xtexto;
      formik.values.transformedText = xChangedTexto;
      formik.values.html = document.getElementById('ContainerViewMensagemSimples').innerHTML;
      formik.values.json = JSON.stringify(xtexto);
      formik.values.htmlImage = await createImageFile(document.getElementById('#view'));
      const response = await updateModel('ModeloLinguagemSimples', formik.values);
      if (response.status !== undefined) {
        if (response.status === 204) {
          showLoading(false);
          setIsSaved(true);
          showAlertSuccess('Modelo atualizado com sucesso.');
          setIdModel(idModel);
        } else {
          showLoading(false);
          if (response.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao gravar o texto.');
          }
        }
      } else {
        showLoading(false);
        if (response.status === 401) {
          showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
        } else {
          showAlertError('Ocorreu um erro ao gravar o texto.');
        }
      }
    }
  };

  const getSampleLanguagebyId = async (modeloId: string) => {
    showLoading(true);

    const response = await getModelById('ModeloLinguagemSimples', clientTitularId, modeloId);

    if (response.status === 200) {
      let dataFormik = {
        id: response.data[0].id,
        descricao: response.data[0].descricao,
        titulo: response.data[0].titulo,
        html: response.data[0].html,
        json: response.data[0].json,
        baseText: response.data[0].baseText,
        transformedText: response.data[0].transformedText,
        clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      };
      formik.setValues(dataFormik);
      setXtexto(dataFormik.baseText);
      setXchangedTexto(dataFormik.transformedText);
      setgerarTexto(true);
      setIsSaved(true);
      showLoading(false);
    } else {
      showLoading(false);

      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
      }
    }
  };

  useEffect(() => {
    if (idModel) {
      getSampleLanguagebyId(idModel);
    }
  }, [idModel]);

  const onCancelEditing = () => history.push('/novo-modelo');

  const onClearText = () => {
    setIsSaved(false);
    setXtexto('');
    formik.values.baseText = '';
    formik.values.transformedText = '';
    setXchangedTexto('');
  };

  const onClickProcess = async () => {
    setIsSaved(false);
    showLoading(true);
    const response = await askOpenAI(xtexto);
    showLoading(false);
    if (typeof response == 'string') {
      setXchangedTexto(response);
    } else {
      setXchangedTexto(response.data);
    }
    formik.values.baseText = xtexto;
    formik.values.transformedText = xChangedTexto;
  };

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  const downloadImage = () => {
    downLoadText(xChangedTexto, formik.values.descricao);
  };

  const downloadPDF = () => {
    convertTextToPDF(xChangedTexto, formik.values.descricao);
  };

  const copy = () => {
    copyText(xChangedTexto);
    setOpenToolTipCopyImage(true);
  };

  return (
    <Container elevation={1}>
      <ModalOptions
        text={`As alterações não foram salvas, deseja continuar?`}
        open={Boolean(controlCancelEditingModal)}
        onCancel={() => setControlCancelEditingModal(false)}
        onSave={() => {
          history.block(() => {});
          history.push(temporaryPathName);
        }}
      />
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <TopContent>
            <div>
              <a style={{ cursor: 'pointer' }} onClick={() => history.push('/meus-modelos')}>
                <ArrowBackIos />
              </a>
              <p>
                Modelo / <span className="color-text">IA - Texto Aprimorado</span>
              </p>
            </div>
          </TopContent>
          <div className="inputs">
            <TextField
              label="Identificação do modelo de Aprimoramento de Texto"
              className="descricao"
              variant="outlined"
              type="text"
              id="descricao"
              name="descricao"
              fullWidth
              value={formik.values.descricao}
              defaultValue={formik.values.descricao}
              onChange={(event) => {
                setIsSaved(false);
                formik.handleChange(event);
              }}
              error={formik.touched.descricao && Boolean(formik.errors.descricao)}
              helperText={formik.touched.descricao && formik.errors.descricao}
            />
            <TextField
              label="Modelo"
              className="modelo"
              variant="outlined"
              value={'IA - Texto Aprimorado'}
              disabled
              onChange={() => {}}
            />
          </div>
        </Content>
        <Container>
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Limpar o texto">
                <CleaningServicesIcon onClick={onClearText} style={{ color: '#3E4756' }} />
              </Tooltip>
              <Tooltip title="Download">
                <span
                  onClick={() =>
                    setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
                  }
                  style={{ cursor: 'pointer', color: '#3E4756' }}
                  className="material-icons-outlined"
                >
                  file_download
                </span>
              </Tooltip>
              <Tooltip
                title="Texto copiado"
                open={openToolTipCopyImage}
                onClose={() => setOpenToolTipCopyImage(false)}
                leaveDelay={1500}
              >
                <Tooltip title="Copiar texto">
                  <span
                    onClick={() =>
                      setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                    }
                    style={{ cursor: 'pointer', color: '#3E4756' }}
                    className="material-icons-outlined"
                  >
                    <ContentCopyOutlinedIcon style={{ color: '#3E4756' }} />
                  </span>
                </Tooltip>
              </Tooltip>
              <Tooltip title="Download PDF">
                <LocalPrintshopOutlinedIcon
                  onClick={() =>
                    setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
                  }
                  style={{ color: '#3E4756' }}
                />
              </Tooltip>
            </div>
          </div>
          <ContainerView id="ContainerViewMensagemSimples">
            <IAConfiguration setText={setXtexto} text={xtexto} />
            <Tooltip title="Faz a transformação do texto à esquerda em linguagem simples...">
              <Button
                endIcon={<ArrowRight></ArrowRight>}
                className={classes.buttonGreen}
                variant="outlined"
                style={{ maxHeight: '50px', alignSelf: 'center' }}
                onClick={onClickProcess}
              >
                Aprimorar
              </Button>
            </Tooltip>
            <IAView setText={setXchangedTexto} text={xChangedTexto} />
          </ContainerView>
        </Container>
      </form>
      <Buttons>
        <Button className={classes.buttonRed} variant="outlined" onClick={onCancelEditing}>
          Cancelar
        </Button>
        <Button onClick={onSave} className={classes.buttonGreen} variant="contained">
          Salvar
        </Button>
      </Buttons>
    </Container>
  );
};

export default IASampleLanguageModel;
