import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ArrowBackIos } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Button, TextField } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Input from '../../../components/EditTableTemplateTextField';
import ModalOptions from '../../../components/ModalOptions';
import QRCode from 'qrcode.react';
import { convertHTMLToImage, convertHTMLToPDF, copyImage } from '../../../helper/convertHTML';
import { createQrCode, getModelById, updateModel } from '../../../services/models';
import { Buttons, Container, Content, Download, ShowQRCode, TopContent, useStyles } from './styles';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { useAuth } from '../../../contexts/auth';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { createImageFile } from '../../../helper/convertHTML';

const QRCodeModel: React.FC = () => {
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const history = useHistory();

  const { user } = useAuth();

  const [idModel, setIdModel] = useState(history?.location?.state?.id);
  const clientTitularId: string = user?.tokenData?.ClientId;
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const [gerarQRCode, setGerarQRCode] = useState<boolean>(false);
  const [controlCancelEditingModal, setControlCancelEditingModal] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState(false);
  const [controlDownloadModal, setControlDownloadModal] = useState(null);
  const [temporaryPathName, setTemporaryPathName] = useState('');

  const validationSchema = Yup.object({
    descricao: Yup.string().required('Insira a descrição'),
    titulo: Yup.string(),
    url: Yup.string().required('Insira a URL'),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      descricao: '',
      titulo: '',
      url: '',
      html: '',
      json: '',
      clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      htmlImage: null,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      setGerarQRCode(true);
    },
  });

  useEffect(() => {
    // handle refresh page
    const beforeUnloadCallback = (event) => {
      if (!isSaved) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    if (!isSaved) {
      history.block((prompt) => {
        setTemporaryPathName(prompt.pathname);
        setControlCancelEditingModal(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      history.block(() => {});
    };
  }, [history, isSaved]);

  const onSave = async () => {
    if (!isEmpty(formik.errors)) {
      showAlertError('Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.');
      return;
    }

    showLoading(true);

    if (!idModel) {
      formik.values.id = uuidv4();
      formik.values.html = ref.current?.innerHTML;
      formik.values.htmlImage = await createImageFile(document.getElementById('#view'));
      createQrCode(formik.values)
        .then(() => {
          setIsSaved(true);
          showLoading(false);
          showAlertSuccess('QrCode criado com sucesso.');
          setIdModel(formik.values.id);
        })
        .catch((error) => {
          showLoading(false);
          if (error.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else if (error.status === 412) {
            showAlertError('Atingido o limite de modelos do plano.');
          } else {
            showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
          }
        });
    } else {
      formik.values.html = ref.current?.innerHTML;
      formik.values.htmlImage = await createImageFile(document.getElementById('#view'), 'scale(2.5,0.8)', 'top center');
      formik.values.json = JSON.stringify(ref.current.outerHTML);
      const response = await updateModel('ModeloQRCode', formik.values);
      if (response.status !== undefined) {
        if (response.status === 204) {
          showLoading(false);
          setIsSaved(true);
          showAlertSuccess('Modelo atualizado com sucesso.');
          setIdModel(idModel);
        } else {
          showLoading(false);
          if (response.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError('Ocorreu um erro ao atualizar os dados do qr code.');
          }
        }
      } else {
        showLoading(false);
        if (response.status === 401) {
          showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
        } else {
          showAlertError('Ocorreu um erro ao atualizar os dados do qr code.');
        }
      }
    }
  };

  const getQRCodebyId = async (modeloId: string) => {
    showLoading(true);

    const response = await getModelById('ModeloQRCode', clientTitularId, modeloId);

    if (response.status === 200) {
      let dataFormik = {
        id: response.data[0].id,
        descricao: response.data[0].descricao,
        titulo: response.data[0].titulo,
        url: response.data[0].url,
        html: ref.current?.outerHTML,
        json: JSON.stringify(ref.current?.outerHTML),
        clienteTitularId: OwnerUserId ? OwnerUserId : clientTitularId,
      };
      formik.setValues(dataFormik);
      setGerarQRCode(true);
      setIsSaved(true);
      showLoading(false);
    } else {
      showLoading(false);

      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
      }
    }
  };

  const onCancelEditing = () => history.push('/novo-modelo');

  useEffect(() => {
    if (idModel) {
      getQRCodebyId(idModel);
    }
  }, [idModel]);

  useEffect(() => {
    if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'IMG') {
      downloadImage();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'PDF') {
      downloadPDF();
    } else if (controlDownloadModal?.allowDownload && controlDownloadModal?.type === 'COPY') {
      copy();
    }
  }, [controlDownloadModal]);

  const downloadImage = () => {
    convertHTMLToImage(ref.current, formik.values.descricao);
  };

  const downloadPDF = () => {
    convertHTMLToPDF(ref.current, formik.values.descricao);
  };

  const copy = () => {
    copyImage(ref.current, formik.values.descricao);
    setOpenToolTipCopyImage(true);
  };

  return (
    <Container elevation={1}>
      <ModalOptions
        text={`As alterações não foram salvas, deseja continuar?`}
        open={Boolean(controlCancelEditingModal)}
        onCancel={() => setControlCancelEditingModal(false)}
        onSave={() => {
          history.block(() => {});
          history.push(temporaryPathName);
        }}
      />
      <ModalOptions
        showActions={false}
        text={`O modelo ainda não foi salvo. Por favor, salve o modelo para continuar`}
        open={controlDownloadModal?.allowDownload === false}
        onCancel={() => setControlDownloadModal(null)}
        onSave={() => setControlDownloadModal((prevState) => ({ ...prevState, allowDownload: true }))}
      />
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <TopContent>
            <div>
              <a style={{ cursor: 'pointer' }} onClick={() => history.push('/meus-modelos')}>
                <ArrowBackIos />
              </a>
              <p>
                Modelo / <span className="color-text">QR Code</span>
              </p>
            </div>
          </TopContent>
          <div className="inputs">
            <TextField
              label="Identificação do modelo de QR Code"
              className="descricao"
              variant="outlined"
              type="text"
              id="descricao"
              name="descricao"
              fullWidth
              value={formik.values.descricao}
              onChange={(event) => {
                setIsSaved(false);
                formik.handleChange(event);
              }}
              error={formik.touched.descricao && Boolean(formik.errors.descricao)}
              helperText={formik.touched.descricao && formik.errors.descricao}
            />
            <TextField
              label="Modelo"
              className="modelo"
              variant="outlined"
              value={'QrCode'}
              disabled
              onChange={() => {}}
            />
          </div>
        </Content>
        <Download>
          <div className="info-title">
            <p>Informe os dados do modelo</p>
            <div>
              <Tooltip title="Download imagem">
                <span
                  onClick={() =>
                    setControlDownloadModal((prevState) => ({ ...prevState, type: 'IMG', allowDownload: isSaved }))
                  }
                  style={{ cursor: 'pointer', color: '#3E4756' }}
                  className="material-icons-outlined"
                >
                  file_download
                </span>
              </Tooltip>

              <Tooltip
                title="Imagem copiada"
                open={openToolTipCopyImage}
                onClose={() => setOpenToolTipCopyImage(false)}
                leaveDelay={1500}
              >
                <Tooltip title="Copiar Imagem" style={{ cursor: 'pointer' }}>
                  <ContentCopyOutlinedIcon
                    onClick={() =>
                      setControlDownloadModal((prevState) => ({ ...prevState, type: 'COPY', allowDownload: isSaved }))
                    }
                  />
                </Tooltip>
              </Tooltip>
              <Tooltip title="Download PDF" style={{ cursor: 'pointer' }}>
                <LocalPrintshopOutlinedIcon
                  onClick={() =>
                    setControlDownloadModal((prevState) => ({ ...prevState, type: 'PDF', allowDownload: isSaved }))
                  }
                />
              </Tooltip>
            </div>
          </div>
          <div className="timeline-input">
            <div className="inputs">
              <div className="qr-title">
                <Input
                  label="Título do QR Code (opcional)"
                  name="titulo"
                  type="text"
                  size="normal"
                  defaultValue={formik.values.titulo}
                  onChange={(value) => {
                    setIsSaved(false);
                    formik.setFieldValue('titulo', value);
                  }}
                  error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                  helperText={formik.touched.titulo && formik.errors.titulo}
                />
              </div>
              <div className="info-url">
                <Input
                  label="Informe a URL"
                  name="url"
                  type="text"
                  size="normal"
                  defaultValue={formik.values.url}
                  onChange={(value) => {
                    setIsSaved(false);
                    formik.setFieldValue('url', value);
                  }}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                />
              </div>
            </div>
            <p className="generateQrCode" onClick={formik.submitForm}>
              <QrCodeIcon className="qr-icon" /> Gerar QR Code
            </p>
          </div>
        </Download>
      </form>
      {gerarQRCode ? (
        <ShowQRCode>
          <div id="#view" style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              ref={ref}
              style={{
                padding: 20,
                display: 'flex',
                minWidth: 600,
                justifyContent: 'center',
                flexDirection: 'column',
                background: '#fff',
                wordBreak: 'break-all',
              }}
            >
              <div className="qrcode-title">
                <p style={{ wordBreak: 'break-all' }}>{formik.values.titulo}</p>
              </div>
              <div className="qrcode-position">
                <QRCode value={formik.values.url} />
              </div>
            </div>
          </div>
          <Buttons>
            <Button className={classes.buttonRed} variant="outlined" onClick={onCancelEditing}>
              Cancelar
            </Button>
            <Button className={classes.buttonGreen} variant="contained" type="submit" onClick={onSave}>
              Salvar
            </Button>
          </Buttons>
        </ShowQRCode>
      ) : (
        ''
      )}
    </Container>
  );
};

export default QRCodeModel;
