import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import { Button, Checkbox, Paper, Switch, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Access, Buttons, Container, Content, GreenText, NameInput, RedText, TopContent, useStyles } from './styles';
import {
  IAccessMenus,
  IAccessProfiles,
  IPermissionAccessProfile,
} from '../../../../interfaces/internalUserAndAccessProfile';
import {
  getAccessProfileById,
  sendAccessUserProfile,
  updateAccessUserProfileById,
} from '../../../../services/internalUsersAndProfileAccess';
import { getPermissions } from '../../../../services/permissions';
import {
  formatAccessProfileCheckboxesByUserPermissions,
  formatAllAccessProfileCheckboxesPermissions,
} from '../../../../helper/profileAccessHelper';
import { useAlert } from '../../../../contexts/alert';
import { History } from 'history';
import { useLoading } from '../../../../contexts/loading';
import { useAuth } from '../../../../contexts/auth';

const AccessProfile: React.FC = () => {
  const classes = useStyles();
  const history: History = useHistory();
  const [profile, setAccessProfile] = useState<IAccessProfiles>();
  const [access, setAccess] = useState<Array<IPermissionAccessProfile> | []>([]);
  const { showAlertSuccess, showAlertError } = useAlert();
  const { showLoading } = useLoading();
  const isEditing = history.location.pathname.includes('edicao');
  const id = history.location.pathname.split('/')[2];
  const { permissions } = useAuth();

  useEffect(() => {
    showLoading(true);
    getPermissions()
      .then((response) => {
        const perm: Array<IPermissionAccessProfile> = formatAllAccessProfileCheckboxesPermissions(response.data);
        setAccess(perm);
        showLoading(false);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar as permissões disponíveis.');
        showLoading(false);
      });
  }, []);

  useEffect(() => {
    if (access.length > 0) {
      if (isEditing) {
        showLoading(true);
        getAccessProfileById(id)
          .then((response: any) => {
            response.data.value[0].rolePermissions = formatAccessProfileCheckboxesByUserPermissions(
              response.data.value[0].rolePermissions,
              access
            );
            setAccessProfile(response?.data?.value[0]);
            showLoading(false);
          })
          .catch((error) => {
            showAlertError(typeof error == 'string' ? error : 'Não foi possível buscar as permissões deste usuário');
            showLoading(false);
          });
      } else {
        const obj = {
          name: '',
          active: true,
          rolePermissions: formatAccessProfileCheckboxesByUserPermissions([], [...access]),
        };
        setAccessProfile(obj);
      }
    }
  }, [access]);

  function handleSumbit() {
    let profileFormatted: IAccessProfiles = profile;
    const permissionIds: Array<string> = [];
    profileFormatted?.rolePermissions?.map((permission: IAccessMenus) => {
      const role = ['view', 'delete', 'edit', 'insert'];
      role.map((a) => {
        if (permission[a].id) {
          permissionIds.push(permission[a].id);
        }
      });
    });
    profileFormatted.active = true;
    profileFormatted.permissionIds = permissionIds;
    if (permissionIds.length <= 0) {
      showAlertError('Selecione ao menos um acesso');
      return;
    }

    if (isEditing) {
      showLoading(true);
      updateAccessUserProfileById(profileFormatted)
        .then((response) => {
          showAlertSuccess('Perfil editado com sucesso');
          showLoading(false);
          history.push({ pathname: '/usuarios-internos', search: 'AccessProfile' });
        })
        .catch((error) => {
          showLoading(false);
          showAlertError('Ocorreu um erro ao tentar salvar o perfil');
        });
    } else {
      showLoading(true);
      sendAccessUserProfile(profileFormatted)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Perfil cadastrado com sucesso');
          history.push({ pathname: '/usuarios-internos', search: 'AccessProfile' });
        })
        .catch((error) => {
          showAlertError(error.data[0]?.ErrorMessage || 'Ocorreu um erro ao tentar criar o perfil');
          showLoading(false);
        });
    }
  }

  function changeAccess(index: number, name: string, attribute: string, checked: boolean) {
    let aux: Array<IAccessMenus> = JSON.parse(JSON.stringify(profile?.rolePermissions));
    let findProfileIdByValue = [...access].find((p: IPermissionAccessProfile) => p[attribute].value === name);

    if (!checked) {
      aux[index][attribute].id = null;
      aux[index][attribute].value = findProfileIdByValue[attribute].value;
    } else {
      aux[index][attribute] = findProfileIdByValue[attribute];
    }

    setAccessProfile((prevState: any) => ({ ...prevState, rolePermissions: aux }));
  }

  function changeAccessAllByAttribute(checked: boolean, attribute: string) {
    let aux: Array<IAccessMenus> = JSON.parse(JSON.stringify(profile?.rolePermissions));
    if (!checked) {
      aux.map((a: IAccessMenus) => {
        let findProfileIdByValue = [...access].find(
          (p: IPermissionAccessProfile) => p[attribute].value === a[attribute].value
        );
        a[attribute] = {
          id: null,
          value: findProfileIdByValue[attribute].value,
        };
      });
    } else {
      aux.map((a: IAccessMenus, index: number) => {
        a[attribute] = access[index][attribute];
      });
    }
    setAccessProfile((prevState: any) => ({ ...prevState, rolePermissions: aux }));
  }

  const AreAllChecked = (attribute: string) => {
    let areAllAttributesTrue = true;
    profile?.rolePermissions?.map((menu: any) => {
      if (!menu[attribute].id) {
        areAllAttributesTrue = false;
      }
    });
    return areAllAttributesTrue;
  };

  return (
    <Container>
      <Paper elevation={3}>
        <Content>
          <TopContent>
            <div>
              <Link to={{ pathname: '/usuarios-internos', search: 'AccessProfile' }}>
                <ArrowBackIos />
              </Link>
              <p>
                Perfis de acesso / <p className="color-text">{isEditing ? 'Editar' : 'Cadastrar'}</p>
              </p>
            </div>
            <span>
              {permissions?.includes('CanDeleteRoles') ? (
                <>
                  {isEditing ? profile?.active ? <GreenText>Ativo</GreenText> : <RedText>Inativo</RedText> : 'Ativo'}
                  <Switch
                    disabled={isEditing ? false : true}
                    className={!isEditing ? classes.disabled : ''}
                    checked={isEditing ? Boolean(profile?.active) : true}
                    value={Boolean(profile?.active)}
                    onChange={(event) => {
                      setAccessProfile((prevState: any) => ({ ...prevState, active: event.target.checked }));
                    }}
                    classes={
                      isEditing
                        ? {
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                            colorPrimary: classes.switch_primary,
                          }
                        : {}
                    }
                  />
                </>
              ) : (
                ''
              )}
            </span>
          </TopContent>
          <NameInput>
            <TextField
              fullWidth
              onChange={(event) => {
                setAccessProfile((prevState: any) => ({ ...prevState, name: event.target.value }));
              }}
              value={profile?.name || ''}
              label="Nome"
              variant="outlined"
            />
          </NameInput>
          <Access>
            <thead>
              <tr>
                <th>
                  <strong className="espacamento">Acessos</strong>
                </th>
                <th>
                  <strong>
                    <Checkbox
                      color="primary"
                      checked={AreAllChecked('view')}
                      onChange={(event) => changeAccessAllByAttribute(event.target.checked, 'view')}
                    />
                    Visualizar
                  </strong>
                </th>
                <th>
                  <strong>
                    <Checkbox
                      color="primary"
                      checked={AreAllChecked('insert')}
                      onChange={(event) => changeAccessAllByAttribute(event.target.checked, 'insert')}
                    />
                    Inserir
                  </strong>
                </th>
                <th>
                  <strong>
                    <Checkbox
                      color="primary"
                      checked={AreAllChecked('edit')}
                      onChange={(event) => changeAccessAllByAttribute(event.target.checked, 'edit')}
                    />
                    Alterar
                  </strong>
                </th>
                <th>
                  <strong>
                    <Checkbox
                      color="primary"
                      checked={AreAllChecked('delete')}
                      onChange={(event) => changeAccessAllByAttribute(event.target.checked, 'delete')}
                    />
                    Deletar
                  </strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {profile?.rolePermissions?.map((menu, index) => (
                <tr key={index}>
                  <td className="espacamento">{menu.name}</td>
                  <td>
                    <Checkbox
                      color="primary"
                      checked={Boolean(menu.view.id)}
                      onChange={(event) => {
                        const attribute = 'view';
                        changeAccess(index, menu[attribute]?.value, attribute, event.target.checked);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox
                      color="primary"
                      checked={Boolean(menu.insert.id)}
                      onChange={(event) => {
                        const attribute = 'insert';
                        changeAccess(index, menu[attribute]?.value, attribute, event.target.checked);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox
                      color="primary"
                      checked={Boolean(menu.edit.id)}
                      onChange={(event) => {
                        const attribute = 'edit';
                        changeAccess(index, menu[attribute]?.value, attribute, event.target.checked);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox
                      color="primary"
                      checked={Boolean(menu.delete.id)}
                      onChange={(event) => {
                        const attribute = 'delete';
                        changeAccess(index, menu[attribute]?.value, attribute, event.target.checked);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Access>
          <Buttons>
            <Button
              className={classes.buttonRed}
              variant="outlined"
              onClick={() => {
                history.push({ pathname: '/usuarios-internos', search: 'AccessProfile' });
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleSumbit();
              }}
              className={classes.buttonGreen}
              variant="contained"
              disabled={!permissions?.includes('CanUpdateRoles') ? true : false}
            >
              Salvar
            </Button>
          </Buttons>
        </Content>
      </Paper>
    </Container>
  );
};

export default AccessProfile;
