import styled from 'styled-components';

export const ContentModal = styled.form`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: none;
  border-radius: 5px;

  div {
    display: flex;
    justify-content: center;
  }
`;
