import React from 'react';
import { Container, TopContent } from './styles';
import FlowChartGif from '../../assets/FlowChart.gif';
import ClearIcon from '@mui/icons-material/Clear';

const ModalGifFlowChart: React.FC = (props: any) => {
  return (
    <Container elevation={1}>
      <TopContent>
        <h2>Use o mouse para movimentar os elementos do fluxograma e posicioná-los como preferir</h2>
        <ClearIcon className="close" onClick={props?.close} />
      </TopContent>
      <img src={FlowChartGif} />
    </Container>
  );
};

export default ModalGifFlowChart;
