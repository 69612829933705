import React, { FormEvent, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  getPlanById,
  getPlanMonthlyById,
  getPlanQuarterlyById,
  getPlanYearlyById,
  updateOrCreateNewPlan,
} from '../../../services/plans';
// import DatePicker from '@mui/lab/DatePicker';
import { Link, useHistory } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import { Buttons, Container, Content, GreenText, Options, RedText, TopContent, useStyles } from './styles';
import { useFormik } from 'formik';
import { useAlert } from '../../../contexts/alert';
import { useLoading } from '../../../contexts/loading';
import { initialValues, validationSchema } from './validation';
import { isValid } from 'date-fns';
import { getAllRecurrenceTypeId } from '../../../services/recurrenceType';
import { useAuth } from '../../../contexts/auth';

const Planos: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const isEditing = history.location.pathname.includes('edicao');
  const id = history.location.pathname.replace('/planos/edicao-plano/', '');
  const { showAlertError, showAlertSuccess } = useAlert();
  const { showLoading } = useLoading();
  const [recTypes, setRecTypes] = useState<any>([]);
  const { permissions } = useAuth();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    getAllRecurrenceTypeId().then((response) => {
      setRecTypes(response.data.value);
    });

    if (isEditing) {
      showLoading(true);
      getPlanById(id)
        .then((response) => {
          formik.setFieldValue('id', response?.data?.value[0]?.id);
          formik.setFieldValue('name', response?.data?.value[0]?.name);
          formik.setFieldValue('status', response?.data?.value[0]?.status);
          formik.setFieldValue('initialDate', response?.data?.value[0]?.initialDate);
          formik.setFieldValue('endDate', response?.data?.value[0]?.endDate);
          formik.setFieldValue('undetermined', response?.data?.value[0]?.undetermined);
          formik.setFieldValue('userLimit', response?.data?.value[0]?.userLimit);
          formik.setFieldValue('userIlimited', response?.data?.value[0]?.userIlimited);
          formik.setFieldValue('modelLimit', response?.data?.value[0]?.modelLimit);
          formik.setFieldValue('modelIlimited', response?.data?.value[0]?.modelIlimited);
          formik.setFieldValue('recurrenceMountTypeId', response?.data?.value[0]?.recurrenceMountTypeId);
          formik.setFieldValue('readjustmentValue', response?.data?.value[0]?.readjustmentValue);
          formik.setFieldValue('readjustmentDays', response?.data?.value[0]?.readjustmentDays);
          formik.setFieldValue('automaticRenewal', response?.data?.value[0]?.automaticRenewal);
          formik.setFieldValue('trialDays', response?.data?.value[0]?.trialDays);
          formik.setFieldValue('pagarMEPlanId', response?.data?.value[0]?.pagarMEPlanId);

          getMonthlyPrice();
          getQuarterlyPrice();
          getYearlyPrice();
          showLoading(false);
        })
        .catch((error) => {
          showLoading(false);

          showAlertError(error?.response?.data || 'Ocorreu um erro ao buscar os dados do plano');
        });
    }
  }, []);

  const getMonthlyPrice = () => {
    getPlanMonthlyById(id)
      .then((response) => {
        formik.setFieldValue('recurrencePlans[0].id', response?.data?.value[0]?.recurrencePlans[0]?.id);
        formik.setFieldValue('recurrencePlans[0].price', response?.data?.value[0]?.recurrencePlans[0]?.price);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar o preço mensal.');
      });
  };

  const getQuarterlyPrice = () => {
    getPlanQuarterlyById(id)
      .then((response) => {
        formik.setFieldValue('recurrencePlans[1].id', response?.data?.value[0]?.recurrencePlans[0]?.id);
        formik.setFieldValue('recurrencePlans[1].price', response?.data?.value[0]?.recurrencePlans[0]?.price);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar o preço trimestral.');
      });
  };

  const getYearlyPrice = () => {
    getPlanYearlyById(id)
      .then((response) => {
        formik.setFieldValue('recurrencePlans[2].id', response?.data?.value[0]?.recurrencePlans[0]?.id);
        formik.setFieldValue('recurrencePlans[2].price', response?.data?.value[0]?.recurrencePlans[0]?.price);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar o preço anual.');
      });
  };

  function handleSubmit(plano: any) {
    showLoading(true);

    updateOrCreateNewPlan(plano, recTypes)
      .then((response) => {
        showLoading(false);
        showAlertSuccess(plano.id ? 'Dados do plano editados com sucesso.' : 'Dados do plano criados com sucesso.');
        history.push('/planos');
      })
      .catch((error) => {
        showLoading(false);
        showAlertError('Ocorreu um erro ao enviar os dados do plano');
      });
  }

  function handleDate(name: string, value: any) {
    formik.setFieldValue(name, value);
  }

  return (
    <Container>
      <Paper elevation={3}>
        <Content>
          <TopContent>
            <div>
              <Link to={'/planos'}>
                <ArrowBackIos />
              </Link>
              <p>
                Plano / <p className="color-text">{isEditing ? 'Editar' : 'Cadastrar'}</p>
              </p>
            </div>
            <span>
              {permissions?.includes('CanDeletePlans') ? (
                <>
                  {isEditing ? (
                    formik.values.status ? (
                      <GreenText>Ativo</GreenText>
                    ) : (
                      <RedText>Inativo</RedText>
                    )
                  ) : (
                    'Ativo'
                  )}
                  <Switch
                    disabled={isEditing ? false : true}
                    value={formik.values.status}
                    className={!isEditing ? classes.disabled : ''}
                    checked={formik.values.status}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      formik.setFieldValue('status', checked);
                    }}
                    classes={
                      isEditing
                        ? {
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                            colorPrimary: classes.switch_primary,
                          }
                        : {}
                    }
                  />
                </>
              ) : (
                ''
              )}
            </span>
          </TopContent>
          <form
            onSubmit={(event: FormEvent<HTMLFormElement>) => {
              formik.handleSubmit();
              event.preventDefault();
            }}
          >
            <Options>
              <FormControl
                fullWidth
                className="nome"
                variant="outlined"
                error={formik.touched.name && Boolean(formik.errors.name)}
              >
                <TextField
                  name="name"
                  fullWidth
                  label="Nome"
                  variant="outlined"
                  className="name"
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  onChange={formik.handleChange}
                />
                <FormHelperText>{formik.touched.name && formik.errors.name}</FormHelperText>
              </FormControl>
              <div className="fantasma"></div>
              <FormControl
                fullWidth
                className="data-inicio"
                variant="outlined"
                error={formik.touched.initialDate && Boolean(formik.errors.initialDate)}
              >
                <DatePicker
                  label="Data de início"
                  value={formik.values.initialDate == null ? null : formik.values.initialDate}
                  autoOk
                  placeholder="dd/mm/aaaa"
                  onChange={(date: Date) => {
                    if (isValid(date)) {
                      handleDate('initialDate', new Date(date.setHours(0, 0, 0)).toISOString());
                    }
                  }}
                  {...(!isEditing && { minDate: new Date() })}
                  inputVariant="outlined"
                  variant="inline"
                  error={formik.touched.initialDate && Boolean(formik.errors.initialDate)}
                  format="dd/MM/yyyy"
                />
                <FormHelperText>{formik.touched.initialDate && formik.errors.initialDate}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                className="data-fim"
                variant="outlined"
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              >
                <DatePicker
                  fullWidth
                  autoOk
                  disabled={formik.values.undetermined}
                  name="endDate"
                  error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  label="Data de fim"
                  format="dd/MM/yyyy"
                  minDate={formik.values.initialDate || new Date()}
                  placeholder="dd/mm/aaaa"
                  value={formik.values.endDate}
                  variant="inline"
                  inputVariant="outlined"
                  onChange={(date: Date) => {
                    if (isValid(date)) {
                      handleDate('endDate', new Date(date.setHours(0, 0, 0)).toISOString());
                    }
                  }}
                />
                <FormHelperText>{formik.touched.endDate && formik.errors.endDate}</FormHelperText>
              </FormControl>
              <label className="indeterminado">
                <Checkbox
                  name="undetermined"
                  color="primary"
                  checked={formik.values.undetermined}
                  onChange={(e) => {
                    formik.setFieldValue('endDate', null);
                    formik.handleChange(e);
                  }}
                />
                Indeterminado
              </label>
              <FormControl
                fullWidth
                className="limite-usuarios"
                variant="outlined"
                error={formik.touched.userLimit && Boolean(formik.errors.userLimit)}
              >
                <NumberFormat
                  value={formik.values.userLimit}
                  format="##"
                  disabled={formik.values.userIlimited}
                  customInput={TextField}
                  label="Limite de usuário"
                  allowNegative={false}
                  fixedDecimalScale={true}
                  name="userLimit"
                  variant="outlined"
                  fullWidth
                  onValueChange={({ floatValue }) => formik.setFieldValue('userLimit', floatValue)}
                  error={formik.touched.userLimit && Boolean(formik.errors.userLimit)}
                />
                <FormHelperText>{formik.touched.userLimit && formik.errors.userLimit}</FormHelperText>
              </FormControl>
              <label className="ilimitado-usuarios">
                <Checkbox
                  name="userIlimited"
                  color="primary"
                  checked={formik.values.userIlimited}
                  onChange={(e) => {
                    formik.setFieldValue('userLimit', null);
                    formik.handleChange(e);
                  }}
                />
                Ilimitado
              </label>
              <FormControl
                fullWidth
                className="limite-modelos"
                variant="outlined"
                error={formik.touched.modelLimit && Boolean(formik.errors.modelLimit)}
              >
                <NumberFormat
                  value={formik.values.modelLimit}
                  format="##"
                  disabled={formik.values.modelIlimited}
                  customInput={TextField}
                  label="Limite de modelos salvos por usuário"
                  allowNegative={false}
                  fixedDecimalScale={true}
                  name="modelLimit"
                  variant="outlined"
                  fullWidth
                  onValueChange={({ floatValue }) => formik.setFieldValue('modelLimit', floatValue)}
                  error={formik.touched.modelLimit && Boolean(formik.errors.modelLimit)}
                />
                <FormHelperText>{formik.touched.modelLimit && formik.errors.modelLimit}</FormHelperText>
              </FormControl>

              <label className="ilimitado-modelos">
                <Checkbox
                  color="primary"
                  checked={formik.values.modelIlimited}
                  name="modelIlimited"
                  onChange={(e) => {
                    formik.setFieldValue('modelLimit', null);
                    formik.handleChange(e);
                  }}
                />
                Ilimitado
              </label>
              <FormControl fullWidth className="mensal" variant="outlined">
                <NumberFormat
                  value={formik.values?.recurrencePlans[0]?.price}
                  customInput={TextField}
                  label="Valor mensal"
                  allowNegative={false}
                  fixedDecimalScale={true}
                  thousandSeparator="."
                  decimalSeparator=","
                  name="monthlyPrice"
                  decimalScale={2}
                  variant="outlined"
                  fullWidth
                  onValueChange={({ floatValue }) => formik.setFieldValue('recurrencePlans[0].price', floatValue)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl fullWidth className="trimestral" variant="outlined">
                <NumberFormat
                  customInput={TextField}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  variant="outlined"
                  name="quarterlyPrice"
                  fullWidth
                  label="Valor trimestral"
                  value={formik.values?.recurrencePlans[1]?.price}
                  onValueChange={({ floatValue }) => formik.setFieldValue('recurrencePlans[1].price', floatValue)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl fullWidth className="anual" variant="outlined">
                <NumberFormat
                  customInput={TextField}
                  fixedDecimalScale={true}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fullWidth
                  label="Valor Anual"
                  variant="outlined"
                  name="yearlyPrice"
                  value={formik.values?.recurrencePlans[2]?.price}
                  onValueChange={({ floatValue }) => formik.setFieldValue('recurrencePlans[2].price', floatValue)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                />
              </FormControl>

              <FormControl
                fullWidth
                className="reajuste"
                variant="outlined"
                error={formik.touched.readjustmentValue && Boolean(formik.errors.readjustmentValue)}
              >
                <NumberFormat
                  value={formik.values.readjustmentValue}
                  format="###"
                  customInput={TextField}
                  label="Valor de reajuste"
                  allowNegative={false}
                  fixedDecimalScale={true}
                  name="readjustmentValue"
                  variant="outlined"
                  fullWidth
                  onValueChange={({ floatValue }) => formik.setFieldValue('readjustmentValue', floatValue)}
                  error={formik.touched.readjustmentValue && Boolean(formik.errors.readjustmentValue)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
                <FormHelperText>{formik.touched.readjustmentValue && formik.errors.readjustmentValue}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                className="tempoReajuste"
                variant="outlined"
                error={formik.touched.readjustmentDays && Boolean(formik.errors.readjustmentDays)}
              >
                <NumberFormat
                  value={formik.values.readjustmentDays}
                  format="###"
                  disabled={!formik.values.readjustmentValue}
                  customInput={TextField}
                  label="Tempo para reajuste"
                  allowNegative={false}
                  fixedDecimalScale={true}
                  name="readjustmentDays"
                  variant="outlined"
                  fullWidth
                  onValueChange={({ floatValue }) => formik.setFieldValue('readjustmentDays', floatValue)}
                  error={formik.touched.readjustmentDays && Boolean(formik.errors.readjustmentDays)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Dias</InputAdornment>,
                  }}
                />
                <FormHelperText>{formik.touched.readjustmentDays && formik.errors.readjustmentDays}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                className="dias"
                variant="outlined"
                error={formik.touched.trialDays && Boolean(formik.errors.trialDays)}
              >
                <InputLabel id="dias-trial">Dias trial</InputLabel>
                <Select
                  name="trialDays"
                  id="dias-trial"
                  label="Dias trial"
                  value={formik.values.trialDays}
                  onChange={formik.handleChange}
                  error={formik.touched.trialDays && Boolean(formik.errors.trialDays)}
                >
                  <MenuItem value="">
                    <em>Selecione um valor</em>
                  </MenuItem>
                  {new Array(31).fill('', 1, 32).map((p, i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.trialDays && formik.errors.trialDays}</FormHelperText>
              </FormControl>
              <div className="fantasma-dias"></div>
            </Options>
            <Buttons>
              <Button
                className={classes.buttonRed}
                variant="outlined"
                onClick={() => {
                  history.push('/planos');
                }}
              >
                Cancelar
              </Button>
              <Button
                className={classes.buttonGreen}
                variant="contained"
                type="submit"
                disabled={!permissions?.includes('CanUpdatePlans') ? true : false}
              >
                Salvar
              </Button>
            </Buttons>
          </form>
        </Content>
      </Paper>
    </Container>
  );
};

export default Planos;
