import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)`
  padding: 20px;
  width: 100%;
  min-width: 670px;
  min-height: 490px;
  img {
    min-width: 630px;
    max-height: 370px;
  }
`;

export const Content = styled.div``;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  h2 {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 30px;
    color: #3e4756;
    font-size: 20px;
  }

  .close {
    color: #3e4756;
    display: flex;
    /* flex: end; */
  }
`;
