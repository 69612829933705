import axios from 'axios';

let headers = {};

const api = axios.create({
  //baseURL: 'https://api-irisvl-homolog.azurewebsites.net/api',
  baseURL: 'https://api-irisvl-prod.azurewebsites.net/api',
  //baseURL: 'https://back-vls-dev.azurewebsites.net/api',
  //baseURL: 'https://vls-back-homolog.azurewebsites.net/api',
  //baseURL: 'https://localhost:49933/api',
  withCredentials: true,
  headers: headers,
});

api.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('user') || '{}';

    if (user !== 'null') config.headers.Authorization = `Bearer ${JSON.parse(user).accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
