import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 450px;
  min-height: 430px;
  max-height: 500px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;

  p.title {
    font-weight: 500;
    font-size: 24px;
    color: #3e4756;
    margin-top: 50px;
    margin-bottom: 0;
  }

  svg {
    cursor: pointer;
  }
  .trocar-conta {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 40px;
    span + span {
      white-space: nowrap;
      color: #3fced2;
      cursor: pointer;
    }
  }

  .acessos-usuario {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      white-space: nowrap;
    }
    a {
      color: #3fced2;
      text-decoration: none;
      font-size: 16px;
      white-space: nowrap;
      margin-right: 20px;
    }

    button span {
      margin: 40px;
    }
  }

  .MuiTextField-root {
    margin: 32px 0;
  }
  button {
    font-weight: 400;
    height: 53px;
    background-color: #3fced2;
    color: #fff;
    &:hover {
      background-color: #3fced2;
    }
  }

  .btnLogin {
    background: transparent linear-gradient(90deg, #3fced2 0%, #e826b3 100%) 0% 0% no-repeat padding-box;
    color: #fff;
  }

  .divRegistro {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 18px;
    span {
      white-space: nowrap;
    }
    a {
      color: #3fced2;
      font-weight: 500;
      text-decoration: none;
      font-size: 16px;
      white-space: nowrap;
    }
  }
`;
