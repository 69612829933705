import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid #e6e6e6;

  td {
    border: 1px solid #e6e6e6;
  }

  tr {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  td {
    border-left: none;
    border-top: none;
    border-bottom: none;
  }
  table {
    border: 1px solid #cccccc;
    border-top: none;
    border-collapse: collapse;
    padding: 20px;
    width: 100%;
    border-left: none;
    border-right: none;
    tr {
      padding: 20px;
    }
    .timeline-title {
      max-width: 390px;
      padding: 20px;
    }
  }
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    margin: 0;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }

    > div {
      padding: 20px;
      width: 100%;
    }

    div:nth-child(1) {
      display: flex;
      justify-content: space-between;
    }
  }

  > div + div {
    max-height: calc(100vh - 310px);
    padding-left: 20px;
    overflow: auto;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Insert = styled.div`
  all: unset;
  color: #1976d2;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 3px;
  }
`;
