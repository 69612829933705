import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 40px; */
  border: none;
`;

export const Content = styled.div`
  /* width: 450px;
  min-height: 400px;
  max-height: 500px;
  max-width: 100vw; */
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiButton-root {
    display: flex;
    align-self: flex-start;
    text-transform: capitalize;
    font-size: 16px;
    padding: 0px;
    font-weight: normal;
    color: #3fced2;
  }
  .btnRecuperar {
    background-color: #3fced2;
  }
  .btnRecuperar:hover {
    background-color: #3fced2;
  }

  div.go-back {
    display: flex;
    align-self: flex-end;
    padding-top: 20px;
  }
  div.email {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 20px;
  }

  h2 {
    color: #3e4756;
    text-align: left;
    margin: 0px;
    font-size: 32px;
  }

  p {
    color: #3e4756;
  }

  .MuiButton-contained {
    height: 53px;
    border-radius: 5px;
    text-transform: uppercase;
    color: white;
  }

  .MuiTextField-root {
    width: 350px;
    height: 53px;
    margin-bottom: 50px;
  }
`;
