/* eslint-disable @typescript-eslint/no-loop-func */
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { mergePDFHandler } from './mergerPDF';
import html2pdf from 'html2pdf.js';
import domtoimage from 'dom-to-image';

export const convertHTMLToImage = async (html, fileName) => {
  try {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);

    if (isSafari) {
      navigator.clipboard.write([
        new ClipboardItem({
          'image/png': snapshotCreator(html),
        }),
      ]);
    }
    html2canvas(html)
      .then(function (canvas) {
        if (!isSafari) {
          canvas.toBlob(async (blob) => {
            navigator.clipboard.write([new ClipboardItem({ 'image/png': await snapshotCreator(html) })]);
          });
        }
        var imageURL = canvas.toDataURL('image/png');
        let a = document.createElement('a');
        a.href = imageURL;
        a.download = `${fileName}.png`;
        a.click();
      })
      .catch((err) => {
        console.log('TRY', err);
        return err;
      });
    return await Promise.resolve();
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const downLoadText = async (s, fileName) => {
  try {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(s));
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    element.click();
    return await Promise.resolve();
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const copyImage = async (html, filename, imageWidth = -1, imageHeight = -1) => {
  try {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);

    if (isSafari) {
      navigator.clipboard.write([
        new ClipboardItem({
          'image/png': snapshotCreator(html),
        }),
      ]);
    }
    html2canvas(html)
      .then(function (canvas) {
        if (!isSafari) {
          const iWidth = typeof imageWidth != 'number' ? -1 : imageWidth;
          const iHeight = typeof imageHeight != 'number' ? -1 : imageHeight;
          canvas.toBlob(async (blob) => {
            navigator.clipboard.write([
              new ClipboardItem({
                'image/png': await (iWidth > 0 || iHeight > 0
                  ? resizedSnapshotCreator(html, iWidth, iHeight)
                  : snapshotCreator(html)),
              }),
            ]);
          });
        }
      })
      .catch((err) => {
        console.log('TRY', err);
        return err;
      });
    return await Promise.resolve();
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const copyText = async (s) => {
  try {
    navigator.clipboard.writeText(s);
    return await Promise.resolve();
  } catch (err) {
    console.log(err);
    return err;
  }
};

const snapshotCreator = async (element) => {
  const response = await domtoimage.toBlob(element, { style: { color: '#fff' } });
  return response;
};

export const createImageFile = async (element, alternateTransform = null, alternateTransformOrigin = null) => {
  const response = await domtoimage.toPng(element, {
    style: {
      color: '#fff',
      transform: alternateTransform == null ? 'scale(1.2,1.2)' : alternateTransform,
      transformOrigin: alternateTransformOrigin == null ? 'top left' : alternateTransformOrigin,
      marginTop: '25px',
    },
  });
  return response;
};

const resizedSnapshotCreator = async (element, iWidth, iHeight) => {
  const w = iWidth > 0 ? iWidth : element.offsetWidth;
  const h = iHeight > 0 ? iHeight : element.offsetHeight;
  const response = await domtoimage.toBlob(element, { style: { color: '#fff' }, width: w, height: h });
  return response;
};

export const convertHTMLToPDF = (html, fileName, orientation = 'p') => {
  const scaleBase = orientation === 'p' ? 434 : 618;
  const scale = scaleBase / html?.getBoundingClientRect().width;

  const pdf = new jsPDF({ unit: 'px', format: 'a4', userUnit: 'px', orientation: orientation });

  pdf
    .html(html, {
      html2canvas: {
        scale,
      },
      x: 5,
      y: 5,
    })
    .then(() => {
      pdf.save(`${fileName}.pdf`);
    });
};

export const convertTextToPDF = (s, fileName, orientation = 'p') => {
  const pdf = new jsPDF({ format: 'a4', orientation });
  pdf.setFont('Times New Roman');
  pdf.setFontSize(12);
  pdf.text(s, 10, 10, { maxWidth: 180 });
  pdf.save(`${fileName}.pdf`);
};

export const convertHTMLTimeLineHasBreakPageToPDF = async (html, fileName, orientation = 'p', defaultScaleBase = 0) => {
  let scale = 1;

  const blobs = [];

  const items = html.getElementsByClassName('page');

  for (const item of items) {
    let scaleHeight = 1;
    let scaleWidth = 1;

    if (orientation == 'p') {
      scaleHeight = 590 / item?.getBoundingClientRect().height;
      scaleWidth = 400 / item?.getBoundingClientRect().width;
    } else {
      scaleWidth = 610 / item?.getBoundingClientRect().width;
    }

    let newScale = scaleHeight < scaleWidth ? scaleHeight : scaleWidth;
    scale = newScale < scale ? newScale : scale;
  }

  for (const item of items) {
    let doc = new jsPDF({
      orientation,
      format: 'a4',
      userUnit: 'px',
      unit: 'px',
    });
    await doc
      .html(item, {
        html2canvas: {
          scale,
        },
        x: 5,
        y: 5,
      })
      .then(() => {
        blobs.push(doc.output('blob'));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  mergePDFHandler(blobs, fileName);
};

export const convertHTMLTimeLineHasNoBreakPageToPDF = async (html, filename, orientation = 'p') => {
  const width = orientation == 'p' ? html?.getBoundingClientRect().width * 1.5 : html?.getBoundingClientRect().width;

  var opt = {
    margin: 0.2,
    filename,
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, width },
    jsPDF: { unit: 'in', format: 'letter', orientation },
  };

  html2pdf().set(opt).from(html).save();
};

export const downloadPDFFlowChart = async (html, fileName, orientation = 'p') => {
  let scaleWidth = (orientation == 'l' ? 297 : 210) / html?.getBoundingClientRect().width;
  let scaleHeight = (orientation == 'l' ? 210 : 297) / html?.getBoundingClientRect().height;

  let scale = scaleHeight < scaleWidth ? scaleHeight : scaleWidth;

  let doc = new jsPDF({
    orientation,
    format: 'a4',
  });

  if (scale < 0.7) {
    await html2canvas(html).then(function (canvas) {
      let imageURL = canvas.toDataURL('image/png');
      doc.addImage(
        imageURL,
        'JPEG',
        3,
        3,
        html?.getBoundingClientRect().width * scale,
        html?.getBoundingClientRect().height * scale
      );
    });
  } else {
    await html2canvas(html).then(function (canvas) {
      let imageURL = canvas.toDataURL('image/png');
      doc.addImage(imageURL, 'JPEG', 3, 3, html?.getBoundingClientRect().width, html?.getBoundingClientRect().height);
    });
  }

  doc.save(fileName + '.pdf');
};
