import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const ContentPlan = styled.div`
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 20px;

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      font-weight: bold;

      span {
        font-weight: normal;
        margin-left: 5px;
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  div.change-plan {
    cursor: pointer;
    color: #1976d2;
    font-weight: bold;
  }
`;

export const ContentCard = styled.div`
  margin-top: 20px;
  width: 100%;

  .MuiTextField-root {
    margin-bottom: 0px;
  }

  > p {
    margin-bottom: 20px;
    font-weight: bold;
  }

  .btn {
    width: 100%;
    height: 50px;
    margin-top: 40px;
  }
`;

export const ContainerCheckbox = styled.div`
  padding-top: 30px;

  span {
    padding: 0px;
  }

  label {
    font-weight: bold;
    color: #3e4756;
  }
`;

export const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}));
