import React, { memo } from 'react';
import { Handle, Position } from 'react-flow-renderer';

interface IProps {
  data: any;
  isConnectable: boolean;
}

const nodeCustom = ({ data, isConnectable }: IProps) => {
  return (
    <>
      <div>{data?.label}</div>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} style={{ background: '#555' }} />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        id="left"
        style={{ background: '#555' }}
      />
      <Handle
        type="target"
        position={Position.Right}
        isConnectable={isConnectable}
        id="right"
        style={{ background: '#555' }}
      />
      <Handle
        type="source"
        position={Position.Top}
        isConnectable={isConnectable}
        id="top"
        style={{ background: '#555' }}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        id="right"
        style={{ background: '#555' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        id="bottom"
        style={{ background: '#555' }}
      />
      <Handle
        type="source"
        position={Position.Left}
        isConnectable={isConnectable}
        id="left"
        style={{ background: '#555' }}
      />
    </>
  );
};

export default memo(nodeCustom);
