import React, { useState } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import Logo from '../../../assets/Logo-large.svg';
import { Link } from 'react-router-dom';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Content } from '../styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';

interface IProps {
  prevStep: () => void;
  email?: string;
  submit: (password: string) => void;
  loadingIndicator: boolean;
}

const validationSchema = object({
  password: string().min(6, 'No minímo 6 caracteres').required('Senha obrigatória'),
});

const InformeSuaSenha = ({ prevStep, email, submit, loadingIndicator }: IProps): React.ReactElement => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const changePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values.password);
    },
  });

  return (
    <Content>
      <img src={Logo} />
      <p className="title"> Informe sua senha </p>
      <div className="trocar-conta">
        <span>
          Você está acessando com:
          <strong> {email}</strong>
        </span>
        <span onClick={prevStep}>Trocar conta</span>
      </div>
      <TextField
        variant="outlined"
        label="Senha"
        fullWidth
        id="password"
        type={!isPasswordVisible ? 'password' : 'text'}
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik?.errors?.password}
        onKeyDown={(event) => event.key === 'Enter' && formik.handleSubmit()}
        InputProps={{
          endAdornment: !isPasswordVisible ? (
            <VisibilityOutlined onClick={changePasswordVisibility} />
          ) : (
            <VisibilityOffOutlined onClick={changePasswordVisibility} />
          ),
        }}
      />
      <div className="acessos-usuario">
        <Link to={{ pathname: '/recuperar-senha', state: email }}>Esqueci minha senha</Link>
        <Button type="submit" fullWidth variant="contained" onClick={() => formik.handleSubmit()}>
          {loadingIndicator ? <CircularProgress size={20} style={{ color: 'white' }} /> : <span>ENTRAR</span>}
        </Button>
      </div>
    </Content>
  );
};

export default InformeSuaSenha;
