import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .verticalDescription {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    /* border: solid red 1px; */
    height: auto;
    transform: rotate(-90deg);
    font-weight: 500;
    font-size: 24px;
  }

  .horizontalDescription {
    margin-top: 10px;
    font-weight: 500;
    font-size: 24px;
  }

  .chart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    padding: 0;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
