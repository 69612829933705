import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const Container = styled(Paper)`
  padding: 20px;
  width: 100%;
  max-width: 800px;

  .MuiTextField-root {
    height: auto;
  }
  p {
    display: flex;
    justify-content: left;
    align-items: inherit;
    color: #1976d2;
    margin-top: 0px;
    cursor: pointer;
  }
`;

export const Btns = styled.div`
  display: flex;
  justify-content: right;
  gap: 20px;
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  a {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    margin-right: 174px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #1976d2;
    font-size: 24px;
    font-weight: 500;

    .color-text {
      font-size: 24px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .inputs {
    display: flex;
    width: 100%;
    gap: 20px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  border: solid 1px #e1e2e3;
  border-radius: 5px;
  padding: 20px;

  label {
    font-size: 24px;
    font-weight: 400;
  }
`;

export const BoxStyle = styled.div`
  display: flex;
  .icon {
    padding-top: 10px;
    margin-right: 10px;
  }
  .text-modal {
    margin-bottom: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #3e4756;
  }

  label {
    font-size: 16px;
    color: #3e4756;
  }

  span {
    font-size: 16px;
    color: #f1504d;
    font-weight: 500;
  }
`;

export const useStyles = makeStyles({
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
  buttonAllRed: {
    backgroundColor: '#3fced2;',
    '&:hover': {
      backgroundColor: '#3fced2;',
    },
    color: '#fff',
    borderRadius: '5px',
  },
  buttonBW: {
    color: '#3E4756',
    borderRadius: '5px',
    border: '1px solid #3E4756',
  },
  buttonBlue: {
    backgroundColor: '#1976D2',
    color: '#fff',
    borderRadius: '5px',
    minWidth: '100px',
  },
  iconGreen: {
    color: '#09B386',
    minWidth: '67px',
    minHeight: '67px',
    margin: '0',
    padding: '0',
  },
  cancelBoxField: {
    marginTop: '10px',
    marginBottom: '100px',
  },
});
