import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Buttons, Container, Fields, TopContent, useStyles } from './styles';
import { IInternalUsers } from '../../../../interfaces/internalUserAndAccessProfile';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import {
  createInternalUser,
  editInternalUserById,
  getAccessProfiles,
  getInternalUserById,
} from '../../../../services/internalUsersAndProfileAccess';
import { useAlert } from '../../../../contexts/alert';
import { History } from 'history';
import { useLoading } from '../../../../contexts/loading';
import { GreenText, RedText } from '../../List/styles';
import { useAuth } from '../../../../contexts/auth';

const InternalUser = (): React.ReactElement => {
  const classes = useStyles();
  const history: History = useHistory();
  const [accessProfileList, setAccessProfileList] = useState([]);
  const { showAlertError, showAlertSuccess } = useAlert();
  const { showLoading } = useLoading();
  const { permissions } = useAuth();
  const userId = history.location.pathname.replace('/edicao-usuario-interno/', '');

  useEffect(() => {
    showLoading(true);
    if (isEditingUser) {
      getInternalUserById(userId)
        .then((response) => {
          showLoading(false);
          formik.setValues(response?.data?.value[0]);
        })
        .catch((error) => {
          showAlertError(error?.response?.data || 'Ocorreu um erro ao buscar os dados do usuário');
        });
    }

    getAccessProfiles()
      .then((result: any) => {
        setAccessProfileList(result?.data?.value);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);

        showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os perfis');
      });
  }, []);

  const isEditingUser = history.location.pathname.includes('edicao');

  const validationSchema = object({
    email: string().required('E-mail é obrigatório'),
    name: string().required('Nome é obrigatório'),
    roleId: string().required('Perfil de acesso é obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      roleId: '',
      active: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveUser(values);
    },
  });

  const saveUser = (values: IInternalUsers) => {
    showLoading(true);

    if (isEditingUser) {
      values.id = userId;
      editInternalUserById(values)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Usuario interno editado com sucesso');
          history.push({ pathname: '/usuarios-internos', search: 'InternalUsers' });
        })
        .catch((error) => {
          showLoading(false);
          showAlertError(
            error?.data[0]?.ErrorMessage || error?.response || 'Ocorreu um erro ao tentar realizar a alteração'
          );
        });
    } else {
      createInternalUser(values)
        .then((response) => {
          showLoading(false);
          showAlertSuccess('Usuario interno cadastrado com sucesso');
          history.push({ pathname: '/usuarios-internos', search: 'InternalUsers' });
        })
        .catch((error) => {
          showLoading(false);
          console.log(error);
          showAlertError(
            error?.data[0]?.ErrorMessage || error?.response || 'Ocorreu um erro ao tentar realizar o cadastro'
          );
        });
    }
  };

  return (
    <Container elevation={3}>
      <TopContent>
        <div>
          <Link to={{ pathname: '/usuarios-internos', search: 'InternalUsers' }}>
            <ArrowBackIos />
          </Link>
          <p>
            Usuários internos / <span className="color-text">{isEditingUser ? 'Editar' : 'Cadastrar'}</span>
          </p>
        </div>
        <span>
          {permissions?.includes('CanDeleteRoles') ? (
            <>
              {isEditingUser ? (
                formik.values.active ? (
                  <GreenText>Ativo</GreenText>
                ) : (
                  <RedText>Inativo</RedText>
                )
              ) : (
                'Ativo'
              )}

              <Switch
                disabled={!isEditingUser}
                checked={isEditingUser ? formik.values.active : true}
                name="status"
                onChange={(event) => {
                  const checked = event.target.checked;
                  formik.setFieldValue('active', checked);
                }}
                className={classes.disabled}
                classes={
                  isEditingUser
                    ? {
                        track: classes.switch_track,
                        switchBase: classes.switch_base,
                        colorPrimary: classes.switch_primary,
                      }
                    : {}
                }
              />
            </>
          ) : (
            ''
          )}
        </span>
      </TopContent>
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Fields>
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            name="name"
            error={Boolean(formik.errors.name)}
            helperText={formik?.errors?.name}
            value={formik.values.name || ''}
            onChange={formik.handleChange}
            InputLabelProps={{
              required: true,
            }}
          />
          <TextField
            fullWidth
            label="E-mail"
            variant="outlined"
            name="email"
            className="email"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            InputLabelProps={{
              required: true,
            }}
            error={Boolean(formik.errors.email)}
            helperText={formik?.errors?.email}
          />
          <FormControl fullWidth variant="outlined" error={Boolean(formik.errors.roleId)}>
            <InputLabel id="perfil-acesso" required>
              Perfil de Acesso
            </InputLabel>
            <Select
              labelId="perfil-acesso"
              label="Perfil de Acesso"
              value={formik.values.roleId || ''}
              name="roleId"
              onChange={formik.handleChange}
            >
              <MenuItem value="">
                <em>Selecione um valor</em>
              </MenuItem>
              {accessProfileList.map((a) => (
                <MenuItem key={a.id} value={a.id}>
                  {a.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik?.errors?.roleId}</FormHelperText>
          </FormControl>
        </Fields>
        <Buttons>
          <Button
            className={classes.buttonRed}
            variant="outlined"
            onClick={() => {
              history.push('/usuarios-internos');
            }}
          >
            Cancelar
          </Button>
          <Button
            className={classes.buttonGreen}
            variant="contained"
            type="submit"
            disabled={!permissions?.includes('CanUpdateInternalUsers') ? true : false}
          >
            Salvar
          </Button>
        </Buttons>
      </form>
    </Container>
  );
};

export default InternalUser;
