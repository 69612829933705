import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 865px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const TopContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 25px;
  .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-top: 9px;
    color: #3e4756;
  }
  p {
    display: inline-block;
    margin: 0 auto;
    color: #9ba3a9;
    font-size: 24px;
    font-family: 'Mosk Semi Bold';

    .color-text {
      color: #3fced2;
      font-family: 'Mosk Semi Bold';
    }
  }
  span {
    font-size: 17px;
  }
`;
export const Options = styled.div`
  display: grid;
  width: 100%;
  label {
    display: flex;
    align-items: center;
    span {
      padding: 0;
      padding-right: 3px;
    }
    @media (min-width: 920px) {
      white-space: nowrap;
    }
  }
  .nome {
    grid-area: nome;
  }

  .fantasma {
    grid-area: fantasma;
  }
  .data-inicio {
    grid-area: dataInicio;
  }
  .data-fim {
    grid-area: dataFim;
  }

  .indeterminado {
    grid-area: indeterminado;
  }
  .limite-usuarios {
    grid-area: limite-usuarios;
  }
  .ilimitado-usuarios {
    grid-area: ilimitado-usuarios;
  }
  .ilimitado-modelos {
    grid-area: ilimitado-modelos;
  }
  .limite-modelos {
    grid-area: limite-modelos;
  }
  .mensal {
    grid-area: mensal;
  }
  .trimestral {
    grid-area: trimestral;
  }
  .anual {
    grid-area: anual;
  }
  .reajuste {
    grid-area: reajuste;
  }
  .tempoReajuste {
    grid-area: tempoReajuste;
  }
  .regraReajuste {
    grid-area: regraReajuste;
  }
  .renovacao {
    grid-area: renovacao;
  }
  .dias {
    grid-area: dias;
  }

  .fantasma-dias {
    grid-area: fantasma-dias;
  }
  gap: 20px;
  grid-template-columns: repeat(16, 1fr);

  grid-template-areas:
    'nome nome nome nome nome nome nome nome nome nome nome nome fantasma fantasma fantasma fantasma'
    'dataInicio dataInicio dataInicio dataInicio dataInicio dataInicio dataFim dataFim dataFim dataFim dataFim dataFim indeterminado indeterminado indeterminado indeterminado'
    'limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios limite-usuarios ilimitado-usuarios  ilimitado-usuarios ilimitado-usuarios ilimitado-usuarios'
    'limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos limite-modelos ilimitado-modelos  ilimitado-modelos ilimitado-modelos ilimitado-modelos'
    'mensal mensal mensal mensal trimestral trimestral trimestral trimestral anual anual anual anual renovacao renovacao renovacao renovacao '
    'reajuste reajuste reajuste reajuste tempoReajuste tempoReajuste tempoReajuste tempoReajuste regraReajuste regraReajuste regraReajuste regraReajuste vazio vazio vazio vazio '
    'dias dias dias dias dias dias dias dias dias dias dias dias fantasma-dias fantasma-dias fantasma-dias fantasma-dias';
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: flex-end;
  align-items: flex-end;
  button {
    margin-left: 20px;
    min-width: 100px;
    min-height: 40px;
  }
`;

export const GreenText = styled.span`
  color: #0abf8f;
`;

export const RedText = styled.span`
  color: #ff5f5d;
`;

export const useStyles = makeStyles({
  switch_base: {
    color: '#FF5F5D',
    '&.Mui-disabled': {
      color: '#FF5F5D',
    },
    '&.Mui-checked': {
      color: '#0ABF8F',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0ABF8F',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#0ABF8F',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#0ABF8F',
    },
  },
  switch_track: {
    backgroundColor: '#FF5F5D',
  },
  disabled: {
    color: '#9BA3A9',
    '&.Mui-disabled': {
      color: '#9BA3A9',
    },
    '&.Mui-checked': {
      color: '#9BA3A9',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#9BA3A9',
    },
  },
  buttonRed: {
    borderColor: 'red',
    color: 'red',
    borderRadius: '5px',
  },
  buttonGreen: {
    backgroundColor: '#3FCED2',
    color: '#fff',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
});
