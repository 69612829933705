import api from './api';

var date = new Date();
var newDate = new Date();
newDate.setDate(date.getDate() + 1);

export const getSoldPlans = async (initialDate, finalDate) => {
  let final = finalDate ? finalDate : new Date(newDate).toISOString();

  try {
    if (initialDate || (initialDate && finalDate)) {
      return await api.get(`Dashboard/SoldPlans?initialDate=${initialDate}&finalDate=${final}`);
    } else {
      return await api.get(`Dashboard/SoldPlans?finalDate=${final}`);
    }
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getAliveClients = async (initialDate, finalDate) => {
  let final = finalDate ? finalDate : new Date(newDate).toISOString();

  try {
    if (initialDate || (initialDate && finalDate)) {
      return await api.get(`Dashboard/AliveClients?initialDate=${initialDate}&finalDate=${final}`);
    } else {
      return await api.get(`Dashboard/AliveClients?finalDate=${final}`);
    }
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getModels = async (initialDate, finalDate) => {
  let final = finalDate ? finalDate : new Date(newDate).toISOString();

  try {
    if (initialDate || (initialDate && finalDate)) {
      return await api.get(`Dashboard/Models?initialDate=${initialDate}&finalDate=${final}`);
    } else {
      return await api.get(`Dashboard/Models?finalDate=${final}`);
    }
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getCancellationByPlan = async (initialDate, finalDate) => {
  let final = finalDate ? finalDate : new Date(newDate).toISOString();

  try {
    if (initialDate || (initialDate && finalDate)) {
      return await api.get(`Dashboard/CancellationByPlan?initialDate=${initialDate}&finalDate=${final}`);
    } else {
      return await api.get(`Dashboard/CancellationByPlan?finalDate=${final}`);
    }
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getCreateAndNotDownload = async (initialDate, finalDate) => {
  let final = finalDate ? finalDate : new Date(newDate).toISOString();

  try {
    if (initialDate || (initialDate && finalDate)) {
      return await api.get(`Dashboard/ModelsNotDownloaded?initialDate=${initialDate}&finalDate=${final}`);
    } else {
      return await api.get(`Dashboard/ModelsNotDownloaded?finalDate=${final}`);
    }
  } catch (error) {
    return Promise.reject(error.response);
  }
};
