import { useState } from 'react';

export const useModal = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const change = () => setIsVisible(!isVisible);
  return {
    isVisible,
    change,
  };
};
