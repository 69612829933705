import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(Paper)`
  width: 450px;
  min-height: 430px;
  max-height: 500px;
  max-width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;

  img {
    padding-bottom: 40px;
  }

  button {
    height: 53px;
    margin-bottom: 25px;
    background-color: #3fced2;

    a {
      text-decoration: none;
      color: #fff;
    }
  }

  .button {
    width: 80%;
    text-decoration: none;
    button {
      background-color: #3fced2;
    }
  }
`;
