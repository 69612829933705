import { PDFDocument } from 'pdf-lib';

export const mergePDFHandler = async (files, fileName) => {
  // Converting File Object Array To PDF Document Array
  // eslint-disable-next-line @typescript-eslint/return-await
  files.forEach(async (file) => await PDFDocumentFromFile(file));
  // Merging The PDF Files to A PDFDocument
  const mergedPDFDocument = await mergePDF(files);

  const base64file = await mergedPDFDocument.saveAsBase64();
  downloadBase64ToPDF(base64file, fileName);
};

const downloadBase64ToPDF = (pdf, fileName) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = `${fileName}.pdf`;
  downloadLink.click();
};

const mergePDF = async (filesDocArray) => {
  const mergedPdf = await PDFDocument.create();
  for (let i = 0; i < filesDocArray.length; i++) {
    const fileDoc = await PDFDocumentFromFile(filesDocArray[i]);
    const pages = await mergedPdf.copyPages(fileDoc, [0]);
    pages.forEach((page) => mergedPdf.addPage(page));
  }

  return mergedPdf;
};

const PDFDocumentFromFile = async (file) =>
  PDFDocument.load(await file.arrayBuffer(), {
    ignoreEncryption: true,
  });
