import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonalData from './PersonalData';
import PersonalDataInternalUser from './PersonalDataInternalUser';
import Payment from './Payment';
import Plan from './Plan';
import { useLoading } from '../../contexts/loading';
import { useAlert } from '../../contexts/alert';
import { clientInitialValues, clientValidationSchema, clientWitchCardValidationSchema } from './validation';
import { clientSubscription, getClientCard, getExternalUserById } from '../../services/externalUsers';
import { useFormik } from 'formik';
import { Buttons, Container, useStyles } from './styles';
import { useAuth } from '../../contexts/auth';
import { getUf } from '../../services/uf';
import { getOccupationArea } from '../../services/occupationArea';
import { getGender } from '../../services/gender';
import { getPlans } from '../../services/plans';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

const MyData: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [plans, setPlans] = useState<any>([]);
  const [filterPlans, setFilterPlans] = useState<any>([]);
  const [recurrenceTypes, setRecurrenceTypes] = useState<any>([]);
  const [occupationAreas, setOccupationAreas] = useState<IOccupation[]>();
  const [ufs, setUfs] = useState<IUfs[]>();
  const [genders, setGenders] = useState<any>([]);
  const [card, setCard] = useState<any>([]);
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.tokenData?.UserType !== 'Internal User') {
      getClient();
      getGenders();
      getOccupations();
      getUfs();
      if (user?.marketPlaceId == 1) {
        getActivePlans();
        getCard();
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: clientInitialValues,
    validationSchema: card?.lastFourDigits ? clientWitchCardValidationSchema : clientValidationSchema,
    onSubmit: () => {
      showLoading(true);
      clientSubscription(formik.values)
        .then((response) => {
          showLoading(false);
          showAlertSuccess(
            'Alteração efetuada com sucesso. Favor efetuar o login novamente para atualizar o ambiente com as novas atualizações.'
          );
        })
        .catch((error) => {
          showLoading(false);
          if (error.status == 412) {
            showAlertError('Campo telefone é obrigatório para atualização de assinatura.');
          } else if (error.status == 422) {
            showAlertError(error.data + ' Ocorreu um erro ao tentar atualizar a assinatura');
          } else {
            if (error.data != null) {
              showAlertError(error.data + ' Ocorreu um erro ao tentar atualizar a assinatura');
            } else {
              showAlertError('Ocorreu um erro ao tentar atualizar a assinatura');
            }
          }
        });
    },
  });

  const getClient = () => {
    showLoading(true);

    getExternalUserById(user?.tokenData?.ClientId)
      .then((response) => {
        let dataFormik: any = {
          id: user?.tokenData?.ClientId || response.data.value[0].clientSubscriptions[0].clientId,
          planId: user?.tokenData?.PlanId,
          recurrenceTypeId: user?.tokenData?.PlanRecurrenceTypeId,
          automaticRenew: response.data.value[0].clientSubscriptions[0].subscription.automaticRenewal ? true : false,
          subscriptionId: response.data.value[0].clientSubscriptions[0].subscriptionId,
          marketPlace: user?.marketPlaceId,
          firstLogin: user?.firstLogin,
          customerId: user?.tokenData?.ClientId,
          expireDate: null,
          creditCardOwnerName: '',
          documentNumber: '',
          creditCardNumber: '',
          securityCode: '',
        };
        formik.setValues(dataFormik);
        showLoading(false);
      })
      .catch((err) => {
        if (user?.tokenData?.PlanActive == 'Inativo') {
          let dataFormik: any = {
            id: user?.tokenData?.ClientId,
            planId: user?.tokenData?.PlanId,
            recurrenceTypeId: user?.tokenData?.PlanRecurrenceTypeId,
            automaticRenew: false,
            customerId: user?.tokenData?.ClientId,
            marketPlace: user?.marketPlaceId,
          };
          formik.setValues(dataFormik);
        }
        showLoading(false);
      });
  };

  const getActivePlans = () => {
    getPlans()
      .then((response) => {
        let teste = response.data.value;
        let filterPlan = teste.filter((plan) => plan.name != 'Plano Parceiro' && plan.status == true);
        setPlans(response.data.value);
        setFilterPlans(filterPlan);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar os planos.');
      });
  };

  const getGenders = () => {
    getGender()
      .then((response) => {
        setGenders(response.data.value);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar os gêneros.');
      });
  };

  const getOccupations = () => {
    getOccupationArea()
      .then((response) => {
        setOccupationAreas(response.data.value);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar as Áreas de ocupação.');
      });
  };

  const getUfs = () => {
    getUf()
      .then((response) => {
        setUfs(response.data.value);
      })
      .catch((error) => {
        showAlertError(typeof error == 'string' ? error : 'Ocorreu um erro ao buscar os estados.');
      });
  };

  const getCard = () => {
    getClientCard(user?.tokenData?.ClientId)
      .then((response) => {
        setCard(response.data);
        formik.setFieldValue('creditCardOwnerName', response.data.holderName);
        formik.setFieldValue('documentNumber', response.data.holderDocument);
        formik.setFieldValue('creditCardNumber', response.data.firstSixDigits);
        formik.setFieldValue('creditCardDueDateMonth', response.data.expMonth);
        formik.setFieldValue('creditCardDueDateYear', response.data.expYear);
        formik.setFieldValue('securityCode', '123');
        formik.setFieldValue('hasCard', true);
        formik.setFieldValue('changeCard', false);
      })
      .catch((error) => {
        formik.setFieldValue('hasCard', false);
      });
  };

  useEffect(() => {
    let clientPlan = plans.find((plan) => plan.id === formik.values.planId);
    if (clientPlan) setRecurrenceTypes(clientPlan?.recurrencePlans);
  }, [formik.values?.planId]);

  return (
    <Container>
      <div className="main">
        <Accordion expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <h1>Dados pessoais</h1>
          </AccordionSummary>
          <AccordionDetails>
            {user?.tokenData?.UserType == 'Internal User' ? (
              <PersonalDataInternalUser />
            ) : (
              <PersonalData genders={genders} occupationAreas={occupationAreas} ufs={ufs} />
            )}
          </AccordionDetails>
        </Accordion>
        <br />
        {user?.tokenData?.OwnerUserType == 'Titular' ? (
          <form onSubmit={formik.handleSubmit}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <h1>Plano</h1>
              </AccordionSummary>
              <AccordionDetails style={{ visibility: user?.marketPlaceId == 2 ? 'hidden' : 'visible' }}>
                <Plan formik={formik} plans={plans} filterPlans={filterPlans} recurrenceTypes={recurrenceTypes} />
              </AccordionDetails>

              <AccordionDetails>
                <Payment formik={formik} card={card} ufs={ufs} />
              </AccordionDetails>
              <Buttons>
                <Button
                  className={classes.buttonRed}
                  variant="outlined"
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  className={classes.buttonGreen}
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    if (!isEmpty(formik.errors))
                      showAlertError(
                        'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.'
                      );
                  }}
                >
                  Salvar
                </Button>
              </Buttons>
            </Accordion>
          </form>
        ) : (
          ''
        )}
      </div>
    </Container>
  );
};

export default MyData;
