import React from 'react';
import { useAuth } from '../../../../contexts/auth';
import { SelectBetweenTables } from '../styles';

interface IPropsTitleMaterialTable {
  selectedTitle: string;
  changeToInternalUsers: () => void;
  changeToAccessProfile: () => void;
}
export const TitleComponentSelector = ({
  selectedTitle,
  changeToInternalUsers,
  changeToAccessProfile,
}: IPropsTitleMaterialTable): React.ReactElement => {
  const { permissions } = useAuth();
  return (
    <SelectBetweenTables selectedTitle={selectedTitle}>
      {permissions?.includes('CanReadInternalUsers') ? (
        <p tabIndex={-1} onClick={changeToInternalUsers}>
          Usuários internos
        </p>
      ) : (
        ''
      )}
      {permissions?.includes('CanReadRoles') ? (
        <p tabIndex={-1} onClick={changeToAccessProfile}>
          Perfis de acesso
        </p>
      ) : (
        ''
      )}
    </SelectBetweenTables>
  );
};
