import React, { useRef, useState } from 'react';
import { TextField } from '@mui/material';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { InputAdornment, InputProps } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

interface IProps {
  onChange: any;
  type: string;
  defaultValue?: string;
  label?: string;
  size?: string;
  error?: boolean;
  helperText?: string;
  multiline?: boolean;
  name?: string;
  maxLength?: number;
  id?: string | number;
  speechVisible: boolean;
}

const EditTableTemplateTextField = ({
  id,
  onChange,
  label,
  type,
  defaultValue,
  error,
  helperText,
  name,
  size = 'small',
  multiline,
  maxLength,
  speechVisible,
}: IProps) => {
  const ref = useRef();
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [fieldValue, setFieldValue] = useState<string>(null);
  const handleField = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeValueItem(event.target.value);
  };
  const [speechText, setSpeechText] = useState('');

  function changeValueItem(text: string) {
    setFieldValue(text.substring(0, maxLength));
    onChange(text);
  }

  return (
    <>
      <TextField
        id={id || ''}
        variant="outlined"
        value={fieldValue == null ? defaultValue : fieldValue}
        fullWidth
        name={name}
        InputProps={{
          endAdornment: speechVisible ? (
            <Tooltip title="Clique e segure para ditar o texto...">
              <InputAdornment position="end">
                <div style={{ cursor: 'pointer' }}>
                  <KeyboardVoiceOutlinedIcon
                    onMouseDown={SpeechRecognition.startListening}
                    onMouseUp={SpeechRecognition.stopListening}
                    onClick={() => {
                      const s = transcript.charAt(0).toUpperCase() + transcript.slice(1);
                      setSpeechText(speechText + ' ' + s);
                      changeValueItem(speechText + ' ' + s);
                      resetTranscript();
                    }}
                  />
                  <ClearIcon
                    onClick={() => {
                      setSpeechText('');
                      changeValueItem('');
                      resetTranscript();
                    }}
                  />
                </div>
              </InputAdornment>
            </Tooltip>
          ) : null,
        }}
        onChange={(e) => {
          handleField(e);
        }}
        label={label ? label : type === 'column' ? 'Título da coluna' : 'Descrição da linha'}
        size={size}
        error={error}
        helperText={helperText}
        multiline={multiline}
        style={{ marginBottom: 0 }}
      />
    </>
  );
};

export default EditTableTemplateTextField;
