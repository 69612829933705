/* eslint-disable react/display-name */
import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { Button } from '@material-ui/core';
import { Container, useStyles } from './styles';
import EditIcon from '../../../assets/edit-icon.svg';
import { useHistory } from 'react-router-dom';
import { localizationOptions } from '../../../helper/tableHelper';
import { getExternalUsers } from '../../../services/externalUsers';
import { useAlert } from '../../../contexts/alert';
import columns from './columns';
import { useAuth } from '../../../contexts/auth';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import * as XLSX from 'xlsx';

const UsuariosExternos: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { permissions } = useAuth();
  const { showAlertError } = useAlert();
  const [users, setUsers] = useState([]);

  const filterUsers = () => {
    let array: any = [];

    users.map((item) => {
      array.push({
        Nome: item?.user?.name + ' ' + item?.user?.lastName,
        Email: item?.user?.email,
        Nascimento: new Date(item?.birthDate).toLocaleDateString('pt-BR'),
        Seccional: item?.uf?.state,
        Area_Atuação: item?.occupationArea?.value,
        Plano: item?.clientSubscriptions[0]?.subscription?.recurrencePlan?.plan?.name,
        Situação_Plano:
          item?.clientSubscriptions[0]?.subscription?.planStatus == 'InUse'
            ? 'Em uso'
            : item?.clientSubscriptions[0]?.subscription?.planStatus == 'Trial'
            ? 'Trial'
            : item?.clientSubscriptions[0]?.subscription?.planStatus == 'Canceled'
            ? 'Cancelado'
            : 'Cancelado trial',
        Situação:
          item?.clientSubscriptions[0]?.subscription?.recurrencePlan?.plan?.status == true ? 'Ativo' : 'Inativo',
        marketPlace: item.user?.marketPlaceId,
      });
    });
    return array;
  };

  const exportExcel = () => {
    let data = filterUsers();
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Usuários Externos');
    let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(workBook, 'Export Usuários Externos.xlsx');
  };

  return (
    <Container>
      {permissions?.includes('CanReadExternalUsers') ? (
        <MaterialTable
          title="Usuários Externos"
          columns={columns}
          actions={[
            {
              icon: () =>
                permissions?.includes('CanUpdateExternalUsers') ? <img src={EditIcon} /> : <VisibilityOutlined />,
              tooltip: permissions?.includes('CanUpdateExternalUsers') ? 'Editar' : 'Vizualizar',
              position: 'row',
              onClick: (event, rowData) => history.push(`/edicao-usuario-externo/${rowData?.id}`),
            },
            {
              icon: () => (
                <Button className={classes.button} variant="contained" color="primary">
                  Exportar
                </Button>
              ),
              isFreeAction: true,
              tooltip: 'Exportar',
              onClick: () => {
                exportExcel();
              },
              position: 'toolbar',
            },
          ]}
          data={({ page, pageSize, search, filters }) => {
            return new Promise((resolve, reject) => {
              let filter: any = [];
              if (filters[0]?.column) {
                filter = filters?.map((f) => {
                  return {
                    field: f?.column?.field,
                    value: f.value,
                  };
                });
              }

              getExternalUsers(page, pageSize, search, filter)
                .then((result: any) => {
                  setUsers(result.data.value);
                  if (result)
                    resolve({
                      data: result.data.value,
                      page: page,
                      totalCount: result.data['@odata.count'],
                    });
                  else {
                    resolve({
                      data: [],
                      page: page,
                      totalCount: result.data['@odata.count'],
                    });
                  }
                })
                .catch((err) => {
                  if (err.status === 401) {
                    showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
                  } else {
                    showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os usuários externos.');
                  }

                  resolve({
                    data: [],
                    page: page,
                    totalCount: 0,
                  });
                });
            });
          }}
          localization={localizationOptions}
          options={{
            headerStyle: {
              padding: '20px',
            },
            rowStyle: {
              padding: 20,
            },
            filtering: true,
            actionsColumnIndex: -1,
            pageSize: 5,
            exportAllData: true,
            sorting: false,
            pageSizeOptions: [3, 5, 10],
            debounceInterval: 700,
          }}
        />
      ) : (
        ''
      )}
    </Container>
  );
};
export default UsuariosExternos;
