import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  > div {
    display: flex;
    align-items: center;
    > div {
      margin-right: 10px;
    }
    svg {
      margin: 0px 2px;
    }
  }

  textarea {
    max-height: 100px;
  }
`;
