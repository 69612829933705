import React from 'react';
import { Button } from '@material-ui/core';
import Logo from '../../assets/Logo-large.svg';
import { Container, Content } from './styles';
import { Link } from 'react-router-dom';

const HomeUsuarioExterno = (): React.ReactElement => {
  return (
    <Container>
      <Content>
        <img src={Logo} />
        <Link to="/tabela-comparativa" className="button">
          <Button color="primary" variant="contained" fullWidth>
            tabela
          </Button>
        </Link>
        <Link to="/linha-do-tempo" className="button">
          <Button color="primary" variant="contained" fullWidth>
            linha do tempo
          </Button>
        </Link>
      </Content>
    </Container>
  );
};

export default HomeUsuarioExterno;
