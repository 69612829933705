import React, { useEffect, useRef, useState } from 'react';
import MaterialTable from '@material-table/core';
import { localizationOptions } from '../../../helper/tableHelper';
import { useHistory } from 'react-router-dom';
import EditIcon from '../../../assets/edit-icon.svg';
import { ButtonsModal, Container } from './styles';
import { Container as ContainerCore } from '@material-ui/core';
import { useAlert } from '../../../contexts/alert';
import { useAuth } from '../../../contexts/auth';
import {
  convertHTMLTimeLineHasBreakPageToPDF,
  convertHTMLToImage,
  convertHTMLToPDF,
  convertTextToPDF,
  copyImage,
  copyText,
  downLoadText,
  downloadPDFFlowChart,
} from '../../../helper/convertHTML';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import QRCode from 'qrcode.react';
import {
  deleteModelById,
  getModelsByUserId,
  updateDownloadComparativeTable,
  updateDownloadDiagram,
  updateDownloadEmenta,
  updateDownloadFlowChart,
  updateDownloadLinguagemSimples,
  updateDownloadQRCode,
  updateDownloadTimeLine,
} from '../../../services/models';
import columns from './columns';
import { Button, Modal, Paper } from '@material-ui/core';
import Chart from '../../../components/Chart';
import { useLoading } from '../../../contexts/loading';
import { Alert, Grid, Tooltip } from '@mui/material';
import { IRoot, IValue } from '../../../services/interfaceType/IModels';
import ItemCardMeusModelos from '../../../components/ItemCardMeusModelos';

interface ITable {
  id: string;
  uui: string;
  html: string;
}
interface IRow {
  id: string;
  nome: string;
  modelo: string;
  dataCriacao: string;
  tableData: ITable;
  html: string;
  quebraPagina: boolean;
  posicao: string;
}

interface IDelete {
  id: string;
  type: string;
}

interface IProps {
  exibirTitulo: boolean;
  exibirTable: boolean;
}

const pageSizeListCard = 30;

const MeusModelos = ({ exibirTitulo = true, exibirTable = true }: IProps): React.ReactElement => {
  const tableRef = React.createRef<HTMLInputElement | any>();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { showLoading } = useLoading();
  const ref = useRef(null);
  const history = useHistory();
  const { user } = useAuth();
  const [urlQrCode, setUrlQrcode] = useState({
    title: '',
    url: '',
  });
  const [chart, setChart] = useState({
    titleText: '',
    horizontalText: '',
    verticalText: '',
    data: null,
    position: null,
    diagramType: null,
  });
  const [objDelete, setObjDelete] = useState<IDelete>({
    id: '',
    type: '',
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [downloadFiles, setDownloadFiles] = useState(false);
  const [copyFiles, setCopyFiles] = useState(false);
  const [openToolTipCopyImage, setOpenToolTipCopyImage] = useState(false);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);

  const [dados, setDados] = useState<IValue[]>([]);
  const [qtdRegistro, setQtdRegistro] = useState(0);

  useEffect(() => {
    if (!exibirTable) {
      getModelsByUserId(user?.tokenData?.ClientId, 0, pageSizeListCard, '')
        .then((result: IRoot) => {
          setQtdRegistro(0);

          if (result) {
            setDados([...result.data.value]);
            setQtdRegistro(result.data['@odata.count']);
          } else {
            console.log('false', 1);
            setDados([]);
          }
        })
        .catch((err: any) => {
          console.log('erro', 1);
          setDados([]);
          setQtdRegistro(0);
          console.log('Erro', err);
          if (err?.status === 401) {
            showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
          } else {
            showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os meus projetos');
          }
        });
    }
  }, []);

  useEffect(() => {
    if (downloadFiles) {
      handleDownload(downloadFiles.rowData, downloadFiles.type);
    }
  }, [downloadFiles]);

  useEffect(() => {
    if (copyFiles) {
      handleCopyImage(copyFiles.rowData, copyFiles.type);
    }
  }, [copyFiles]);

  const handleOpen = () => setOpenModalDelete(true);
  const handleClose = () => setOpenModalDelete(false);

  const redirectEdit = (rowData: IRow | any) => {
    if (rowData.modelo == 'ModeloQRCode') {
      history.push(`/qrcode`, { id: rowData.id });
    } else if (rowData.modelo == 'ModeloLinhaDoTempo') {
      history.push(`/linha-do-tempo`, { id: rowData.id });
    } else if (rowData.modelo == 'TabelaComparativa') {
      history.push(`/tabela-comparativa`, { id: rowData.id });
    } else if (rowData.modelo === 'ModeloFluxograma') {
      history.push(`/fluxograma`, { id: rowData.id });
    } else if (rowData.modelo === 'ModeloGrafico') {
      history.push(`/grafico`, { id: rowData.id });
    } else if (rowData.modelo === 'ModeloLinguagemSimples') {
      history.push(`/ia-linguagem-simples`, { id: rowData.id });
    } else if (rowData.modelo === 'ModeloEmenta') {
      history.push(`/ementa`, { id: rowData.id });
    } else if (rowData.modelo === 'ModeloBibliotecaIcones') {
      history.push(`/icons`, { id: rowData.id });
    }
  };

  const handleDelete = async (typeModel: string, modelId: string) => {
    const response = await deleteModelById(typeModel, user?.tokenData?.ClientId, modelId);
    if (response.status !== undefined) {
      if (response.status === 204) {
        if (!exibirTable) {
          setDados([...dados.filter((d) => d.id !== modelId)]);
          handleClose();
        } else if (tableRef.current) {
          tableRef.current.onQueryChange();
          handleClose();
        }
      } else {
        showAlertError('Não foi possível deletar o modelo. Tente novamente');
      }
    } else {
      showAlertError('Não foi possível deletar o modelo. Tente novamente');
    }
  };

  function createElementFromHTML(htmlString: string) {
    ref.current.innerHTML = htmlString;
  }

  const handleDownloadPdf = (rowData: IRow) => {
    if (rowData.modelo === 'ModeloLinhaDoTempo' && rowData.quebraPagina) {
      if (rowData.posicao === 'Horizontal') {
        convertHTMLTimeLineHasBreakPageToPDF(ref.current, rowData.descricao, 'l');
      } else {
        convertHTMLTimeLineHasBreakPageToPDF(ref.current, rowData.descricao, 'p');
      }
    } else if (rowData.modelo === 'ModeloLinhaDoTempo') {
      convertHTMLToPDF(ref.current, rowData.descricao, 'l');
    } else if (rowData.modelo === 'ModeloGrafico') {
      downloadPDFFlowChart(ref.current, rowData.descricao, 'l', 600);
    } else if (rowData.modelo === 'ModeloFluxograma') {
      if (rowData.posicao === 'Horizontal') {
        downloadPDFFlowChart(ref.current, rowData.descricao, 'l');
      } else {
        downloadPDFFlowChart(ref.current, rowData.descricao, 'p');
      }
    } else if (rowData.modelo === 'ModeloLinguagemSimples' || rowData.modelo === 'ModeloEmenta') {
      convertTextToPDF(rowData.json, rowData.descricao);
    } else {
      convertHTMLToPDF(ref.current, rowData.descricao);
    }
  };

  const handleDownload = (rowData: IRow, type: string) => {
    showLoading(true);

    setTimeout(() => {
      if (type == 'PDF') {
        handleDownloadPdf(rowData);
      } else {
        if (!isSafari) convertHTMLToImage(ref.current, rowData.descricao);
      }

      if (rowData.modelo == 'ModeloFluxograma') {
        updateCountDownloadFlowChart(rowData.id);
      } else if (rowData.modelo == 'ModeloLinhaDoTempo') {
        updateCountDownloadTimeLine(rowData.id);
      } else if (rowData.modelo == 'ModeloQRCode') {
        updateCountDownloadQRCode(rowData.id);
      } else if (rowData.modelo == 'TabelaComparativa') {
        updateCountDownloadComparativeTable(rowData.id);
      } else if (rowData.modelo == 'ModeloGrafico') {
        updateCountDownloadDiagram(rowData.id);
      } else if (rowData.modelo == 'ModeloLinguagemSimples') {
        updateCountDownloadLinguagemSimples(rowData.id);
      } else if (rowData.modelo == 'ModeloEmenta') {
        updateCountDownloadEmenta(rowData.id);
      }

      setTimeout(() => {
        setUrlQrcode({ title: '', url: '' });
        setChart(null);
        setDownloadFiles(false);
        showLoading(false);
      }, 1000);
    }, 4000);
  };

  const handleCopyImage = async (rowData: IRow, type: string) => {
    showLoading(true);
    copyImage(ref.current, rowData.descricao);

    setTimeout(() => {
      if (!isSafari);

      if (rowData.modelo == 'ModeloLinguagemSimples') {
        copyText(rowData.json);
      } else {
        copyImage(ref.current, rowData.descricao);
      }

      if (rowData.modelo == 'ModeloFluxograma') {
        updateCountDownloadFlowChart(rowData.id);
      } else if (rowData.modelo == 'ModeloLinhaDoTempo') {
        updateCountDownloadTimeLine(rowData.id);
      } else if (rowData.modelo == 'ModeloQRCode') {
        updateCountDownloadQRCode(rowData.id);
      } else if (rowData.modelo == 'TabelaComparativa') {
        updateCountDownloadComparativeTable(rowData.id);
      } else if (rowData.modelo == 'ModeloGrafico') {
        updateCountDownloadDiagram(rowData.id);
      } else if (rowData.modelo == 'ModeloLinguagemSimples') {
        updateCountDownloadLinguagemSimples(rowData.id);
      } else if (rowData.modelo == 'ModeloEmenta') {
        updateCountDownloadEmenta(rowData.id);
      }

      setTimeout(() => {
        setUrlQrcode({ title: '', url: '' });
        setChart(null);
        setDownloadFiles(false);
        setCopyFiles(false);
        showLoading(false);
        showAlertSuccess('Imagem copiada com sucesso');
      }, 1000);
    }, 4000);
  };

  const handleDownloadChart = (data, fileType) => {
    let dataJson = JSON.parse(data.json);

    let diagramType = dataJson?.format;
    setChart({
      titleText: dataJson.title,
      horizontalText: dataJson.horizontalDescription,
      verticalText: dataJson.verticalDescription,
      data: dataJson.data,
      position: data.posicao === 'Horizontal' ? 1 : 2,
      diagramType,
      fileType,
    });
  };

  const updateCountDownloadFlowChart = (modelId: string) => {
    updateDownloadFlowChart(modelId);
  };

  const updateCountDownloadTimeLine = (modelId: string) => {
    updateDownloadTimeLine(modelId);
  };

  const updateCountDownloadQRCode = (modelId: string) => {
    updateDownloadQRCode(modelId);
  };

  const updateCountDownloadComparativeTable = (modelId: string) => {
    updateDownloadComparativeTable(modelId);
  };

  const updateCountDownloadDiagram = (modelId: string) => {
    updateDownloadDiagram(modelId);
  };

  const updateCountDownloadLinguagemSimples = (modelId: string) => {
    updateDownloadLinguagemSimples(modelId);
  };

  const updateCountDownloadEmenta = (modelId: string) => {
    updateDownloadEmenta(modelId);
  };

  //Eventos List Card
  const imprimirCardHandleClick = (item: IValue): void => {
    if (item.modelo == 'ModeloQRCode') {
      setUrlQrcode({
        title: item.nome,
        url: item.url ?? '',
      });
    } else if (item.modelo == 'ModeloGrafico') {
      handleDownloadChart(item, 'PDF');
    } else {
      if (item.html !== null) createElementFromHTML(item.html ?? '');
    }
    setDownloadFiles({ type: 'PDF', rowData: item });
  };

  const downloadCardHandleClick = (item: IValue): void => {
    const data: IRow | any = item;
    if (data.modelo == 'ModeloQRCode') {
      setUrlQrcode({
        title: data.nome,
        url: data.url,
      });
    } else if (data.modelo == 'ModeloGrafico') {
      handleDownloadChart(data, 'IMG');
    } else if (data.modelo == 'ModeloLinguagemSimples' || data.modelo == 'ModeloEmenta') {
      downLoadText(data.json, data.descricao);
    } else {
      if (data.html !== null) ref.current.innerHTML = data.html;
    }
    setDownloadFiles({ type: 'IMG', rowData: item });
  };

  const copiarCardImagemHandleClick = (item: IValue): void => {
    const data: IRow | any = item;
    if (data.modelo == 'ModeloQRCode') {
      setUrlQrcode({
        title: data.nome,
        url: data.url,
      });
    } else if (data.modelo == 'ModeloGrafico') {
      handleDownloadChart(data, 'IMG');
    } else if (data.modelo == 'ModeloLinguagemSimples' || data.modelo == 'ModeloEmenta') {
      copyText(data.json);
    } else {
      if (data.html !== null) ref.current.innerHTML = data.html;
    }
    setCopyFiles({ type: 'IMG', rowData: item });
  };

  const editarCardImagemHandleClick = (item: IValue): void => {
    const data: IRow | any = item;
    redirectEdit(data);
  };

  const excluirCardImagemHandleClick = (item: IValue): void => {
    const data: IRow | any = item;
    handleOpen();
    setObjDelete({ id: data.id, type: data.modelo });
  };

  return (
    <Container className="principal">
      <Modal
        open={openModalDelete}
        onClose={handleClose}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Paper elevation={1} style={{ width: 550, padding: '40px 60px' }}>
          <h1>Deseja excluir o modelo?</h1>
          <p>
            Ao excluir o modelo, ele não poderá mais ser visualizado, editado, impresso e baixado, deseja continuar com
            a ação?{' '}
          </p>
          <ButtonsModal>
            <Button className="btnNao" onClick={handleClose} variant="outlined">
              Não
            </Button>
            <Button className="btnSim" variant="contained" onClick={() => handleDelete(objDelete.type, objDelete.id)}>
              Sim
            </Button>
          </ButtonsModal>
        </Paper>
      </Modal>

      {exibirTable ? (
        <MaterialTable
          style={{ position: 'inherit', minHeight: '940px', height: '100%', overflowY: 'auto' }}
          tableRef={tableRef}
          key="table"
          title={exibirTitulo ? 'Meus projetos' : ''}
          columns={columns}
          actions={[
            {
              icon: () => <PrintOutlinedIcon style={{ color: '#3E4756' }} />,
              tooltip: 'Imprimir',
              position: 'row',
              onClick: (__, rowData) => {
                if (rowData.modelo == 'ModeloQRCode') {
                  setUrlQrcode({
                    title: rowData.nome,
                    url: rowData.url,
                  });
                } else if (rowData.modelo == 'ModeloGrafico') {
                  handleDownloadChart(rowData, 'PDF');
                } else {
                  if (rowData.html !== null) createElementFromHTML(rowData.html);
                }
                setDownloadFiles({ type: 'PDF', rowData });
              },
            },
            {
              icon: () => <SaveAltOutlinedIcon style={{ color: '#3E4756' }} />,
              position: 'row',
              tooltip: 'Download Imagem',
              onClick: (__, rowData) => {
                const data: IRow | any = rowData;
                if (data.modelo == 'ModeloQRCode') {
                  setUrlQrcode({
                    title: data.nome,
                    url: data.url,
                  });
                } else if (data.modelo == 'ModeloGrafico') {
                  handleDownloadChart(data, 'IMG');
                } else if (data.modelo == 'ModeloLinguagemSimples') {
                  downLoadText(data.json, data.descricao);
                } else {
                  if (rowData.html !== null) ref.current.innerHTML = data.html;
                }
                setDownloadFiles({ type: 'IMG', rowData });
              },
            },
            {
              icon: () => (
                <Tooltip
                  title="Imagem copiada"
                  open={openToolTipCopyImage}
                  onClose={() => setOpenToolTipCopyImage(false)}
                  leaveDelay={1500}
                >
                  <ContentCopyOutlinedIcon style={{ color: '#3E4756' }} />
                </Tooltip>
              ),
              position: 'row',
              tooltip: 'Copiar Imagem',
              onClick: (__, rowData) => {
                const data: IRow | any = rowData;
                if (data.modelo == 'ModeloQRCode') {
                  setUrlQrcode({
                    title: data.nome,
                    url: data.url,
                  });
                } else if (data.modelo == 'ModeloGrafico') {
                  handleDownloadChart(data, 'IMG');
                } else if (data.modelo == 'ModeloLinguagemSimples') {
                  copyText(data.json);
                } else {
                  if (rowData.html !== null) ref.current.innerHTML = data.html;
                }
                setCopyFiles({ type: 'IMG', rowData });
              },
            },
            {
              icon: () => <img src={EditIcon} />,
              tooltip: 'Editar',
              position: 'row',
              onClick: (__, rowData) => {
                const data: IRow | any = rowData;
                redirectEdit(data);
              },
            },
            {
              icon: () => <DeleteOutlinedIcon style={{ color: '#F1504D' }} />,
              tooltip: 'Excluir',
              position: 'row',
              onClick: (__, rowData) => {
                const data: IRow | any = rowData;
                handleOpen();
                setObjDelete({ id: data.tableData.id, type: data.modelo });
              },
            },
          ]}
          localization={localizationOptions}
          data={({ page, pageSize, search }) => {
            return new Promise((resolve, reject) => {
              getModelsByUserId(user?.tokenData?.ClientId, page, pageSize, search)
                .then((result: any) => {
                  if (result) {
                    setDados([...result.data.value]);
                    resolve({
                      data: result.data.value,
                      page: page,
                      totalCount: result.data['@odata.count'],
                    });
                  } else {
                    reject({
                      data: [],
                      page: page,
                      totalCount: 0,
                    });
                  }
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: page,
                    totalCount: 0,
                  });
                  if (err?.status === 401) {
                    showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
                  } else {
                    showAlertError(err?.response?.data || 'Ocorreu um erro ao buscar os meus projetos');
                  }
                });
            });
          }}
          options={{
            headerStyle: {
              padding: '20px',
            },
            rowStyle: {
              padding: 20,
            },
            filtering: false,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
            sorting: false,
            paging: exibirTitulo ? true : false,
          }}
        />
      ) : (
        <ContainerCore maxWidth="xl">
          {qtdRegistro > 0 && qtdRegistro > pageSizeListCard && (
            <Alert style={{ width: '100%', marginBottom: '16px' }} severity="info">
              Atenção, em modo grade só é visualizado os últimos <strong>{pageSizeListCard}</strong> registros!
            </Alert>
          )}
          <Grid container spacing={3}>
            {dados?.map((item) => (
              <ItemCardMeusModelos
                key={item.id}
                item={item}
                imprimirHandleClick={() => imprimirCardHandleClick(item)}
                downloadHandleClick={() => downloadCardHandleClick(item)}
                copiarImagemHandleClick={() => copiarCardImagemHandleClick(item)}
                editarImagemHandleClick={() => editarCardImagemHandleClick(item)}
                excluirImagemHandleClick={() => excluirCardImagemHandleClick(item)}
              />
            ))}
          </Grid>
        </ContainerCore>
      )}

      <div style={copyFiles || downloadFiles ? { marginTop: 250 } : { display: 'none' }}>
        <div ref={ref} id="renderTable" style={{ paddingBottom: 75 }}>
          {urlQrCode.url && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div
                style={{
                  padding: 20,
                  fontSize: 40,
                  display: 'flex',
                  textAlign: 'center',
                  minWidth: 400,
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <div className="qrcode-title">
                  <p>{urlQrCode.title}</p>
                </div>
                <div className="qrcode-position">
                  <QRCode value={urlQrCode.url} size={400} />
                </div>
              </div>
            </div>
          )}
          {chart?.data && (
            <div className="page">
              <h1 className="diagram-title">{chart?.titleText}</h1>
              <div>
                <Chart
                  horizontalText={chart?.horizontalText}
                  verticalText={chart?.verticalText}
                  diagramData={chart?.data}
                  diagramType={chart?.diagramType}
                  position={chart?.position}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default MeusModelos;
