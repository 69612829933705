import api from './api';

export const getDependentUsersById = async (id) => {
  try {
    const route = `odata/user?$filter=id eq (${id})`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getDependentUsers = async (ownerUserId, page, pageSize, search, filter) => {
  try {
    // prettier-ignore
    let route = `odata/client?$filter=id eq (${ownerUserId})&$count=true&$skip=${page * pageSize}&$top=${pageSize}&$expand=dependentUsers($expand=user;$select=user)&$select=dependentUsers`;

    if (filter.length > 0 && !search) {
      if (filter[0].field === 'user.name') {
        route = `odata/client?$filter=dependentUsers/any(u: u/user/name eq '${filter[0].value}')&$expand=dependentUsers($filter=user/name eq '${filter[0].value}';$expand=user)`;
      } else if (filter[0].field === 'user.email') {
        route = `odata/client?$filter=dependentUsers/any(u: u/user/email eq '${filter[0].value}')&$expand=dependentUsers($filter=user/email eq '${filter[0].value}';$expand=user)`;
      } else if (filter[0].field === 'user.active') {
        route = `odata/client?$filter=dependentUsers/any(u: u/user/active eq ${Boolean(
          Number(filter[0].value)
        )})&$expand=dependentUsers($filter=user/active eq ${Boolean(Number(filter[0].value))};$expand=user)`;
      }
    }

    if (search) {
      // eslint-disable-next-line prettier/prettier
      route = `odata/client?$filter=dependentUsers/any(u: u/user/name eq '${search}')&$expand=dependentUsers($filter=user/name eq '${search}';$expand=user)`;
    }
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const createDependentUser = async (values) => {
  let body = {
    ownerUserId: values.ownerUserId,
    id: values.clientId,
    user: {
      name: values.name,
      active: values.active,
      email: values.email,
      id: values.userId,
    },
  };

  try {
    return await api.post('Client/Dependent', body);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const editDependentUserById = async (values) => {
  try {
    return await api.put('user', values);
  } catch (error) {
    return Promise.reject(error.response);
  }
};
