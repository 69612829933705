import React, { useEffect, useRef, useState } from 'react';
import { Buttons, Container, Content, ModalButtons, TableContainer, Tables, TopContent, useStyles } from './styles';
import { ArrowBackIos } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@material-ui/core';
import ModalColors from '../../../components/ModalChooseColor';
import ModalOptions from '../../../components/ModalOptions';
import ConfigurationDiagram from './ConfigurationDiagram';
import ViewDiagram from './ViewDiagram';
import { createDiagram, getModelById, updateDiagram } from '../../../services/models';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import BarDiagram from '../../../assets/bar-diagram.svg';
import InlineDiagram from '../../../assets/inline-diagram.svg';
import Collapse from '@mui/material/Collapse';
import { DIAGRAMTYPE } from '../../../interfaces/diagram';
import { useAuth } from '../../../contexts/auth';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { getClientColors } from '../../../services/externalUsers';
import Split from 'react-split';
import { createImageFile } from '../../../helper/convertHTML';
const transparencyColor = 'CC';
const columnsBorderStyle: React.CSSProperties = { border: '1px solid #e6e6e6', borderCollapse: 'collapse' };

interface IModalColor {
  name: string;
  value?: string;
}

interface IModalDelete {
  attributeType: string;
  index?: number;
}

interface ITableCell {
  value: number;
  label: string;
  color?: string;
}

interface IDiagram {
  data: Array<ITableCell> | [];
  title: string;
  titleColor?: string;
  diagramSelect?: string;
}

const validationSchema = Yup.object({
  description: Yup.string().required('Identificação do modelo obrigatória.'),
  verticalDescription: Yup.string()
    .required('Descrição linha vertical obrigatória.')
    .max(500, 'Precisa ter no máximo 500 letras'),
  horizontalDescription: Yup.string()
    .required('Descrição linha horizontal é obrigatória.')
    .max(500, 'Precisa ter no máximo 500 letras'),
  title: Yup.string().max(500, 'Precisa ter no máximo 500 letras'),
  html: Yup.string(),
  json: Yup.string(),
});

const Diagram: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { user } = useAuth();
  const [firstColor, setFirstColor] = useState('');
  const [lastColor, setLastColor] = useState('');
  const [state, setState] = useState<IDiagram>({
    data: [],
    title: '',
    titleColor: '',
    diagramSelect: '',
  });
  const [isSaved, setIsSaved] = useState(false);
  const clientTitularId: string = user?.tokenData?.ClientId;
  const OwnerUserId: string = user?.tokenData?.OwnerUserId;
  const clientId: string = user?.tokenData?.ClientId;
  const [expandBar, setExpandBar] = useState(false);
  const [expandLine, setExpandLine] = useState(false);
  const [chooseDiagram, setChooseDiagram] = useState(true);
  const [controlCancelModal, setControlCancelModal] = useState(false);
  const handleOpenCancelModal = () => history.push('/novo-modelo');
  const handleCloseCancelModal = () => setControlCancelModal(false);

  const [temporaryPathName, setTemporaryPathName] = useState('');
  const [controlModalColor, setControlModalColor] = useState<IModalColor | null>(null);
  const [controlDeleteModal, setControlDeleteModal] = useState<IModalDelete | null>(null);
  const [idModel, setIdModel] = useState(history?.location?.state?.id);
  const [splitSizes, setSplitSizes] = useState([50, 50]);

  const formik = useFormik({
    initialValues: {
      id: idModel || null,
      description: '',
      verticalDescription: '',
      horizontalDescription: '',
      position: 2,
      format: '',
      title: '',
      html: '',
      json: '',
      clienteTitularId: clientId,
      htmlImage: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (ref.current) {
        values.html = ref.current.outerHTML;
        values.htmlImage = await createImageFile(document.getElementById('#view'));
      }

      let json = state;
      json = state;
      json.verticalDescription = values.verticalDescription;
      json.horizontalDescription = values.horizontalDescription;
      json.format = values.format;

      values.json = JSON.stringify(json);
      showLoading(true);
      if (values.id) {
        updateDiagram(values)
          .then((response) => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Grafico editado com sucesso.');
            setIdModel(values.id);
          })
          .catch((error) => {
            showLoading(false);
            if (error.status === 401) {
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            } else if (error.status === 412) {
              showAlertError('Atingido o limite de modelos do plano.');
            } else {
              showAlertError('Ocorreu um erro ao enviar os dados do grafico.');
            }
          });
      } else {
        values.id = uuidv4();
        createDiagram(values)
          .then((response) => {
            setIsSaved(true);
            showLoading(false);
            showAlertSuccess('Grafico criado com sucesso.');
            setIdModel(values.id);
          })
          .catch((error) => {
            showLoading(false);
            if (error.status === 401) {
              showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
            } else if (error.status === 412) {
              showAlertError('Atingido o limite de modelos do plano.');
            } else {
              showAlertError('Ocorreu um erro ao enviar os dados do grafico.');
            }
          });
      }
    },
  });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    showLoading(true);
    getClientColors(user?.tokenData?.UserId)
      .then((response: any) => {
        setFirstColor(response?.data?.value[0]?.color1);
        setLastColor(response?.data?.value[0]?.color1);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  useEffect(() => {
    setState({
      data: [{ label: '', value: null, color: firstColor }],
      title: '',
      titleColor: '',
      diagramSelect: '',
    });
  }, [firstColor]);

  useEffect(() => {
    // handle refresh page
    const beforeUnloadCallback = (event) => {
      if (!isSaved) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    if (!isSaved) {
      history.block((prompt) => {
        setTemporaryPathName(prompt.pathname);
        setControlCancelModal(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      history.block(() => {});
    };
  }, [history, isSaved]);

  const handleDiagramSelect = (value: string) => {
    setState((prevState) => ({ ...prevState, diagramSelect: value }));
    setIsSaved(false);
    setChooseDiagram(false);
    formik.setFieldValue('format', value);
  };

  const addNewItem = (index: number) => {
    setIsSaved(false);
    let auxData = [...state.data];
    auxData.splice(index + 1, 0, { label: '', value: null, color: lastColor });

    setState((prevState) => ({ ...prevState, data: auxData }));
    const timeOut = setTimeout(() => {
      document.getElementById(`itemLinha${index + 2}`)?.focus();
      clearTimeout(timeOut);
    }, 5);
  };

  const deleteItem = (index: number) => {
    setIsSaved(false);
    let aux = [...state.data];
    aux.splice(index, 1);
    setState((prevState) => ({ ...prevState, data: aux }));
  };

  const changeItemValue = (index, value: string) => {
    setIsSaved(false);
    let aux = [...state.data];
    aux[index].value = parseFloat(value);
    setState((prevState) => ({ ...prevState, data: aux }));
  };

  const changeItemLabel = (index: number, label: string) => {
    setIsSaved(false);
    let aux = [...state.data];
    aux[index].label = label;
    setState((prevState) => ({ ...prevState, data: aux }));
  };

  const changeItemColor = (index: string, color: string) => {
    setIsSaved(false);
    let aux = [...state.data];

    if (index === 'all') {
      aux.map((a) => {
        a.color = color;
      });
    } else {
      aux[Number(index)].color = color;
    }
    setState((prevState) => ({ ...prevState, data: aux }));
    setLastColor(color);
  };

  const onSaveModalOptions = () => {
    deleteItem(controlDeleteModal?.index);
    setControlDeleteModal(null);
  };

  const changeTableTitle = (value) => {
    setIsSaved(false);
    formik.setFieldValue('title', value);
  };

  const getDiagramById = async (modeloId: string) => {
    showLoading(true);
    const response = await getModelById('ModeloGrafico', OwnerUserId ? OwnerUserId : clientTitularId, modeloId);
    if (response.status === 200) {
      const dataDiagram = JSON.parse(response.data[0].json);
      setState(dataDiagram);

      let dataFormik = {
        id: response.data[0].id,
        verticalDescription: dataDiagram.verticalDescription,
        horizontalDescription: dataDiagram.horizontalDescription,
        format: dataDiagram.format,
        title: response.data[0].titulo,
        clienteTitularId: clientId,
        description: response.data[0].descricao,
        position: response.data[0].posicao,
      };
      handleDiagramSelect(dataDiagram.diagramSelect);
      formik.setValues(dataFormik);
      showLoading(false);
      setIsSaved(true);
    } else {
      showLoading(false);
      if (response.status === 401) {
        showAlertError('Uma nova sessão foi iniciada em outro dispositivo.');
      } else {
        showAlertError('Ocorreu um erro ao enviar os dados do qr code.');
      }
    }
  };
  useEffect(() => {
    if (idModel) {
      getDiagramById(idModel);
    }
  }, [idModel]);

  return (
    <Container elevation={1} style={{ width: '1200px !important' }}>
      <form onSubmit={formik.handleSubmit}>
        <ModalColors
          closeModal={() => setControlModalColor(null)}
          open={Boolean(controlModalColor)}
          changeColor={changeItemColor}
          selectedColor={controlModalColor}
        />
        <ModalOptions
          text={`Tem certeza que deseja excluir essa ${controlDeleteModal?.attributeType}?`}
          open={Boolean(controlDeleteModal)}
          onCancel={() => setControlDeleteModal(null)}
          onSave={onSaveModalOptions}
        />
        <ModalOptions
          text={`As alterações não foram salvas, deseja continuar?`}
          open={controlCancelModal}
          onCancel={handleCloseCancelModal}
          onSave={() => {
            history.block(() => {});
            history.push(temporaryPathName);
          }}
        />
        <Content>
          <TopContent>
            <div>
              <Link to={{ pathname: '/meus-modelos', search: 'NovoModelo' }}>
                <ArrowBackIos />
              </Link>
              <p>
                Modelo /{' '}
                <span className="color-text">
                  Gráfico {chooseDiagram ? '' : formik.values.format === 'bar' ? 'em barra' : 'em linha'}
                </span>
              </p>
            </div>
          </TopContent>
          <div className="inputs">
            <TextField
              label="Identificação do modelo de gráfico"
              className="description"
              variant="outlined"
              type="text"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
            <TextField
              label="Modelo"
              className="description"
              variant="outlined"
              type="text"
              name="description"
              value={'Gráfico'}
              disabled
            />
            <FormControl className="description" variant="outlined">
              <InputLabel id="posicao">Posição do gráfico</InputLabel>
              <Select
                labelId="posicao"
                id="position"
                value={formik.values.position}
                onChange={(e) => formik.setFieldValue('position', e.target.value)}
                label="Posição do gráfico"
                // disabled={state.diagramSelect === DIAGRAMTYPE.LINE}
              >
                <MenuItem value={1}>Horizontal</MenuItem>
                <MenuItem value={2}>Vertical</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Content>
        <br />
        {chooseDiagram ? (
          <Content>
            <div className="titleRow">
              <label className="titleLabel">Escolha o formato do gráfico</label>
            </div>
            <div className="cardsContainer">
              <Card
                className="card"
                onMouseOver={() => setExpandBar(true)}
                onMouseOut={() => setExpandBar(false)}
                onClick={() => handleDiagramSelect('bar')}
              >
                <CardActionArea>
                  <CardMedia component="img" height="140" image={BarDiagram} alt="diagram" />
                  <CardContent>
                    <div className="titleRow">
                      <label className="titleDiagram">Gráfico em barra</label>
                    </div>
                    <Collapse in={expandBar} timeout="auto" unmountOnExit>
                      <div className="descriptionCard">
                        Quando a intenção é apenas comparar dados, gráficos de barra são mais eficientes.
                      </div>
                    </Collapse>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="card"
                onMouseOver={() => setExpandLine(true)}
                onMouseOut={() => setExpandLine(false)}
                onClick={() => handleDiagramSelect('line')}
              >
                <CardActionArea>
                  <CardMedia component="img" height="140" image={InlineDiagram} alt="diagram" />
                  <CardContent>
                    <div className="titleRow">
                      <label className="titleDiagram">Gráfico em linha</label>
                    </div>
                    <Collapse in={expandLine} timeout="auto" unmountOnExit>
                      <div className="descriptionCard">
                        Tendências ou evoluções são mais frequentemente representadas por gráficos de linha.
                      </div>
                    </Collapse>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </Content>
        ) : (
          <div>
            <Tables>
              <Split
                sizes={splitSizes}
                minSize={[500, 1000]}
                maxSize={[850, Infinity]}
                direction="horizontal"
                style={{ display: 'flex', width: '100%' }}
                cursor="col-resize"
                gutterSize={5}
                onDragEnd={(sizes) => {
                  setSplitSizes(sizes);
                }}
              >
                <TableContainer>
                  <div style={{ maxWidth: '800px' }}>
                    <ConfigurationDiagram
                      {...{
                        formik,
                        addNewItem,
                        changeItemLabel,
                        changeItemValue,
                        columnsBorderStyle,
                        setControlDeleteModal,
                        setControlModalColor,
                        data: state.data,
                        changeTableTitle,
                        setChooseDiagram,
                        position: formik.values.position,
                        setIsSaved,
                        diagramSelect: state.diagramSelect,
                      }}
                    />
                  </div>
                </TableContainer>
                <TableContainer length={state.data.length}>
                  <ViewDiagram
                    {...{
                      id: formik.values.id,
                      isSaved,
                      fileName: formik.values.description,
                      titleColor: state.titleColor,
                      titleText: formik.values.title,
                      verticalText: formik.values.verticalDescription,
                      horizontalText: formik.values.horizontalDescription,
                      refView: ref,
                      columnsBorderStyle,
                      data: state.data,
                      diagramSelect: state.diagramSelect,
                      position: formik.values.position,
                    }}
                  />
                </TableContainer>
              </Split>
            </Tables>

            <Buttons>
              <Button className={classes.buttonRed} variant="outlined" onClick={handleOpenCancelModal}>
                Cancelar
              </Button>
              <Button
                className={classes.buttonGreen}
                variant="contained"
                type="submit"
                onClick={() => {
                  if (!isEmpty(formik.errors))
                    showAlertError(
                      'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.'
                    );
                }}
              >
                Salvar
              </Button>
            </Buttons>
          </div>
        )}
      </form>
    </Container>
  );
};

export default Diagram;
