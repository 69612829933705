/* eslint-disable prettier/prettier */
import api from './api';
import { v4 as uuidv4 } from 'uuid';

export const getPlans = async (page, pageSize, search, filter) => {
  try {
    // prettier-ignore
    let route = `odata/plan?$expand=recurrencePlans($orderby=recurrenceType/Value;$expand=recurrenceType)`;
    const response = await api.get(route);

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMonthlyPlans = async (page, pageSize, search, filter) => {
  try {
    // prettier-ignore
    let route = `odata/plan?${page || pageSize ? `&$count=true&$skip=${page * pageSize}&$top=${pageSize}&$expand=recurrencePlans($filter=recurrenceType/Value eq 'Mensal';$expand=recurrenceType)` : ''}`;
    if (filter?.length > 0 && !search) {
      route += `&$filter=`;
      filter.map((f, index, array) => {
        if (f.field === 'status' || f.field.includes('Date')) {
          if (f.value === ' ') {
            route += `${f.field} ne null`;
          } else {
            route += `${f.field} eq ${f.field === 'status' ? Boolean(Number(f.value)) : f.value} `;
          }
        } else if (f.field === 'monthlyPrice') {
          route = `odata/plan?$filter=recurrencePlans/any(c: c/price eq ${f.value})&$expand=recurrencePlans($filter=recurrenceType/Value eq 'Mensal';$expand=recurrenceType)`;
        } else {
          route += `contains(${f.field.replace('.', '/')},'${f.value}')`;
        }
        if (index !== array.length - 1) {
          route += 'and ';
        }
      });
    }

    if (search) {
      // eslint-disable-next-line prettier/prettier
      route += `&$filter=contains(name, '${search}')${
        !isNaN(Number(search)) ? ` or monthlyPrice eq ${Number(search)}` : ''
      } ${
        'ATIVO'.includes(search.toUpperCase())
          ? `or status eq ${true}`
          : 'INATIVO'.includes(search.toUpperCase())
          ? `or status eq ${false}`
          : ''
      }`;
    }
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPlanById = async (id) => {
  try {
    const route = `odata/plan?$filter=id eq (${id})&$expand=recurrencePlans`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getClientPlan = async (id, period) => {
  try {
    const route = `odata/plan?$filter=id eq (${id})&$expand=recurrencePlans($filter=contains(recurrenceType/value , '${period}');$expand=recurrenceType)`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getClientNewPlan = async (id, period) => {
  try {
    const route = `odata/plan?$filter=id eq (${id})&$expand=recurrencePlans($filter=recurrenceTypeId eq (${period});$expand=recurrenceType)`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const updateOrCreateNewPlan = async (values, recTypes) => {
  let id;
  let body;

  if (values.id) {
    id = values.id;
  } else {
    id = uuidv4();
  }

  if (values.id != null) {
    body = {
      id: id,
      name: values.name,
      initialDate: values.initialDate,
      endDate: values.endDate,
      undetermined: values.undetermined,
      userLimit: values.userLimit,
      userIlimited: values.userIlimited,
      modelLimit: values.modelLimit,
      modelIlimited: values.modelIlimited,
      automaticRenewal: values.automaticRenewal,
      trialDays: values.trialDays,
      status: values.status,
      readjustmentValue: values.readjustmentValue,
      readjustmentDays: values.readjustmentDays,
      pagarMEPlanId: values.pagarMEPlanId, 

      recurrencePlans: [
        {
          id: values.recurrencePlans[2]?.id,
          planId: values.id,
          recurrenceTypeId: recTypes[2]?.id,
          price: values.recurrencePlans[1]?.price == null ? 0 : values.recurrencePlans[1]?.price,
        },
        {
          id: values.recurrencePlans[0]?.id,
          planId: values.id,
          recurrenceTypeId: recTypes[0]?.id,
          price: values.recurrencePlans[2]?.price == null ? 0 : values.recurrencePlans[2]?.price,
        },
        {
          id: values.recurrencePlans[1]?.id,
          planId: values.id,
          recurrenceTypeId: recTypes[1]?.id,
          price: values.recurrencePlans[0]?.price == null ? 0 : values.recurrencePlans[0]?.price,
        },
      ],
    };
  } else {
    body = {
      id: id,
      name: values.name,
      initialDate: values.initialDate,
      endDate: values.endDate,
      undetermined: values.undetermined,
      userLimit: values.userLimit,
      userIlimited: values.userIlimited,
      modelLimit: values.modelLimit,
      modelIlimited: values.modelIlimited,
      automaticRenewal: values.automaticRenewal,
      trialDays: values.trialDays,
      status: values.status,
      readjustmentValue: values.readjustmentValue,
      readjustmentDays: values.readjustmentDays,
      pagarMEPlanId: values.pagarMEPlanId, 

      recurrencePlans: [
        {
          id: uuidv4(),
          planId: id,
          recurrenceTypeId: recTypes[2]?.id,
          price: values.recurrencePlans[1].price == null ? 0 : values.recurrencePlans[1].price,
        },
        {
          id: uuidv4(),
          planId: id,
          recurrenceTypeId: recTypes[0]?.id,
          price: values.recurrencePlans[2].price == null ? 0 : values.recurrencePlans[2].price,
        },
        {
          id: uuidv4(),
          planId: id,
          recurrenceTypeId: recTypes[1]?.id,
          price: values.recurrencePlans[0].price == null ? 0 : values.recurrencePlans[0].price,
        },
      ],
    };
  }

  try {
    return values.id ? await api.put('plan', body) : await api.post('plan', body);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPlanMonthlyById = async (id) => {
  try {
    const route = `odata/plan?$filter=id eq (${id})&$expand=recurrencePlans($filter=contains(recurrenceType/value , 'Mensal'))`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getPlanQuarterlyById = async (id) => {
  try {
    const route = `odata/plan?$filter=id eq (${id})&$expand=recurrencePlans($filter=contains(recurrenceType/value , 'Trimestral'))`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getPlanYearlyById = async (id) => {
  try {
    const route = `odata/plan?$filter=id eq (${id})&$expand=recurrencePlans($filter=contains(recurrenceType/value , 'Anual'))`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};
