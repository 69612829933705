import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { Buttons, ColorsContent, ColorsTitle, Content, TopContent, useStyles } from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ModalColors from '../../../components/ModalChooseColor';
import { isValid } from 'date-fns';
import ReactInputMask from 'react-input-mask';
import Modal from '@mui/material/Modal';
import { get } from 'lodash';
import { useFormik } from 'formik';
import { editExternalUserById, getExternalUserById } from '../../../services/externalUsers';
import { clientInitialValues, clientValidationSchema } from './validation';
import { useLoading } from '../../../contexts/loading';
import { useAlert } from '../../../contexts/alert';
import { useAuth } from '../../../contexts/auth';
import { isEmpty } from 'lodash';
import Terms from '../../../components/TermsOrPolicies/Terms';
import Policies from '../../../components/TermsOrPolicies/Policies';
import Tooltip from '@mui/material/Tooltip';

const PersonalData: React.FC = ({ genders, ufs, occupationAreas }: any) => {
  const history = useHistory();
  const [showTermsOrPolicies, setShowTermsOrPolicies] = useState<string | boolean>('');
  const [openModalColors, setOpenModalColors] = useState<boolean>(false);
  const { showLoading } = useLoading();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { user } = useAuth();
  const classes = useStyles();
  const [selectedColor, setSelectedColor] = useState({
    name: '',
    value: '',
  });

  useEffect(() => {
    getClient();
  }, []);

  const formik = useFormik({
    initialValues: clientInitialValues,
    validationSchema: clientValidationSchema,
    onSubmit: () => {
      let body = {
        birthDate: formik.values.birthDate,
        genderId: formik.values.genderId,
        phone: formik.values.phone,
        oab: formik.values.oab,
        ufId: formik.values.uf,
        occupationAreaId: formik.values.occupation,
        pagarMECustomerId: formik.values.pagarMECustomerId,
        company: formik.values.company,
        color1: formik.values.colors.firstColor,
        color2: formik.values.colors.secondColor,
        color3: formik.values.colors.thirdColor,
        color4: formik.values.colors.fourthColor,
        color5: formik.values.colors.fifthColor,
        userId: formik.values.userId,
        ownerUserId: null,
        pagarMECustomerId: formik.values.pagarMECustomerId,
        id: formik.values.id,
        cep: formik.values.ownerCEP,
        uf: formik.values.ownerUF,
        cidade: formik.values.ownerCity,
        endereco: formik.values.ownerAddress,
        user: {
          id: formik.values.userId,
          email: formik.values.email,
          name: formik.values.name,
          active: formik.values.active,
          lastName: formik.values.lastName,
          roleId: formik.values.roleId,
          marketPlaceId: formik.values.marketPlaceId,
        },
      };
      showLoading(true);
      editExternalUserById(body)
        .then((response) => {
          showLoading(false);
          showAlertSuccess(
            'Alteração efetuada com sucesso. Favor efetuar o login novamente para atualizar o ambiente com as novas atualizações.'
          );
        })
        .catch((error) => {
          showLoading(false);
          showAlertError('Ocorreu um erro ao tentar atualizar os dados');
        });
    },
  });

  const changeColors = (attribute: string, color: string) => {
    formik.setFieldValue(`colors.${attribute}`, color);
  };

  const changeSelectedColor = (name: string, value: string) => {
    setOpenModalColors(true);
    setSelectedColor({ name, value });
  };

  function handleDate(name: string, value: Date) {
    let dateNow = new Date();
    if (isValid(value)) {
      if (value < dateNow) {
        let date = new Date(new Date(value).setHours(0, 0, 0)).toISOString();
        formik.setFieldValue(name, date);
      } else {
        formik.setFieldValue(name, null);
      }
    }
  }

  const getClient = () => {
    showLoading(true);
    getExternalUserById(user?.tokenData?.ClientId)
      .then((response) => {
        let dataFormik: any = {
          id: user?.tokenData?.ClientId,
          birthDate: response.data.value[0].birthDate ? new Date(response.data.value[0].birthDate) : null,
          genderId: response.data.value[0]?.genderId,
          phone: response.data.value[0].phone,
          oab: response.data.value[0].oab,
          uf: response.data.value[0].uf?.id,
          occupation: response.data.value[0]?.occupationAreaId,
          pagarMECustomerId: response.data.value[0]?.pagarMECustomerId,
          company: response.data.value[0]?.company,
          ownerUserId: null,
          name: response?.data?.value[0]?.user?.name,
          lastName: response?.data?.value[0]?.user?.lastName,
          active: response.data.value[0].user?.active,
          email: response.data.value[0].user?.email,
          roleId: response.data.value[0].user?.roleId,
          marketPlaceId: response.data.value[0].user?.marketPlaceId,
          colors: {
            firstColor: response.data.value[0]?.color1,
            secondColor: response.data.value[0]?.color2,
            thirdColor: response.data.value[0]?.color3,
            fourthColor: response.data.value[0]?.color4,
            fifthColor: response.data.value[0]?.color5,
          },
          userId: response.data.value[0].userId,
        };

        formik.setValues(dataFormik);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  return (
    <>
      <ModalColors
        closeModal={() => setOpenModalColors(false)}
        open={openModalColors}
        changeColor={changeColors}
        selectedColor={selectedColor}
      />
      <Modal
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        open={Boolean(showTermsOrPolicies)}
        onClose={() => setShowTermsOrPolicies(false)}
      >
        <Paper
          elevation={1}
          style={{ width: 800, padding: '40px 60px', height: '90%', maxWidth: '100vw', overflow: 'auto' }}
        >
          <h1> {showTermsOrPolicies === 'terms' ? 'Termos de uso' : 'Política de Privacidade'}</h1>
          <>{showTermsOrPolicies === 'terms' ? <Terms /> : <Policies />}</>
        </Paper>
      </Modal>
      <div>
        <TopContent>
          <p
            onClick={() => {
              history.push('/alterar-senha');
            }}
          >
            <LockOutlinedIcon /> Alterar senha
          </p>
        </TopContent>
        <form onSubmit={formik.handleSubmit}>
          <Content>
            <div className="inputs">
              <TextField
                label="Nome"
                InputLabelProps={{ required: true }}
                className="name"
                variant="outlined"
                id="name"
                name="name"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Sobrenome"
                  InputLabelProps={{ required: true }}
                  className="lastName"
                  variant="outlined"
                  id="lastName"
                  name="lastName"
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </FormControl>
              <FormControl className="date" variant="outlined" fullWidth>
                <DatePicker
                  autoOk
                  label="Data de nascimento"
                  value={formik.values.birthDate == null ? null : new Date(formik.values.birthDate)}
                  onChange={(value: Date) => handleDate('birthDate', value)}
                  maxDate={new Date()}
                  InputLabelProps={{ required: true }}
                  disableFuture
                  inputVariant="outlined"
                  variant="inline"
                  error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                  helperText={formik.touched.birthDate && formik.errors.birthDate}
                  format="dd/MM/yyyy"
                />
              </FormControl>
              <FormControl className="genderId" variant="outlined" fullWidth>
                <InputLabel id="seccional">Gênero</InputLabel>
                <Select
                  label="Gênero"
                  id="genderId"
                  name="genderId"
                  value={formik.values.genderId}
                  onChange={formik.handleChange}
                >
                  {genders?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="E-mail"
                InputLabelProps={{ required: true }}
                className="email"
                variant="outlined"
                type="text"
                id="email"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <ReactInputMask mask="(99) 99999-9999" value={formik.values.phone} onChange={formik.handleChange}>
                {() => (
                  <TextField
                    fullWidth
                    id="phone"
                    InputLabelProps={{ required: true }}
                    name="phone"
                    label="Telefone"
                    variant="outlined"
                    className="phone"
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                )}
              </ReactInputMask>
              <TextField
                label="OAB"
                className="oab"
                variant="outlined"
                type="text"
                id="oab"
                inputProps={{
                  maxLength: 7,
                }}
                name="oab"
                fullWidth
                value={formik.values.oab}
                onChange={formik.handleChange}
                error={formik.touched.oab && Boolean(formik.errors.oab)}
                helperText={formik.touched.oab && formik.errors.oab}
              />
              <FormControl fullWidth className="seccional" variant="outlined">
                <InputLabel id="seccional">Seccional</InputLabel>
                <Select
                  labelId="Seccional"
                  label="Seccional"
                  id="uf"
                  name="uf"
                  value={formik.values.uf}
                  onChange={formik.handleChange}
                  error={formik.touched.uf && Boolean(formik.errors.uf)}
                >
                  {ufs &&
                    ufs.map((uf) => {
                      return (
                        <MenuItem key={uf.id} value={uf.id}>
                          {uf.state} ({uf.initials})
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText error>{formik.touched.uf && formik.errors.uf}</FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                error={formik.touched.occupation && Boolean(formik.errors.occupation)}
              >
                <InputLabel id="occupation">Área de atuação</InputLabel>
                <Select
                  name="occupation"
                  labelId="occupation"
                  id="occupation"
                  label="Área de atuação"
                  value={formik.values.occupation}
                  onChange={formik.handleChange}
                >
                  {occupationAreas &&
                    occupationAreas.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          <em>{item.value}</em>
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText error>{formik.touched.occupation && formik.errors.occupation}</FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                id="company"
                name="company"
                label="Escritório"
                variant="outlined"
                type="text"
                value={formik.values.company}
                onChange={formik.handleChange}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
              />
              <div className="terms-and-policies">
                <a href="#" onClick={() => setShowTermsOrPolicies('terms')}>
                  Termos de Uso
                </a>{' '}
                e{' '}
                <a href="#" onClick={() => setShowTermsOrPolicies('policies')}>
                  Política de Privacidade.
                </a>
              </div>
            </div>
          </Content>
          <ColorsTitle>Escolha de 3 a 5 cores em ordem de importância para os modelos:</ColorsTitle>
          <ColorsContent>
            {Object.keys(formik.values.colors).map(function (key) {
              return (
                <div
                  key={key}
                  onClick={() => changeSelectedColor(key, get(formik.values.colors, key))}
                  style={{ background: `${get(formik.values.colors, key)}` }}
                >
                  <Tooltip title="Escolha sua cor" arrow>
                    <a>{!get(formik.values.colors, key) && <AddCircleOutlineIcon />}</a>
                  </Tooltip>
                </div>
              );
            })}
          </ColorsContent>
          <Buttons>
            <Button
              className={classes.buttonRed}
              variant="outlined"
              onClick={() => {
                //history.push('/novo-modelo');
                history.push('/');
              }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonGreen}
              variant="contained"
              type="submit"
              onClick={() => {
                if (!isEmpty(formik.errors))
                  showAlertError(
                    'Há campos preenchidos incorretamente, por favor verifique os campos e tente novamente.'
                  );
              }}
            >
              Salvar
            </Button>
          </Buttons>
        </form>
      </div>
    </>
  );
};

export default PersonalData;
