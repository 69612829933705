import api from './api';
export const getInternalUsers = async (page, pageSize, search, filter) => {
  try {
    // prettier-ignore
    let route = `odata/user?$orderby=Name asc&$count=true&$skip=${page * pageSize}&$top=${pageSize}&$expand=role&$filter=internalUser eq true`;
    if (filter.length > 0 && !search) {
      route += ` and `;
      filter.map((f, index, array) => {
        if (f.field === 'active') {
          if (f.value === ' ') {
            route += `${f.field} ne null`;
          } else {
            route += `${f.field} eq ${Boolean(Number(f.value))} `;
          }
        } else {
          route += `contains(${f.field.replace('.', '/')},'${f.value}')`;
        }
        if (index !== array.length - 1) {
          route += 'and ';
        }
      });
    }

    if (search) {
      // eslint-disable-next-line prettier/prettier
      route += ` and contains(name, '${search}') or contains(email, '${search}') or contains(role/name, '${search}') ${
        search.toUpperCase() === 'ATIVO'
          ? `or active eq ${true}`
          : search.toUpperCase() === 'INATIVO'
          ? `or active eq ${false}`
          : ''
      }`;
    }
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getInternalUserById = async (id) => {
  try {
    const route = `odata/user?$filter=id eq (${id})`;
    return await api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const createInternalUser = async (values) => {
  try {
    return await api.post('user', values);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const editInternalUserById = async (values, id) => {
  let body;

  if (id) {
    body = {
      id: id,
      name: values.name,
      email: values.email,
      roleId: values.roleId,
    };
  } else {
    body = values;
  }

  try {
    return await api.put('user', body);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getAccessProfiles = (page, pageSize, search, filter) => {
  try {
    // prettier-ignore
    let route = `odata/role?$orderby=Name asc${page || pageSize ? `&$count=true&$skip=${page * pageSize}&$top=${pageSize}` : ''}`;

    if (filter && filter.length > 0 && !search) {
      route += `&$filter=`;
      filter.map((f, index, array) => {
        if (f.field === 'active') {
          if (f.value === ' ') {
            route += `${f.field} ne null`;
          } else {
            route += `${f.field} eq ${Boolean(Number(f.value))} `;
          }
        } else {
          route += `contains(${f.field.replace('.', '/')}, '${f.value}')`;
        }
        if (index !== array.length - 1) {
          route += 'and ';
        }
      });
    }
    if (search) {
      // eslint-disable-next-line prettier/prettier
      route += `&$filter=contains(name, '${search}') ${
        search.toUpperCase() === 'ATIVO'
          ? `or active eq ${true}`
          : search.toUpperCase() === 'INATIVO'
          ? `or active eq ${false}`
          : ''
      }`;
    }
    return api.get(route);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getAccessProfileById = (id) => {
  try {
    const route = `odata/role?$expand=RolePermissions($expand=Permission)&$filter=id eq(${id})`;
    return api.get(route);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendAccessUserProfile = async (profile) => {
  try {
    const route = `role`;
    delete profile.rolePermissions;
    return await api.post(route, profile);
  } catch (error) {
    return Promise.reject(error.response || error.response[0].ErrorMessage || error);
  }
};

export const updateAccessUserProfileById = async (profile) => {
  let body = {
    id: profile.id,
    name: profile.name,
    active: profile.active,
    permissionIds: profile.permissionIds,
  };

  try {
    return await api.put('Role', body);
  } catch (error) {
    return Promise.reject(error.response);
  }
};
