import React from 'react';
import Modal from '@mui/material/Modal';
import { ContentModal } from './styles';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@material-ui/core/TextField/TextField';
import { Spacing } from '@material-ui/core/styles/createSpacing';

interface IProps {
  open: boolean;
  height: string;
  width: string;
  onCancel: () => void;
  onSave: () => void;
  showActions?: boolean;
}

const ModalRowColSettings = ({
  open,
  height,
  width,
  onCancel,
  onSave,
  showActions = true,
}: IProps): React.ReactElement => {
  return (
    <>
      <Modal open={open} onClose={onCancel} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ContentModal>
          {!showActions && (
            <span style={{ alignSelf: 'end', cursor: 'pointer' }} onClick={onCancel}>
              <CloseIcon />
            </span>
          )}
          <p>Configuração das medidas para a linha do tempo:</p>
          {showActions && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                height: '50px',
              }}
            >
              <div className="ContentModal">
                <TextField id="widthTimeLine" label="Largura" variant="outlined" defaultValue={width} />
                <TextField
                  id="heightTimeLine"
                  label="Altura"
                  variant="outlined"
                  defaultValue={height}
                  style={{ marginLeft: 10 }}
                />
              </div>
              <div className="ContentModal">
                <Button
                  variant="outlined"
                  onClick={onCancel}
                  style={{ color: '#3FCED2', borderColor: '#3FCED2', marginLeft: 10, height: 55 }}
                >
                  Cancelar
                </Button>
                <Button
                  style={{ marginLeft: 10, backgroundColor: '#3FCED2', height: 55 }}
                  variant="contained"
                  onClick={onSave}
                >
                  Prosseguir
                </Button>
              </div>
            </div>
          )}
        </ContentModal>
      </Modal>
    </>
  );
};

export default React.memo(ModalRowColSettings);
