import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid #e6e6e6;

  .table-title {
    padding: 12px;
    height: 80px;
    width: 360px;
    font-size: 24px;
    font-weight: 400;
    overflow-y: hidden;
  }

  td,
  tr {
    border: 1px solid #e6e6e6;
  }

  tr {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  td {
    border-left: none;
    border-bottom: none;
  }
  table {
    border: 1px solid #cccccc;
    border-top: none;
    border-collapse: collapse;
    padding: 20px;
    border-left: none;
    border-right: none;

    .timeline-title {
      max-width: 390px;
      padding: 20px;
    }
  }
  .info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    margin: 0;
    p {
      font-weight: bold;
      font-size: 18px;
      margin: 0;
    }

    > div {
      padding: 20px;
      width: 100%;
    }

    div:first-child {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  > div + div {
    overflow-x: auto;
  }
`;
