import React, { useEffect, useState } from 'react';
import { Container, Insert } from './styles';
import { TextField } from '@material-ui/core';

interface IProps {
  text: string;
  setText: (s: string) => void;
}

function IAView({ text, setText }: IProps): React.ReactElement {
  return (
    <Container id="#view">
      <TextField
        fullWidth
        label="Resumo Jurídico gerado a partir do texto carregado"
        InputLabelProps={{ shrink: true }}
        InputProps={{ readOnly: true }}
        value={text}
        variant="outlined"
        id="inputTextTransformed"
        name="inputTextTransformed"
        multiline
        rows={20}
        onChange={(event) => setText(event.target.value)}
        //error={formik.touched.textoBase && Boolean(formik.errors.textoBase)}
        //helperText={formik.touched.textoBase && formik.errors.textoBase}
      />
    </Container>
  );
}

export default IAView;
