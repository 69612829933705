import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const Container = styled.div`
  .MuiFormControl-root {
    height: 100%;
  }

  .MuiTypography-h6 {
    font-size: 30px;
    color: #3e4756;
    font-family: 'Mosk Semi Bold';
  }

  .MuiToolbar-root {
    padding-left: 20px;
  }

  .MuiTable-root,
  .MuiInputBase-root,
  .MuiTableCell-head {
    color: #3e4756;
    font-size: 16px;
  }
`;

export const GreenText = styled.span`
  color: #3fced2;
`;

export const RedText = styled.span`
  color: #ff5f5d;
`;

export const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    fontWeight: 400,
    backgroundColor: '#3FCED2',
    '&:hover': {
      backgroundColor: '#3FCED2',
    },
  },
  svg: {
    marginRight: 8,
  },
});
